import React from "react";
import { connect } from "react-redux";
import { NumberFormat } from "../../../lib/App/common/ValueFormatter";
import Translate from "../../../lib/App/common/translator/components/translate";

const StickyNotePrice = props => {
  const konfigurationV = props.konfigurationV || {};

  const totalNum = (
    <strong>
      <NumberFormat value={konfigurationV.totalMonthly || 0} /> CHF/m
      <sup>2</sup>
    </strong>
  );
  const translateTotal = (
    <Translate id={"StickyNote/Price/total"} data={{ total: totalNum }}>
      {"Das Total beträgt ${total}"}
    </Translate>
  );

  return (
    <div
      id="bk-search-note-sticky-wrapper"
      className="sticky-wrapper"
      style={{ height: "60px" }}
    >
      <ul id="bk-search-note" className="group" style={{ bottom: "2vh" }}>
        <li>
          <p>
            <i
              className="fas fa-calculator fa-w-14 fa-lg"
              style={{ maxHeight: "50px" }}
            />
            {translateTotal}
          </p>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    konfigurationV: state.konfiguration.currentV
  };
};

export default connect(mapStateToProps)(StickyNotePrice);
