import React, { Component } from "react";
import PropTypes from "prop-types";
import { withGetScreen } from "react-getscreen";
import { PICS_URL } from "../../lib/App/common/config";

class Image extends Component {
  constructor(props, context) {
    super(props, context);

    this.imageRef = null;
  }

  render() {
    if (this.props.src !== undefined) {
      const ratio =
        this.props.ratio !== undefined
          ? this.props.ratio
          : getDefaultRatio(this.props.src);
      const dim = getOneDimension(this.props);
      return (
        <img
          style={this.props.style}
          ref={node => (this.imageRef = node)}
          src={
            PICS_URL +
            "/" +
            dim +
            "/" +
            ratio +
            "/" +
            this.props.src +
            "." +
            this.props.type
          }
          className={this.props.className}
          alt={this.props.alt}
        />
      );
    } else {
      return (
        <img
          style={this.props.style}
          src="//:0"
          className={this.props.className}
          alt="not set"
        />
      );
    }
  }
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  tabletHeight: PropTypes.string,
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  tabletWidth: PropTypes.string,
  ratio: PropTypes.string,
  type: PropTypes.string
};

Image.defaultProps = {
  alt: "not set",
  height: "400p",
  mobileHeight: "400p",
  tabletHeight: "400p",
  width: "400w",
  type: "jpg",
  className: ""
};

const withGetScreenOptions = {
  mobileLimit: 770,
  tabletLimit: 1025,
  shouldListenOnResize: true
};

export default withGetScreen(Image, withGetScreenOptions);

function getOneDimension(props) {
  if (props.type === "svg") {
    return "raw";
  }

  const [mobile, tablet, desktop] =
    props.width !== undefined &&
    props.tabletWidth !== undefined &&
    props.mobileWidth !== undefined
      ? [props.mobileWidth, props.tabletWidth, props.width]
      : [props.mobileHeight, props.tabletHeight, props.height];

  if (props.isMobile()) {
    return mobile;
  }

  if (props.isTablet()) {
    return tablet;
  }

  return desktop;
}

function getDefaultRatio(src) {
  if (src.indexOf("variationen") !== -1) {
    return "r1.20";
  }

  if (src.indexOf("renderings") !== -1) {
    return "r3.125";
  }

  if (src.indexOf("grundrisse") !== -1) {
    return "r1.20";
  }
}
