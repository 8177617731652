import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  PasswortWiederherstellungForm_,
  mapStateToProps,
  mapDispatchToProps
} from "../../lib/User/components/PasswortWiederherstellung";
import SubHeader from "../../App/common/Header/SubHeader";
import { InputLeonardo } from "../../App/common/Form/Form";
import Translate from "../../lib/App/common/translator/components/translate";

const PasswortWiederherstellungLeonardo = props => {
  return (
    <Fragment>
      <SubHeader
        title="Passwort vergessen?"
        description="Bitte geben Sie Ihre E-Mail Adresse ein. Sie erhalten anschliessend eine E-Mail mit einem Link. Klicken Sie auf diesen Link um das Passwort neu zu setzen."
        translateId={"PasswortWiederherstellung/"}
      />
      <section className="group bk-first">
        <PasswortWiederherstellungFormLeonardo history={props.history} />
      </section>
    </Fragment>
  );
};

export default PasswortWiederherstellungLeonardo;

export class PasswortWiederherstellungFormLeonardo_ extends PasswortWiederherstellungForm_ {
  getError() {
    const translate = (
      <Translate id="PasswortWiederherstellung/error">
        Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben...
      </Translate>
    );
    return this.props.feedback.passwordRecovery === false ? (
      <div className="error">
        <i className="fas fa-bell" /> {translate}
      </div>
    ) : null;
  }

  getSuccessOrForm() {
    const translate = (
      <Translate id="PasswortWiederherstellung/success">
        Sie erhalten in Kürze eine E-Mail mit einem Link. Klicken Sie auf diesen
        Link um das Passwort neu zu setzen.
      </Translate>
    );
    return this.props.feedback.passwordRecovery === true ? (
      <div className="success">
        <i className="far fa-check-circle" /> {translate}
      </div>
    ) : (
      this.getForm()
    );
  }

  getForm() {
    return (
      <div className="columns large-12 medium-12 small-12">
        <div className="group">
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("email", isValid)}
              getValue={value => this.updateValue("email", value)}
              showError={this.state.form.showError}
              id="passwordRecoveryEmail"
              displayName="E-Mail"
              type="email"
              title="Bitte geben Sie eine gültige E-Mail Adresse ein"
              translateId={"PasswortWiederherstellung/"}
              required
            />
            <li className="bk-submit">
              <button className="btn btn-inverted" onClick={this.handleSubmit}>
                <Translate id={"PasswortWiederherstellung/abschicken"}>
                  Passwort Zurücksetzen
                </Translate>
              </button>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  render() {
    const error = this.getError();
    const successOrForm = this.getSuccessOrForm();

    return (
      <form className="group" noValidate>
        <fieldset className="group row">
          {error}
          {successOrForm}
        </fieldset>
      </form>
    );
  }
}

const PasswortWiederherstellungFormLeonardo = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswortWiederherstellungFormLeonardo_);
