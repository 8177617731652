import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as wohnungsgrundrissActions from "../lib/App/common/wohnungsgrundriss/action";
import * as konfigurationActions from "../lib/App/common/konfigurator/action";
import * as wohnungListActions from "../lib/WohnungList/action";
import * as optionenActions from "../lib/App/common/optionen/action";
import { WohnungList } from "../lib/WohnungList";

export class KonfiguratorStartOne extends WohnungList {
  constructor(props, context) {
    super(props, context);

    this.state = {
      buero: null
    };
  }

  componentDidMount() {
    this.props.optionenActions.fetchStaticVariations("buerolist");
    this.props.wohnungListActions.fetchList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { list } = this.props;
    const bueroId = Number(this.props.match.params.bueroId);
    if (list.length) {
      const buero = list.find(b => b.defaultWohnungsgrundrissId === bueroId);
      if (buero && buero !== prevState.buero) {
        this.setState({ buero });
        this.handleKonfigurationStart(buero);
      } else if (!buero) {
        this.props.history.push("/fehler");
      }
    }
  }

  handleKonfigurationStart(buero) {
    const fetchNewParams = {
      wohnungsgrundrissId: buero.defaultWohnungsgrundrissId,
      nextPage: konfigurationVId =>
        "/konfigurator/" + konfigurationVId + "/zusatzoptionen",
      variationIds: [],
      numbers: []
    };

    this.props.konfigurationActions.fetchNew(fetchNewParams);
  }

  render() {
    return <div />;
  }
}

export function mapStateToProps(state) {
  return {
    list: state.wohnungList.filteredList,
    hasGebaeudeCode: state.wohnungList.filters.availableGebaeudeCode.length > 0,
    staticVariationOptions: state.staticVariations
      ? state.staticVariations[0].optionen
      : [],
    sort: state.wohnungList.sort
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    wohnungsgrundrissActions: bindActionCreators(
      wohnungsgrundrissActions,
      dispatch
    ),
    konfigurationActions: bindActionCreators(konfigurationActions, dispatch),
    wohnungListActions: bindActionCreators(wohnungListActions, dispatch),
    optionenActions: bindActionCreators(optionenActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KonfiguratorStartOne);
