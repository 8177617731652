/**
 * A pure react component rendering a svg showing the building.
 * It can be given a list of unit codes which cause the floors to open up and the units to be highlighted.
 *
 * Call it using eg. <Isometrie />
 * <Isometrie highlightedUnits={['2.3', '3.3', '4.3', '5.3']} />
 */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  registerStickyPolyfillElement,
  unregisterStickyPolyfillElement
} from "../../../lib/App/common/helper";

// get a distinct set of open floors out of the unit codes (1.6, 1.7, 3.6 => 1, 3)
function getOpenFloors(highlightedUnits) {
  return [...new Set(highlightedUnits.map(e => ~~e.split(".")[0]))];
}

// Compute the translation for a given floor number.
// Move the given floor up by a small amount for each open single tenent below the given floor.
// Move the given floor up by a big amount for each multi tenent below the given floor and for the top floor.
function getFloorStyle(openFloors, highlightedUnits, floor) {
  const narrow = v =>
    highlightedUnits.indexOf(v + ".3") > -1 && v !== Math.max(...openFloors);
  const y = openFloors.reduce(
    (acc, v) => acc + (v < floor ? (narrow(v) ? -20 : -70) : 0),
    0
  );
  if (y === 0) return {};
  return { transform: "translateY(" + y + "px)", matrix: y };
}

// The <g id="_01">...</g> element of the svg must be replaced by this component.
// It adds the needed transform to open up the floors.
const Floor = props => {
  const floorStyle = getFloorStyle(
    props.openFloors,
    props.highlightedUnits,
    props.floor
  );
  const style = { transform: floorStyle.transform };
  const matrix =
    floorStyle.matrix !== undefined
      ? "matrix(1 0 0 1 0 " + floorStyle.matrix + ")"
      : "";

  return (
    <g id={"floor_" + props.floor} style={style} transform={matrix}>
      {props.children}
    </g>
  );
};
// The <g id="_01_neutral">...</g> element of the svg can be replaced by this component.
// It doesn't do much usefull stuff anymore.
const FloorNeutral = props => {
  return <g id={"neutral_" + props.floor}>{props.children}</g>;
};
// The <g id="_01_01">...</g> element of the svg must be replaced by this component.
// It shows/hides the units (Einheit) when is is highlighted. It (partially) covers the FloorNeutral.
const Unit = props => {
  if (props.highlightedUnits.indexOf(props.floor + "." + props.unit) < 0)
    return null;
  return <g id={"unit_" + props.floor + "." + props.unit}>{props.children}</g>;
};

class Isometrie extends PureComponent {
  static propTypes = {
    // a list of einheitenCodes eg. ['2.3', '3.3', '4.3', '5.3']
    highlightedUnits: PropTypes.arrayOf(PropTypes.string)
  };

  componentDidMount() {
    registerStickyPolyfillElement(".sticky-rendering");
  }

  componentWillUnmount() {
    unregisterStickyPolyfillElement(".sticky-rendering");
  }

  render() {
    const highlightedUnits = this.props.highlightedUnits || [];
    const openFloors = getOpenFloors(highlightedUnits);

    const svg = (
      <svg
        version="1.1"
        id="Ebene_1"
        x="0px"
        y="0px"
        viewBox="35 0 610 955"
        className={"isometrie"}
      >
        <style type="text/css">{`
          g {transition: all 0.5s ease-in-out;}
          .st0{fill:none;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st1{fill:#FFFFFF;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st2{fill:#FFFFFF;stroke:#1D1E1B;stroke-width:0.1872;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st3{fill:#FFFFFF;}
	      .st4{opacity:0.32;fill:#E7D5AB;enable-background:new    ;}
	      .st5{fill:none;}
	      .st6{fill:none;stroke:#999998;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st7{fill:#F0F0F0;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st8{fill:#EBEBEB;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st9{fill:#E5E4E4;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st10{fill:#BDBCBC;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st11{fill:#CAE1E5;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st12{fill:#B8D7DC;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st13{fill:#E7D5AB;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st14{fill:#DBCBA3;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st15{fill:#B9AC8B;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st16{fill:#EFEEEE;stroke:#1D1E1B;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	      .st17{fill:none;stroke:#999998;stroke-width:0.2523;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;enable-background:new    ;}
          .st18{opacity:0.32;fill:#E7D5AB;enable-background:new;}
        `}</style>
        <g style={{ transform: "translateY(300px)" }}>
          <g id="NEUER_UNTEN">
            <line className="st0" x1="253.8" y1="624.1" x2="594.5" y2="465.6" />
            <line className="st0" x1="275" y1="634.4" x2="624.3" y2="471.4" />
            <line className="st0" x1="278" y1="635.6" x2="626.8" y2="473.1" />
            <line className="st0" x1="299.8" y1="646.1" x2="640.8" y2="487.1" />
            <path
              d="M326.6,601.1c0.9,2,0.2,3.7-1.8,4.6s-3.8,0.3-4.7-1.7l-2-4.5l0.9-0.4l2,4.5c0.7,1.5,2,1.9,3.4,1.3
			c1.5-0.7,1.9-1.9,1.3-3.4l-2-4.5l0.9-0.4L326.6,601.1z M320.1,596.6c0.1,0.3,0,0.7-0.3,0.8c-0.3,0.1-0.6,0-0.8-0.3
			c-0.1-0.3,0-0.7,0.3-0.8C319.6,596.1,319.9,596.3,320.1,596.6z M321.9,595.8c0.1,0.3,0,0.7-0.3,0.8c-0.3,0.1-0.6,0-0.8-0.3
			c-0.1-0.3,0-0.7,0.3-0.8C321.4,595.3,321.7,595.4,321.9,595.8z"
            />
            <path d="M313.1,610.1l5.1-2.3l0.4,0.8l-6.3,2.8l-0.3-0.7l2.2-8.4l-4.9,2.2l-0.4-0.8l6.1-2.7l0.3,0.7L313.1,610.1z" />
            <path
              d="M334,598.6c-0.2,0.1-0.3,0.2-0.5,0.3l-2,0.9l1.1,2.4l-0.9,0.4l-3.6-7.7l2.9-1.3c1.9-0.9,3.4-0.4,4.2,1.2
			c0.6,1.3,0.3,2.4-0.6,3.3l3,1.8l-1,0.4L334,598.6z M333,598c1.4-0.6,1.9-1.6,1.4-2.8c-0.5-1.2-1.6-1.4-3-0.8l-2,0.9l1.6,3.6
			L333,598z"
            />
            <path d="M341.1,598.2l-3.6-7.7l0.9-0.4l3.6,7.7L341.1,598.2z" />
            <path
              d="M348.8,586.8l-0.2,0.9c-0.9-0.3-1.9-0.3-2.7,0.1c-1.7,0.8-2.5,2.7-1.8,4.4c0.8,1.7,2.8,2.4,4.5,1.6
			c0.8-0.4,1.5-1.1,1.8-1.9l0.8,0.4c-0.4,1.1-1.2,2-2.3,2.5c-2.2,1-4.7,0.1-5.7-2.1c-1-2.1,0-4.6,2.3-5.6
			C346.6,586.4,347.8,586.4,348.8,586.8z"
            />
            <path
              d="M360.4,589.3l-1.5-3.4l-4.6,2.1l1.5,3.4l-0.9,0.4l-3.6-7.7l0.9-0.4l1.6,3.5l4.6-2.1l-1.6-3.5l0.9-0.4l3.6,7.7L360.4,589.3
			z"
            />
            <path
              d="M374.9,573.3c1.5-0.7,2.8-0.4,3.3,0.8c0.4,0.9,0.2,1.8-0.6,2.4c1.2-0.3,2.1,0.1,2.6,1.1c0.6,1.3-0.1,2.5-1.8,3.3l-3.3,1.5
			l-3.6-7.7L374.9,573.3z M374,577.7l2.3-1c1-0.5,1.4-1.2,1.1-2c-0.4-0.8-1.1-0.9-2.2-0.5l-2.3,1L374,577.7z M375.7,581.2l2.3-1
			c1.2-0.6,1.7-1.4,1.3-2.3c-0.4-0.8-1.3-1-2.6-0.4l-2.3,1L375.7,581.2z"
            />
            <path d="M382.3,570.9l1.1,2.5l3.9-1.7l0.4,0.8l-3.9,1.7l1.2,2.6l4.5-2l0.4,0.8l-5.3,2.4l-3.6-7.6l5.2-2.3l0.4,0.8L382.3,570.9z" />
            <path
              d="M395.4,570.1c-0.2,0.1-0.3,0.2-0.5,0.3l-2,0.9l1.1,2.4l-0.9,0.4l-3.6-7.7l2.9-1.3c1.9-0.9,3.4-0.4,4.2,1.2
			c0.6,1.3,0.3,2.4-0.6,3.3l3,1.8l-1,0.4L395.4,570.1z M394.6,569.5c1.4-0.6,1.9-1.6,1.4-2.8s-1.6-1.4-3-0.8l-2,0.9l1.6,3.6
			L394.6,569.5z"
            />
            <path d="M407.9,567.3l-7.4-4l2.8,6.1l-0.9,0.4l-3.6-7.7l0.9-0.4l7.4,4l-2.8-6.2l0.9-0.4l3.6,7.7L407.9,567.3z" />
            <polyline
              className="st1"
              points="307.4,613.8 301.9,611.6 304.1,606.1 	"
            />

            <path
              d="M520.1,528.5l0,1c-0.9-0.1-1.8,0-2.4,0.3c-1,0.4-1.5,1.1-1.2,1.8c0.9,2,4.7-1,6,1.8c0.6,1.3-0.2,2.6-1.8,3.3
			c-1.1,0.5-2.4,0.5-3.5,0.2l0-1c1,0.4,2.2,0.3,3-0.1c1.1-0.5,1.6-1.3,1.3-2c-0.9-2-4.7,1-6-1.7c-0.5-1.2,0.2-2.5,1.8-3.2
			C518.3,528.5,519.3,528.4,520.1,528.5z"
            />
            <path d="M526,526l3.1,6.8l-0.9,0.4l-3.1-6.8l-2.5,1.1l-0.4-0.8l5.9-2.7l0.4,0.8L526,526z" />
            <path d="M534.1,530c0.1,0.3,0,0.7-0.3,0.8c-0.3,0.1-0.6,0-0.8-0.3c-0.1-0.3,0-0.7,0.3-0.8C533.6,529.5,533.9,529.6,534.1,530z" />
            <path
              d="M546.5,520.3l1.3,2.8c-0.5,0.9-1.4,1.8-2.4,2.3c-2.2,1-4.8,0.1-5.7-2.1c-1-2.2,0-4.7,2.3-5.7c1.1-0.5,2.3-0.5,3.3-0.2
			l-0.2,0.9c-0.9-0.3-1.9-0.2-2.7,0.2c-1.7,0.8-2.5,2.8-1.8,4.5c0.8,1.7,2.8,2.4,4.5,1.6c0.7-0.3,1.3-0.9,1.7-1.6l-1-2.3
			L546.5,520.3z"
            />
            <path
              d="M551.6,520.2l0,2.3l-0.9,0.4l0-9.2l0.9-0.4l6.9,6.1l-1,0.4l-1.7-1.5L551.6,520.2z M551.5,514.5l0,4.7l3.4-1.6L551.5,514.5
			z"
            />
            <path d="M565,515.3l0.4,0.8l-4.2,1.9l-3.5-7.7l0.9-0.4l3.1,6.8L565,515.3z" />
            <path d="M572.1,512.1l0.4,0.8l-4.2,1.9l-3.5-7.7l0.9-0.4l3.1,6.8L572.1,512.1z" />
            <path d="M573.3,504.4l1.1,2.5l3.9-1.7l0.4,0.8l-3.9,1.7l1.2,2.6l4.5-2l0.4,0.8l-5.3,2.4l-3.5-7.7l5.2-2.3l0.4,0.8L573.3,504.4z" />
            <path d="M589.6,505.1l-7.4-4.1l2.8,6.1l-0.9,0.4l-3.5-7.7l0.9-0.4l7.4,4.1l-2.8-6.2l0.9-0.4l3.5,7.7L589.6,505.1z" />
            <polyline
              className="st1"
              points="594.9,502.1 597.2,496.7 591.8,494.4 		"
            />

            <ellipse className="st2" cx="478.5" cy="478.1" rx="25.5" ry="14" />
            <polygon points="477.8,479.3 479.7,477.5 457,470.8 455.3,472.5" />

            <polygon
              className="st3"
              points="631.7,502.6 274.3,333.8 304.4,320.7 658.6,488.4"
            />
            <line className="st0" x1="216.5" y1="279.2" x2="658.9" y2="488.7" />
            <line className="st0" x1="632" y1="502.9" x2="199.1" y2="298.4" />

            <path
              d="M422.6,393.8l1-7.4l-5.1,5.4l-0.9-0.4l1.1-8.8l0.9,0.4l-1,7.6l5.2-5.6l0.9,0.4l-1,7.6l5.2-5.6l0.9,0.4l-6.2,6.4
				L422.6,393.8z"
            />
            <path
              d="M429.7,394.9l-1.8,1.5L427,396l7-6l0.9,0.4l-0.2,9.2l-0.9-0.4l0.1-2.3L429.7,394.9z M434,391.2l-3.5,3l3.4,1.6L434,391.2
				z"
            />
            <path d="M442,402.1l-0.4,0.8l-4.2-2l3.6-7.6l0.9,0.4l-3.2,6.8L442,402.1z" />
            <path d="M449.2,405.5l-0.4,0.8l-4.2-2l3.6-7.6l0.9,0.4l-3.2,6.8L449.2,405.5z" />
            <path d="M451.7,407.7l3.6-7.6l0.9,0.4l-3.6,7.6L451.7,407.7z" />
            <path
              d="M464.3,405.2l-0.8,0.6c-0.5-0.8-1.2-1.3-1.8-1.6c-1-0.5-1.8-0.4-2.1,0.2c-0.9,1.9,3.8,3,2.5,5.7
				c-0.6,1.3-2.1,1.5-3.7,0.8c-1.1-0.5-2-1.5-2.4-2.5l0.8-0.6c0.4,1,1.2,1.9,2,2.3c1.1,0.5,2,0.4,2.3-0.3c1-2-3.8-3-2.5-5.7
				c0.6-1.2,2-1.5,3.6-0.7C463.1,403.8,463.9,404.5,464.3,405.2z"
            />
            <path
              d="M468.6,407.4l-1.2,2.5l3.8,1.8l-0.4,0.8l-3.8-1.8l-1.2,2.6l4.4,2.1l-0.4,0.8l-5.3-2.5l3.6-7.6l5.1,2.4l-0.4,0.8
				L468.6,407.4z"
            />
            <path d="M477.7,419l-0.4,0.8l-4.2-2l3.6-7.6l0.9,0.4l-3.2,6.8L477.7,419z" />
            <path d="M484.8,422.4l-0.4,0.8l-4.2-2l3.6-7.6l0.9,0.4l-3.2,6.8L484.8,422.4z" />
            <path
              d="M491.4,418.3l-1.2,2.5l3.8,1.8l-0.4,0.8l-3.8-1.8l-1.2,2.6l4.4,2.1l-0.4,0.8l-5.3-2.5l3.6-7.6l5.1,2.4l-0.4,0.8
				L491.4,418.3z"
            />
            <path d="M501.3,431.3l-1.7-8.2l-2.9,6.1l-0.9-0.4l3.6-7.6l0.9,0.4l1.6,8.3l2.9-6.1l0.9,0.4l-3.6,7.6L501.3,431.3z" />
            <polyline
              className="st1"
              points="508.3,435 513.6,432.4 511.1,427.1"
            />
          </g>

          <g id="Haus_links">
            <g className="st4">
              <polygon
                className="st4"
                points="294,403.7 305.4,398.3 324.9,389.2 353.8,375.6 316.6,358.2 278.3,340.3 249.6,353.7 218.7,368.2
				218.6,368.4"
              />
              <polygon
                className="st4"
                points="218.6,368.3 218.6,470 294,505.4 353.8,477.2 353.8,375.6 294,403.8"
              />
              <line className="st5" x1="294" y1="403.9" x2="294" y2="505.4" />
            </g>
            <g>
              <polygon
                className="st6"
                points="294,403.8 305.4,398.4 324.9,389.2 353.8,375.6 316.5,358.2 278.2,340.3 249.5,353.7 218.7,368.2
				218.6,368.4 			"
              />
              <polygon
                className="st6"
                points="218.6,368.4 218.6,470 294,505.4 353.8,477.3 353.8,375.6 294,403.8"
              />
              <line className="st6" x1="294" y1="403.9" x2="294" y2="505.4" />
            </g>
          </g>

          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={0}
          >
            <FloorNeutral openFloors={openFloors} floor={0}>
              <polygon
                className="st7"
                points="132.5,454.6 103.1,468.3 71.5,483.1 42.1,496.9 91.2,519.9 120.6,506.1 110.7,501.5 129.5,492.7
                142.3,486.7 163.5,496.6 192.9,482.9 		"
              />
              <g>
                <polygon
                  className="st8"
                  points="213.1,492.3 183.7,506.1 152.1,520.8 132.6,511.7 103.2,525.5 102.2,525.1 161.9,553 191.4,539.2
                    222.9,524.4 252.3,510.7 			"
                />
                <polygon
                  className="st1"
                  points="192.9,482.9 163.5,496.6 142.3,486.7 110.7,501.5 120.6,506.1 91.2,519.9 102.2,525.1 103.2,525.5
                    132.6,511.7 152.1,520.8 183.7,506.1 213.1,492.3 			"
                />
              </g>
              <g>
                <g>
                  <polygon
                    className="st9"
                    points="54.3,517.9 44.3,513.2 44.3,502.8 54.3,507.4 				"
                  />
                  <polygon
                    className="st9"
                    points="44.3,513.2 42.1,512.2 42.1,496.9 44.3,497.9 				"
                  />
                  <polygon
                    className="st9"
                    points="54.3,513 44.3,508.4 44.3,497.9 54.3,502.6 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="64.1,522.5 54.1,517.8 54.1,507.3 64.1,512 				"
                  />
                  <polygon
                    className="st9"
                    points="54.1,517.8 51.9,516.7 51.9,501.5 54.1,502.5 				"
                  />
                  <polygon
                    className="st9"
                    points="64.1,517.6 54.1,512.9 54.1,502.5 64.1,507.2 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="73.9,527 63.9,522.4 63.9,511.9 73.9,516.6 				"
                  />
                  <polygon
                    className="st9"
                    points="63.9,522.4 61.7,521.3 61.7,506.1 63.9,507.1 				"
                  />
                  <polygon
                    className="st9"
                    points="73.9,522.2 63.9,517.5 63.9,507.1 73.9,511.8 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="83.7,531.6 73.7,526.9 73.7,516.5 83.7,521.2 				"
                  />
                  <polygon
                    className="st9"
                    points="73.7,526.9 71.5,525.9 71.5,510.7 73.7,511.7 				"
                  />
                  <polygon
                    className="st9"
                    points="83.7,526.8 73.7,522.1 73.7,511.7 83.7,516.4 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="93.5,536.2 83.5,531.5 83.5,521.1 93.5,525.8 				"
                  />
                  <polygon
                    className="st9"
                    points="83.5,531.5 81.3,530.5 81.3,515.2 83.5,516.3 				"
                  />
                  <polygon
                    className="st9"
                    points="93.5,531.4 83.5,526.7 83.5,516.3 93.5,521 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="103.3,540.8 93.3,536.1 93.3,525.7 103.3,530.4 				"
                  />
                  <polygon
                    className="st9"
                    points="93.3,536.1 91.1,535.1 91.1,519.8 93.3,520.9 				"
                  />
                  <polygon
                    className="st9"
                    points="103.3,536 93.3,531.3 93.3,520.9 103.3,525.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="113.1,545.4 103,540.7 103,530.3 113.1,535 				"
                  />
                  <polygon
                    className="st9"
                    points="103.1,540.7 100.9,539.7 100.9,524.4 103.1,525.4 				"
                  />
                  <polygon
                    className="st9"
                    points="113.1,540.6 103,535.9 103,525.4 113.1,530.1 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="122.9,550 112.8,545.3 112.8,534.9 122.9,539.6 				"
                  />
                  <polygon
                    className="st9"
                    points="112.9,545.3 110.7,544.3 110.7,529 112.9,530 				"
                  />
                  <polygon
                    className="st9"
                    points="122.9,545.1 112.8,540.5 112.8,530 122.9,534.7 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="132.7,554.6 122.6,549.9 122.6,539.4 132.7,544.1 				"
                  />
                  <polygon
                    className="st9"
                    points="122.7,549.9 120.5,548.9 120.5,533.6 122.7,534.6 				"
                  />
                  <polygon
                    className="st9"
                    points="132.7,549.7 122.6,545 122.6,534.6 132.7,539.3 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="142.5,559.2 132.4,554.5 132.4,544 142.5,548.7 				"
                  />
                  <polygon
                    className="st9"
                    points="132.4,554.5 130.2,553.4 130.2,538.2 132.4,539.2 				"
                  />
                  <polygon
                    className="st9"
                    points="142.5,554.3 132.4,549.6 132.4,539.2 142.5,543.9 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="152.3,563.7 142.2,559.1 142.2,548.6 152.3,553.3 				"
                  />
                  <polygon
                    className="st9"
                    points="142.2,559.1 140,558 140,542.8 142.2,543.8 				"
                  />
                  <polygon
                    className="st9"
                    points="152.3,558.9 142.2,554.2 142.2,543.8 152.3,548.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="161.2,567.9 152,563.6 152,553.2 161.2,557.5 				"
                  />
                  <polygon
                    className="st9"
                    points="152,563.6 149.8,562.6 149.8,547.4 152,548.4 				"
                  />
                  <polygon
                    className="st9"
                    points="160.9,563 152,558.8 152,548.4 160.9,552.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st9"
                    points="161.8,568.2 159.6,567.2 159.6,551.9 161.8,553 				"
                  />
                </g>
              </g>
              <g>
                <g>
                  <polygon
                    className="st10"
                    points="174.1,562.5 164.1,567.2 164.1,556.8 174.1,552.1 				"
                  />
                  <polygon
                    className="st10"
                    points="164.1,567.2 161.9,568.2 161.9,553 164.1,551.9 				"
                  />
                  <polygon
                    className="st10"
                    points="174.1,557.7 164.1,562.4 164.1,551.9 174.1,547.3 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="183.9,557.9 173.9,562.6 173.9,552.2 183.9,547.5 				"
                  />
                  <polygon
                    className="st10"
                    points="173.9,562.6 171.7,563.6 171.7,548.4 173.9,547.4 				"
                  />
                  <polygon
                    className="st10"
                    points="183.9,553.1 173.9,557.8 173.9,547.4 183.9,542.7 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="193.7,553.3 183.7,558 183.7,547.6 193.7,542.9 				"
                  />
                  <polygon
                    className="st10"
                    points="183.7,558 181.5,559.1 181.5,543.8 183.7,542.8 				"
                  />
                  <polygon
                    className="st10"
                    points="193.7,548.5 183.7,553.2 183.7,542.8 193.7,538.1 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="203.5,548.8 193.5,553.4 193.5,543 203.5,538.3 				"
                  />
                  <polygon
                    className="st10"
                    points="193.5,553.4 191.3,554.5 191.3,539.2 193.5,538.2 				"
                  />
                  <polygon
                    className="st10"
                    points="203.5,543.9 193.5,548.6 193.5,538.2 203.5,533.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="213.3,544.2 203.3,548.9 203.3,538.4 213.3,533.7 				"
                  />
                  <polygon
                    className="st10"
                    points="203.3,548.9 201.1,549.9 201.1,534.6 203.3,533.6 				"
                  />
                  <polygon
                    className="st10"
                    points="213.3,539.3 203.3,544 203.3,533.6 213.3,528.9 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="223.1,539.6 213.1,544.3 213.1,533.8 223.1,529.2 				"
                  />
                  <polygon
                    className="st10"
                    points="213.1,544.3 210.9,545.3 210.9,530 213.1,529 				"
                  />
                  <polygon
                    className="st10"
                    points="223.1,534.8 213.1,539.4 213.1,529 223.1,524.3 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="232.9,535 222.9,539.7 222.9,529.3 232.9,524.6 				"
                  />
                  <polygon
                    className="st10"
                    points="222.9,539.7 220.7,540.7 220.7,525.5 222.9,524.4 				"
                  />
                  <polygon
                    className="st10"
                    points="232.9,530.2 222.9,534.9 222.9,524.4 232.9,519.7 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="242.7,530.4 232.7,535.1 232.7,524.7 242.7,520 				"
                  />
                  <polygon
                    className="st10"
                    points="232.7,535.1 230.5,536.1 230.5,520.9 232.7,519.8 				"
                  />
                  <polygon
                    className="st10"
                    points="242.7,525.6 232.7,530.3 232.7,519.8 242.7,515.2 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="251.4,526.3 242.5,530.5 242.5,520.1 251.4,515.9 				"
                  />
                  <polygon
                    className="st10"
                    points="242.5,530.5 240.3,531.5 240.3,516.3 242.5,515.2 				"
                  />
                  <polygon
                    className="st10"
                    points="251.1,521.6 242.5,525.7 242.5,515.3 251.1,511.2 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st10"
                    points="252.3,525.9 250.1,527 250.1,511.7 252.3,510.7 				"
                  />
                </g>
              </g>
            </FloorNeutral>
            <g id="galerie">
              <polygon
                className="st11"
                points="160.3,486.1 151.9,482.2 143.3,478.2 134.9,474.2 125.3,478.7 103.1,468.3 71.5,483.1 42.1,496.9
			91.2,519.9 120.6,506.1 110.7,501.5 129.5,492.7 142.3,486.7 142.3,486.7 150.7,490.6 		"
              />
              <g>
                <g>
                  <polygon
                    className="st12"
                    points="54.3,517.9 44.3,513.2 44.3,502.8 54.3,507.4 				"
                  />
                  <polygon
                    className="st12"
                    points="44.3,513.2 42.1,512.2 42.1,496.9 44.3,497.9 				"
                  />
                  <polygon
                    className="st12"
                    points="54.3,513 44.3,508.4 44.3,497.9 54.3,502.6 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st12"
                    points="64.1,522.5 54.1,517.8 54.1,507.3 64.1,512 				"
                  />
                  <polygon
                    className="st12"
                    points="54.1,517.8 51.9,516.7 51.9,501.5 54.1,502.5 				"
                  />
                  <polygon
                    className="st12"
                    points="64.1,517.6 54.1,512.9 54.1,502.5 64.1,507.2 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st12"
                    points="73.9,527 63.9,522.4 63.9,511.9 73.9,516.6 				"
                  />
                  <polygon
                    className="st12"
                    points="63.9,522.4 61.7,521.3 61.7,506.1 63.9,507.1 				"
                  />
                  <polygon
                    className="st12"
                    points="73.9,522.2 63.9,517.5 63.9,507.1 73.9,511.8 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st12"
                    points="83.7,531.6 73.7,526.9 73.7,516.5 83.7,521.2 				"
                  />
                  <polygon
                    className="st12"
                    points="73.7,526.9 71.5,525.9 71.5,510.7 73.7,511.7 				"
                  />
                  <polygon
                    className="st12"
                    points="83.7,526.8 73.7,522.1 73.7,511.7 83.7,516.4 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st12"
                    points="91.1,535.1 83.5,531.5 83.5,521.1 91.1,524.7 				"
                  />
                  <polygon
                    className="st12"
                    points="83.5,531.5 81.3,530.5 81.3,515.2 83.5,516.3 				"
                  />
                  <polygon
                    className="st12"
                    points="91.1,530.3 83.5,526.7 83.5,516.3 91.1,519.9 				"
                  />
                </g>
              </g>
            </g>
            <Unit highlightedUnits={highlightedUnits} floor={0} unit={2}>
              <g>
                <polygon
                  className="st13"
                  points="213.1,492.3 183.7,506.1 152.1,520.8 132.6,511.7 103.2,525.5 102.2,525.1 161.9,553 191.4,539.2
				222.9,524.4 252.3,510.7 			"
                />
              </g>
              <g>
                <g>
                  <polygon
                    className="st14"
                    points="113.1,545.4 103,540.7 103,530.3 113.1,535 				"
                  />
                  <polygon
                    className="st14"
                    points="113.1,540.6 103,535.9 103,525.4 113.1,530.1 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st14"
                    points="122.9,550 112.8,545.3 112.8,534.9 122.9,539.6 				"
                  />
                  <polygon
                    className="st14"
                    points="112.9,545.3 110.7,544.3 110.7,529 112.9,530 				"
                  />
                  <polygon
                    className="st14"
                    points="122.9,545.1 112.8,540.5 112.8,530 122.9,534.7 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st14"
                    points="132.7,554.6 122.6,549.9 122.6,539.4 132.7,544.1 				"
                  />
                  <polygon
                    className="st14"
                    points="122.7,549.9 120.5,548.9 120.5,533.6 122.7,534.6 				"
                  />
                  <polygon
                    className="st14"
                    points="132.7,549.7 122.6,545 122.6,534.6 132.7,539.3 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st14"
                    points="142.5,559.2 132.4,554.5 132.4,544 142.5,548.7 				"
                  />
                  <polygon
                    className="st14"
                    points="132.4,554.5 130.2,553.4 130.2,538.2 132.4,539.2 				"
                  />
                  <polygon
                    className="st14"
                    points="142.5,554.3 132.4,549.6 132.4,539.2 142.5,543.9 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st14"
                    points="152.3,563.7 142.2,559.1 142.2,548.6 152.3,553.3 				"
                  />
                  <polygon
                    className="st14"
                    points="142.2,559.1 140,558 140,542.8 142.2,543.8 				"
                  />
                  <polygon
                    className="st14"
                    points="152.3,558.9 142.2,554.2 142.2,543.8 152.3,548.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st14"
                    points="161.2,567.9 152,563.6 152,553.2 161.2,557.5 				"
                  />
                  <polygon
                    className="st14"
                    points="152,563.6 149.8,562.6 149.8,547.4 152,548.4 				"
                  />
                  <polygon
                    className="st14"
                    points="160.9,563 152,558.8 152,548.4 160.9,552.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st14"
                    points="161.8,568.2 159.6,567.2 159.6,551.9 161.8,553 				"
                  />
                </g>
              </g>
              <g>
                <g>
                  <polygon
                    className="st15"
                    points="174.1,562.5 164.1,567.2 164.1,556.8 174.1,552.1 				"
                  />
                  <polygon
                    className="st15"
                    points="164.1,567.2 161.9,568.2 161.9,553 164.1,551.9 				"
                  />
                  <polygon
                    className="st15"
                    points="174.1,557.7 164.1,562.4 164.1,551.9 174.1,547.3 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="183.9,557.9 173.9,562.6 173.9,552.2 183.9,547.5 				"
                  />
                  <polygon
                    className="st15"
                    points="173.9,562.6 171.7,563.6 171.7,548.4 173.9,547.4 				"
                  />
                  <polygon
                    className="st15"
                    points="183.9,553.1 173.9,557.8 173.9,547.4 183.9,542.7 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="193.7,553.3 183.7,558 183.7,547.6 193.7,542.9 				"
                  />
                  <polygon
                    className="st15"
                    points="183.7,558 181.5,559.1 181.5,543.8 183.7,542.8 				"
                  />
                  <polygon
                    className="st15"
                    points="193.7,548.5 183.7,553.2 183.7,542.8 193.7,538.1 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="203.5,548.8 193.5,553.4 193.5,543 203.5,538.3 				"
                  />
                  <polygon
                    className="st15"
                    points="193.5,553.4 191.3,554.5 191.3,539.2 193.5,538.2 				"
                  />
                  <polygon
                    className="st15"
                    points="203.5,543.9 193.5,548.6 193.5,538.2 203.5,533.5 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="213.3,544.2 203.3,548.9 203.3,538.4 213.3,533.7 				"
                  />
                  <polygon
                    className="st15"
                    points="203.3,548.9 201.1,549.9 201.1,534.6 203.3,533.6 				"
                  />
                  <polygon
                    className="st15"
                    points="213.3,539.3 203.3,544 203.3,533.6 213.3,528.9 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="223.1,539.6 213.1,544.3 213.1,533.8 223.1,529.2 				"
                  />
                  <polygon
                    className="st15"
                    points="213.1,544.3 210.9,545.3 210.9,530 213.1,529 				"
                  />
                  <polygon
                    className="st15"
                    points="223.1,534.8 213.1,539.4 213.1,529 223.1,524.3 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="232.9,535 222.9,539.7 222.9,529.3 232.9,524.6 				"
                  />
                  <polygon
                    className="st15"
                    points="222.9,539.7 220.7,540.7 220.7,525.5 222.9,524.4 				"
                  />
                  <polygon
                    className="st15"
                    points="232.9,530.2 222.9,534.9 222.9,524.4 232.9,519.7 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="242.7,530.4 232.7,535.1 232.7,524.7 242.7,520 				"
                  />
                  <polygon
                    className="st15"
                    points="232.7,535.1 230.5,536.1 230.5,520.9 232.7,519.8 				"
                  />
                  <polygon
                    className="st15"
                    points="242.7,525.6 232.7,530.3 232.7,519.8 242.7,515.2 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="251.4,526.3 242.5,530.5 242.5,520.1 251.4,515.9 				"
                  />
                  <polygon
                    className="st15"
                    points="242.5,530.5 240.3,531.5 240.3,516.3 242.5,515.2 				"
                  />
                  <polygon
                    className="st15"
                    points="251.1,521.6 242.5,525.7 242.5,515.3 251.1,511.2 				"
                  />
                </g>
                <g>
                  <polygon
                    className="st15"
                    points="252.3,525.9 250.1,527 250.1,511.7 252.3,510.7 				"
                  />
                </g>
              </g>
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={0} unit={1}>
              <polygon
                className="st13"
                points="192.9,482.9 132.5,454.6 103.1,468.3 163.5,496.6 		"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={1}
          >
            <FloorNeutral openFloors={openFloors} floor={1}>
              <g>
                <g>
                  <path
                    className="st7"
                    d="M213.1,475l-41.4-19.4l-39.2-18.3l0,0l-29.4,13.8l-31.6,14.8L42,479.7l39.2,18.4l21.8,10.2l0,0l58.9,27.6
					l29.5-13.8l31.6-14.8l29.4-13.8L213.1,475z M132.5,494.3l-21.8-10.2l31.6-14.8l41.4,19.4L132.5,494.3z"
                  />
                  <polygon
                    className="st1"
                    points="110.7,484.1 152.1,503.5 183.7,488.7 142.3,469.4 				"
                  />
                </g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,500.5 44.3,495.9 44.3,485.4 54.3,490.1 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,495.9 42.1,494.8 42.1,479.6 44.3,480.6 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,495.7 44.3,491 44.3,480.6 54.3,485.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,505.1 54.1,500.4 54.1,490 64.1,494.7 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,500.5 51.9,499.4 51.9,484.2 54.1,485.2 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,500.3 54.1,495.6 54.1,485.2 64.1,489.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,509.7 63.9,505 63.9,494.6 73.9,499.3 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,505 61.7,504 61.7,488.7 63.9,489.8 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,504.9 63.9,500.2 63.9,489.8 73.9,494.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,514.3 73.7,509.6 73.7,499.2 83.7,503.9 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,509.6 71.5,508.6 71.5,493.3 73.7,494.4 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,509.5 73.7,504.8 73.7,494.4 83.7,499.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,518.9 83.5,514.2 83.5,503.8 93.5,508.5 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,514.2 81.3,513.2 81.3,497.9 83.5,499 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,514.1 83.5,509.4 83.5,498.9 93.5,503.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,523.5 93.3,518.8 93.3,508.4 103.3,513.1 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,518.8 91.1,517.8 91.1,502.5 93.3,503.5 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,518.7 93.3,514 93.3,503.5 103.3,508.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,528.1 103,523.4 103,513 113.1,517.6 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,523.4 100.9,522.4 100.9,507.1 103.1,508.1 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,523.2 103,518.5 103,508.1 113.1,512.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,532.7 112.8,528 112.8,517.5 122.9,522.2 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,528 110.7,526.9 110.7,511.7 112.9,512.7 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,527.8 112.8,523.1 112.8,512.7 122.9,517.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,537.2 122.6,532.6 122.6,522.1 132.7,526.8 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,532.6 120.5,531.5 120.5,516.3 122.7,517.3 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,532.4 122.6,527.7 122.6,517.3 132.7,522 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,541.8 132.4,537.1 132.4,526.7 142.5,531.4 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,537.1 130.2,536.1 130.2,520.9 132.4,521.9 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,537 132.4,532.3 132.4,521.9 142.5,526.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,546.4 142.2,541.7 142.2,531.3 152.3,536 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,541.7 140,540.7 140,525.4 142.2,526.5 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,541.6 142.2,536.9 142.2,526.5 152.3,531.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,550.6 152,546.3 152,535.9 161.2,540.2 					"
                    />
                    <polygon
                      className="st9"
                      points="152,546.3 149.8,545.3 149.8,530 152,531.1 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,545.6 152,541.5 152,531.1 160.9,535.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,550.9 159.6,549.9 159.6,534.6 161.8,535.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,545.2 164.1,549.9 164.1,539.5 174.1,534.8 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,549.9 161.9,550.9 161.9,535.7 164.1,534.6 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,540.4 164.1,545.1 164.1,534.6 174.1,529.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,540.6 173.9,545.3 173.9,534.9 183.9,530.2 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,545.3 171.7,546.3 171.7,531.1 173.9,530 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,535.8 173.9,540.5 173.9,530 183.9,525.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,536 183.7,540.7 183.7,530.3 193.7,525.6 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,540.7 181.5,541.7 181.5,526.5 183.7,525.4 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,531.2 183.7,535.9 183.7,525.5 193.7,520.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,531.4 193.5,536.1 193.5,525.7 203.5,521 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,536.1 191.3,537.2 191.3,521.9 193.5,520.9 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,526.6 193.5,531.3 193.5,520.9 203.5,516.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,526.9 203.3,531.5 203.3,521.1 213.3,516.4 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,531.5 201.1,532.6 201.1,517.3 203.3,516.3 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,522 203.3,526.7 203.3,516.3 213.3,511.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,522.3 213.1,527 213.1,516.5 223.1,511.8 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,526.9 210.9,528 210.9,512.7 213.1,511.7 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,517.4 213.1,522.1 213.1,511.7 223.1,507 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,517.7 222.9,522.4 222.9,511.9 232.9,507.2 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,522.4 220.7,523.4 220.7,508.1 222.9,507.1 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,512.8 222.9,517.5 222.9,507.1 232.9,502.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,513.1 232.7,517.8 232.7,507.4 242.7,502.7 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,517.8 230.5,518.8 230.5,503.5 232.7,502.5 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,508.3 232.7,512.9 232.7,502.5 242.7,497.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,509 242.5,513.2 242.5,502.8 251.4,498.6 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,513.2 240.3,514.2 240.3,499 242.5,497.9 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,504.3 242.5,508.4 242.5,497.9 251.1,493.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,508.6 250.1,509.6 250.1,494.4 252.3,493.3 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={1} unit={1}>
              <g>
                <g>
                  <polygon
                    className="st13"
                    points="213.1,475 132.5,437.3 103.1,451 142.3,469.4 183.7,488.7 152.1,503.5 132.5,494.3 103,508.1
					161.9,535.7 191.4,521.9 222.9,507.1 252.3,493.4 				"
                  />
                </g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,528.1 103,523.4 103,513 113.1,517.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,523.2 103,518.5 103,508.1 113.1,512.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.9,532.7 112.8,528 112.8,517.5 122.9,522.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,528 110.7,526.9 110.7,511.7 112.9,512.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,527.8 112.8,523.1 112.8,512.7 122.9,517.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,537.2 122.6,532.6 122.6,522.1 132.7,526.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,532.6 120.5,531.5 120.5,516.3 122.7,517.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,532.4 122.6,527.7 122.6,517.3 132.7,522 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,541.8 132.4,537.1 132.4,526.7 142.5,531.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,537.1 130.2,536.1 130.2,520.9 132.4,521.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,537 132.4,532.3 132.4,521.9 142.5,526.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,546.4 142.2,541.7 142.2,531.3 152.3,536 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,541.7 140,540.7 140,525.4 142.2,526.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,541.6 142.2,536.9 142.2,526.5 152.3,531.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,550.6 152,546.3 152,535.9 161.2,540.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,546.3 149.8,545.3 149.8,530 152,531.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,545.6 152,541.5 152,531.1 160.9,535.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,550.9 159.6,549.9 159.6,534.6 161.8,535.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,545.2 164.1,549.9 164.1,539.5 174.1,534.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,549.9 161.9,550.9 161.9,535.7 164.1,534.6 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,540.4 164.1,545.1 164.1,534.6 174.1,529.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,540.6 173.9,545.3 173.9,534.9 183.9,530.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,545.3 171.7,546.3 171.7,531.1 173.9,530 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,535.8 173.9,540.5 173.9,530 183.9,525.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,536 183.7,540.7 183.7,530.3 193.7,525.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,540.7 181.5,541.7 181.5,526.5 183.7,525.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,531.2 183.7,535.9 183.7,525.5 193.7,520.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,531.4 193.5,536.1 193.5,525.7 203.5,521 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,536.1 191.3,537.2 191.3,521.9 193.5,520.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,526.6 193.5,531.3 193.5,520.9 203.5,516.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,526.9 203.3,531.5 203.3,521.1 213.3,516.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,531.5 201.1,532.6 201.1,517.3 203.3,516.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,522 203.3,526.7 203.3,516.3 213.3,511.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,522.3 213.1,527 213.1,516.5 223.1,511.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,526.9 210.9,528 210.9,512.7 213.1,511.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,517.4 213.1,522.1 213.1,511.7 223.1,507 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,517.7 222.9,522.4 222.9,511.9 232.9,507.2 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,522.4 220.7,523.4 220.7,508.1 222.9,507.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,512.8 222.9,517.5 222.9,507.1 232.9,502.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,513.1 232.7,517.8 232.7,507.4 242.7,502.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,517.8 230.5,518.8 230.5,503.5 232.7,502.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,508.3 232.7,512.9 232.7,502.5 242.7,497.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,509 242.5,513.2 242.5,502.8 251.4,498.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,513.2 240.3,514.2 240.3,499 242.5,497.9 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,504.3 242.5,508.4 242.5,497.9 251.1,493.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,508.6 250.1,509.6 250.1,494.4 252.3,493.3 					"
                    />
                  </g>
                </g>
              </g>
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={2}
          >
            <FloorNeutral openFloors={openFloors} floor={2}>
              <polygon
                className="st1"
                points="152,486.2 183.6,471.4 183.6,471.4 183.6,471.4 142.2,452 142.2,452 110.6,466.8 		"
              />
              <path
                className="st16"
                d="M213,457.6L213,457.6l-41.4-19.4l0,0l-39.2-18.3L103,433.7l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,457.6z M183.6,471.4L152,486.2l0,0l-41.4-19.4l31.6-14.8l0,0
			L183.6,471.4L183.6,471.4L183.6,471.4z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,483.2 44.3,478.5 44.3,468.1 54.3,472.8 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,478.5 42.1,477.5 42.1,462.2 44.3,463.3 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,478.4 44.3,473.7 44.3,463.3 54.3,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,487.8 54.1,483.1 54.1,472.7 64.1,477.4 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,483.1 51.9,482.1 51.9,466.8 54.1,467.8 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,483 54.1,478.3 54.1,467.8 64.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,492.4 63.9,487.7 63.9,477.3 73.9,481.9 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,487.7 61.7,486.7 61.7,471.4 63.9,472.4 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,487.5 63.9,482.9 63.9,472.4 73.9,477.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,497 73.7,492.3 73.7,481.8 83.7,486.5 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,492.3 71.5,491.3 71.5,476 73.7,477 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,492.1 73.7,487.4 73.7,477 83.7,481.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,501.6 83.5,496.9 83.5,486.4 93.5,491.1 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,496.9 81.3,495.8 81.3,480.6 83.5,481.6 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,496.7 83.5,492 83.5,481.6 93.5,486.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,506.1 93.3,501.4 93.3,491 103.3,495.7 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,501.5 91.1,500.4 91.1,485.2 93.3,486.2 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,501.3 93.3,496.6 93.3,486.2 103.3,490.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,510.7 103,506 103,495.6 113.1,500.3 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,506 100.9,505 100.9,489.8 103.1,490.8 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,505.9 103,501.2 103,490.8 113.1,495.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,515.3 112.8,510.6 112.8,500.2 122.9,504.9 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,510.6 110.7,509.6 110.7,494.3 112.9,495.4 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,510.5 112.8,505.8 112.8,495.4 122.9,500.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,519.9 122.6,515.2 122.6,504.8 132.7,509.5 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,515.2 120.5,514.2 120.5,498.9 122.7,500 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,515.1 122.6,510.4 122.6,499.9 132.7,504.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,524.5 132.4,519.8 132.4,509.4 142.5,514.1 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,519.8 130.2,518.8 130.2,503.5 132.4,504.5 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,519.7 132.4,515 132.4,504.5 142.5,509.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,529.1 142.2,524.4 142.2,514 152.3,518.6 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,524.4 140,523.4 140,508.1 142.2,509.1 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,524.2 142.2,519.6 142.2,509.1 152.3,513.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,533.3 152,529 152,518.5 161.2,522.8 					"
                    />
                    <polygon
                      className="st9"
                      points="152,529 149.8,527.9 149.8,512.7 152,513.7 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,528.3 152,524.1 152,513.7 160.9,517.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,533.6 159.6,532.5 159.6,517.3 161.8,518.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,527.9 164.1,532.5 164.1,522.1 174.1,517.4 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,532.5 161.9,533.6 161.9,518.3 164.1,517.3 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,523 164.1,527.7 164.1,517.3 174.1,512.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,523.3 173.9,528 173.9,517.5 183.9,512.8 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,528 171.7,529 171.7,513.7 173.9,512.7 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,518.4 173.9,523.1 173.9,512.7 183.9,508 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,518.7 183.7,523.4 183.7,512.9 193.7,508.3 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,523.4 181.5,524.4 181.5,509.1 183.7,508.1 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,513.8 183.7,518.5 183.7,508.1 193.7,503.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,514.1 193.5,518.8 193.5,508.4 203.5,503.7 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,518.8 191.3,519.8 191.3,504.5 193.5,503.5 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,509.3 193.5,513.9 193.5,503.5 203.5,498.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,509.5 203.3,514.2 203.3,503.8 213.3,499.1 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,514.2 201.1,515.2 201.1,500 203.3,498.9 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,504.7 203.3,509.4 203.3,498.9 213.3,494.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,504.9 213.1,509.6 213.1,499.2 223.1,494.5 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,509.6 210.9,510.6 210.9,495.4 213.1,494.3 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,500.1 213.1,504.8 213.1,494.3 223.1,489.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,500.3 222.9,505 222.9,494.6 232.9,489.9 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,505 220.7,506 220.7,490.8 222.9,489.8 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,495.5 222.9,500.2 222.9,489.8 232.9,485.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,495.7 232.7,500.4 232.7,490 242.7,485.3 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,500.4 230.5,501.5 230.5,486.2 232.7,485.2 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,490.9 232.7,495.6 232.7,485.2 242.7,480.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,491.7 242.5,495.8 242.5,485.4 251.4,481.2 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,495.8 240.3,496.9 240.3,481.6 242.5,480.6 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,487 242.5,491 242.5,480.6 251.1,476.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,491.3 250.1,492.3 250.1,477 252.3,476 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,519.9 122.6,515.2 122.6,504.8 132.7,509.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,515.1 122.6,510.4 122.6,499.9 132.7,504.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,524.5 132.4,519.8 132.4,509.4 142.5,514.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,519.8 130.2,518.8 130.2,503.5 132.4,504.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,519.7 132.4,515 132.4,504.5 142.5,509.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,529.1 142.2,524.4 142.2,514 152.3,518.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,524.4 140,523.4 140,508.1 142.2,509.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,524.2 142.2,519.6 142.2,509.1 152.3,513.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,533.3 152,529 152,518.5 161.2,522.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,529 149.8,527.9 149.8,512.7 152,513.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,528.3 152,524.1 152,513.7 160.9,517.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,533.6 159.6,532.5 159.6,517.3 161.8,518.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,527.9 164.1,532.5 164.1,522.1 174.1,517.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,532.5 161.9,533.6 161.9,518.3 164.1,517.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,523 164.1,527.7 164.1,517.3 174.1,512.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,523.3 173.9,528 173.9,517.5 183.9,512.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,528 171.7,529 171.7,513.7 173.9,512.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,518.4 173.9,523.1 173.9,512.7 183.9,508 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,518.7 183.7,523.4 183.7,512.9 193.7,508.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,523.4 181.5,524.4 181.5,509.1 183.7,508.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,513.8 183.7,518.5 183.7,508.1 193.7,503.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,514.1 193.5,518.8 193.5,508.4 203.5,503.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,518.8 191.3,519.8 191.3,504.5 193.5,503.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,509.3 193.5,513.9 193.5,503.5 203.5,498.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,509.5 203.3,514.2 203.3,503.8 213.3,499.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,514.2 201.1,515.2 201.1,500 203.3,498.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,504.7 203.3,509.4 203.3,498.9 213.3,494.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,505 213.1,509.6 213.1,499.2 222.7,494.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,509.6 210.9,510.6 210.9,495.4 213.1,494.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,500.2 213.1,504.8 213.1,494.3 222.7,489.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,505 220.7,506 220.7,490.8 222.9,489.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,486.2 152,486.2 122.4,500 122.6,500.1 161.9,518.5 191.4,504.7 223,489.9 223,489.9
			183.6,471.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,483.2 44.3,478.5 44.3,468.1 54.3,472.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,478.5 42.1,477.5 42.1,462.2 44.3,463.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,478.4 44.3,473.7 44.3,463.3 54.3,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,487.8 54.1,483.1 54.1,472.7 64.1,477.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,483.1 51.9,482.1 51.9,466.8 54.1,467.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,483 54.1,478.3 54.1,467.8 64.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,492.4 63.9,487.7 63.9,477.3 73.9,481.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,487.7 61.7,486.7 61.7,471.4 63.9,472.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,487.5 63.9,482.9 63.9,472.4 73.9,477.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,497 73.7,492.3 73.7,481.8 83.7,486.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,492.3 71.5,491.3 71.5,476 73.7,477 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,492.1 73.7,487.4 73.7,477 83.7,481.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,501.6 83.5,496.9 83.5,486.4 93.5,491.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,496.9 81.3,495.8 81.3,480.6 83.5,481.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,496.7 83.5,492 83.5,481.6 93.5,486.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,506.1 93.3,501.4 93.3,491 103.3,495.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,501.5 91.1,500.4 91.1,485.2 93.3,486.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,501.3 93.3,496.6 93.3,486.2 103.3,490.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,510.7 103,506 103,495.6 113.1,500.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,505.9 103,501.2 103,490.8 113.1,495.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,506 100.9,505 100.9,489.8 103.1,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,515.2 112.8,510.6 112.8,500.2 122.7,504.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,510.4 112.8,505.8 112.8,495.4 122.7,500 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,510.6 110.7,509.6 110.7,494.3 112.9,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,515.2 120.5,514.2 120.5,498.9 122.7,500 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,500.3 222.9,505 222.9,494.6 232.9,489.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,495.5 222.9,500.2 222.9,489.8 232.9,485.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,495.7 232.7,500.4 232.7,490 242.7,485.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,500.4 230.5,501.5 230.5,486.2 232.7,485.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,490.9 232.7,495.6 232.7,485.2 242.7,480.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,491.7 242.5,495.8 242.5,485.4 251.4,481.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,495.8 240.3,496.9 240.3,481.6 242.5,480.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,487 242.5,491 242.5,480.6 251.1,476.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,491.3 250.1,492.3 250.1,477 252.3,476 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,457.6 213,457.6 171.7,438.2 171.7,438.2 132.5,419.9 103.1,433.7 71.5,448.5 42,462.3
			81.2,480.7 122.4,500 152,486.2 110.7,466.8 142.3,452 142.3,452 183.6,471.4 183.7,471.4 183.6,471.4 223,489.9 252.4,476.1
			"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,519.9 122.6,515.2 122.6,504.8 132.7,509.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,515.1 122.6,510.4 122.6,499.9 132.7,504.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,524.5 132.4,519.8 132.4,509.4 142.5,514.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,519.8 130.2,518.8 130.2,503.5 132.4,504.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,519.7 132.4,515 132.4,504.5 142.5,509.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,529.1 142.2,524.4 142.2,514 152.3,518.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,524.4 140,523.4 140,508.1 142.2,509.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,524.2 142.2,519.6 142.2,509.1 152.3,513.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,533.3 152,529 152,518.5 161.2,522.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,529 149.8,527.9 149.8,512.7 152,513.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,528.3 152,524.1 152,513.7 160.9,517.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,533.6 159.6,532.5 159.6,517.3 161.8,518.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,527.9 164.1,532.5 164.1,522.1 174.1,517.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,532.5 161.9,533.6 161.9,518.3 164.1,517.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,523 164.1,527.7 164.1,517.3 174.1,512.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,523.3 173.9,528 173.9,517.5 183.9,512.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,528 171.7,529 171.7,513.7 173.9,512.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,518.4 173.9,523.1 173.9,512.7 183.9,508 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,518.7 183.7,523.4 183.7,512.9 193.7,508.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,523.4 181.5,524.4 181.5,509.1 183.7,508.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,513.8 183.7,518.5 183.7,508.1 193.7,503.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,514.1 193.5,518.8 193.5,508.4 203.5,503.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,518.8 191.3,519.8 191.3,504.5 193.5,503.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,509.3 193.5,513.9 193.5,503.5 203.5,498.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,509.5 203.3,514.2 203.3,503.8 213.3,499.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,514.2 201.1,515.2 201.1,500 203.3,498.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,504.7 203.3,509.4 203.3,498.9 213.3,494.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,504.9 213.1,509.6 213.1,499.2 223.1,494.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,509.6 210.9,510.6 210.9,495.4 213.1,494.3 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,500.1 213.1,504.8 213.1,494.3 223.1,489.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,500.3 222.9,505 222.9,494.6 232.9,489.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,505 220.7,506 220.7,490.8 222.9,489.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,495.5 222.9,500.2 222.9,489.8 232.9,485.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,495.7 232.7,500.4 232.7,490 242.7,485.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,500.4 230.5,501.5 230.5,486.2 232.7,485.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,490.9 232.7,495.6 232.7,485.2 242.7,480.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,491.7 242.5,495.8 242.5,485.4 251.4,481.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,495.8 240.3,496.9 240.3,481.6 242.5,480.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,487 242.5,491 242.5,480.6 251.1,476.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,491.3 250.1,492.3 250.1,477 252.3,476 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,457.6 213,457.6 183.6,471.4 183.7,471.4 183.6,471.4 152.1,486.2 152,486.2 122.4,500
			122.6,500.1 161.9,518.5 191.4,504.7 223,489.9 223,489.9 252.4,476.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,483.2 44.3,478.5 44.3,468.1 54.3,472.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,478.5 42.1,477.5 42.1,462.2 44.3,463.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,478.4 44.3,473.7 44.3,463.3 54.3,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,487.8 54.1,483.1 54.1,472.7 64.1,477.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,483.1 51.9,482.1 51.9,466.8 54.1,467.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,483 54.1,478.3 54.1,467.8 64.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,492.4 63.9,487.7 63.9,477.3 73.9,481.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,487.7 61.7,486.7 61.7,471.4 63.9,472.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,487.5 63.9,482.9 63.9,472.4 73.9,477.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,497 73.7,492.3 73.7,481.8 83.7,486.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,492.3 71.5,491.3 71.5,476 73.7,477 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,492.1 73.7,487.4 73.7,477 83.7,481.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,501.6 83.5,496.9 83.5,486.4 93.5,491.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,496.9 81.3,495.8 81.3,480.6 83.5,481.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,496.7 83.5,492 83.5,481.6 93.5,486.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,506.1 93.3,501.4 93.3,491 103.3,495.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,501.5 91.1,500.4 91.1,485.2 93.3,486.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,501.3 93.3,496.6 93.3,486.2 103.3,490.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,510.7 103,506 103,495.6 113.1,500.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,505.9 103,501.2 103,490.8 113.1,495.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,506 100.9,505 100.9,489.8 103.1,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,515.2 112.8,510.6 112.8,500.2 122.6,504.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,510.4 112.8,505.8 112.8,495.4 122.6,500 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,510.6 110.7,509.6 110.7,494.3 112.9,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,515.2 120.5,514.2 120.5,498.9 122.7,500 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,452 183.6,471.4 213,457.6 171.7,438.2 171.7,438.2 132.5,419.9 103.1,433.7 71.5,448.5
			42,462.3 81.2,480.7 122.4,500 152,486.2 110.7,466.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,519.9 122.6,515.2 122.6,504.8 132.7,509.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,515.1 122.6,510.4 122.6,499.9 132.7,504.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,524.5 132.4,519.8 132.4,509.4 142.5,514.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,519.8 130.2,518.8 130.2,503.5 132.4,504.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,519.7 132.4,515 132.4,504.5 142.5,509.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,529.1 142.2,524.4 142.2,514 152.3,518.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,524.4 140,523.4 140,508.1 142.2,509.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,524.2 142.2,519.6 142.2,509.1 152.3,513.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,533.3 152,529 152,518.5 161.2,522.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,529 149.8,527.9 149.8,512.7 152,513.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,528.3 152,524.1 152,513.7 160.9,517.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,533.6 159.6,532.5 159.6,517.3 161.8,518.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,527.9 164.1,532.5 164.1,522.1 174.1,517.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,532.5 161.9,533.6 161.9,518.3 164.1,517.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,523 164.1,527.7 164.1,517.3 174.1,512.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,523.3 173.9,528 173.9,517.5 183.9,512.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,528 171.7,529 171.7,513.7 173.9,512.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,518.4 173.9,523.1 173.9,512.7 183.9,508 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,518.7 183.7,523.4 183.7,512.9 193.7,508.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,523.4 181.5,524.4 181.5,509.1 183.7,508.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,513.8 183.7,518.5 183.7,508.1 193.7,503.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,514.1 193.5,518.8 193.5,508.4 203.5,503.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,518.8 191.3,519.8 191.3,504.5 193.5,503.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,509.3 193.5,513.9 193.5,503.5 203.5,498.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,509.5 203.3,514.2 203.3,503.8 213.3,499.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,514.2 201.1,515.2 201.1,500 203.3,498.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,504.7 203.3,509.4 203.3,498.9 213.3,494.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,504.9 213.1,509.6 213.1,499.2 223.1,494.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,509.6 210.9,510.6 210.9,495.4 213.1,494.3 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,500.1 213.1,504.8 213.1,494.3 223.1,489.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,500.3 222.9,505 222.9,494.6 232.9,489.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,505 220.7,506 220.7,490.8 222.9,489.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,495.5 222.9,500.2 222.9,489.8 232.9,485.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,495.7 232.7,500.4 232.7,490 242.7,485.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,500.4 230.5,501.5 230.5,486.2 232.7,485.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,490.9 232.7,495.6 232.7,485.2 242.7,480.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,491.7 242.5,495.8 242.5,485.4 251.4,481.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,495.8 240.3,496.9 240.3,481.6 242.5,480.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,487 242.5,491 242.5,480.6 251.1,476.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,491.3 250.1,492.3 250.1,477 252.3,476 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,457.6 213,457.6 171.7,438.2 142.3,452 183.6,471.4 183.7,471.4 183.6,471.4 152.1,486.2
			152,486.2 122.4,500 122.6,500.1 161.9,518.5 191.4,504.7 223,489.9 223,489.9 252.4,476.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,483.2 44.3,478.5 44.3,468.1 54.3,472.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,478.5 42.1,477.5 42.1,462.2 44.3,463.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,478.4 44.3,473.7 44.3,463.3 54.3,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,487.8 54.1,483.1 54.1,472.7 64.1,477.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,483.1 51.9,482.1 51.9,466.8 54.1,467.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,483 54.1,478.3 54.1,467.8 64.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,492.4 63.9,487.7 63.9,477.3 73.9,481.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,487.7 61.7,486.7 61.7,471.4 63.9,472.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,487.5 63.9,482.9 63.9,472.4 73.9,477.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,497 73.7,492.3 73.7,481.8 83.7,486.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,492.3 71.5,491.3 71.5,476 73.7,477 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,492.1 73.7,487.4 73.7,477 83.7,481.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,501.6 83.5,496.9 83.5,486.4 93.5,491.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,496.9 81.3,495.8 81.3,480.6 83.5,481.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,496.7 83.5,492 83.5,481.6 93.5,486.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,506.1 93.3,501.4 93.3,491 103.3,495.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,501.5 91.1,500.4 91.1,485.2 93.3,486.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,501.3 93.3,496.6 93.3,486.2 103.3,490.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,510.7 103,506 103,495.6 113.1,500.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,505.9 103,501.2 103,490.8 113.1,495.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,506 100.9,505 100.9,489.8 103.1,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,515.2 112.8,510.6 112.8,500.2 122.6,504.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,510.4 112.8,505.8 112.8,495.4 122.6,500 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,510.6 110.7,509.6 110.7,494.3 112.9,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,515.2 120.5,514.2 120.5,498.9 122.7,500 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,466.8 142.3,452 142.3,452 171.7,438.2 171.7,438.2 132.5,419.9 103.1,433.7 71.5,448.5
			42,462.3 81.2,480.7 122.4,500 152,486.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={2} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,483.2 44.3,478.5 44.3,468.1 54.3,472.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,478.5 42.1,477.5 42.1,462.2 44.3,463.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,478.4 44.3,473.7 44.3,463.3 54.3,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,487.8 54.1,483.1 54.1,472.7 64.1,477.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,483.1 51.9,482.1 51.9,466.8 54.1,467.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,483 54.1,478.3 54.1,467.8 64.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,492.4 63.9,487.7 63.9,477.3 73.9,481.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,487.7 61.7,486.7 61.7,471.4 63.9,472.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,487.5 63.9,482.9 63.9,472.4 73.9,477.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,497 73.7,492.3 73.7,481.8 83.7,486.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,492.3 71.5,491.3 71.5,476 73.7,477 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,492.1 73.7,487.4 73.7,477 83.7,481.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,501.6 83.5,496.9 83.5,486.4 93.5,491.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,496.9 81.3,495.8 81.3,480.6 83.5,481.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,496.7 83.5,492 83.5,481.6 93.5,486.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,506.1 93.3,501.4 93.3,491 103.3,495.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,501.5 91.1,500.4 91.1,485.2 93.3,486.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,501.3 93.3,496.6 93.3,486.2 103.3,490.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,506 100.9,505 100.9,489.8 103.1,490.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,510.7 103,506 103,495.6 113.1,500.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,505.9 103,501.2 103,490.8 113.1,495.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,510.6 110.7,509.6 110.7,494.3 112.9,495.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,515.3 112.8,510.6 112.8,500.2 122.9,504.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,510.5 112.8,505.8 112.8,495.4 122.9,500.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,515.2 120.5,514.2 120.5,498.9 122.7,500 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,519.9 122.6,515.2 122.6,504.8 132.7,509.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,515.1 122.6,510.4 122.6,499.9 132.7,504.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,524.5 132.4,519.8 132.4,509.4 142.5,514.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,519.8 130.2,518.8 130.2,503.5 132.4,504.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,519.7 132.4,515 132.4,504.5 142.5,509.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,529.1 142.2,524.4 142.2,514 152.3,518.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,524.4 140,523.4 140,508.1 142.2,509.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,524.2 142.2,519.6 142.2,509.1 152.3,513.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,533.3 152,529 152,518.5 161.2,522.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,529 149.8,527.9 149.8,512.7 152,513.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,528.3 152,524.1 152,513.7 160.9,517.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,533.6 159.6,532.5 159.6,517.3 161.8,518.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,527.9 164.1,532.5 164.1,522.1 174.1,517.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,532.5 161.9,533.6 161.9,518.3 164.1,517.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,523 164.1,527.7 164.1,517.3 174.1,512.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,523.3 173.9,528 173.9,517.5 183.9,512.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,528 171.7,529 171.7,513.7 173.9,512.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,518.4 173.9,523.1 173.9,512.7 183.9,508 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,518.7 183.7,523.4 183.7,512.9 193.7,508.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,523.4 181.5,524.4 181.5,509.1 183.7,508.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,513.8 183.7,518.5 183.7,508.1 193.7,503.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,514.1 193.5,518.8 193.5,508.4 203.5,503.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,518.8 191.3,519.8 191.3,504.5 193.5,503.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,509.3 193.5,513.9 193.5,503.5 203.5,498.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,509.5 203.3,514.2 203.3,503.8 213.3,499.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,514.2 201.1,515.2 201.1,500 203.3,498.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,504.7 203.3,509.4 203.3,498.9 213.3,494.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,504.9 213.1,509.6 213.1,499.2 223.1,494.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,509.6 210.9,510.6 210.9,495.4 213.1,494.3 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,500.1 213.1,504.8 213.1,494.3 223.1,489.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,500.3 222.9,505 222.9,494.6 232.9,489.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,505 220.7,506 220.7,490.8 222.9,489.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,495.5 222.9,500.2 222.9,489.8 232.9,485.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,495.7 232.7,500.4 232.7,490 242.7,485.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,500.4 230.5,501.5 230.5,486.2 232.7,485.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,490.9 232.7,495.6 232.7,485.2 242.7,480.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,491.7 242.5,495.8 242.5,485.4 251.4,481.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,495.8 240.3,496.9 240.3,481.6 242.5,480.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,487 242.5,491 242.5,480.6 251.1,476.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,491.3 250.1,492.3 250.1,477 252.3,476 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,457.6L213.1,457.6l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,462.3l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,457.6z M152.1,486.2L152.1,486.2l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,486.2z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={3}
          >
            <FloorNeutral openFloors={openFloors} floor={3}>
              <polygon
                className="st1"
                points="152,469 183.6,454.2 183.6,454.2 183.6,454.2 142.2,434.8 142.2,434.8 110.6,449.6 		"
              />
              <path
                className="st16"
                d="M213,440.4L213,440.4L171.6,421l0,0l-39.2-18.3L103,416.5l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,440.4z M183.6,454.2L152,469l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,454.2
			L183.6,454.2L183.6,454.2z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,466 44.3,461.3 44.3,450.9 54.3,455.6 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,461.3 42.1,460.3 42.1,445 44.3,446.1 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,461.2 44.3,456.5 44.3,446.1 54.3,450.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,470.6 54.1,465.9 54.1,455.5 64.1,460.2 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,465.9 51.9,464.9 51.9,449.6 54.1,450.6 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,465.8 54.1,461.1 54.1,450.6 64.1,455.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,475.2 63.9,470.5 63.9,460.1 73.9,464.7 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,470.5 61.7,469.5 61.7,454.2 63.9,455.2 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,470.3 63.9,465.7 63.9,455.2 73.9,459.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,479.8 73.7,475.1 73.7,464.6 83.7,469.3 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,475.1 71.5,474.1 71.5,458.8 73.7,459.8 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,474.9 73.7,470.2 73.7,459.8 83.7,464.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,484.4 83.5,479.7 83.5,469.2 93.5,473.9 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,479.7 81.3,478.6 81.3,463.4 83.5,464.4 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,479.5 83.5,474.8 83.5,464.4 93.5,469.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,488.9 93.3,484.2 93.3,473.8 103.3,478.5 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,484.3 91.1,483.2 91.1,468 93.3,469 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,484.1 93.3,479.4 93.3,469 103.3,473.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,493.5 103,488.8 103,478.4 113.1,483.1 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,488.8 100.9,487.8 100.9,472.6 103.1,473.6 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,488.7 103,484 103,473.6 113.1,478.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,498.1 112.8,493.4 112.8,483 122.9,487.7 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,493.4 110.7,492.4 110.7,477.1 112.9,478.2 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,493.3 112.8,488.6 112.8,478.2 122.9,482.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,502.7 122.6,498 122.6,487.6 132.7,492.3 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,498 120.5,497 120.5,481.7 122.7,482.8 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,497.9 122.6,493.2 122.6,482.7 132.7,487.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,507.3 132.4,502.6 132.4,492.2 142.5,496.9 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,502.6 130.2,501.6 130.2,486.3 132.4,487.3 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,502.5 132.4,497.8 132.4,487.3 142.5,492 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,511.9 142.2,507.2 142.2,496.8 152.3,501.4 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,507.2 140,506.2 140,490.9 142.2,491.9 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,507 142.2,502.4 142.2,491.9 152.3,496.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,516.1 152,511.8 152,501.3 161.2,505.6 					"
                    />
                    <polygon
                      className="st9"
                      points="152,511.8 149.8,510.7 149.8,495.5 152,496.5 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,511.1 152,506.9 152,496.5 160.9,500.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,516.4 159.6,515.3 159.6,500.1 161.8,501.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,510.7 164.1,515.3 164.1,504.9 174.1,500.2 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,515.3 161.9,516.4 161.9,501.1 164.1,500.1 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,505.8 164.1,510.5 164.1,500.1 174.1,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,506.1 173.9,510.8 173.9,500.3 183.9,495.6 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,510.8 171.7,511.8 171.7,496.5 173.9,495.5 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,501.2 173.9,505.9 173.9,495.5 183.9,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,501.5 183.7,506.2 183.7,495.7 193.7,491.1 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,506.2 181.5,507.2 181.5,491.9 183.7,490.9 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,496.6 183.7,501.3 183.7,490.9 193.7,486.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,496.9 193.5,501.6 193.5,491.2 203.5,486.5 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,501.6 191.3,502.6 191.3,487.3 193.5,486.3 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,492.1 193.5,496.7 193.5,486.3 203.5,481.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,492.3 203.3,497 203.3,486.6 213.3,481.9 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,497 201.1,498 201.1,482.8 203.3,481.7 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,487.5 203.3,492.2 203.3,481.7 213.3,477 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,487.7 213.1,492.4 213.1,482 223.1,477.3 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,492.4 210.9,493.4 210.9,478.2 213.1,477.1 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,482.9 213.1,487.6 213.1,477.1 223.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,483.1 222.9,487.8 222.9,477.4 232.9,472.7 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,487.8 220.7,488.8 220.7,473.6 222.9,472.6 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,478.3 222.9,483 222.9,472.6 232.9,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,478.5 232.7,483.2 232.7,472.8 242.7,468.1 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,483.2 230.5,484.3 230.5,469 232.7,468 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,473.7 232.7,478.4 232.7,468 242.7,463.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,474.5 242.5,478.6 242.5,468.2 251.4,464 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,478.6 240.3,479.7 240.3,464.4 242.5,463.4 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,469.8 242.5,473.8 242.5,463.4 251.1,459.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,474.1 250.1,475.1 250.1,459.8 252.3,458.8 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,502.7 122.6,498 122.6,487.6 132.7,492.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,497.9 122.6,493.2 122.6,482.7 132.7,487.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,507.3 132.4,502.6 132.4,492.2 142.5,496.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,502.6 130.2,501.6 130.2,486.3 132.4,487.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,502.5 132.4,497.8 132.4,487.3 142.5,492 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,511.9 142.2,507.2 142.2,496.8 152.3,501.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,507.2 140,506.2 140,490.9 142.2,491.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,507 142.2,502.4 142.2,491.9 152.3,496.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,516.1 152,511.8 152,501.3 161.2,505.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,511.8 149.8,510.7 149.8,495.5 152,496.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,511.1 152,506.9 152,496.5 160.9,500.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,516.4 159.6,515.3 159.6,500.1 161.8,501.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,510.7 164.1,515.3 164.1,504.9 174.1,500.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,515.3 161.9,516.4 161.9,501.1 164.1,500.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,505.8 164.1,510.5 164.1,500.1 174.1,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,506.1 173.9,510.8 173.9,500.3 183.9,495.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,510.8 171.7,511.8 171.7,496.5 173.9,495.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,501.2 173.9,505.9 173.9,495.5 183.9,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,501.5 183.7,506.2 183.7,495.7 193.7,491.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,506.2 181.5,507.2 181.5,491.9 183.7,490.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,496.6 183.7,501.3 183.7,490.9 193.7,486.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,496.9 193.5,501.6 193.5,491.2 203.5,486.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,501.6 191.3,502.6 191.3,487.3 193.5,486.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,492.1 193.5,496.7 193.5,486.3 203.5,481.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,492.3 203.3,497 203.3,486.6 213.3,481.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,497 201.1,498 201.1,482.8 203.3,481.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,487.5 203.3,492.2 203.3,481.7 213.3,477 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,487.8 213.1,492.4 213.1,482 222.7,477.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,492.4 210.9,493.4 210.9,478.2 213.1,477.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,483 213.1,487.6 213.1,477.1 222.7,472.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,487.8 220.7,488.8 220.7,473.6 222.9,472.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,469 152,469 122.4,482.8 122.6,482.9 161.9,501.3 191.4,487.5 223,472.7 223,472.7
			183.6,454.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,466 44.3,461.3 44.3,450.9 54.3,455.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,461.3 42.1,460.3 42.1,445 44.3,446.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,461.2 44.3,456.5 44.3,446.1 54.3,450.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,470.6 54.1,465.9 54.1,455.5 64.1,460.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,465.9 51.9,464.9 51.9,449.6 54.1,450.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,465.8 54.1,461.1 54.1,450.6 64.1,455.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,475.2 63.9,470.5 63.9,460.1 73.9,464.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,470.5 61.7,469.5 61.7,454.2 63.9,455.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,470.3 63.9,465.7 63.9,455.2 73.9,459.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,479.8 73.7,475.1 73.7,464.6 83.7,469.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,475.1 71.5,474.1 71.5,458.8 73.7,459.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,474.9 73.7,470.2 73.7,459.8 83.7,464.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,484.4 83.5,479.7 83.5,469.2 93.5,473.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,479.7 81.3,478.6 81.3,463.4 83.5,464.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,479.5 83.5,474.8 83.5,464.4 93.5,469.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,488.9 93.3,484.2 93.3,473.8 103.3,478.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,484.3 91.1,483.2 91.1,468 93.3,469 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,484.1 93.3,479.4 93.3,469 103.3,473.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,493.5 103,488.8 103,478.4 113.1,483.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,488.7 103,484 103,473.6 113.1,478.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,488.8 100.9,487.8 100.9,472.6 103.1,473.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,498 112.8,493.4 112.8,483 122.7,487.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,493.2 112.8,488.6 112.8,478.2 122.7,482.8 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,493.4 110.7,492.4 110.7,477.1 112.9,478.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,498 120.5,497 120.5,481.7 122.7,482.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,483.1 222.9,487.8 222.9,477.4 232.9,472.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,478.3 222.9,483 222.9,472.6 232.9,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,478.5 232.7,483.2 232.7,472.8 242.7,468.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,483.2 230.5,484.3 230.5,469 232.7,468 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,473.7 232.7,478.4 232.7,468 242.7,463.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,474.5 242.5,478.6 242.5,468.2 251.4,464 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,478.6 240.3,479.7 240.3,464.4 242.5,463.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,469.8 242.5,473.8 242.5,463.4 251.1,459.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,474.1 250.1,475.1 250.1,459.8 252.3,458.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,440.4 213,440.4 171.7,421 171.7,421 132.5,402.7 103.1,416.5 71.5,431.3 42,445.1
			81.2,463.5 122.4,482.8 152,469 110.7,449.6 142.3,434.8 142.3,434.8 183.6,454.2 183.7,454.2 183.6,454.2 223,472.7 252.4,458.9
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,502.7 122.6,498 122.6,487.6 132.7,492.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,497.9 122.6,493.2 122.6,482.7 132.7,487.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,507.3 132.4,502.6 132.4,492.2 142.5,496.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,502.6 130.2,501.6 130.2,486.3 132.4,487.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,502.5 132.4,497.8 132.4,487.3 142.5,492 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,511.9 142.2,507.2 142.2,496.8 152.3,501.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,507.2 140,506.2 140,490.9 142.2,491.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,507 142.2,502.4 142.2,491.9 152.3,496.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,516.1 152,511.8 152,501.3 161.2,505.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,511.8 149.8,510.7 149.8,495.5 152,496.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,511.1 152,506.9 152,496.5 160.9,500.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,516.4 159.6,515.3 159.6,500.1 161.8,501.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,510.7 164.1,515.3 164.1,504.9 174.1,500.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,515.3 161.9,516.4 161.9,501.1 164.1,500.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,505.8 164.1,510.5 164.1,500.1 174.1,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,506.1 173.9,510.8 173.9,500.3 183.9,495.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,510.8 171.7,511.8 171.7,496.5 173.9,495.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,501.2 173.9,505.9 173.9,495.5 183.9,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,501.5 183.7,506.2 183.7,495.7 193.7,491.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,506.2 181.5,507.2 181.5,491.9 183.7,490.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,496.6 183.7,501.3 183.7,490.9 193.7,486.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,496.9 193.5,501.6 193.5,491.2 203.5,486.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,501.6 191.3,502.6 191.3,487.3 193.5,486.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,492.1 193.5,496.7 193.5,486.3 203.5,481.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,492.3 203.3,497 203.3,486.6 213.3,481.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,497 201.1,498 201.1,482.8 203.3,481.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,487.5 203.3,492.2 203.3,481.7 213.3,477 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,487.7 213.1,492.4 213.1,482 223.1,477.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,492.4 210.9,493.4 210.9,478.2 213.1,477.1 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,482.9 213.1,487.6 213.1,477.1 223.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,483.1 222.9,487.8 222.9,477.4 232.9,472.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,487.8 220.7,488.8 220.7,473.6 222.9,472.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,478.3 222.9,483 222.9,472.6 232.9,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,478.5 232.7,483.2 232.7,472.8 242.7,468.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,483.2 230.5,484.3 230.5,469 232.7,468 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,473.7 232.7,478.4 232.7,468 242.7,463.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,474.5 242.5,478.6 242.5,468.2 251.4,464 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,478.6 240.3,479.7 240.3,464.4 242.5,463.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,469.8 242.5,473.8 242.5,463.4 251.1,459.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,474.1 250.1,475.1 250.1,459.8 252.3,458.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,440.4 213,440.4 183.6,454.2 183.7,454.2 183.6,454.2 152.1,469 152,469 122.4,482.8
			122.6,482.9 161.9,501.3 191.4,487.5 223,472.7 223,472.7 252.4,458.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,466 44.3,461.3 44.3,450.9 54.3,455.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,461.3 42.1,460.3 42.1,445 44.3,446.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,461.2 44.3,456.5 44.3,446.1 54.3,450.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,470.6 54.1,465.9 54.1,455.5 64.1,460.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,465.9 51.9,464.9 51.9,449.6 54.1,450.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,465.8 54.1,461.1 54.1,450.6 64.1,455.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,475.2 63.9,470.5 63.9,460.1 73.9,464.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,470.5 61.7,469.5 61.7,454.2 63.9,455.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,470.3 63.9,465.7 63.9,455.2 73.9,459.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,479.8 73.7,475.1 73.7,464.6 83.7,469.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,475.1 71.5,474.1 71.5,458.8 73.7,459.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,474.9 73.7,470.2 73.7,459.8 83.7,464.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,484.4 83.5,479.7 83.5,469.2 93.5,473.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,479.7 81.3,478.6 81.3,463.4 83.5,464.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,479.5 83.5,474.8 83.5,464.4 93.5,469.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,488.9 93.3,484.2 93.3,473.8 103.3,478.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,484.3 91.1,483.2 91.1,468 93.3,469 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,484.1 93.3,479.4 93.3,469 103.3,473.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,493.5 103,488.8 103,478.4 113.1,483.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,488.7 103,484 103,473.6 113.1,478.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,488.8 100.9,487.8 100.9,472.6 103.1,473.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,498 112.8,493.4 112.8,483 122.6,487.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,493.2 112.8,488.6 112.8,478.2 122.6,482.8 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,493.4 110.7,492.4 110.7,477.1 112.9,478.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,498 120.5,497 120.5,481.7 122.7,482.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,434.8 183.6,454.2 213,440.4 171.7,421 171.7,421 132.5,402.7 103.1,416.5 71.5,431.3
			42,445.1 81.2,463.5 122.4,482.8 152,469 110.7,449.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,502.7 122.6,498 122.6,487.6 132.7,492.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,497.9 122.6,493.2 122.6,482.7 132.7,487.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,507.3 132.4,502.6 132.4,492.2 142.5,496.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,502.6 130.2,501.6 130.2,486.3 132.4,487.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,502.5 132.4,497.8 132.4,487.3 142.5,492 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,511.9 142.2,507.2 142.2,496.8 152.3,501.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,507.2 140,506.2 140,490.9 142.2,491.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,507 142.2,502.4 142.2,491.9 152.3,496.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,516.1 152,511.8 152,501.3 161.2,505.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,511.8 149.8,510.7 149.8,495.5 152,496.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,511.1 152,506.9 152,496.5 160.9,500.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,516.4 159.6,515.3 159.6,500.1 161.8,501.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,510.7 164.1,515.3 164.1,504.9 174.1,500.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,515.3 161.9,516.4 161.9,501.1 164.1,500.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,505.8 164.1,510.5 164.1,500.1 174.1,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,506.1 173.9,510.8 173.9,500.3 183.9,495.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,510.8 171.7,511.8 171.7,496.5 173.9,495.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,501.2 173.9,505.9 173.9,495.5 183.9,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,501.5 183.7,506.2 183.7,495.7 193.7,491.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,506.2 181.5,507.2 181.5,491.9 183.7,490.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,496.6 183.7,501.3 183.7,490.9 193.7,486.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,496.9 193.5,501.6 193.5,491.2 203.5,486.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,501.6 191.3,502.6 191.3,487.3 193.5,486.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,492.1 193.5,496.7 193.5,486.3 203.5,481.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,492.3 203.3,497 203.3,486.6 213.3,481.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,497 201.1,498 201.1,482.8 203.3,481.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,487.5 203.3,492.2 203.3,481.7 213.3,477 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,487.7 213.1,492.4 213.1,482 223.1,477.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,492.4 210.9,493.4 210.9,478.2 213.1,477.1 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,482.9 213.1,487.6 213.1,477.1 223.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,483.1 222.9,487.8 222.9,477.4 232.9,472.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,487.8 220.7,488.8 220.7,473.6 222.9,472.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,478.3 222.9,483 222.9,472.6 232.9,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,478.5 232.7,483.2 232.7,472.8 242.7,468.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,483.2 230.5,484.3 230.5,469 232.7,468 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,473.7 232.7,478.4 232.7,468 242.7,463.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,474.5 242.5,478.6 242.5,468.2 251.4,464 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,478.6 240.3,479.7 240.3,464.4 242.5,463.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,469.8 242.5,473.8 242.5,463.4 251.1,459.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,474.1 250.1,475.1 250.1,459.8 252.3,458.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,440.4 213,440.4 171.7,421 142.3,434.8 183.6,454.2 183.7,454.2 183.6,454.2 152.1,469
			152,469 122.4,482.8 122.6,482.9 161.9,501.3 191.4,487.5 223,472.7 223,472.7 252.4,458.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,466 44.3,461.3 44.3,450.9 54.3,455.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,461.3 42.1,460.3 42.1,445 44.3,446.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,461.2 44.3,456.5 44.3,446.1 54.3,450.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,470.6 54.1,465.9 54.1,455.5 64.1,460.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,465.9 51.9,464.9 51.9,449.6 54.1,450.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,465.8 54.1,461.1 54.1,450.6 64.1,455.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,475.2 63.9,470.5 63.9,460.1 73.9,464.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,470.5 61.7,469.5 61.7,454.2 63.9,455.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,470.3 63.9,465.7 63.9,455.2 73.9,459.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,479.8 73.7,475.1 73.7,464.6 83.7,469.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,475.1 71.5,474.1 71.5,458.8 73.7,459.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,474.9 73.7,470.2 73.7,459.8 83.7,464.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,484.4 83.5,479.7 83.5,469.2 93.5,473.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,479.7 81.3,478.6 81.3,463.4 83.5,464.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,479.5 83.5,474.8 83.5,464.4 93.5,469.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,488.9 93.3,484.2 93.3,473.8 103.3,478.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,484.3 91.1,483.2 91.1,468 93.3,469 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,484.1 93.3,479.4 93.3,469 103.3,473.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,493.5 103,488.8 103,478.4 113.1,483.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,488.7 103,484 103,473.6 113.1,478.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,488.8 100.9,487.8 100.9,472.6 103.1,473.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,498 112.8,493.4 112.8,483 122.6,487.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,493.2 112.8,488.6 112.8,478.2 122.6,482.8 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,493.4 110.7,492.4 110.7,477.1 112.9,478.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,498 120.5,497 120.5,481.7 122.7,482.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,449.6 142.3,434.8 142.3,434.8 171.7,421 171.7,421 132.5,402.7 103.1,416.5 71.5,431.3
			42,445.1 81.2,463.5 122.4,482.8 152,469 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={3} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,466 44.3,461.3 44.3,450.9 54.3,455.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,461.3 42.1,460.3 42.1,445 44.3,446.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,461.2 44.3,456.5 44.3,446.1 54.3,450.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,470.6 54.1,465.9 54.1,455.5 64.1,460.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,465.9 51.9,464.9 51.9,449.6 54.1,450.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,465.8 54.1,461.1 54.1,450.6 64.1,455.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,475.2 63.9,470.5 63.9,460.1 73.9,464.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,470.5 61.7,469.5 61.7,454.2 63.9,455.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,470.3 63.9,465.7 63.9,455.2 73.9,459.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,479.8 73.7,475.1 73.7,464.6 83.7,469.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,475.1 71.5,474.1 71.5,458.8 73.7,459.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,474.9 73.7,470.2 73.7,459.8 83.7,464.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,484.4 83.5,479.7 83.5,469.2 93.5,473.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,479.7 81.3,478.6 81.3,463.4 83.5,464.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,479.5 83.5,474.8 83.5,464.4 93.5,469.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,488.9 93.3,484.2 93.3,473.8 103.3,478.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,484.3 91.1,483.2 91.1,468 93.3,469 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,484.1 93.3,479.4 93.3,469 103.3,473.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,488.8 100.9,487.8 100.9,472.6 103.1,473.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,493.5 103,488.8 103,478.4 113.1,483.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,488.7 103,484 103,473.6 113.1,478.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,493.4 110.7,492.4 110.7,477.1 112.9,478.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,498.1 112.8,493.4 112.8,483 122.9,487.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,493.3 112.8,488.6 112.8,478.2 122.9,482.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,498 120.5,497 120.5,481.7 122.7,482.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,502.7 122.6,498 122.6,487.6 132.7,492.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,497.9 122.6,493.2 122.6,482.7 132.7,487.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,507.3 132.4,502.6 132.4,492.2 142.5,496.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,502.6 130.2,501.6 130.2,486.3 132.4,487.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,502.5 132.4,497.8 132.4,487.3 142.5,492 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,511.9 142.2,507.2 142.2,496.8 152.3,501.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,507.2 140,506.2 140,490.9 142.2,491.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,507 142.2,502.4 142.2,491.9 152.3,496.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,516.1 152,511.8 152,501.3 161.2,505.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,511.8 149.8,510.7 149.8,495.5 152,496.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,511.1 152,506.9 152,496.5 160.9,500.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,516.4 159.6,515.3 159.6,500.1 161.8,501.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,510.7 164.1,515.3 164.1,504.9 174.1,500.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,515.3 161.9,516.4 161.9,501.1 164.1,500.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,505.8 164.1,510.5 164.1,500.1 174.1,495.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,506.1 173.9,510.8 173.9,500.3 183.9,495.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,510.8 171.7,511.8 171.7,496.5 173.9,495.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,501.2 173.9,505.9 173.9,495.5 183.9,490.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,501.5 183.7,506.2 183.7,495.7 193.7,491.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,506.2 181.5,507.2 181.5,491.9 183.7,490.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,496.6 183.7,501.3 183.7,490.9 193.7,486.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,496.9 193.5,501.6 193.5,491.2 203.5,486.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,501.6 191.3,502.6 191.3,487.3 193.5,486.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,492.1 193.5,496.7 193.5,486.3 203.5,481.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,492.3 203.3,497 203.3,486.6 213.3,481.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,497 201.1,498 201.1,482.8 203.3,481.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,487.5 203.3,492.2 203.3,481.7 213.3,477 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,487.7 213.1,492.4 213.1,482 223.1,477.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,492.4 210.9,493.4 210.9,478.2 213.1,477.1 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,482.9 213.1,487.6 213.1,477.1 223.1,472.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,483.1 222.9,487.8 222.9,477.4 232.9,472.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,487.8 220.7,488.8 220.7,473.6 222.9,472.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,478.3 222.9,483 222.9,472.6 232.9,467.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,478.5 232.7,483.2 232.7,472.8 242.7,468.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,483.2 230.5,484.3 230.5,469 232.7,468 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,473.7 232.7,478.4 232.7,468 242.7,463.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,474.5 242.5,478.6 242.5,468.2 251.4,464 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,478.6 240.3,479.7 240.3,464.4 242.5,463.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,469.8 242.5,473.8 242.5,463.4 251.1,459.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,474.1 250.1,475.1 250.1,459.8 252.3,458.8 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,440.4L213.1,440.4L171.7,421l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,445.1l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,440.4z M152.1,469L152.1,469l-41.4-19.4l31.6-14.8l0,0l41.3,19.4l0,0
			h-0.1L152.1,469z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={4}
          >
            <FloorNeutral openFloors={openFloors} floor={4}>
              <polygon
                className="st1"
                points="152,451.7 183.6,436.9 183.6,436.9 183.6,436.9 142.2,417.5 142.2,417.5 110.6,432.3 		"
              />
              <path
                className="st16"
                d="M213,423.1L213,423.1l-41.4-19.4l0,0l-39.2-18.3L103,399.2L71.4,414l-29.5,13.8l39.2,18.4l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,423.1z M183.6,436.9L152,451.7l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,436.9
			L183.6,436.9L183.6,436.9z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,448.7 44.3,444 44.3,433.6 54.3,438.3 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,444 42.1,443 42.1,427.7 44.3,428.8 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,443.9 44.3,439.2 44.3,428.8 54.3,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,453.3 54.1,448.6 54.1,438.2 64.1,442.9 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,448.6 51.9,447.6 51.9,432.3 54.1,433.3 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,448.5 54.1,443.8 54.1,433.3 64.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,457.9 63.9,453.2 63.9,442.8 73.9,447.4 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,453.2 61.7,452.2 61.7,436.9 63.9,437.9 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,453 63.9,448.4 63.9,437.9 73.9,442.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,462.5 73.7,457.8 73.7,447.3 83.7,452 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,457.8 71.5,456.8 71.5,441.5 73.7,442.5 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,457.6 73.7,452.9 73.7,442.5 83.7,447.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,467.1 83.5,462.4 83.5,451.9 93.5,456.6 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,462.4 81.3,461.3 81.3,446.1 83.5,447.1 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,462.2 83.5,457.5 83.5,447.1 93.5,451.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,471.6 93.3,466.9 93.3,456.5 103.3,461.2 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,467 91.1,465.9 91.1,450.7 93.3,451.7 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,466.8 93.3,462.1 93.3,451.7 103.3,456.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,476.2 103,471.5 103,461.1 113.1,465.8 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,471.5 100.9,470.5 100.9,455.3 103.1,456.3 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,471.4 103,466.7 103,456.3 113.1,461 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,480.8 112.8,476.1 112.8,465.7 122.9,470.4 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,476.1 110.7,475.1 110.7,459.8 112.9,460.9 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,476 112.8,471.3 112.8,460.9 122.9,465.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,485.4 122.6,480.7 122.6,470.3 132.7,475 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,480.7 120.5,479.7 120.5,464.4 122.7,465.5 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,480.6 122.6,475.9 122.6,465.4 132.7,470.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,490 132.4,485.3 132.4,474.9 142.5,479.6 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,485.3 130.2,484.3 130.2,469 132.4,470 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,485.2 132.4,480.5 132.4,470 142.5,474.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,494.6 142.2,489.9 142.2,479.5 152.3,484.1 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,489.9 140,488.9 140,473.6 142.2,474.6 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,489.7 142.2,485.1 142.2,474.6 152.3,479.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,498.8 152,494.5 152,484 161.2,488.3 					"
                    />
                    <polygon
                      className="st9"
                      points="152,494.5 149.8,493.4 149.8,478.2 152,479.2 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,493.8 152,489.6 152,479.2 160.9,483.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,499.1 159.6,498 159.6,482.8 161.8,483.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,493.4 164.1,498 164.1,487.6 174.1,482.9 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,498 161.9,499.1 161.9,483.8 164.1,482.8 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,488.5 164.1,493.2 164.1,482.8 174.1,478.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,488.8 173.9,493.5 173.9,483 183.9,478.3 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,493.5 171.7,494.5 171.7,479.2 173.9,478.2 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,483.9 173.9,488.6 173.9,478.2 183.9,473.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,484.2 183.7,488.9 183.7,478.4 193.7,473.8 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,488.9 181.5,489.9 181.5,474.6 183.7,473.6 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,479.3 183.7,484 183.7,473.6 193.7,468.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,479.6 193.5,484.3 193.5,473.9 203.5,469.2 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,484.3 191.3,485.3 191.3,470 193.5,469 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,474.8 193.5,479.4 193.5,469 203.5,464.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,475 203.3,479.7 203.3,469.3 213.3,464.6 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,479.7 201.1,480.7 201.1,465.5 203.3,464.4 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,470.2 203.3,474.9 203.3,464.4 213.3,459.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,470.4 213.1,475.1 213.1,464.7 223.1,460 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,475.1 210.9,476.1 210.9,460.9 213.1,459.8 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,465.6 213.1,470.3 213.1,459.8 223.1,455.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,465.8 222.9,470.5 222.9,460.1 232.9,455.4 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,470.5 220.7,471.5 220.7,456.3 222.9,455.3 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,461 222.9,465.7 222.9,455.3 232.9,450.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,461.2 232.7,465.9 232.7,455.5 242.7,450.8 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,465.9 230.5,467 230.5,451.7 232.7,450.7 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,456.4 232.7,461.1 232.7,450.7 242.7,446 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,457.2 242.5,461.3 242.5,450.9 251.4,446.7 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,461.3 240.3,462.4 240.3,447.1 242.5,446.1 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,452.5 242.5,456.5 242.5,446.1 251.1,442 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,456.8 250.1,457.8 250.1,442.5 252.3,441.5 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,485.4 122.6,480.7 122.6,470.3 132.7,475 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,480.6 122.6,475.9 122.6,465.4 132.7,470.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,490 132.4,485.3 132.4,474.9 142.5,479.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,485.3 130.2,484.3 130.2,469 132.4,470 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,485.2 132.4,480.5 132.4,470 142.5,474.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,494.6 142.2,489.9 142.2,479.5 152.3,484.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,489.9 140,488.9 140,473.6 142.2,474.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,489.7 142.2,485.1 142.2,474.6 152.3,479.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,498.8 152,494.5 152,484 161.2,488.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,494.5 149.8,493.4 149.8,478.2 152,479.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,493.8 152,489.6 152,479.2 160.9,483.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,499.1 159.6,498 159.6,482.8 161.8,483.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,493.4 164.1,498 164.1,487.6 174.1,482.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,498 161.9,499.1 161.9,483.8 164.1,482.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,488.5 164.1,493.2 164.1,482.8 174.1,478.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,488.8 173.9,493.5 173.9,483 183.9,478.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,493.5 171.7,494.5 171.7,479.2 173.9,478.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,483.9 173.9,488.6 173.9,478.2 183.9,473.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,484.2 183.7,488.9 183.7,478.4 193.7,473.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,488.9 181.5,489.9 181.5,474.6 183.7,473.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,479.3 183.7,484 183.7,473.6 193.7,468.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,479.6 193.5,484.3 193.5,473.9 203.5,469.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,484.3 191.3,485.3 191.3,470 193.5,469 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,474.8 193.5,479.4 193.5,469 203.5,464.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,475 203.3,479.7 203.3,469.3 213.3,464.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,479.7 201.1,480.7 201.1,465.5 203.3,464.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,470.2 203.3,474.9 203.3,464.4 213.3,459.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,470.5 213.1,475.1 213.1,464.7 222.7,460.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,475.1 210.9,476.1 210.9,460.9 213.1,459.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,465.7 213.1,470.3 213.1,459.8 222.7,455.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,470.5 220.7,471.5 220.7,456.3 222.9,455.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,451.7 152,451.7 122.4,465.5 122.6,465.6 161.9,484 191.4,470.2 223,455.4 223,455.4
			183.6,436.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,448.7 44.3,444 44.3,433.6 54.3,438.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,444 42.1,443 42.1,427.7 44.3,428.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,443.9 44.3,439.2 44.3,428.8 54.3,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,453.3 54.1,448.6 54.1,438.2 64.1,442.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,448.6 51.9,447.6 51.9,432.3 54.1,433.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,448.5 54.1,443.8 54.1,433.3 64.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,457.9 63.9,453.2 63.9,442.8 73.9,447.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,453.2 61.7,452.2 61.7,436.9 63.9,437.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,453 63.9,448.4 63.9,437.9 73.9,442.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,462.5 73.7,457.8 73.7,447.3 83.7,452 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,457.8 71.5,456.8 71.5,441.5 73.7,442.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,457.6 73.7,452.9 73.7,442.5 83.7,447.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,467.1 83.5,462.4 83.5,451.9 93.5,456.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,462.4 81.3,461.3 81.3,446.1 83.5,447.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,462.2 83.5,457.5 83.5,447.1 93.5,451.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,471.6 93.3,466.9 93.3,456.5 103.3,461.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,467 91.1,465.9 91.1,450.7 93.3,451.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,466.8 93.3,462.1 93.3,451.7 103.3,456.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,476.2 103,471.5 103,461.1 113.1,465.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,471.4 103,466.7 103,456.3 113.1,461 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,471.5 100.9,470.5 100.9,455.3 103.1,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,480.7 112.8,476.1 112.8,465.7 122.7,470.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,475.9 112.8,471.3 112.8,460.9 122.7,465.5 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,476.1 110.7,475.1 110.7,459.8 112.9,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,480.7 120.5,479.7 120.5,464.4 122.7,465.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,465.8 222.9,470.5 222.9,460.1 232.9,455.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,461 222.9,465.7 222.9,455.3 232.9,450.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,461.2 232.7,465.9 232.7,455.5 242.7,450.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,465.9 230.5,467 230.5,451.7 232.7,450.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,456.4 232.7,461.1 232.7,450.7 242.7,446 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,457.2 242.5,461.3 242.5,450.9 251.4,446.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,461.3 240.3,462.4 240.3,447.1 242.5,446.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,452.5 242.5,456.5 242.5,446.1 251.1,442 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,456.8 250.1,457.8 250.1,442.5 252.3,441.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,423.1 213,423.1 171.7,403.7 171.7,403.7 132.5,385.4 103.1,399.2 71.5,414 42,427.8
			81.2,446.2 122.4,465.5 152,451.7 110.7,432.3 142.3,417.5 142.3,417.5 183.6,436.9 183.7,436.9 183.6,436.9 223,455.4
			252.4,441.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,485.4 122.6,480.7 122.6,470.3 132.7,475 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,480.6 122.6,475.9 122.6,465.4 132.7,470.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,490 132.4,485.3 132.4,474.9 142.5,479.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,485.3 130.2,484.3 130.2,469 132.4,470 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,485.2 132.4,480.5 132.4,470 142.5,474.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,494.6 142.2,489.9 142.2,479.5 152.3,484.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,489.9 140,488.9 140,473.6 142.2,474.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,489.7 142.2,485.1 142.2,474.6 152.3,479.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,498.8 152,494.5 152,484 161.2,488.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,494.5 149.8,493.4 149.8,478.2 152,479.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,493.8 152,489.6 152,479.2 160.9,483.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,499.1 159.6,498 159.6,482.8 161.8,483.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,493.4 164.1,498 164.1,487.6 174.1,482.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,498 161.9,499.1 161.9,483.8 164.1,482.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,488.5 164.1,493.2 164.1,482.8 174.1,478.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,488.8 173.9,493.5 173.9,483 183.9,478.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,493.5 171.7,494.5 171.7,479.2 173.9,478.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,483.9 173.9,488.6 173.9,478.2 183.9,473.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,484.2 183.7,488.9 183.7,478.4 193.7,473.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,488.9 181.5,489.9 181.5,474.6 183.7,473.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,479.3 183.7,484 183.7,473.6 193.7,468.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,479.6 193.5,484.3 193.5,473.9 203.5,469.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,484.3 191.3,485.3 191.3,470 193.5,469 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,474.8 193.5,479.4 193.5,469 203.5,464.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,475 203.3,479.7 203.3,469.3 213.3,464.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,479.7 201.1,480.7 201.1,465.5 203.3,464.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,470.2 203.3,474.9 203.3,464.4 213.3,459.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,470.4 213.1,475.1 213.1,464.7 223.1,460 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,475.1 210.9,476.1 210.9,460.9 213.1,459.8 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,465.6 213.1,470.3 213.1,459.8 223.1,455.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,465.8 222.9,470.5 222.9,460.1 232.9,455.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,470.5 220.7,471.5 220.7,456.3 222.9,455.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,461 222.9,465.7 222.9,455.3 232.9,450.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,461.2 232.7,465.9 232.7,455.5 242.7,450.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,465.9 230.5,467 230.5,451.7 232.7,450.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,456.4 232.7,461.1 232.7,450.7 242.7,446 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,457.2 242.5,461.3 242.5,450.9 251.4,446.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,461.3 240.3,462.4 240.3,447.1 242.5,446.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,452.5 242.5,456.5 242.5,446.1 251.1,442 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,456.8 250.1,457.8 250.1,442.5 252.3,441.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,423.1 213,423.1 183.6,436.9 183.7,436.9 183.6,436.9 152.1,451.7 152,451.7 122.4,465.5
			122.6,465.6 161.9,484 191.4,470.2 223,455.4 223,455.4 252.4,441.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,448.7 44.3,444 44.3,433.6 54.3,438.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,444 42.1,443 42.1,427.7 44.3,428.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,443.9 44.3,439.2 44.3,428.8 54.3,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,453.3 54.1,448.6 54.1,438.2 64.1,442.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,448.6 51.9,447.6 51.9,432.3 54.1,433.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,448.5 54.1,443.8 54.1,433.3 64.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,457.9 63.9,453.2 63.9,442.8 73.9,447.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,453.2 61.7,452.2 61.7,436.9 63.9,437.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,453 63.9,448.4 63.9,437.9 73.9,442.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,462.5 73.7,457.8 73.7,447.3 83.7,452 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,457.8 71.5,456.8 71.5,441.5 73.7,442.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,457.6 73.7,452.9 73.7,442.5 83.7,447.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,467.1 83.5,462.4 83.5,451.9 93.5,456.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,462.4 81.3,461.3 81.3,446.1 83.5,447.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,462.2 83.5,457.5 83.5,447.1 93.5,451.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,471.6 93.3,466.9 93.3,456.5 103.3,461.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,467 91.1,465.9 91.1,450.7 93.3,451.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,466.8 93.3,462.1 93.3,451.7 103.3,456.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,476.2 103,471.5 103,461.1 113.1,465.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,471.4 103,466.7 103,456.3 113.1,461 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,471.5 100.9,470.5 100.9,455.3 103.1,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,480.7 112.8,476.1 112.8,465.7 122.6,470.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,475.9 112.8,471.3 112.8,460.9 122.6,465.5 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,476.1 110.7,475.1 110.7,459.8 112.9,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,480.7 120.5,479.7 120.5,464.4 122.7,465.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,417.5 183.6,436.9 213,423.1 171.7,403.7 171.7,403.7 132.5,385.4 103.1,399.2 71.5,414
			42,427.8 81.2,446.2 122.4,465.5 152,451.7 110.7,432.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,485.4 122.6,480.7 122.6,470.3 132.7,475 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,480.6 122.6,475.9 122.6,465.4 132.7,470.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,490 132.4,485.3 132.4,474.9 142.5,479.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,485.3 130.2,484.3 130.2,469 132.4,470 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,485.2 132.4,480.5 132.4,470 142.5,474.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,494.6 142.2,489.9 142.2,479.5 152.3,484.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,489.9 140,488.9 140,473.6 142.2,474.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,489.7 142.2,485.1 142.2,474.6 152.3,479.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,498.8 152,494.5 152,484 161.2,488.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,494.5 149.8,493.4 149.8,478.2 152,479.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,493.8 152,489.6 152,479.2 160.9,483.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,499.1 159.6,498 159.6,482.8 161.8,483.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,493.4 164.1,498 164.1,487.6 174.1,482.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,498 161.9,499.1 161.9,483.8 164.1,482.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,488.5 164.1,493.2 164.1,482.8 174.1,478.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,488.8 173.9,493.5 173.9,483 183.9,478.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,493.5 171.7,494.5 171.7,479.2 173.9,478.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,483.9 173.9,488.6 173.9,478.2 183.9,473.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,484.2 183.7,488.9 183.7,478.4 193.7,473.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,488.9 181.5,489.9 181.5,474.6 183.7,473.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,479.3 183.7,484 183.7,473.6 193.7,468.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,479.6 193.5,484.3 193.5,473.9 203.5,469.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,484.3 191.3,485.3 191.3,470 193.5,469 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,474.8 193.5,479.4 193.5,469 203.5,464.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,475 203.3,479.7 203.3,469.3 213.3,464.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,479.7 201.1,480.7 201.1,465.5 203.3,464.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,470.2 203.3,474.9 203.3,464.4 213.3,459.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,470.4 213.1,475.1 213.1,464.7 223.1,460 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,475.1 210.9,476.1 210.9,460.9 213.1,459.8 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,465.6 213.1,470.3 213.1,459.8 223.1,455.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,465.8 222.9,470.5 222.9,460.1 232.9,455.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,470.5 220.7,471.5 220.7,456.3 222.9,455.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,461 222.9,465.7 222.9,455.3 232.9,450.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,461.2 232.7,465.9 232.7,455.5 242.7,450.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,465.9 230.5,467 230.5,451.7 232.7,450.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,456.4 232.7,461.1 232.7,450.7 242.7,446 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,457.2 242.5,461.3 242.5,450.9 251.4,446.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,461.3 240.3,462.4 240.3,447.1 242.5,446.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,452.5 242.5,456.5 242.5,446.1 251.1,442 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,456.8 250.1,457.8 250.1,442.5 252.3,441.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,423.1 213,423.1 171.7,403.7 142.3,417.5 183.6,436.9 183.7,436.9 183.6,436.9 152.1,451.7
			152,451.7 122.4,465.5 122.6,465.6 161.9,484 191.4,470.2 223,455.4 223,455.4 252.4,441.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,448.7 44.3,444 44.3,433.6 54.3,438.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,444 42.1,443 42.1,427.7 44.3,428.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,443.9 44.3,439.2 44.3,428.8 54.3,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,453.3 54.1,448.6 54.1,438.2 64.1,442.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,448.6 51.9,447.6 51.9,432.3 54.1,433.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,448.5 54.1,443.8 54.1,433.3 64.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,457.9 63.9,453.2 63.9,442.8 73.9,447.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,453.2 61.7,452.2 61.7,436.9 63.9,437.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,453 63.9,448.4 63.9,437.9 73.9,442.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,462.5 73.7,457.8 73.7,447.3 83.7,452 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,457.8 71.5,456.8 71.5,441.5 73.7,442.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,457.6 73.7,452.9 73.7,442.5 83.7,447.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,467.1 83.5,462.4 83.5,451.9 93.5,456.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,462.4 81.3,461.3 81.3,446.1 83.5,447.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,462.2 83.5,457.5 83.5,447.1 93.5,451.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,471.6 93.3,466.9 93.3,456.5 103.3,461.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,467 91.1,465.9 91.1,450.7 93.3,451.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,466.8 93.3,462.1 93.3,451.7 103.3,456.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,476.2 103,471.5 103,461.1 113.1,465.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,471.4 103,466.7 103,456.3 113.1,461 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,471.5 100.9,470.5 100.9,455.3 103.1,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,480.7 112.8,476.1 112.8,465.7 122.6,470.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,475.9 112.8,471.3 112.8,460.9 122.6,465.5 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,476.1 110.7,475.1 110.7,459.8 112.9,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,480.7 120.5,479.7 120.5,464.4 122.7,465.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,432.3 142.3,417.5 142.3,417.5 171.7,403.7 171.7,403.7 132.5,385.4 103.1,399.2 71.5,414
			42,427.8 81.2,446.2 122.4,465.5 152,451.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={4} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,448.7 44.3,444 44.3,433.6 54.3,438.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,444 42.1,443 42.1,427.7 44.3,428.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,443.9 44.3,439.2 44.3,428.8 54.3,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,453.3 54.1,448.6 54.1,438.2 64.1,442.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,448.6 51.9,447.6 51.9,432.3 54.1,433.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,448.5 54.1,443.8 54.1,433.3 64.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,457.9 63.9,453.2 63.9,442.8 73.9,447.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,453.2 61.7,452.2 61.7,436.9 63.9,437.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,453 63.9,448.4 63.9,437.9 73.9,442.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,462.5 73.7,457.8 73.7,447.3 83.7,452 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,457.8 71.5,456.8 71.5,441.5 73.7,442.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,457.6 73.7,452.9 73.7,442.5 83.7,447.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,467.1 83.5,462.4 83.5,451.9 93.5,456.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,462.4 81.3,461.3 81.3,446.1 83.5,447.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,462.2 83.5,457.5 83.5,447.1 93.5,451.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,471.6 93.3,466.9 93.3,456.5 103.3,461.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,467 91.1,465.9 91.1,450.7 93.3,451.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,466.8 93.3,462.1 93.3,451.7 103.3,456.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,471.5 100.9,470.5 100.9,455.3 103.1,456.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,476.2 103,471.5 103,461.1 113.1,465.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,471.4 103,466.7 103,456.3 113.1,461 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,476.1 110.7,475.1 110.7,459.8 112.9,460.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,480.8 112.8,476.1 112.8,465.7 122.9,470.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,476 112.8,471.3 112.8,460.9 122.9,465.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,480.7 120.5,479.7 120.5,464.4 122.7,465.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,485.4 122.6,480.7 122.6,470.3 132.7,475 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,480.6 122.6,475.9 122.6,465.4 132.7,470.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,490 132.4,485.3 132.4,474.9 142.5,479.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,485.3 130.2,484.3 130.2,469 132.4,470 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,485.2 132.4,480.5 132.4,470 142.5,474.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,494.6 142.2,489.9 142.2,479.5 152.3,484.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,489.9 140,488.9 140,473.6 142.2,474.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,489.7 142.2,485.1 142.2,474.6 152.3,479.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,498.8 152,494.5 152,484 161.2,488.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,494.5 149.8,493.4 149.8,478.2 152,479.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,493.8 152,489.6 152,479.2 160.9,483.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,499.1 159.6,498 159.6,482.8 161.8,483.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,493.4 164.1,498 164.1,487.6 174.1,482.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,498 161.9,499.1 161.9,483.8 164.1,482.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,488.5 164.1,493.2 164.1,482.8 174.1,478.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,488.8 173.9,493.5 173.9,483 183.9,478.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,493.5 171.7,494.5 171.7,479.2 173.9,478.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,483.9 173.9,488.6 173.9,478.2 183.9,473.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,484.2 183.7,488.9 183.7,478.4 193.7,473.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,488.9 181.5,489.9 181.5,474.6 183.7,473.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,479.3 183.7,484 183.7,473.6 193.7,468.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,479.6 193.5,484.3 193.5,473.9 203.5,469.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,484.3 191.3,485.3 191.3,470 193.5,469 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,474.8 193.5,479.4 193.5,469 203.5,464.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,475 203.3,479.7 203.3,469.3 213.3,464.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,479.7 201.1,480.7 201.1,465.5 203.3,464.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,470.2 203.3,474.9 203.3,464.4 213.3,459.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,470.4 213.1,475.1 213.1,464.7 223.1,460 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,475.1 210.9,476.1 210.9,460.9 213.1,459.8 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,465.6 213.1,470.3 213.1,459.8 223.1,455.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,465.8 222.9,470.5 222.9,460.1 232.9,455.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,470.5 220.7,471.5 220.7,456.3 222.9,455.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,461 222.9,465.7 222.9,455.3 232.9,450.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,461.2 232.7,465.9 232.7,455.5 242.7,450.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,465.9 230.5,467 230.5,451.7 232.7,450.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,456.4 232.7,461.1 232.7,450.7 242.7,446 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,457.2 242.5,461.3 242.5,450.9 251.4,446.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,461.3 240.3,462.4 240.3,447.1 242.5,446.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,452.5 242.5,456.5 242.5,446.1 251.1,442 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,456.8 250.1,457.8 250.1,442.5 252.3,441.5 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,423.1L213.1,423.1l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8L71.5,414L42,427.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,423.1z M152.1,451.7L152.1,451.7l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,451.7z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={5}
          >
            <FloorNeutral openFloors={openFloors} floor={5}>
              <polygon
                className="st1"
                points="152,434.5 183.6,419.7 183.6,419.7 183.6,419.7 142.2,400.3 142.2,400.3 110.6,415.1 		"
              />
              <path
                className="st16"
                d="M213,405.9L213,405.9l-41.4-19.4l0,0l-39.2-18.3L103,382l-31.6,14.8l-29.5,13.8L81.1,429l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,405.9z M183.6,419.7L152,434.5l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,419.7
			L183.6,419.7L183.6,419.7z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,431.5 44.3,426.8 44.3,416.4 54.3,421.1 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,426.8 42.1,425.8 42.1,410.5 44.3,411.6 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,426.7 44.3,422 44.3,411.6 54.3,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,436.1 54.1,431.4 54.1,421 64.1,425.7 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,431.4 51.9,430.4 51.9,415.1 54.1,416.1 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,431.3 54.1,426.6 54.1,416.1 64.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,440.7 63.9,436 63.9,425.6 73.9,430.2 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,436 61.7,435 61.7,419.7 63.9,420.7 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,435.8 63.9,431.2 63.9,420.7 73.9,425.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,445.3 73.7,440.6 73.7,430.1 83.7,434.8 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,440.6 71.5,439.6 71.5,424.3 73.7,425.3 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,440.4 73.7,435.7 73.7,425.3 83.7,430 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,449.9 83.5,445.2 83.5,434.7 93.5,439.4 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,445.2 81.3,444.1 81.3,428.9 83.5,429.9 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,445 83.5,440.3 83.5,429.9 93.5,434.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,454.4 93.3,449.7 93.3,439.3 103.3,444 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,449.8 91.1,448.7 91.1,433.5 93.3,434.5 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,449.6 93.3,444.9 93.3,434.5 103.3,439.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,459 103,454.3 103,443.9 113.1,448.6 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,454.3 100.9,453.3 100.9,438.1 103.1,439.1 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,454.2 103,449.5 103,439.1 113.1,443.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,463.6 112.8,458.9 112.8,448.5 122.9,453.2 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,458.9 110.7,457.9 110.7,442.6 112.9,443.7 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,458.8 112.8,454.1 112.8,443.7 122.9,448.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,468.2 122.6,463.5 122.6,453.1 132.7,457.8 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,463.5 120.5,462.5 120.5,447.2 122.7,448.3 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,463.4 122.6,458.7 122.6,448.2 132.7,452.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,472.8 132.4,468.1 132.4,457.7 142.5,462.4 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,468.1 130.2,467.1 130.2,451.8 132.4,452.8 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,468 132.4,463.3 132.4,452.8 142.5,457.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,477.4 142.2,472.7 142.2,462.3 152.3,466.9 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,472.7 140,471.7 140,456.4 142.2,457.4 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,472.5 142.2,467.9 142.2,457.4 152.3,462.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,481.6 152,477.3 152,466.8 161.2,471.1 					"
                    />
                    <polygon
                      className="st9"
                      points="152,477.3 149.8,476.2 149.8,461 152,462 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,476.6 152,472.4 152,462 160.9,466.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,481.9 159.6,480.8 159.6,465.6 161.8,466.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,476.2 164.1,480.8 164.1,470.4 174.1,465.7 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,480.8 161.9,481.9 161.9,466.6 164.1,465.6 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,471.3 164.1,476 164.1,465.6 174.1,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,471.6 173.9,476.3 173.9,465.8 183.9,461.1 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,476.3 171.7,477.3 171.7,462 173.9,461 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,466.7 173.9,471.4 173.9,461 183.9,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,467 183.7,471.7 183.7,461.2 193.7,456.6 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,471.7 181.5,472.7 181.5,457.4 183.7,456.4 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,462.1 183.7,466.8 183.7,456.4 193.7,451.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,462.4 193.5,467.1 193.5,456.7 203.5,452 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,467.1 191.3,468.1 191.3,452.8 193.5,451.8 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,457.6 193.5,462.2 193.5,451.8 203.5,447.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,457.8 203.3,462.5 203.3,452.1 213.3,447.4 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,462.5 201.1,463.5 201.1,448.3 203.3,447.2 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,453 203.3,457.7 203.3,447.2 213.3,442.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,453.2 213.1,457.9 213.1,447.5 223.1,442.8 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,457.9 210.9,458.9 210.9,443.7 213.1,442.6 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,448.4 213.1,453.1 213.1,442.6 223.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,448.6 222.9,453.3 222.9,442.9 232.9,438.2 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,453.3 220.7,454.3 220.7,439.1 222.9,438.1 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,443.8 222.9,448.5 222.9,438.1 232.9,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,444 232.7,448.7 232.7,438.3 242.7,433.6 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,448.7 230.5,449.8 230.5,434.5 232.7,433.5 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,439.2 232.7,443.9 232.7,433.5 242.7,428.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,440 242.5,444.1 242.5,433.7 251.4,429.5 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,444.1 240.3,445.2 240.3,429.9 242.5,428.9 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,435.3 242.5,439.3 242.5,428.9 251.1,424.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,439.6 250.1,440.6 250.1,425.3 252.3,424.3 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,468.2 122.6,463.5 122.6,453.1 132.7,457.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,463.4 122.6,458.7 122.6,448.2 132.7,452.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,472.8 132.4,468.1 132.4,457.7 142.5,462.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,468.1 130.2,467.1 130.2,451.8 132.4,452.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,468 132.4,463.3 132.4,452.8 142.5,457.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,477.4 142.2,472.7 142.2,462.3 152.3,466.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,472.7 140,471.7 140,456.4 142.2,457.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,472.5 142.2,467.9 142.2,457.4 152.3,462.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,481.6 152,477.3 152,466.8 161.2,471.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152,477.3 149.8,476.2 149.8,461 152,462 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,476.6 152,472.4 152,462 160.9,466.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,481.9 159.6,480.8 159.6,465.6 161.8,466.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,476.2 164.1,480.8 164.1,470.4 174.1,465.7 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,480.8 161.9,481.9 161.9,466.6 164.1,465.6 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,471.3 164.1,476 164.1,465.6 174.1,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,471.6 173.9,476.3 173.9,465.8 183.9,461.1 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,476.3 171.7,477.3 171.7,462 173.9,461 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,466.7 173.9,471.4 173.9,461 183.9,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,467 183.7,471.7 183.7,461.2 193.7,456.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,471.7 181.5,472.7 181.5,457.4 183.7,456.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,462.1 183.7,466.8 183.7,456.4 193.7,451.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,462.4 193.5,467.1 193.5,456.7 203.5,452 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,467.1 191.3,468.1 191.3,452.8 193.5,451.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,457.6 193.5,462.2 193.5,451.8 203.5,447.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,457.8 203.3,462.5 203.3,452.1 213.3,447.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,462.5 201.1,463.5 201.1,448.3 203.3,447.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,453 203.3,457.7 203.3,447.2 213.3,442.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,453.3 213.1,457.9 213.1,447.5 222.7,442.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,457.9 210.9,458.9 210.9,443.7 213.1,442.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,448.5 213.1,453.1 213.1,442.6 222.7,438.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,453.3 220.7,454.3 220.7,439.1 222.9,438.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,434.5 152,434.5 122.4,448.3 122.6,448.4 161.9,466.8 191.4,453 223,438.2 223,438.2
			183.6,419.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,431.5 44.3,426.8 44.3,416.4 54.3,421.1 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,426.8 42.1,425.8 42.1,410.5 44.3,411.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,426.7 44.3,422 44.3,411.6 54.3,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,436.1 54.1,431.4 54.1,421 64.1,425.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,431.4 51.9,430.4 51.9,415.1 54.1,416.1 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,431.3 54.1,426.6 54.1,416.1 64.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,440.7 63.9,436 63.9,425.6 73.9,430.2 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,436 61.7,435 61.7,419.7 63.9,420.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,435.8 63.9,431.2 63.9,420.7 73.9,425.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,445.3 73.7,440.6 73.7,430.1 83.7,434.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,440.6 71.5,439.6 71.5,424.3 73.7,425.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,440.4 73.7,435.7 73.7,425.3 83.7,430 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,449.9 83.5,445.2 83.5,434.7 93.5,439.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,445.2 81.3,444.1 81.3,428.9 83.5,429.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,445 83.5,440.3 83.5,429.9 93.5,434.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,454.4 93.3,449.7 93.3,439.3 103.3,444 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,449.8 91.1,448.7 91.1,433.5 93.3,434.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,449.6 93.3,444.9 93.3,434.5 103.3,439.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,459 103,454.3 103,443.9 113.1,448.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,454.2 103,449.5 103,439.1 113.1,443.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,454.3 100.9,453.3 100.9,438.1 103.1,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,463.5 112.8,458.9 112.8,448.5 122.7,453.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,458.7 112.8,454.1 112.8,443.7 122.7,448.3 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,458.9 110.7,457.9 110.7,442.6 112.9,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,463.5 120.5,462.5 120.5,447.2 122.7,448.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,448.6 222.9,453.3 222.9,442.9 232.9,438.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,443.8 222.9,448.5 222.9,438.1 232.9,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,444 232.7,448.7 232.7,438.3 242.7,433.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,448.7 230.5,449.8 230.5,434.5 232.7,433.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,439.2 232.7,443.9 232.7,433.5 242.7,428.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,440 242.5,444.1 242.5,433.7 251.4,429.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,444.1 240.3,445.2 240.3,429.9 242.5,428.9 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,435.3 242.5,439.3 242.5,428.9 251.1,424.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,439.6 250.1,440.6 250.1,425.3 252.3,424.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,405.9 213,405.9 171.7,386.5 171.7,386.5 132.5,368.2 103.1,382 71.5,396.8 42,410.6
			81.2,429 122.4,448.3 152,434.5 110.7,415.1 142.3,400.3 142.3,400.3 183.6,419.7 183.7,419.7 183.6,419.7 223,438.2 252.4,424.4
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,468.2 122.6,463.5 122.6,453.1 132.7,457.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,463.4 122.6,458.7 122.6,448.2 132.7,452.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,472.8 132.4,468.1 132.4,457.7 142.5,462.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,468.1 130.2,467.1 130.2,451.8 132.4,452.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,468 132.4,463.3 132.4,452.8 142.5,457.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,477.4 142.2,472.7 142.2,462.3 152.3,466.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,472.7 140,471.7 140,456.4 142.2,457.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,472.5 142.2,467.9 142.2,457.4 152.3,462.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,481.6 152,477.3 152,466.8 161.2,471.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152,477.3 149.8,476.2 149.8,461 152,462 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,476.6 152,472.4 152,462 160.9,466.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,481.9 159.6,480.8 159.6,465.6 161.8,466.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,476.2 164.1,480.8 164.1,470.4 174.1,465.7 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,480.8 161.9,481.9 161.9,466.6 164.1,465.6 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,471.3 164.1,476 164.1,465.6 174.1,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,471.6 173.9,476.3 173.9,465.8 183.9,461.1 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,476.3 171.7,477.3 171.7,462 173.9,461 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,466.7 173.9,471.4 173.9,461 183.9,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,467 183.7,471.7 183.7,461.2 193.7,456.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,471.7 181.5,472.7 181.5,457.4 183.7,456.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,462.1 183.7,466.8 183.7,456.4 193.7,451.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,462.4 193.5,467.1 193.5,456.7 203.5,452 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,467.1 191.3,468.1 191.3,452.8 193.5,451.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,457.6 193.5,462.2 193.5,451.8 203.5,447.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,457.8 203.3,462.5 203.3,452.1 213.3,447.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,462.5 201.1,463.5 201.1,448.3 203.3,447.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,453 203.3,457.7 203.3,447.2 213.3,442.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,453.2 213.1,457.9 213.1,447.5 223.1,442.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,457.9 210.9,458.9 210.9,443.7 213.1,442.6 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,448.4 213.1,453.1 213.1,442.6 223.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,448.6 222.9,453.3 222.9,442.9 232.9,438.2 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,453.3 220.7,454.3 220.7,439.1 222.9,438.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,443.8 222.9,448.5 222.9,438.1 232.9,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,444 232.7,448.7 232.7,438.3 242.7,433.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,448.7 230.5,449.8 230.5,434.5 232.7,433.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,439.2 232.7,443.9 232.7,433.5 242.7,428.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,440 242.5,444.1 242.5,433.7 251.4,429.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,444.1 240.3,445.2 240.3,429.9 242.5,428.9 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,435.3 242.5,439.3 242.5,428.9 251.1,424.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,439.6 250.1,440.6 250.1,425.3 252.3,424.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,405.9 213,405.9 183.6,419.7 183.7,419.7 183.6,419.7 152.1,434.5 152,434.5 122.4,448.3
			122.6,448.4 161.9,466.8 191.4,453 223,438.2 223,438.2 252.4,424.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,431.5 44.3,426.8 44.3,416.4 54.3,421.1 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,426.8 42.1,425.8 42.1,410.5 44.3,411.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,426.7 44.3,422 44.3,411.6 54.3,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,436.1 54.1,431.4 54.1,421 64.1,425.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,431.4 51.9,430.4 51.9,415.1 54.1,416.1 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,431.3 54.1,426.6 54.1,416.1 64.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,440.7 63.9,436 63.9,425.6 73.9,430.2 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,436 61.7,435 61.7,419.7 63.9,420.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,435.8 63.9,431.2 63.9,420.7 73.9,425.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,445.3 73.7,440.6 73.7,430.1 83.7,434.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,440.6 71.5,439.6 71.5,424.3 73.7,425.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,440.4 73.7,435.7 73.7,425.3 83.7,430 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,449.9 83.5,445.2 83.5,434.7 93.5,439.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,445.2 81.3,444.1 81.3,428.9 83.5,429.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,445 83.5,440.3 83.5,429.9 93.5,434.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,454.4 93.3,449.7 93.3,439.3 103.3,444 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,449.8 91.1,448.7 91.1,433.5 93.3,434.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,449.6 93.3,444.9 93.3,434.5 103.3,439.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,459 103,454.3 103,443.9 113.1,448.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,454.2 103,449.5 103,439.1 113.1,443.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,454.3 100.9,453.3 100.9,438.1 103.1,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,463.5 112.8,458.9 112.8,448.5 122.6,453.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,458.7 112.8,454.1 112.8,443.7 122.6,448.3 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,458.9 110.7,457.9 110.7,442.6 112.9,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,463.5 120.5,462.5 120.5,447.2 122.7,448.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,400.3 183.6,419.7 213,405.9 171.7,386.5 171.7,386.5 132.5,368.2 103.1,382 71.5,396.8
			42,410.6 81.2,429 122.4,448.3 152,434.5 110.7,415.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,468.2 122.6,463.5 122.6,453.1 132.7,457.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,463.4 122.6,458.7 122.6,448.2 132.7,452.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,472.8 132.4,468.1 132.4,457.7 142.5,462.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,468.1 130.2,467.1 130.2,451.8 132.4,452.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,468 132.4,463.3 132.4,452.8 142.5,457.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,477.4 142.2,472.7 142.2,462.3 152.3,466.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,472.7 140,471.7 140,456.4 142.2,457.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,472.5 142.2,467.9 142.2,457.4 152.3,462.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,481.6 152,477.3 152,466.8 161.2,471.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152,477.3 149.8,476.2 149.8,461 152,462 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,476.6 152,472.4 152,462 160.9,466.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,481.9 159.6,480.8 159.6,465.6 161.8,466.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,476.2 164.1,480.8 164.1,470.4 174.1,465.7 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,480.8 161.9,481.9 161.9,466.6 164.1,465.6 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,471.3 164.1,476 164.1,465.6 174.1,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,471.6 173.9,476.3 173.9,465.8 183.9,461.1 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,476.3 171.7,477.3 171.7,462 173.9,461 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,466.7 173.9,471.4 173.9,461 183.9,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,467 183.7,471.7 183.7,461.2 193.7,456.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,471.7 181.5,472.7 181.5,457.4 183.7,456.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,462.1 183.7,466.8 183.7,456.4 193.7,451.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,462.4 193.5,467.1 193.5,456.7 203.5,452 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,467.1 191.3,468.1 191.3,452.8 193.5,451.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,457.6 193.5,462.2 193.5,451.8 203.5,447.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,457.8 203.3,462.5 203.3,452.1 213.3,447.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,462.5 201.1,463.5 201.1,448.3 203.3,447.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,453 203.3,457.7 203.3,447.2 213.3,442.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,453.2 213.1,457.9 213.1,447.5 223.1,442.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,457.9 210.9,458.9 210.9,443.7 213.1,442.6 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,448.4 213.1,453.1 213.1,442.6 223.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,448.6 222.9,453.3 222.9,442.9 232.9,438.2 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,453.3 220.7,454.3 220.7,439.1 222.9,438.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,443.8 222.9,448.5 222.9,438.1 232.9,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,444 232.7,448.7 232.7,438.3 242.7,433.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,448.7 230.5,449.8 230.5,434.5 232.7,433.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,439.2 232.7,443.9 232.7,433.5 242.7,428.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,440 242.5,444.1 242.5,433.7 251.4,429.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,444.1 240.3,445.2 240.3,429.9 242.5,428.9 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,435.3 242.5,439.3 242.5,428.9 251.1,424.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,439.6 250.1,440.6 250.1,425.3 252.3,424.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,405.9 213,405.9 171.7,386.5 142.3,400.3 183.6,419.7 183.7,419.7 183.6,419.7 152.1,434.5
			152,434.5 122.4,448.3 122.6,448.4 161.9,466.8 191.4,453 223,438.2 223,438.2 252.4,424.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,431.5 44.3,426.8 44.3,416.4 54.3,421.1 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,426.8 42.1,425.8 42.1,410.5 44.3,411.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,426.7 44.3,422 44.3,411.6 54.3,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,436.1 54.1,431.4 54.1,421 64.1,425.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,431.4 51.9,430.4 51.9,415.1 54.1,416.1 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,431.3 54.1,426.6 54.1,416.1 64.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,440.7 63.9,436 63.9,425.6 73.9,430.2 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,436 61.7,435 61.7,419.7 63.9,420.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,435.8 63.9,431.2 63.9,420.7 73.9,425.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,445.3 73.7,440.6 73.7,430.1 83.7,434.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,440.6 71.5,439.6 71.5,424.3 73.7,425.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,440.4 73.7,435.7 73.7,425.3 83.7,430 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,449.9 83.5,445.2 83.5,434.7 93.5,439.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,445.2 81.3,444.1 81.3,428.9 83.5,429.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,445 83.5,440.3 83.5,429.9 93.5,434.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,454.4 93.3,449.7 93.3,439.3 103.3,444 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,449.8 91.1,448.7 91.1,433.5 93.3,434.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,449.6 93.3,444.9 93.3,434.5 103.3,439.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,459 103,454.3 103,443.9 113.1,448.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,454.2 103,449.5 103,439.1 113.1,443.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,454.3 100.9,453.3 100.9,438.1 103.1,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,463.5 112.8,458.9 112.8,448.5 122.6,453.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,458.7 112.8,454.1 112.8,443.7 122.6,448.3 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,458.9 110.7,457.9 110.7,442.6 112.9,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,463.5 120.5,462.5 120.5,447.2 122.7,448.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,415.1 142.3,400.3 142.3,400.3 171.7,386.5 171.7,386.5 132.5,368.2 103.1,382 71.5,396.8
			42,410.6 81.2,429 122.4,448.3 152,434.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={5} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,431.5 44.3,426.8 44.3,416.4 54.3,421.1 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,426.8 42.1,425.8 42.1,410.5 44.3,411.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,426.7 44.3,422 44.3,411.6 54.3,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,436.1 54.1,431.4 54.1,421 64.1,425.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,431.4 51.9,430.4 51.9,415.1 54.1,416.1 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,431.3 54.1,426.6 54.1,416.1 64.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,440.7 63.9,436 63.9,425.6 73.9,430.2 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,436 61.7,435 61.7,419.7 63.9,420.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,435.8 63.9,431.2 63.9,420.7 73.9,425.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,445.3 73.7,440.6 73.7,430.1 83.7,434.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,440.6 71.5,439.6 71.5,424.3 73.7,425.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,440.4 73.7,435.7 73.7,425.3 83.7,430 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,449.9 83.5,445.2 83.5,434.7 93.5,439.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,445.2 81.3,444.1 81.3,428.9 83.5,429.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,445 83.5,440.3 83.5,429.9 93.5,434.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,454.4 93.3,449.7 93.3,439.3 103.3,444 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,449.8 91.1,448.7 91.1,433.5 93.3,434.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,449.6 93.3,444.9 93.3,434.5 103.3,439.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,454.3 100.9,453.3 100.9,438.1 103.1,439.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,459 103,454.3 103,443.9 113.1,448.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,454.2 103,449.5 103,439.1 113.1,443.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,458.9 110.7,457.9 110.7,442.6 112.9,443.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,463.6 112.8,458.9 112.8,448.5 122.9,453.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,458.8 112.8,454.1 112.8,443.7 122.9,448.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,463.5 120.5,462.5 120.5,447.2 122.7,448.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,468.2 122.6,463.5 122.6,453.1 132.7,457.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,463.4 122.6,458.7 122.6,448.2 132.7,452.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,472.8 132.4,468.1 132.4,457.7 142.5,462.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,468.1 130.2,467.1 130.2,451.8 132.4,452.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,468 132.4,463.3 132.4,452.8 142.5,457.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,477.4 142.2,472.7 142.2,462.3 152.3,466.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,472.7 140,471.7 140,456.4 142.2,457.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,472.5 142.2,467.9 142.2,457.4 152.3,462.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,481.6 152,477.3 152,466.8 161.2,471.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152,477.3 149.8,476.2 149.8,461 152,462 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,476.6 152,472.4 152,462 160.9,466.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,481.9 159.6,480.8 159.6,465.6 161.8,466.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,476.2 164.1,480.8 164.1,470.4 174.1,465.7 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,480.8 161.9,481.9 161.9,466.6 164.1,465.6 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,471.3 164.1,476 164.1,465.6 174.1,460.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,471.6 173.9,476.3 173.9,465.8 183.9,461.1 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,476.3 171.7,477.3 171.7,462 173.9,461 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,466.7 173.9,471.4 173.9,461 183.9,456.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,467 183.7,471.7 183.7,461.2 193.7,456.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,471.7 181.5,472.7 181.5,457.4 183.7,456.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,462.1 183.7,466.8 183.7,456.4 193.7,451.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,462.4 193.5,467.1 193.5,456.7 203.5,452 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,467.1 191.3,468.1 191.3,452.8 193.5,451.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,457.6 193.5,462.2 193.5,451.8 203.5,447.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,457.8 203.3,462.5 203.3,452.1 213.3,447.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,462.5 201.1,463.5 201.1,448.3 203.3,447.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,453 203.3,457.7 203.3,447.2 213.3,442.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,453.2 213.1,457.9 213.1,447.5 223.1,442.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,457.9 210.9,458.9 210.9,443.7 213.1,442.6 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,448.4 213.1,453.1 213.1,442.6 223.1,438 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,448.6 222.9,453.3 222.9,442.9 232.9,438.2 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,453.3 220.7,454.3 220.7,439.1 222.9,438.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,443.8 222.9,448.5 222.9,438.1 232.9,433.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,444 232.7,448.7 232.7,438.3 242.7,433.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,448.7 230.5,449.8 230.5,434.5 232.7,433.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,439.2 232.7,443.9 232.7,433.5 242.7,428.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,440 242.5,444.1 242.5,433.7 251.4,429.5 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,444.1 240.3,445.2 240.3,429.9 242.5,428.9 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,435.3 242.5,439.3 242.5,428.9 251.1,424.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,439.6 250.1,440.6 250.1,425.3 252.3,424.3 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,405.9L213.1,405.9l-41.4-19.4l0,0l-39.2-18.3L103.1,382l-31.6,14.8L42,410.6L81.2,429l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,405.9z M152.1,434.5L152.1,434.5l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,434.5z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={6}
          >
            <FloorNeutral openFloors={openFloors} floor={6}>
              <polygon
                className="st1"
                points="152,417.3 183.6,402.5 183.6,402.5 183.6,402.5 142.2,383.1 142.2,383.1 110.6,397.9 		"
              />
              <path
                className="st16"
                d="M213,388.7L213,388.7l-41.4-19.4l0,0L132.4,351L103,364.8l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,388.7z M183.6,402.5L152,417.3l0,0l-41.4-19.4l31.6-14.8l0,0
			L183.6,402.5L183.6,402.5L183.6,402.5z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,414.3 44.3,409.6 44.3,399.2 54.3,403.9 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,409.6 42.1,408.6 42.1,393.3 44.3,394.4 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,409.5 44.3,404.8 44.3,394.4 54.3,399 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,418.9 54.1,414.2 54.1,403.8 64.1,408.5 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,414.2 51.9,413.2 51.9,397.9 54.1,398.9 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,414.1 54.1,409.4 54.1,398.9 64.1,403.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,423.5 63.9,418.8 63.9,408.4 73.9,413 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,418.8 61.7,417.8 61.7,402.5 63.9,403.5 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,418.6 63.9,414 63.9,403.5 73.9,408.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,428.1 73.7,423.4 73.7,412.9 83.7,417.6 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,423.4 71.5,422.4 71.5,407.1 73.7,408.1 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,423.2 73.7,418.5 73.7,408.1 83.7,412.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,432.7 83.5,428 83.5,417.5 93.5,422.2 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,428 81.3,426.9 81.3,411.7 83.5,412.7 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,427.8 83.5,423.1 83.5,412.7 93.5,417.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,437.2 93.3,432.5 93.3,422.1 103.3,426.8 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,432.6 91.1,431.5 91.1,416.3 93.3,417.3 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,432.4 93.3,427.7 93.3,417.3 103.3,422 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,441.8 103,437.1 103,426.7 113.1,431.4 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,437.1 100.9,436.1 100.9,420.9 103.1,421.9 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,437 103,432.3 103,421.9 113.1,426.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,446.4 112.8,441.7 112.8,431.3 122.9,436 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,441.7 110.7,440.7 110.7,425.4 112.9,426.5 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,441.6 112.8,436.9 112.8,426.5 122.9,431.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,451 122.6,446.3 122.6,435.9 132.7,440.6 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,446.3 120.5,445.3 120.5,430 122.7,431.1 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,446.2 122.6,441.5 122.6,431 132.7,435.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,455.6 132.4,450.9 132.4,440.5 142.5,445.2 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,450.9 130.2,449.9 130.2,434.6 132.4,435.6 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,450.8 132.4,446.1 132.4,435.6 142.5,440.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,460.2 142.2,455.5 142.2,445.1 152.3,449.7 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,455.5 140,454.5 140,439.2 142.2,440.2 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,455.3 142.2,450.7 142.2,440.2 152.3,444.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,464.4 152,460.1 152,449.6 161.2,453.9 					"
                    />
                    <polygon
                      className="st9"
                      points="152,460.1 149.8,459 149.8,443.8 152,444.8 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,459.4 152,455.2 152,444.8 160.9,449 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,464.7 159.6,463.6 159.6,448.4 161.8,449.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,459 164.1,463.6 164.1,453.2 174.1,448.5 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,463.6 161.9,464.7 161.9,449.4 164.1,448.4 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,454.1 164.1,458.8 164.1,448.4 174.1,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,454.4 173.9,459.1 173.9,448.6 183.9,443.9 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,459.1 171.7,460.1 171.7,444.8 173.9,443.8 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,449.5 173.9,454.2 173.9,443.8 183.9,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,449.8 183.7,454.5 183.7,444 193.7,439.4 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,454.5 181.5,455.5 181.5,440.2 183.7,439.2 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,444.9 183.7,449.6 183.7,439.2 193.7,434.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,445.2 193.5,449.9 193.5,439.5 203.5,434.8 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,449.9 191.3,450.9 191.3,435.6 193.5,434.6 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,440.4 193.5,445 193.5,434.6 203.5,429.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,440.6 203.3,445.3 203.3,434.9 213.3,430.2 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,445.3 201.1,446.3 201.1,431.1 203.3,430 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,435.8 203.3,440.5 203.3,430 213.3,425.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,436 213.1,440.7 213.1,430.3 223.1,425.6 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,440.7 210.9,441.7 210.9,426.5 213.1,425.4 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,431.2 213.1,435.9 213.1,425.4 223.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,431.4 222.9,436.1 222.9,425.7 232.9,421 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,436.1 220.7,437.1 220.7,421.9 222.9,420.9 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,426.6 222.9,431.3 222.9,420.9 232.9,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,426.8 232.7,431.5 232.7,421.1 242.7,416.4 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,431.5 230.5,432.6 230.5,417.3 232.7,416.3 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,422 232.7,426.7 232.7,416.3 242.7,411.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,422.8 242.5,426.9 242.5,416.5 251.4,412.3 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,426.9 240.3,428 240.3,412.7 242.5,411.7 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,418.1 242.5,422.1 242.5,411.7 251.1,407.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,422.4 250.1,423.4 250.1,408.1 252.3,407.1 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,451 122.6,446.3 122.6,435.9 132.7,440.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,446.2 122.6,441.5 122.6,431 132.7,435.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,455.6 132.4,450.9 132.4,440.5 142.5,445.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,450.9 130.2,449.9 130.2,434.6 132.4,435.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,450.8 132.4,446.1 132.4,435.6 142.5,440.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,460.2 142.2,455.5 142.2,445.1 152.3,449.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,455.5 140,454.5 140,439.2 142.2,440.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,455.3 142.2,450.7 142.2,440.2 152.3,444.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,464.4 152,460.1 152,449.6 161.2,453.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,460.1 149.8,459 149.8,443.8 152,444.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,459.4 152,455.2 152,444.8 160.9,449 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,464.7 159.6,463.6 159.6,448.4 161.8,449.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,459 164.1,463.6 164.1,453.2 174.1,448.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,463.6 161.9,464.7 161.9,449.4 164.1,448.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,454.1 164.1,458.8 164.1,448.4 174.1,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,454.4 173.9,459.1 173.9,448.6 183.9,443.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,459.1 171.7,460.1 171.7,444.8 173.9,443.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,449.5 173.9,454.2 173.9,443.8 183.9,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,449.8 183.7,454.5 183.7,444 193.7,439.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,454.5 181.5,455.5 181.5,440.2 183.7,439.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,444.9 183.7,449.6 183.7,439.2 193.7,434.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,445.2 193.5,449.9 193.5,439.5 203.5,434.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,449.9 191.3,450.9 191.3,435.6 193.5,434.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,440.4 193.5,445 193.5,434.6 203.5,429.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,440.6 203.3,445.3 203.3,434.9 213.3,430.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,445.3 201.1,446.3 201.1,431.1 203.3,430 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,435.8 203.3,440.5 203.3,430 213.3,425.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,436.1 213.1,440.7 213.1,430.3 222.7,425.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,440.7 210.9,441.7 210.9,426.5 213.1,425.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,431.3 213.1,435.9 213.1,425.4 222.7,420.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,436.1 220.7,437.1 220.7,421.9 222.9,420.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,417.3 152,417.3 122.4,431.1 122.6,431.2 161.9,449.6 191.4,435.8 223,421 223,421
			183.6,402.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,414.3 44.3,409.6 44.3,399.2 54.3,403.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,409.6 42.1,408.6 42.1,393.3 44.3,394.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,409.5 44.3,404.8 44.3,394.4 54.3,399 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,418.9 54.1,414.2 54.1,403.8 64.1,408.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,414.2 51.9,413.2 51.9,397.9 54.1,398.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,414.1 54.1,409.4 54.1,398.9 64.1,403.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,423.5 63.9,418.8 63.9,408.4 73.9,413 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,418.8 61.7,417.8 61.7,402.5 63.9,403.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,418.6 63.9,414 63.9,403.5 73.9,408.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,428.1 73.7,423.4 73.7,412.9 83.7,417.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,423.4 71.5,422.4 71.5,407.1 73.7,408.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,423.2 73.7,418.5 73.7,408.1 83.7,412.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,432.7 83.5,428 83.5,417.5 93.5,422.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,428 81.3,426.9 81.3,411.7 83.5,412.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,427.8 83.5,423.1 83.5,412.7 93.5,417.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,437.2 93.3,432.5 93.3,422.1 103.3,426.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,432.6 91.1,431.5 91.1,416.3 93.3,417.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,432.4 93.3,427.7 93.3,417.3 103.3,422 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,441.8 103,437.1 103,426.7 113.1,431.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,437 103,432.3 103,421.9 113.1,426.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,437.1 100.9,436.1 100.9,420.9 103.1,421.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,446.3 112.8,441.7 112.8,431.3 122.7,435.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,441.5 112.8,436.9 112.8,426.5 122.7,431.1 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,441.7 110.7,440.7 110.7,425.4 112.9,426.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,446.3 120.5,445.3 120.5,430 122.7,431.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,431.4 222.9,436.1 222.9,425.7 232.9,421 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,426.6 222.9,431.3 222.9,420.9 232.9,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,426.8 232.7,431.5 232.7,421.1 242.7,416.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,431.5 230.5,432.6 230.5,417.3 232.7,416.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,422 232.7,426.7 232.7,416.3 242.7,411.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,422.8 242.5,426.9 242.5,416.5 251.4,412.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,426.9 240.3,428 240.3,412.7 242.5,411.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,418.1 242.5,422.1 242.5,411.7 251.1,407.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,422.4 250.1,423.4 250.1,408.1 252.3,407.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,388.7 213,388.7 171.7,369.3 171.7,369.3 132.5,351 103.1,364.8 71.5,379.6 42,393.4
			81.2,411.8 122.4,431.1 152,417.3 110.7,397.9 142.3,383.1 142.3,383.1 183.6,402.5 183.7,402.5 183.6,402.5 223,421 252.4,407.2 
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,451 122.6,446.3 122.6,435.9 132.7,440.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,446.2 122.6,441.5 122.6,431 132.7,435.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,455.6 132.4,450.9 132.4,440.5 142.5,445.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,450.9 130.2,449.9 130.2,434.6 132.4,435.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,450.8 132.4,446.1 132.4,435.6 142.5,440.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,460.2 142.2,455.5 142.2,445.1 152.3,449.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,455.5 140,454.5 140,439.2 142.2,440.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,455.3 142.2,450.7 142.2,440.2 152.3,444.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,464.4 152,460.1 152,449.6 161.2,453.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,460.1 149.8,459 149.8,443.8 152,444.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,459.4 152,455.2 152,444.8 160.9,449 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,464.7 159.6,463.6 159.6,448.4 161.8,449.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,459 164.1,463.6 164.1,453.2 174.1,448.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,463.6 161.9,464.7 161.9,449.4 164.1,448.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,454.1 164.1,458.8 164.1,448.4 174.1,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,454.4 173.9,459.1 173.9,448.6 183.9,443.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,459.1 171.7,460.1 171.7,444.8 173.9,443.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,449.5 173.9,454.2 173.9,443.8 183.9,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,449.8 183.7,454.5 183.7,444 193.7,439.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,454.5 181.5,455.5 181.5,440.2 183.7,439.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,444.9 183.7,449.6 183.7,439.2 193.7,434.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,445.2 193.5,449.9 193.5,439.5 203.5,434.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,449.9 191.3,450.9 191.3,435.6 193.5,434.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,440.4 193.5,445 193.5,434.6 203.5,429.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,440.6 203.3,445.3 203.3,434.9 213.3,430.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,445.3 201.1,446.3 201.1,431.1 203.3,430 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,435.8 203.3,440.5 203.3,430 213.3,425.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,436 213.1,440.7 213.1,430.3 223.1,425.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,440.7 210.9,441.7 210.9,426.5 213.1,425.4 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,431.2 213.1,435.9 213.1,425.4 223.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,431.4 222.9,436.1 222.9,425.7 232.9,421 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,436.1 220.7,437.1 220.7,421.9 222.9,420.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,426.6 222.9,431.3 222.9,420.9 232.9,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,426.8 232.7,431.5 232.7,421.1 242.7,416.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,431.5 230.5,432.6 230.5,417.3 232.7,416.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,422 232.7,426.7 232.7,416.3 242.7,411.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,422.8 242.5,426.9 242.5,416.5 251.4,412.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,426.9 240.3,428 240.3,412.7 242.5,411.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,418.1 242.5,422.1 242.5,411.7 251.1,407.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,422.4 250.1,423.4 250.1,408.1 252.3,407.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,388.7 213,388.7 183.6,402.5 183.7,402.5 183.6,402.5 152.1,417.3 152,417.3 122.4,431.1
			122.6,431.2 161.9,449.6 191.4,435.8 223,421 223,421 252.4,407.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,414.3 44.3,409.6 44.3,399.2 54.3,403.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,409.6 42.1,408.6 42.1,393.3 44.3,394.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,409.5 44.3,404.8 44.3,394.4 54.3,399 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,418.9 54.1,414.2 54.1,403.8 64.1,408.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,414.2 51.9,413.2 51.9,397.9 54.1,398.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,414.1 54.1,409.4 54.1,398.9 64.1,403.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,423.5 63.9,418.8 63.9,408.4 73.9,413 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,418.8 61.7,417.8 61.7,402.5 63.9,403.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,418.6 63.9,414 63.9,403.5 73.9,408.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,428.1 73.7,423.4 73.7,412.9 83.7,417.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,423.4 71.5,422.4 71.5,407.1 73.7,408.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,423.2 73.7,418.5 73.7,408.1 83.7,412.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,432.7 83.5,428 83.5,417.5 93.5,422.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,428 81.3,426.9 81.3,411.7 83.5,412.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,427.8 83.5,423.1 83.5,412.7 93.5,417.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,437.2 93.3,432.5 93.3,422.1 103.3,426.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,432.6 91.1,431.5 91.1,416.3 93.3,417.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,432.4 93.3,427.7 93.3,417.3 103.3,422 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,441.8 103,437.1 103,426.7 113.1,431.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,437 103,432.3 103,421.9 113.1,426.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,437.1 100.9,436.1 100.9,420.9 103.1,421.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,446.3 112.8,441.7 112.8,431.3 122.6,435.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,441.5 112.8,436.9 112.8,426.5 122.6,431.1 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,441.7 110.7,440.7 110.7,425.4 112.9,426.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,446.3 120.5,445.3 120.5,430 122.7,431.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,383.1 183.6,402.5 213,388.7 171.7,369.3 171.7,369.3 132.5,351 103.1,364.8 71.5,379.6
			42,393.4 81.2,411.8 122.4,431.1 152,417.3 110.7,397.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,451 122.6,446.3 122.6,435.9 132.7,440.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,446.2 122.6,441.5 122.6,431 132.7,435.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,455.6 132.4,450.9 132.4,440.5 142.5,445.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,450.9 130.2,449.9 130.2,434.6 132.4,435.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,450.8 132.4,446.1 132.4,435.6 142.5,440.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,460.2 142.2,455.5 142.2,445.1 152.3,449.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,455.5 140,454.5 140,439.2 142.2,440.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,455.3 142.2,450.7 142.2,440.2 152.3,444.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,464.4 152,460.1 152,449.6 161.2,453.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,460.1 149.8,459 149.8,443.8 152,444.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,459.4 152,455.2 152,444.8 160.9,449 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,464.7 159.6,463.6 159.6,448.4 161.8,449.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,459 164.1,463.6 164.1,453.2 174.1,448.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,463.6 161.9,464.7 161.9,449.4 164.1,448.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,454.1 164.1,458.8 164.1,448.4 174.1,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,454.4 173.9,459.1 173.9,448.6 183.9,443.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,459.1 171.7,460.1 171.7,444.8 173.9,443.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,449.5 173.9,454.2 173.9,443.8 183.9,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,449.8 183.7,454.5 183.7,444 193.7,439.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,454.5 181.5,455.5 181.5,440.2 183.7,439.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,444.9 183.7,449.6 183.7,439.2 193.7,434.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,445.2 193.5,449.9 193.5,439.5 203.5,434.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,449.9 191.3,450.9 191.3,435.6 193.5,434.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,440.4 193.5,445 193.5,434.6 203.5,429.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,440.6 203.3,445.3 203.3,434.9 213.3,430.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,445.3 201.1,446.3 201.1,431.1 203.3,430 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,435.8 203.3,440.5 203.3,430 213.3,425.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,436 213.1,440.7 213.1,430.3 223.1,425.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,440.7 210.9,441.7 210.9,426.5 213.1,425.4 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,431.2 213.1,435.9 213.1,425.4 223.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,431.4 222.9,436.1 222.9,425.7 232.9,421 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,436.1 220.7,437.1 220.7,421.9 222.9,420.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,426.6 222.9,431.3 222.9,420.9 232.9,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,426.8 232.7,431.5 232.7,421.1 242.7,416.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,431.5 230.5,432.6 230.5,417.3 232.7,416.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,422 232.7,426.7 232.7,416.3 242.7,411.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,422.8 242.5,426.9 242.5,416.5 251.4,412.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,426.9 240.3,428 240.3,412.7 242.5,411.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,418.1 242.5,422.1 242.5,411.7 251.1,407.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,422.4 250.1,423.4 250.1,408.1 252.3,407.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,388.7 213,388.7 171.7,369.3 142.3,383.1 183.6,402.5 183.7,402.5 183.6,402.5 152.1,417.3
			152,417.3 122.4,431.1 122.6,431.2 161.9,449.6 191.4,435.8 223,421 223,421 252.4,407.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,414.3 44.3,409.6 44.3,399.2 54.3,403.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,409.6 42.1,408.6 42.1,393.3 44.3,394.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,409.5 44.3,404.8 44.3,394.4 54.3,399 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,418.9 54.1,414.2 54.1,403.8 64.1,408.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,414.2 51.9,413.2 51.9,397.9 54.1,398.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,414.1 54.1,409.4 54.1,398.9 64.1,403.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,423.5 63.9,418.8 63.9,408.4 73.9,413 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,418.8 61.7,417.8 61.7,402.5 63.9,403.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,418.6 63.9,414 63.9,403.5 73.9,408.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,428.1 73.7,423.4 73.7,412.9 83.7,417.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,423.4 71.5,422.4 71.5,407.1 73.7,408.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,423.2 73.7,418.5 73.7,408.1 83.7,412.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,432.7 83.5,428 83.5,417.5 93.5,422.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,428 81.3,426.9 81.3,411.7 83.5,412.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,427.8 83.5,423.1 83.5,412.7 93.5,417.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,437.2 93.3,432.5 93.3,422.1 103.3,426.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,432.6 91.1,431.5 91.1,416.3 93.3,417.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,432.4 93.3,427.7 93.3,417.3 103.3,422 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,441.8 103,437.1 103,426.7 113.1,431.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,437 103,432.3 103,421.9 113.1,426.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,437.1 100.9,436.1 100.9,420.9 103.1,421.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,446.3 112.8,441.7 112.8,431.3 122.6,435.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,441.5 112.8,436.9 112.8,426.5 122.6,431.1 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,441.7 110.7,440.7 110.7,425.4 112.9,426.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,446.3 120.5,445.3 120.5,430 122.7,431.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,397.9 142.3,383.1 142.3,383.1 171.7,369.3 171.7,369.3 132.5,351 103.1,364.8 71.5,379.6
			42,393.4 81.2,411.8 122.4,431.1 152,417.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={6} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,414.3 44.3,409.6 44.3,399.2 54.3,403.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,409.6 42.1,408.6 42.1,393.3 44.3,394.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,409.5 44.3,404.8 44.3,394.4 54.3,399 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,418.9 54.1,414.2 54.1,403.8 64.1,408.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,414.2 51.9,413.2 51.9,397.9 54.1,398.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,414.1 54.1,409.4 54.1,398.9 64.1,403.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,423.5 63.9,418.8 63.9,408.4 73.9,413 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,418.8 61.7,417.8 61.7,402.5 63.9,403.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,418.6 63.9,414 63.9,403.5 73.9,408.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,428.1 73.7,423.4 73.7,412.9 83.7,417.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,423.4 71.5,422.4 71.5,407.1 73.7,408.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,423.2 73.7,418.5 73.7,408.1 83.7,412.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,432.7 83.5,428 83.5,417.5 93.5,422.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,428 81.3,426.9 81.3,411.7 83.5,412.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,427.8 83.5,423.1 83.5,412.7 93.5,417.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,437.2 93.3,432.5 93.3,422.1 103.3,426.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,432.6 91.1,431.5 91.1,416.3 93.3,417.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,432.4 93.3,427.7 93.3,417.3 103.3,422 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,437.1 100.9,436.1 100.9,420.9 103.1,421.9 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,441.8 103,437.1 103,426.7 113.1,431.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,437 103,432.3 103,421.9 113.1,426.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,441.7 110.7,440.7 110.7,425.4 112.9,426.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,446.4 112.8,441.7 112.8,431.3 122.9,436 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,441.6 112.8,436.9 112.8,426.5 122.9,431.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,446.3 120.5,445.3 120.5,430 122.7,431.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,451 122.6,446.3 122.6,435.9 132.7,440.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,446.2 122.6,441.5 122.6,431 132.7,435.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,455.6 132.4,450.9 132.4,440.5 142.5,445.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,450.9 130.2,449.9 130.2,434.6 132.4,435.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,450.8 132.4,446.1 132.4,435.6 142.5,440.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,460.2 142.2,455.5 142.2,445.1 152.3,449.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,455.5 140,454.5 140,439.2 142.2,440.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,455.3 142.2,450.7 142.2,440.2 152.3,444.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,464.4 152,460.1 152,449.6 161.2,453.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,460.1 149.8,459 149.8,443.8 152,444.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,459.4 152,455.2 152,444.8 160.9,449 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,464.7 159.6,463.6 159.6,448.4 161.8,449.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,459 164.1,463.6 164.1,453.2 174.1,448.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,463.6 161.9,464.7 161.9,449.4 164.1,448.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,454.1 164.1,458.8 164.1,448.4 174.1,443.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,454.4 173.9,459.1 173.9,448.6 183.9,443.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,459.1 171.7,460.1 171.7,444.8 173.9,443.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,449.5 173.9,454.2 173.9,443.8 183.9,439.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,449.8 183.7,454.5 183.7,444 193.7,439.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,454.5 181.5,455.5 181.5,440.2 183.7,439.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,444.9 183.7,449.6 183.7,439.2 193.7,434.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,445.2 193.5,449.9 193.5,439.5 203.5,434.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,449.9 191.3,450.9 191.3,435.6 193.5,434.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,440.4 193.5,445 193.5,434.6 203.5,429.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,440.6 203.3,445.3 203.3,434.9 213.3,430.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,445.3 201.1,446.3 201.1,431.1 203.3,430 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,435.8 203.3,440.5 203.3,430 213.3,425.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,436 213.1,440.7 213.1,430.3 223.1,425.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,440.7 210.9,441.7 210.9,426.5 213.1,425.4 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,431.2 213.1,435.9 213.1,425.4 223.1,420.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,431.4 222.9,436.1 222.9,425.7 232.9,421 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,436.1 220.7,437.1 220.7,421.9 222.9,420.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,426.6 222.9,431.3 222.9,420.9 232.9,416.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,426.8 232.7,431.5 232.7,421.1 242.7,416.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,431.5 230.5,432.6 230.5,417.3 232.7,416.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,422 232.7,426.7 232.7,416.3 242.7,411.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,422.8 242.5,426.9 242.5,416.5 251.4,412.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,426.9 240.3,428 240.3,412.7 242.5,411.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,418.1 242.5,422.1 242.5,411.7 251.1,407.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,422.4 250.1,423.4 250.1,408.1 252.3,407.1 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,388.7L213.1,388.7l-41.4-19.4l0,0L132.5,351l-29.4,13.8l-31.6,14.8L42,393.4l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8L223,421l0,0l29.4-13.8L213.1,388.7z M152.1,417.3L152.1,417.3l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,417.3z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={7}
          >
            <FloorNeutral openFloors={openFloors} floor={7}>
              <polygon
                className="st1"
                points="152,400 183.6,385.2 183.6,385.2 183.6,385.2 142.2,365.8 142.2,365.8 110.6,380.6 		"
              />
              <path
                className="st16"
                d="M213,371.4L213,371.4L171.6,352l0,0l-39.2-18.3L103,347.5l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,371.4z M183.6,385.2L152,400l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,385.2
			L183.6,385.2L183.6,385.2z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,397 44.3,392.3 44.3,381.9 54.3,386.6 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,392.3 42.1,391.3 42.1,376 44.3,377.1 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,392.2 44.3,387.5 44.3,377.1 54.3,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,401.6 54.1,396.9 54.1,386.5 64.1,391.2 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,396.9 51.9,395.9 51.9,380.6 54.1,381.6 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,396.8 54.1,392.1 54.1,381.6 64.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,406.2 63.9,401.5 63.9,391.1 73.9,395.7 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,401.5 61.7,400.5 61.7,385.2 63.9,386.2 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,401.3 63.9,396.7 63.9,386.2 73.9,390.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,410.8 73.7,406.1 73.7,395.6 83.7,400.3 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,406.1 71.5,405.1 71.5,389.8 73.7,390.8 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,405.9 73.7,401.2 73.7,390.8 83.7,395.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,415.4 83.5,410.7 83.5,400.2 93.5,404.9 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,410.7 81.3,409.6 81.3,394.4 83.5,395.4 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,410.5 83.5,405.8 83.5,395.4 93.5,400.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,419.9 93.3,415.2 93.3,404.8 103.3,409.5 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,415.3 91.1,414.2 91.1,399 93.3,400 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,415.1 93.3,410.4 93.3,400 103.3,404.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,424.5 103,419.8 103,409.4 113.1,414.1 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,419.8 100.9,418.8 100.9,403.6 103.1,404.6 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,419.7 103,415 103,404.6 113.1,409.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,429.1 112.8,424.4 112.8,414 122.9,418.7 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,424.4 110.7,423.4 110.7,408.1 112.9,409.2 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,424.3 112.8,419.6 112.8,409.2 122.9,413.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,433.7 122.6,429 122.6,418.6 132.7,423.3 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,429 120.5,428 120.5,412.7 122.7,413.8 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,428.9 122.6,424.2 122.6,413.7 132.7,418.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,438.3 132.4,433.6 132.4,423.2 142.5,427.9 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,433.6 130.2,432.6 130.2,417.3 132.4,418.3 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,433.5 132.4,428.8 132.4,418.3 142.5,423 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,442.9 142.2,438.2 142.2,427.8 152.3,432.4 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,438.2 140,437.2 140,421.9 142.2,422.9 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,438 142.2,433.4 142.2,422.9 152.3,427.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,447.1 152,442.8 152,432.3 161.2,436.6 					"
                    />
                    <polygon
                      className="st9"
                      points="152,442.8 149.8,441.7 149.8,426.5 152,427.5 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,442.1 152,437.9 152,427.5 160.9,431.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,447.4 159.6,446.3 159.6,431.1 161.8,432.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,441.7 164.1,446.3 164.1,435.9 174.1,431.2 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,446.3 161.9,447.4 161.9,432.1 164.1,431.1 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,436.8 164.1,441.5 164.1,431.1 174.1,426.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,437.1 173.9,441.8 173.9,431.3 183.9,426.6 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,441.8 171.7,442.8 171.7,427.5 173.9,426.5 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,432.2 173.9,436.9 173.9,426.5 183.9,421.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,432.5 183.7,437.2 183.7,426.7 193.7,422.1 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,437.2 181.5,438.2 181.5,422.9 183.7,421.9 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,427.6 183.7,432.3 183.7,421.9 193.7,417.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,427.9 193.5,432.6 193.5,422.2 203.5,417.5 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,432.6 191.3,433.6 191.3,418.3 193.5,417.3 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,423.1 193.5,427.7 193.5,417.3 203.5,412.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,423.3 203.3,428 203.3,417.6 213.3,412.9 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,428 201.1,429 201.1,413.8 203.3,412.7 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,418.5 203.3,423.2 203.3,412.7 213.3,408 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,418.7 213.1,423.4 213.1,413 223.1,408.3 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,423.4 210.9,424.4 210.9,409.2 213.1,408.1 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,413.9 213.1,418.6 213.1,408.1 223.1,403.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,414.1 222.9,418.8 222.9,408.4 232.9,403.7 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,418.8 220.7,419.8 220.7,404.6 222.9,403.6 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,409.3 222.9,414 222.9,403.6 232.9,398.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,409.5 232.7,414.2 232.7,403.8 242.7,399.1 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,414.2 230.5,415.3 230.5,400 232.7,399 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,404.7 232.7,409.4 232.7,399 242.7,394.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,405.5 242.5,409.6 242.5,399.2 251.4,395 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,409.6 240.3,410.7 240.3,395.4 242.5,394.4 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,400.8 242.5,404.8 242.5,394.4 251.1,390.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,405.1 250.1,406.1 250.1,390.8 252.3,389.8 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,433.7 122.6,429 122.6,418.6 132.7,423.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,428.9 122.6,424.2 122.6,413.7 132.7,418.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,438.3 132.4,433.6 132.4,423.2 142.5,427.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,433.6 130.2,432.6 130.2,417.3 132.4,418.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,433.5 132.4,428.8 132.4,418.3 142.5,423 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,442.9 142.2,438.2 142.2,427.8 152.3,432.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,438.2 140,437.2 140,421.9 142.2,422.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,438 142.2,433.4 142.2,422.9 152.3,427.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,447.1 152,442.8 152,432.3 161.2,436.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,442.8 149.8,441.7 149.8,426.5 152,427.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,442.1 152,437.9 152,427.5 160.9,431.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,447.4 159.6,446.3 159.6,431.1 161.8,432.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,441.7 164.1,446.3 164.1,435.9 174.1,431.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,446.3 161.9,447.4 161.9,432.1 164.1,431.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,436.8 164.1,441.5 164.1,431.1 174.1,426.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,437.1 173.9,441.8 173.9,431.3 183.9,426.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,441.8 171.7,442.8 171.7,427.5 173.9,426.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,432.2 173.9,436.9 173.9,426.5 183.9,421.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,432.5 183.7,437.2 183.7,426.7 193.7,422.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,437.2 181.5,438.2 181.5,422.9 183.7,421.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,427.6 183.7,432.3 183.7,421.9 193.7,417.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,427.9 193.5,432.6 193.5,422.2 203.5,417.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,432.6 191.3,433.6 191.3,418.3 193.5,417.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,423.1 193.5,427.7 193.5,417.3 203.5,412.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,423.3 203.3,428 203.3,417.6 213.3,412.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,428 201.1,429 201.1,413.8 203.3,412.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,418.5 203.3,423.2 203.3,412.7 213.3,408 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,418.8 213.1,423.4 213.1,413 222.7,408.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,423.4 210.9,424.4 210.9,409.2 213.1,408.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,414 213.1,418.6 213.1,408.1 222.7,403.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,418.8 220.7,419.8 220.7,404.6 222.9,403.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,400 152,400 122.4,413.8 122.6,413.9 161.9,432.3 191.4,418.5 223,403.7 223,403.7
			183.6,385.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,397 44.3,392.3 44.3,381.9 54.3,386.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,392.3 42.1,391.3 42.1,376 44.3,377.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,392.2 44.3,387.5 44.3,377.1 54.3,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,401.6 54.1,396.9 54.1,386.5 64.1,391.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,396.9 51.9,395.9 51.9,380.6 54.1,381.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,396.8 54.1,392.1 54.1,381.6 64.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,406.2 63.9,401.5 63.9,391.1 73.9,395.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,401.5 61.7,400.5 61.7,385.2 63.9,386.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,401.3 63.9,396.7 63.9,386.2 73.9,390.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,410.8 73.7,406.1 73.7,395.6 83.7,400.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,406.1 71.5,405.1 71.5,389.8 73.7,390.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,405.9 73.7,401.2 73.7,390.8 83.7,395.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,415.4 83.5,410.7 83.5,400.2 93.5,404.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,410.7 81.3,409.6 81.3,394.4 83.5,395.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,410.5 83.5,405.8 83.5,395.4 93.5,400.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,419.9 93.3,415.2 93.3,404.8 103.3,409.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,415.3 91.1,414.2 91.1,399 93.3,400 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,415.1 93.3,410.4 93.3,400 103.3,404.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,424.5 103,419.8 103,409.4 113.1,414.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,419.7 103,415 103,404.6 113.1,409.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,419.8 100.9,418.8 100.9,403.6 103.1,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,429 112.8,424.4 112.8,414 122.7,418.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,424.2 112.8,419.6 112.8,409.2 122.7,413.8 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,424.4 110.7,423.4 110.7,408.1 112.9,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,429 120.5,428 120.5,412.7 122.7,413.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,414.1 222.9,418.8 222.9,408.4 232.9,403.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,409.3 222.9,414 222.9,403.6 232.9,398.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,409.5 232.7,414.2 232.7,403.8 242.7,399.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,414.2 230.5,415.3 230.5,400 232.7,399 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,404.7 232.7,409.4 232.7,399 242.7,394.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,405.5 242.5,409.6 242.5,399.2 251.4,395 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,409.6 240.3,410.7 240.3,395.4 242.5,394.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,400.8 242.5,404.8 242.5,394.4 251.1,390.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,405.1 250.1,406.1 250.1,390.8 252.3,389.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,371.4 213,371.4 171.7,352 171.7,352 132.5,333.7 103.1,347.5 71.5,362.3 42,376.1
			81.2,394.5 122.4,413.8 152,400 110.7,380.6 142.3,365.8 142.3,365.8 183.6,385.2 183.7,385.2 183.6,385.2 223,403.7 252.4,389.9
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,433.7 122.6,429 122.6,418.6 132.7,423.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,428.9 122.6,424.2 122.6,413.7 132.7,418.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,438.3 132.4,433.6 132.4,423.2 142.5,427.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,433.6 130.2,432.6 130.2,417.3 132.4,418.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,433.5 132.4,428.8 132.4,418.3 142.5,423 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,442.9 142.2,438.2 142.2,427.8 152.3,432.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,438.2 140,437.2 140,421.9 142.2,422.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,438 142.2,433.4 142.2,422.9 152.3,427.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,447.1 152,442.8 152,432.3 161.2,436.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,442.8 149.8,441.7 149.8,426.5 152,427.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,442.1 152,437.9 152,427.5 160.9,431.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,447.4 159.6,446.3 159.6,431.1 161.8,432.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,441.7 164.1,446.3 164.1,435.9 174.1,431.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,446.3 161.9,447.4 161.9,432.1 164.1,431.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,436.8 164.1,441.5 164.1,431.1 174.1,426.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,437.1 173.9,441.8 173.9,431.3 183.9,426.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,441.8 171.7,442.8 171.7,427.5 173.9,426.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,432.2 173.9,436.9 173.9,426.5 183.9,421.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,432.5 183.7,437.2 183.7,426.7 193.7,422.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,437.2 181.5,438.2 181.5,422.9 183.7,421.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,427.6 183.7,432.3 183.7,421.9 193.7,417.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,427.9 193.5,432.6 193.5,422.2 203.5,417.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,432.6 191.3,433.6 191.3,418.3 193.5,417.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,423.1 193.5,427.7 193.5,417.3 203.5,412.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,423.3 203.3,428 203.3,417.6 213.3,412.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,428 201.1,429 201.1,413.8 203.3,412.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,418.5 203.3,423.2 203.3,412.7 213.3,408 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,418.7 213.1,423.4 213.1,413 223.1,408.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,423.4 210.9,424.4 210.9,409.2 213.1,408.1 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,413.9 213.1,418.6 213.1,408.1 223.1,403.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,414.1 222.9,418.8 222.9,408.4 232.9,403.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,418.8 220.7,419.8 220.7,404.6 222.9,403.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,409.3 222.9,414 222.9,403.6 232.9,398.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,409.5 232.7,414.2 232.7,403.8 242.7,399.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,414.2 230.5,415.3 230.5,400 232.7,399 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,404.7 232.7,409.4 232.7,399 242.7,394.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,405.5 242.5,409.6 242.5,399.2 251.4,395 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,409.6 240.3,410.7 240.3,395.4 242.5,394.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,400.8 242.5,404.8 242.5,394.4 251.1,390.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,405.1 250.1,406.1 250.1,390.8 252.3,389.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,371.4 213,371.4 183.6,385.2 183.7,385.2 183.6,385.2 152.1,400 152,400 122.4,413.8
			122.6,413.9 161.9,432.3 191.4,418.5 223,403.7 223,403.7 252.4,389.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,397 44.3,392.3 44.3,381.9 54.3,386.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,392.3 42.1,391.3 42.1,376 44.3,377.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,392.2 44.3,387.5 44.3,377.1 54.3,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,401.6 54.1,396.9 54.1,386.5 64.1,391.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,396.9 51.9,395.9 51.9,380.6 54.1,381.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,396.8 54.1,392.1 54.1,381.6 64.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,406.2 63.9,401.5 63.9,391.1 73.9,395.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,401.5 61.7,400.5 61.7,385.2 63.9,386.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,401.3 63.9,396.7 63.9,386.2 73.9,390.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,410.8 73.7,406.1 73.7,395.6 83.7,400.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,406.1 71.5,405.1 71.5,389.8 73.7,390.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,405.9 73.7,401.2 73.7,390.8 83.7,395.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,415.4 83.5,410.7 83.5,400.2 93.5,404.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,410.7 81.3,409.6 81.3,394.4 83.5,395.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,410.5 83.5,405.8 83.5,395.4 93.5,400.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,419.9 93.3,415.2 93.3,404.8 103.3,409.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,415.3 91.1,414.2 91.1,399 93.3,400 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,415.1 93.3,410.4 93.3,400 103.3,404.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,424.5 103,419.8 103,409.4 113.1,414.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,419.7 103,415 103,404.6 113.1,409.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,419.8 100.9,418.8 100.9,403.6 103.1,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,429 112.8,424.4 112.8,414 122.6,418.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,424.2 112.8,419.6 112.8,409.2 122.6,413.8 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,424.4 110.7,423.4 110.7,408.1 112.9,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,429 120.5,428 120.5,412.7 122.7,413.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,365.8 183.6,385.2 213,371.4 171.7,352 171.7,352 132.5,333.7 103.1,347.5 71.5,362.3
			42,376.1 81.2,394.5 122.4,413.8 152,400 110.7,380.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,433.7 122.6,429 122.6,418.6 132.7,423.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,428.9 122.6,424.2 122.6,413.7 132.7,418.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,438.3 132.4,433.6 132.4,423.2 142.5,427.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,433.6 130.2,432.6 130.2,417.3 132.4,418.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,433.5 132.4,428.8 132.4,418.3 142.5,423 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,442.9 142.2,438.2 142.2,427.8 152.3,432.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,438.2 140,437.2 140,421.9 142.2,422.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,438 142.2,433.4 142.2,422.9 152.3,427.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,447.1 152,442.8 152,432.3 161.2,436.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,442.8 149.8,441.7 149.8,426.5 152,427.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,442.1 152,437.9 152,427.5 160.9,431.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,447.4 159.6,446.3 159.6,431.1 161.8,432.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,441.7 164.1,446.3 164.1,435.9 174.1,431.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,446.3 161.9,447.4 161.9,432.1 164.1,431.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,436.8 164.1,441.5 164.1,431.1 174.1,426.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,437.1 173.9,441.8 173.9,431.3 183.9,426.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,441.8 171.7,442.8 171.7,427.5 173.9,426.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,432.2 173.9,436.9 173.9,426.5 183.9,421.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,432.5 183.7,437.2 183.7,426.7 193.7,422.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,437.2 181.5,438.2 181.5,422.9 183.7,421.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,427.6 183.7,432.3 183.7,421.9 193.7,417.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,427.9 193.5,432.6 193.5,422.2 203.5,417.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,432.6 191.3,433.6 191.3,418.3 193.5,417.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,423.1 193.5,427.7 193.5,417.3 203.5,412.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,423.3 203.3,428 203.3,417.6 213.3,412.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,428 201.1,429 201.1,413.8 203.3,412.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,418.5 203.3,423.2 203.3,412.7 213.3,408 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,418.7 213.1,423.4 213.1,413 223.1,408.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,423.4 210.9,424.4 210.9,409.2 213.1,408.1 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,413.9 213.1,418.6 213.1,408.1 223.1,403.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,414.1 222.9,418.8 222.9,408.4 232.9,403.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,418.8 220.7,419.8 220.7,404.6 222.9,403.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,409.3 222.9,414 222.9,403.6 232.9,398.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,409.5 232.7,414.2 232.7,403.8 242.7,399.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,414.2 230.5,415.3 230.5,400 232.7,399 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,404.7 232.7,409.4 232.7,399 242.7,394.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,405.5 242.5,409.6 242.5,399.2 251.4,395 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,409.6 240.3,410.7 240.3,395.4 242.5,394.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,400.8 242.5,404.8 242.5,394.4 251.1,390.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,405.1 250.1,406.1 250.1,390.8 252.3,389.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,371.4 213,371.4 171.7,352 142.3,365.8 183.6,385.2 183.7,385.2 183.6,385.2 152.1,400
			152,400 122.4,413.8 122.6,413.9 161.9,432.3 191.4,418.5 223,403.7 223,403.7 252.4,389.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,397 44.3,392.3 44.3,381.9 54.3,386.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,392.3 42.1,391.3 42.1,376 44.3,377.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,392.2 44.3,387.5 44.3,377.1 54.3,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,401.6 54.1,396.9 54.1,386.5 64.1,391.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,396.9 51.9,395.9 51.9,380.6 54.1,381.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,396.8 54.1,392.1 54.1,381.6 64.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,406.2 63.9,401.5 63.9,391.1 73.9,395.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,401.5 61.7,400.5 61.7,385.2 63.9,386.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,401.3 63.9,396.7 63.9,386.2 73.9,390.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,410.8 73.7,406.1 73.7,395.6 83.7,400.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,406.1 71.5,405.1 71.5,389.8 73.7,390.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,405.9 73.7,401.2 73.7,390.8 83.7,395.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,415.4 83.5,410.7 83.5,400.2 93.5,404.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,410.7 81.3,409.6 81.3,394.4 83.5,395.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,410.5 83.5,405.8 83.5,395.4 93.5,400.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,419.9 93.3,415.2 93.3,404.8 103.3,409.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,415.3 91.1,414.2 91.1,399 93.3,400 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,415.1 93.3,410.4 93.3,400 103.3,404.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,424.5 103,419.8 103,409.4 113.1,414.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,419.7 103,415 103,404.6 113.1,409.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,419.8 100.9,418.8 100.9,403.6 103.1,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,429 112.8,424.4 112.8,414 122.6,418.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,424.2 112.8,419.6 112.8,409.2 122.6,413.8 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,424.4 110.7,423.4 110.7,408.1 112.9,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,429 120.5,428 120.5,412.7 122.7,413.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,380.6 142.3,365.8 142.3,365.8 171.7,352 171.7,352 132.5,333.7 103.1,347.5 71.5,362.3
			42,376.1 81.2,394.5 122.4,413.8 152,400 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={7} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,397 44.3,392.3 44.3,381.9 54.3,386.6 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,392.3 42.1,391.3 42.1,376 44.3,377.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,392.2 44.3,387.5 44.3,377.1 54.3,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,401.6 54.1,396.9 54.1,386.5 64.1,391.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,396.9 51.9,395.9 51.9,380.6 54.1,381.6 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,396.8 54.1,392.1 54.1,381.6 64.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,406.2 63.9,401.5 63.9,391.1 73.9,395.7 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,401.5 61.7,400.5 61.7,385.2 63.9,386.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,401.3 63.9,396.7 63.9,386.2 73.9,390.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,410.8 73.7,406.1 73.7,395.6 83.7,400.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,406.1 71.5,405.1 71.5,389.8 73.7,390.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,405.9 73.7,401.2 73.7,390.8 83.7,395.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,415.4 83.5,410.7 83.5,400.2 93.5,404.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,410.7 81.3,409.6 81.3,394.4 83.5,395.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,410.5 83.5,405.8 83.5,395.4 93.5,400.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,419.9 93.3,415.2 93.3,404.8 103.3,409.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,415.3 91.1,414.2 91.1,399 93.3,400 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,415.1 93.3,410.4 93.3,400 103.3,404.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,419.8 100.9,418.8 100.9,403.6 103.1,404.6 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,424.5 103,419.8 103,409.4 113.1,414.1 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,419.7 103,415 103,404.6 113.1,409.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,424.4 110.7,423.4 110.7,408.1 112.9,409.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,429.1 112.8,424.4 112.8,414 122.9,418.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,424.3 112.8,419.6 112.8,409.2 122.9,413.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,429 120.5,428 120.5,412.7 122.7,413.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,433.7 122.6,429 122.6,418.6 132.7,423.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,428.9 122.6,424.2 122.6,413.7 132.7,418.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,438.3 132.4,433.6 132.4,423.2 142.5,427.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,433.6 130.2,432.6 130.2,417.3 132.4,418.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,433.5 132.4,428.8 132.4,418.3 142.5,423 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,442.9 142.2,438.2 142.2,427.8 152.3,432.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,438.2 140,437.2 140,421.9 142.2,422.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,438 142.2,433.4 142.2,422.9 152.3,427.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,447.1 152,442.8 152,432.3 161.2,436.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152,442.8 149.8,441.7 149.8,426.5 152,427.5 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,442.1 152,437.9 152,427.5 160.9,431.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,447.4 159.6,446.3 159.6,431.1 161.8,432.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,441.7 164.1,446.3 164.1,435.9 174.1,431.2 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,446.3 161.9,447.4 161.9,432.1 164.1,431.1 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,436.8 164.1,441.5 164.1,431.1 174.1,426.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,437.1 173.9,441.8 173.9,431.3 183.9,426.6 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,441.8 171.7,442.8 171.7,427.5 173.9,426.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,432.2 173.9,436.9 173.9,426.5 183.9,421.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,432.5 183.7,437.2 183.7,426.7 193.7,422.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,437.2 181.5,438.2 181.5,422.9 183.7,421.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,427.6 183.7,432.3 183.7,421.9 193.7,417.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,427.9 193.5,432.6 193.5,422.2 203.5,417.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,432.6 191.3,433.6 191.3,418.3 193.5,417.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,423.1 193.5,427.7 193.5,417.3 203.5,412.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,423.3 203.3,428 203.3,417.6 213.3,412.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,428 201.1,429 201.1,413.8 203.3,412.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,418.5 203.3,423.2 203.3,412.7 213.3,408 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,418.7 213.1,423.4 213.1,413 223.1,408.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,423.4 210.9,424.4 210.9,409.2 213.1,408.1 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,413.9 213.1,418.6 213.1,408.1 223.1,403.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,414.1 222.9,418.8 222.9,408.4 232.9,403.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,418.8 220.7,419.8 220.7,404.6 222.9,403.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,409.3 222.9,414 222.9,403.6 232.9,398.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,409.5 232.7,414.2 232.7,403.8 242.7,399.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,414.2 230.5,415.3 230.5,400 232.7,399 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,404.7 232.7,409.4 232.7,399 242.7,394.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,405.5 242.5,409.6 242.5,399.2 251.4,395 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,409.6 240.3,410.7 240.3,395.4 242.5,394.4 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,400.8 242.5,404.8 242.5,394.4 251.1,390.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,405.1 250.1,406.1 250.1,390.8 252.3,389.8 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,371.4L213.1,371.4L171.7,352l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,376.1l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,371.4z M152.1,400L152.1,400l-41.4-19.4l31.6-14.8l0,0l41.3,19.4l0,0
			h-0.1L152.1,400z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={8}
          >
            <FloorNeutral openFloors={openFloors} floor={8}>
              <polygon
                className="st1"
                points="152,382.8 183.6,368 183.6,368 183.6,368 142.2,348.6 142.2,348.6 110.6,363.4 		"
              />
              <path
                className="st16"
                d="M213,354.2L213,354.2l-41.4-19.4l0,0l-39.2-18.3L103,330.3l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,354.2z M183.6,368L152,382.8l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,368
			L183.6,368L183.6,368z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,379.8 44.3,375.1 44.3,364.7 54.3,369.4 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,375.1 42.1,374.1 42.1,358.8 44.3,359.9 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,375 44.3,370.3 44.3,359.9 54.3,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,384.4 54.1,379.7 54.1,369.3 64.1,374 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,379.7 51.9,378.7 51.9,363.4 54.1,364.4 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,379.6 54.1,374.9 54.1,364.4 64.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,389 63.9,384.3 63.9,373.9 73.9,378.5 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,384.3 61.7,383.3 61.7,368 63.9,369 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,384.1 63.9,379.5 63.9,369 73.9,373.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,393.6 73.7,388.9 73.7,378.4 83.7,383.1 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,388.9 71.5,387.9 71.5,372.6 73.7,373.6 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,388.7 73.7,384 73.7,373.6 83.7,378.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,398.2 83.5,393.5 83.5,383 93.5,387.7 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,393.5 81.3,392.4 81.3,377.2 83.5,378.2 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,393.3 83.5,388.6 83.5,378.2 93.5,382.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,402.7 93.3,398 93.3,387.6 103.3,392.3 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,398.1 91.1,397 91.1,381.8 93.3,382.8 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,397.9 93.3,393.2 93.3,382.8 103.3,387.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,407.3 103,402.6 103,392.2 113.1,396.9 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,402.6 100.9,401.6 100.9,386.4 103.1,387.4 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,402.5 103,397.8 103,387.4 113.1,392.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,411.9 112.8,407.2 112.8,396.8 122.9,401.5 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,407.2 110.7,406.2 110.7,390.9 112.9,392 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,407.1 112.8,402.4 112.8,392 122.9,396.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,416.5 122.6,411.8 122.6,401.4 132.7,406.1 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,411.8 120.5,410.8 120.5,395.5 122.7,396.6 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,411.7 122.6,407 122.6,396.5 132.7,401.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,421.1 132.4,416.4 132.4,406 142.5,410.7 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,416.4 130.2,415.4 130.2,400.1 132.4,401.1 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,416.3 132.4,411.6 132.4,401.1 142.5,405.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,425.7 142.2,421 142.2,410.6 152.3,415.2 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,421 140,420 140,404.7 142.2,405.7 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,420.8 142.2,416.2 142.2,405.7 152.3,410.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,429.9 152,425.6 152,415.1 161.2,419.4 					"
                    />
                    <polygon
                      className="st9"
                      points="152,425.6 149.8,424.5 149.8,409.3 152,410.3 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,424.9 152,420.7 152,410.3 160.9,414.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,430.2 159.6,429.1 159.6,413.9 161.8,414.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,424.5 164.1,429.1 164.1,418.7 174.1,414 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,429.1 161.9,430.2 161.9,414.9 164.1,413.9 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,419.6 164.1,424.3 164.1,413.9 174.1,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,419.9 173.9,424.6 173.9,414.1 183.9,409.4 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,424.6 171.7,425.6 171.7,410.3 173.9,409.3 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,415 173.9,419.7 173.9,409.3 183.9,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,415.3 183.7,420 183.7,409.5 193.7,404.9 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,420 181.5,421 181.5,405.7 183.7,404.7 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,410.4 183.7,415.1 183.7,404.7 193.7,400 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,410.7 193.5,415.4 193.5,405 203.5,400.3 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,415.4 191.3,416.4 191.3,401.1 193.5,400.1 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,405.9 193.5,410.5 193.5,400.1 203.5,395.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,406.1 203.3,410.8 203.3,400.4 213.3,395.7 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,410.8 201.1,411.8 201.1,396.6 203.3,395.5 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,401.3 203.3,406 203.3,395.5 213.3,390.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,401.5 213.1,406.2 213.1,395.8 223.1,391.1 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,406.2 210.9,407.2 210.9,392 213.1,390.9 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,396.7 213.1,401.4 213.1,390.9 223.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,396.9 222.9,401.6 222.9,391.2 232.9,386.5 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,401.6 220.7,402.6 220.7,387.4 222.9,386.4 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,392.1 222.9,396.8 222.9,386.4 232.9,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,392.3 232.7,397 232.7,386.6 242.7,381.9 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,397 230.5,398.1 230.5,382.8 232.7,381.8 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,387.5 232.7,392.2 232.7,381.8 242.7,377.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,388.3 242.5,392.4 242.5,382 251.4,377.8 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,392.4 240.3,393.5 240.3,378.2 242.5,377.2 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,383.6 242.5,387.6 242.5,377.2 251.1,373.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,387.9 250.1,388.9 250.1,373.6 252.3,372.6 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,416.5 122.6,411.8 122.6,401.4 132.7,406.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,411.7 122.6,407 122.6,396.5 132.7,401.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,421.1 132.4,416.4 132.4,406 142.5,410.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,416.4 130.2,415.4 130.2,400.1 132.4,401.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,416.3 132.4,411.6 132.4,401.1 142.5,405.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,425.7 142.2,421 142.2,410.6 152.3,415.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,421 140,420 140,404.7 142.2,405.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,420.8 142.2,416.2 142.2,405.7 152.3,410.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,429.9 152,425.6 152,415.1 161.2,419.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152,425.6 149.8,424.5 149.8,409.3 152,410.3 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,424.9 152,420.7 152,410.3 160.9,414.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,430.2 159.6,429.1 159.6,413.9 161.8,414.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,424.5 164.1,429.1 164.1,418.7 174.1,414 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,429.1 161.9,430.2 161.9,414.9 164.1,413.9 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,419.6 164.1,424.3 164.1,413.9 174.1,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,419.9 173.9,424.6 173.9,414.1 183.9,409.4 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,424.6 171.7,425.6 171.7,410.3 173.9,409.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,415 173.9,419.7 173.9,409.3 183.9,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,415.3 183.7,420 183.7,409.5 193.7,404.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,420 181.5,421 181.5,405.7 183.7,404.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,410.4 183.7,415.1 183.7,404.7 193.7,400 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,410.7 193.5,415.4 193.5,405 203.5,400.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,415.4 191.3,416.4 191.3,401.1 193.5,400.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,405.9 193.5,410.5 193.5,400.1 203.5,395.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,406.1 203.3,410.8 203.3,400.4 213.3,395.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,410.8 201.1,411.8 201.1,396.6 203.3,395.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,401.3 203.3,406 203.3,395.5 213.3,390.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,401.6 213.1,406.2 213.1,395.8 222.7,391.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,406.2 210.9,407.2 210.9,392 213.1,390.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,396.8 213.1,401.4 213.1,390.9 222.7,386.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,401.6 220.7,402.6 220.7,387.4 222.9,386.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,382.8 152,382.8 122.4,396.6 122.6,396.7 161.9,415.1 191.4,401.3 223,386.5 223,386.5
			183.6,368 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,379.8 44.3,375.1 44.3,364.7 54.3,369.4 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,375.1 42.1,374.1 42.1,358.8 44.3,359.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,375 44.3,370.3 44.3,359.9 54.3,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,384.4 54.1,379.7 54.1,369.3 64.1,374 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,379.7 51.9,378.7 51.9,363.4 54.1,364.4 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,379.6 54.1,374.9 54.1,364.4 64.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,389 63.9,384.3 63.9,373.9 73.9,378.5 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,384.3 61.7,383.3 61.7,368 63.9,369 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,384.1 63.9,379.5 63.9,369 73.9,373.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,393.6 73.7,388.9 73.7,378.4 83.7,383.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,388.9 71.5,387.9 71.5,372.6 73.7,373.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,388.7 73.7,384 73.7,373.6 83.7,378.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,398.2 83.5,393.5 83.5,383 93.5,387.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,393.5 81.3,392.4 81.3,377.2 83.5,378.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,393.3 83.5,388.6 83.5,378.2 93.5,382.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,402.7 93.3,398 93.3,387.6 103.3,392.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,398.1 91.1,397 91.1,381.8 93.3,382.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,397.9 93.3,393.2 93.3,382.8 103.3,387.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,407.3 103,402.6 103,392.2 113.1,396.9 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,402.5 103,397.8 103,387.4 113.1,392.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,402.6 100.9,401.6 100.9,386.4 103.1,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,411.8 112.8,407.2 112.8,396.8 122.7,401.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,407 112.8,402.4 112.8,392 122.7,396.6 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,407.2 110.7,406.2 110.7,390.9 112.9,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,411.8 120.5,410.8 120.5,395.5 122.7,396.6 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,396.9 222.9,401.6 222.9,391.2 232.9,386.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,392.1 222.9,396.8 222.9,386.4 232.9,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,392.3 232.7,397 232.7,386.6 242.7,381.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,397 230.5,398.1 230.5,382.8 232.7,381.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,387.5 232.7,392.2 232.7,381.8 242.7,377.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,388.3 242.5,392.4 242.5,382 251.4,377.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,392.4 240.3,393.5 240.3,378.2 242.5,377.2 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,383.6 242.5,387.6 242.5,377.2 251.1,373.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,387.9 250.1,388.9 250.1,373.6 252.3,372.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,354.2 213,354.2 171.7,334.8 171.7,334.8 132.5,316.5 103.1,330.3 71.5,345.1 42,358.9
			81.2,377.3 122.4,396.6 152,382.8 110.7,363.4 142.3,348.6 142.3,348.6 183.6,368 183.7,368 183.6,368 223,386.5 252.4,372.7
			"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,416.5 122.6,411.8 122.6,401.4 132.7,406.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,411.7 122.6,407 122.6,396.5 132.7,401.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,421.1 132.4,416.4 132.4,406 142.5,410.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,416.4 130.2,415.4 130.2,400.1 132.4,401.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,416.3 132.4,411.6 132.4,401.1 142.5,405.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,425.7 142.2,421 142.2,410.6 152.3,415.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,421 140,420 140,404.7 142.2,405.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,420.8 142.2,416.2 142.2,405.7 152.3,410.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,429.9 152,425.6 152,415.1 161.2,419.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152,425.6 149.8,424.5 149.8,409.3 152,410.3 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,424.9 152,420.7 152,410.3 160.9,414.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,430.2 159.6,429.1 159.6,413.9 161.8,414.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,424.5 164.1,429.1 164.1,418.7 174.1,414 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,429.1 161.9,430.2 161.9,414.9 164.1,413.9 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,419.6 164.1,424.3 164.1,413.9 174.1,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,419.9 173.9,424.6 173.9,414.1 183.9,409.4 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,424.6 171.7,425.6 171.7,410.3 173.9,409.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,415 173.9,419.7 173.9,409.3 183.9,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,415.3 183.7,420 183.7,409.5 193.7,404.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,420 181.5,421 181.5,405.7 183.7,404.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,410.4 183.7,415.1 183.7,404.7 193.7,400 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,410.7 193.5,415.4 193.5,405 203.5,400.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,415.4 191.3,416.4 191.3,401.1 193.5,400.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,405.9 193.5,410.5 193.5,400.1 203.5,395.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,406.1 203.3,410.8 203.3,400.4 213.3,395.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,410.8 201.1,411.8 201.1,396.6 203.3,395.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,401.3 203.3,406 203.3,395.5 213.3,390.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,401.5 213.1,406.2 213.1,395.8 223.1,391.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,406.2 210.9,407.2 210.9,392 213.1,390.9 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,396.7 213.1,401.4 213.1,390.9 223.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,396.9 222.9,401.6 222.9,391.2 232.9,386.5 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,401.6 220.7,402.6 220.7,387.4 222.9,386.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,392.1 222.9,396.8 222.9,386.4 232.9,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,392.3 232.7,397 232.7,386.6 242.7,381.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,397 230.5,398.1 230.5,382.8 232.7,381.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,387.5 232.7,392.2 232.7,381.8 242.7,377.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,388.3 242.5,392.4 242.5,382 251.4,377.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,392.4 240.3,393.5 240.3,378.2 242.5,377.2 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,383.6 242.5,387.6 242.5,377.2 251.1,373.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,387.9 250.1,388.9 250.1,373.6 252.3,372.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,354.2 213,354.2 183.6,368 183.7,368 183.6,368 152.1,382.8 152,382.8 122.4,396.6
			122.6,396.7 161.9,415.1 191.4,401.3 223,386.5 223,386.5 252.4,372.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,379.8 44.3,375.1 44.3,364.7 54.3,369.4 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,375.1 42.1,374.1 42.1,358.8 44.3,359.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,375 44.3,370.3 44.3,359.9 54.3,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,384.4 54.1,379.7 54.1,369.3 64.1,374 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,379.7 51.9,378.7 51.9,363.4 54.1,364.4 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,379.6 54.1,374.9 54.1,364.4 64.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,389 63.9,384.3 63.9,373.9 73.9,378.5 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,384.3 61.7,383.3 61.7,368 63.9,369 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,384.1 63.9,379.5 63.9,369 73.9,373.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,393.6 73.7,388.9 73.7,378.4 83.7,383.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,388.9 71.5,387.9 71.5,372.6 73.7,373.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,388.7 73.7,384 73.7,373.6 83.7,378.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,398.2 83.5,393.5 83.5,383 93.5,387.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,393.5 81.3,392.4 81.3,377.2 83.5,378.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,393.3 83.5,388.6 83.5,378.2 93.5,382.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,402.7 93.3,398 93.3,387.6 103.3,392.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,398.1 91.1,397 91.1,381.8 93.3,382.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,397.9 93.3,393.2 93.3,382.8 103.3,387.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,407.3 103,402.6 103,392.2 113.1,396.9 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,402.5 103,397.8 103,387.4 113.1,392.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,402.6 100.9,401.6 100.9,386.4 103.1,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,411.8 112.8,407.2 112.8,396.8 122.6,401.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,407 112.8,402.4 112.8,392 122.6,396.6 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,407.2 110.7,406.2 110.7,390.9 112.9,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,411.8 120.5,410.8 120.5,395.5 122.7,396.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,348.6 183.6,368 213,354.2 171.7,334.8 171.7,334.8 132.5,316.5 103.1,330.3 71.5,345.1
			42,358.9 81.2,377.3 122.4,396.6 152,382.8 110.7,363.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,416.5 122.6,411.8 122.6,401.4 132.7,406.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,411.7 122.6,407 122.6,396.5 132.7,401.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,421.1 132.4,416.4 132.4,406 142.5,410.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,416.4 130.2,415.4 130.2,400.1 132.4,401.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,416.3 132.4,411.6 132.4,401.1 142.5,405.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,425.7 142.2,421 142.2,410.6 152.3,415.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,421 140,420 140,404.7 142.2,405.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,420.8 142.2,416.2 142.2,405.7 152.3,410.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,429.9 152,425.6 152,415.1 161.2,419.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152,425.6 149.8,424.5 149.8,409.3 152,410.3 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,424.9 152,420.7 152,410.3 160.9,414.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,430.2 159.6,429.1 159.6,413.9 161.8,414.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,424.5 164.1,429.1 164.1,418.7 174.1,414 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,429.1 161.9,430.2 161.9,414.9 164.1,413.9 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,419.6 164.1,424.3 164.1,413.9 174.1,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,419.9 173.9,424.6 173.9,414.1 183.9,409.4 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,424.6 171.7,425.6 171.7,410.3 173.9,409.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,415 173.9,419.7 173.9,409.3 183.9,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,415.3 183.7,420 183.7,409.5 193.7,404.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,420 181.5,421 181.5,405.7 183.7,404.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,410.4 183.7,415.1 183.7,404.7 193.7,400 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,410.7 193.5,415.4 193.5,405 203.5,400.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,415.4 191.3,416.4 191.3,401.1 193.5,400.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,405.9 193.5,410.5 193.5,400.1 203.5,395.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,406.1 203.3,410.8 203.3,400.4 213.3,395.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,410.8 201.1,411.8 201.1,396.6 203.3,395.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,401.3 203.3,406 203.3,395.5 213.3,390.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,401.5 213.1,406.2 213.1,395.8 223.1,391.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,406.2 210.9,407.2 210.9,392 213.1,390.9 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,396.7 213.1,401.4 213.1,390.9 223.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,396.9 222.9,401.6 222.9,391.2 232.9,386.5 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,401.6 220.7,402.6 220.7,387.4 222.9,386.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,392.1 222.9,396.8 222.9,386.4 232.9,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,392.3 232.7,397 232.7,386.6 242.7,381.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,397 230.5,398.1 230.5,382.8 232.7,381.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,387.5 232.7,392.2 232.7,381.8 242.7,377.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,388.3 242.5,392.4 242.5,382 251.4,377.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,392.4 240.3,393.5 240.3,378.2 242.5,377.2 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,383.6 242.5,387.6 242.5,377.2 251.1,373.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,387.9 250.1,388.9 250.1,373.6 252.3,372.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,354.2 213,354.2 171.7,334.8 142.3,348.6 183.6,368 183.7,368 183.6,368 152.1,382.8
			152,382.8 122.4,396.6 122.6,396.7 161.9,415.1 191.4,401.3 223,386.5 223,386.5 252.4,372.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,379.8 44.3,375.1 44.3,364.7 54.3,369.4 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,375.1 42.1,374.1 42.1,358.8 44.3,359.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,375 44.3,370.3 44.3,359.9 54.3,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,384.4 54.1,379.7 54.1,369.3 64.1,374 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,379.7 51.9,378.7 51.9,363.4 54.1,364.4 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,379.6 54.1,374.9 54.1,364.4 64.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,389 63.9,384.3 63.9,373.9 73.9,378.5 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,384.3 61.7,383.3 61.7,368 63.9,369 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,384.1 63.9,379.5 63.9,369 73.9,373.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,393.6 73.7,388.9 73.7,378.4 83.7,383.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,388.9 71.5,387.9 71.5,372.6 73.7,373.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,388.7 73.7,384 73.7,373.6 83.7,378.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,398.2 83.5,393.5 83.5,383 93.5,387.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,393.5 81.3,392.4 81.3,377.2 83.5,378.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,393.3 83.5,388.6 83.5,378.2 93.5,382.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,402.7 93.3,398 93.3,387.6 103.3,392.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,398.1 91.1,397 91.1,381.8 93.3,382.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,397.9 93.3,393.2 93.3,382.8 103.3,387.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,407.3 103,402.6 103,392.2 113.1,396.9 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,402.5 103,397.8 103,387.4 113.1,392.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,402.6 100.9,401.6 100.9,386.4 103.1,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,411.8 112.8,407.2 112.8,396.8 122.6,401.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,407 112.8,402.4 112.8,392 122.6,396.6 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,407.2 110.7,406.2 110.7,390.9 112.9,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,411.8 120.5,410.8 120.5,395.5 122.7,396.6 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,363.4 142.3,348.6 142.3,348.6 171.7,334.8 171.7,334.8 132.5,316.5 103.1,330.3 71.5,345.1
			42,358.9 81.2,377.3 122.4,396.6 152,382.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={8} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,379.8 44.3,375.1 44.3,364.7 54.3,369.4 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,375.1 42.1,374.1 42.1,358.8 44.3,359.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,375 44.3,370.3 44.3,359.9 54.3,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,384.4 54.1,379.7 54.1,369.3 64.1,374 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,379.7 51.9,378.7 51.9,363.4 54.1,364.4 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,379.6 54.1,374.9 54.1,364.4 64.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,389 63.9,384.3 63.9,373.9 73.9,378.5 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,384.3 61.7,383.3 61.7,368 63.9,369 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,384.1 63.9,379.5 63.9,369 73.9,373.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,393.6 73.7,388.9 73.7,378.4 83.7,383.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,388.9 71.5,387.9 71.5,372.6 73.7,373.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,388.7 73.7,384 73.7,373.6 83.7,378.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,398.2 83.5,393.5 83.5,383 93.5,387.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,393.5 81.3,392.4 81.3,377.2 83.5,378.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,393.3 83.5,388.6 83.5,378.2 93.5,382.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,402.7 93.3,398 93.3,387.6 103.3,392.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,398.1 91.1,397 91.1,381.8 93.3,382.8 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,397.9 93.3,393.2 93.3,382.8 103.3,387.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,402.6 100.9,401.6 100.9,386.4 103.1,387.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,407.3 103,402.6 103,392.2 113.1,396.9 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,402.5 103,397.8 103,387.4 113.1,392.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,407.2 110.7,406.2 110.7,390.9 112.9,392 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,411.9 112.8,407.2 112.8,396.8 122.9,401.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,407.1 112.8,402.4 112.8,392 122.9,396.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,411.8 120.5,410.8 120.5,395.5 122.7,396.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,416.5 122.6,411.8 122.6,401.4 132.7,406.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,411.7 122.6,407 122.6,396.5 132.7,401.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,421.1 132.4,416.4 132.4,406 142.5,410.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,416.4 130.2,415.4 130.2,400.1 132.4,401.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,416.3 132.4,411.6 132.4,401.1 142.5,405.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,425.7 142.2,421 142.2,410.6 152.3,415.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,421 140,420 140,404.7 142.2,405.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,420.8 142.2,416.2 142.2,405.7 152.3,410.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,429.9 152,425.6 152,415.1 161.2,419.4 					"
                    />
                    <polygon
                      className="st14"
                      points="152,425.6 149.8,424.5 149.8,409.3 152,410.3 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,424.9 152,420.7 152,410.3 160.9,414.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,430.2 159.6,429.1 159.6,413.9 161.8,414.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,424.5 164.1,429.1 164.1,418.7 174.1,414 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,429.1 161.9,430.2 161.9,414.9 164.1,413.9 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,419.6 164.1,424.3 164.1,413.9 174.1,409.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,419.9 173.9,424.6 173.9,414.1 183.9,409.4 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,424.6 171.7,425.6 171.7,410.3 173.9,409.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,415 173.9,419.7 173.9,409.3 183.9,404.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,415.3 183.7,420 183.7,409.5 193.7,404.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,420 181.5,421 181.5,405.7 183.7,404.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,410.4 183.7,415.1 183.7,404.7 193.7,400 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,410.7 193.5,415.4 193.5,405 203.5,400.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,415.4 191.3,416.4 191.3,401.1 193.5,400.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,405.9 193.5,410.5 193.5,400.1 203.5,395.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,406.1 203.3,410.8 203.3,400.4 213.3,395.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,410.8 201.1,411.8 201.1,396.6 203.3,395.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,401.3 203.3,406 203.3,395.5 213.3,390.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,401.5 213.1,406.2 213.1,395.8 223.1,391.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,406.2 210.9,407.2 210.9,392 213.1,390.9 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,396.7 213.1,401.4 213.1,390.9 223.1,386.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,396.9 222.9,401.6 222.9,391.2 232.9,386.5 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,401.6 220.7,402.6 220.7,387.4 222.9,386.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,392.1 222.9,396.8 222.9,386.4 232.9,381.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,392.3 232.7,397 232.7,386.6 242.7,381.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,397 230.5,398.1 230.5,382.8 232.7,381.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,387.5 232.7,392.2 232.7,381.8 242.7,377.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,388.3 242.5,392.4 242.5,382 251.4,377.8 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,392.4 240.3,393.5 240.3,378.2 242.5,377.2 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,383.6 242.5,387.6 242.5,377.2 251.1,373.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,387.9 250.1,388.9 250.1,373.6 252.3,372.6 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,354.2L213.1,354.2l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,358.9l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,354.2z M152.1,382.8L152.1,382.8l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,382.8z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={9}
          >
            <FloorNeutral openFloors={openFloors} floor={9}>
              <polygon
                className="st1"
                points="152,365.6 183.6,350.8 183.6,350.8 183.6,350.8 142.2,331.4 142.2,331.4 110.6,346.2 		"
              />
              <path
                className="st16"
                d="M213,337L213,337l-41.4-19.4l0,0l-39.2-18.3L103,313.1l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,337z M183.6,350.8L152,365.6l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,350.8
			L183.6,350.8L183.6,350.8z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,362.6 44.3,357.9 44.3,347.5 54.3,352.2 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,357.9 42.1,356.9 42.1,341.6 44.3,342.7 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,357.8 44.3,353.1 44.3,342.7 54.3,347.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,367.2 54.1,362.5 54.1,352.1 64.1,356.8 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,362.5 51.9,361.5 51.9,346.2 54.1,347.2 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,362.4 54.1,357.7 54.1,347.2 64.1,351.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,371.8 63.9,367.1 63.9,356.7 73.9,361.3 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,367.1 61.7,366.1 61.7,350.8 63.9,351.8 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,366.9 63.9,362.3 63.9,351.8 73.9,356.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,376.4 73.7,371.7 73.7,361.2 83.7,365.9 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,371.7 71.5,370.7 71.5,355.4 73.7,356.4 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,371.5 73.7,366.8 73.7,356.4 83.7,361.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,381 83.5,376.3 83.5,365.8 93.5,370.5 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,376.3 81.3,375.2 81.3,360 83.5,361 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,376.1 83.5,371.4 83.5,361 93.5,365.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,385.5 93.3,380.8 93.3,370.4 103.3,375.1 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,380.9 91.1,379.8 91.1,364.6 93.3,365.6 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,380.7 93.3,376 93.3,365.6 103.3,370.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,390.1 103,385.4 103,375 113.1,379.7 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,385.4 100.9,384.4 100.9,369.2 103.1,370.2 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,385.3 103,380.6 103,370.2 113.1,374.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,394.7 112.8,390 112.8,379.6 122.9,384.3 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,390 110.7,389 110.7,373.7 112.9,374.8 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,389.9 112.8,385.2 112.8,374.8 122.9,379.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,399.3 122.6,394.6 122.6,384.2 132.7,388.9 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,394.6 120.5,393.6 120.5,378.3 122.7,379.4 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,394.5 122.6,389.8 122.6,379.3 132.7,384 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,403.9 132.4,399.2 132.4,388.8 142.5,393.5 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,399.2 130.2,398.2 130.2,382.9 132.4,383.9 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,399.1 132.4,394.4 132.4,383.9 142.5,388.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,408.5 142.2,403.8 142.2,393.4 152.3,398 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,403.8 140,402.8 140,387.5 142.2,388.5 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,403.6 142.2,399 142.2,388.5 152.3,393.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,412.7 152,408.4 152,397.9 161.2,402.2 					"
                    />
                    <polygon
                      className="st9"
                      points="152,408.4 149.8,407.3 149.8,392.1 152,393.1 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,407.7 152,403.5 152,393.1 160.9,397.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,413 159.6,411.9 159.6,396.7 161.8,397.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,407.3 164.1,411.9 164.1,401.5 174.1,396.8 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,411.9 161.9,413 161.9,397.7 164.1,396.7 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,402.4 164.1,407.1 164.1,396.7 174.1,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,402.7 173.9,407.4 173.9,396.9 183.9,392.2 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,407.4 171.7,408.4 171.7,393.1 173.9,392.1 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,397.8 173.9,402.5 173.9,392.1 183.9,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,398.1 183.7,402.8 183.7,392.3 193.7,387.7 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,402.8 181.5,403.8 181.5,388.5 183.7,387.5 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,393.2 183.7,397.9 183.7,387.5 193.7,382.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,393.5 193.5,398.2 193.5,387.8 203.5,383.1 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,398.2 191.3,399.2 191.3,383.9 193.5,382.9 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,388.7 193.5,393.3 193.5,382.9 203.5,378.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,388.9 203.3,393.6 203.3,383.2 213.3,378.5 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,393.6 201.1,394.6 201.1,379.4 203.3,378.3 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,384.1 203.3,388.8 203.3,378.3 213.3,373.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,384.3 213.1,389 213.1,378.6 223.1,373.9 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,389 210.9,390 210.9,374.8 213.1,373.7 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,379.5 213.1,384.2 213.1,373.7 223.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,379.7 222.9,384.4 222.9,374 232.9,369.3 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,384.4 220.7,385.4 220.7,370.2 222.9,369.2 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,374.9 222.9,379.6 222.9,369.2 232.9,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,375.1 232.7,379.8 232.7,369.4 242.7,364.7 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,379.8 230.5,380.9 230.5,365.6 232.7,364.6 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,370.3 232.7,375 232.7,364.6 242.7,359.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,371.1 242.5,375.2 242.5,364.8 251.4,360.6 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,375.2 240.3,376.3 240.3,361 242.5,360 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,366.4 242.5,370.4 242.5,360 251.1,355.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,370.7 250.1,371.7 250.1,356.4 252.3,355.4 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,399.3 122.6,394.6 122.6,384.2 132.7,388.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,394.5 122.6,389.8 122.6,379.3 132.7,384 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,403.9 132.4,399.2 132.4,388.8 142.5,393.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,399.2 130.2,398.2 130.2,382.9 132.4,383.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,399.1 132.4,394.4 132.4,383.9 142.5,388.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,408.5 142.2,403.8 142.2,393.4 152.3,398 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,403.8 140,402.8 140,387.5 142.2,388.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,403.6 142.2,399 142.2,388.5 152.3,393.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,412.7 152,408.4 152,397.9 161.2,402.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,408.4 149.8,407.3 149.8,392.1 152,393.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,407.7 152,403.5 152,393.1 160.9,397.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,413 159.6,411.9 159.6,396.7 161.8,397.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,407.3 164.1,411.9 164.1,401.5 174.1,396.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,411.9 161.9,413 161.9,397.7 164.1,396.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,402.4 164.1,407.1 164.1,396.7 174.1,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,402.7 173.9,407.4 173.9,396.9 183.9,392.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,407.4 171.7,408.4 171.7,393.1 173.9,392.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,397.8 173.9,402.5 173.9,392.1 183.9,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,398.1 183.7,402.8 183.7,392.3 193.7,387.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,402.8 181.5,403.8 181.5,388.5 183.7,387.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,393.2 183.7,397.9 183.7,387.5 193.7,382.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,393.5 193.5,398.2 193.5,387.8 203.5,383.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,398.2 191.3,399.2 191.3,383.9 193.5,382.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,388.7 193.5,393.3 193.5,382.9 203.5,378.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,388.9 203.3,393.6 203.3,383.2 213.3,378.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,393.6 201.1,394.6 201.1,379.4 203.3,378.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,384.1 203.3,388.8 203.3,378.3 213.3,373.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,384.4 213.1,389 213.1,378.6 222.7,374 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,389 210.9,390 210.9,374.8 213.1,373.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,379.6 213.1,384.2 213.1,373.7 222.7,369.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,384.4 220.7,385.4 220.7,370.2 222.9,369.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,365.6 152,365.6 122.4,379.4 122.6,379.5 161.9,397.9 191.4,384.1 223,369.3 223,369.3
			183.6,350.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,362.6 44.3,357.9 44.3,347.5 54.3,352.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,357.9 42.1,356.9 42.1,341.6 44.3,342.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,357.8 44.3,353.1 44.3,342.7 54.3,347.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,367.2 54.1,362.5 54.1,352.1 64.1,356.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,362.5 51.9,361.5 51.9,346.2 54.1,347.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,362.4 54.1,357.7 54.1,347.2 64.1,351.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,371.8 63.9,367.1 63.9,356.7 73.9,361.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,367.1 61.7,366.1 61.7,350.8 63.9,351.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,366.9 63.9,362.3 63.9,351.8 73.9,356.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,376.4 73.7,371.7 73.7,361.2 83.7,365.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,371.7 71.5,370.7 71.5,355.4 73.7,356.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,371.5 73.7,366.8 73.7,356.4 83.7,361.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,381 83.5,376.3 83.5,365.8 93.5,370.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,376.3 81.3,375.2 81.3,360 83.5,361 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,376.1 83.5,371.4 83.5,361 93.5,365.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,385.5 93.3,380.8 93.3,370.4 103.3,375.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,380.9 91.1,379.8 91.1,364.6 93.3,365.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,380.7 93.3,376 93.3,365.6 103.3,370.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,390.1 103,385.4 103,375 113.1,379.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,385.3 103,380.6 103,370.2 113.1,374.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,385.4 100.9,384.4 100.9,369.2 103.1,370.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,394.6 112.8,390 112.8,379.6 122.7,384.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,389.8 112.8,385.2 112.8,374.8 122.7,379.4 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,390 110.7,389 110.7,373.7 112.9,374.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,394.6 120.5,393.6 120.5,378.3 122.7,379.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,379.7 222.9,384.4 222.9,374 232.9,369.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,374.9 222.9,379.6 222.9,369.2 232.9,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,375.1 232.7,379.8 232.7,369.4 242.7,364.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,379.8 230.5,380.9 230.5,365.6 232.7,364.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,370.3 232.7,375 232.7,364.6 242.7,359.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,371.1 242.5,375.2 242.5,364.8 251.4,360.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,375.2 240.3,376.3 240.3,361 242.5,360 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,366.4 242.5,370.4 242.5,360 251.1,355.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,370.7 250.1,371.7 250.1,356.4 252.3,355.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,337 213,337 171.7,317.6 171.7,317.6 132.5,299.3 103.1,313.1 71.5,327.9 42,341.7
			81.2,360.1 122.4,379.4 152,365.6 110.7,346.2 142.3,331.4 142.3,331.4 183.6,350.8 183.7,350.8 183.6,350.8 223,369.3
			252.4,355.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,399.3 122.6,394.6 122.6,384.2 132.7,388.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,394.5 122.6,389.8 122.6,379.3 132.7,384 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,403.9 132.4,399.2 132.4,388.8 142.5,393.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,399.2 130.2,398.2 130.2,382.9 132.4,383.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,399.1 132.4,394.4 132.4,383.9 142.5,388.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,408.5 142.2,403.8 142.2,393.4 152.3,398 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,403.8 140,402.8 140,387.5 142.2,388.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,403.6 142.2,399 142.2,388.5 152.3,393.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,412.7 152,408.4 152,397.9 161.2,402.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,408.4 149.8,407.3 149.8,392.1 152,393.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,407.7 152,403.5 152,393.1 160.9,397.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,413 159.6,411.9 159.6,396.7 161.8,397.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,407.3 164.1,411.9 164.1,401.5 174.1,396.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,411.9 161.9,413 161.9,397.7 164.1,396.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,402.4 164.1,407.1 164.1,396.7 174.1,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,402.7 173.9,407.4 173.9,396.9 183.9,392.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,407.4 171.7,408.4 171.7,393.1 173.9,392.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,397.8 173.9,402.5 173.9,392.1 183.9,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,398.1 183.7,402.8 183.7,392.3 193.7,387.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,402.8 181.5,403.8 181.5,388.5 183.7,387.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,393.2 183.7,397.9 183.7,387.5 193.7,382.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,393.5 193.5,398.2 193.5,387.8 203.5,383.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,398.2 191.3,399.2 191.3,383.9 193.5,382.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,388.7 193.5,393.3 193.5,382.9 203.5,378.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,388.9 203.3,393.6 203.3,383.2 213.3,378.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,393.6 201.1,394.6 201.1,379.4 203.3,378.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,384.1 203.3,388.8 203.3,378.3 213.3,373.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,384.3 213.1,389 213.1,378.6 223.1,373.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,389 210.9,390 210.9,374.8 213.1,373.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,379.5 213.1,384.2 213.1,373.7 223.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,379.7 222.9,384.4 222.9,374 232.9,369.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,384.4 220.7,385.4 220.7,370.2 222.9,369.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,374.9 222.9,379.6 222.9,369.2 232.9,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,375.1 232.7,379.8 232.7,369.4 242.7,364.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,379.8 230.5,380.9 230.5,365.6 232.7,364.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,370.3 232.7,375 232.7,364.6 242.7,359.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,371.1 242.5,375.2 242.5,364.8 251.4,360.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,375.2 240.3,376.3 240.3,361 242.5,360 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,366.4 242.5,370.4 242.5,360 251.1,355.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,370.7 250.1,371.7 250.1,356.4 252.3,355.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,337 213,337 183.6,350.8 183.7,350.8 183.6,350.8 152.1,365.6 152,365.6 122.4,379.4
			122.6,379.5 161.9,397.9 191.4,384.1 223,369.3 223,369.3 252.4,355.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,362.6 44.3,357.9 44.3,347.5 54.3,352.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,357.9 42.1,356.9 42.1,341.6 44.3,342.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,357.8 44.3,353.1 44.3,342.7 54.3,347.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,367.2 54.1,362.5 54.1,352.1 64.1,356.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,362.5 51.9,361.5 51.9,346.2 54.1,347.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,362.4 54.1,357.7 54.1,347.2 64.1,351.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,371.8 63.9,367.1 63.9,356.7 73.9,361.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,367.1 61.7,366.1 61.7,350.8 63.9,351.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,366.9 63.9,362.3 63.9,351.8 73.9,356.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,376.4 73.7,371.7 73.7,361.2 83.7,365.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,371.7 71.5,370.7 71.5,355.4 73.7,356.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,371.5 73.7,366.8 73.7,356.4 83.7,361.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,381 83.5,376.3 83.5,365.8 93.5,370.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,376.3 81.3,375.2 81.3,360 83.5,361 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,376.1 83.5,371.4 83.5,361 93.5,365.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,385.5 93.3,380.8 93.3,370.4 103.3,375.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,380.9 91.1,379.8 91.1,364.6 93.3,365.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,380.7 93.3,376 93.3,365.6 103.3,370.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,390.1 103,385.4 103,375 113.1,379.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,385.3 103,380.6 103,370.2 113.1,374.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,385.4 100.9,384.4 100.9,369.2 103.1,370.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,394.6 112.8,390 112.8,379.6 122.6,384.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,389.8 112.8,385.2 112.8,374.8 122.6,379.4 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,390 110.7,389 110.7,373.7 112.9,374.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,394.6 120.5,393.6 120.5,378.3 122.7,379.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,331.4 183.6,350.8 213,337 171.7,317.6 171.7,317.6 132.5,299.3 103.1,313.1 71.5,327.9
			42,341.7 81.2,360.1 122.4,379.4 152,365.6 110.7,346.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,399.3 122.6,394.6 122.6,384.2 132.7,388.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,394.5 122.6,389.8 122.6,379.3 132.7,384 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,403.9 132.4,399.2 132.4,388.8 142.5,393.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,399.2 130.2,398.2 130.2,382.9 132.4,383.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,399.1 132.4,394.4 132.4,383.9 142.5,388.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,408.5 142.2,403.8 142.2,393.4 152.3,398 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,403.8 140,402.8 140,387.5 142.2,388.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,403.6 142.2,399 142.2,388.5 152.3,393.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,412.7 152,408.4 152,397.9 161.2,402.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,408.4 149.8,407.3 149.8,392.1 152,393.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,407.7 152,403.5 152,393.1 160.9,397.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,413 159.6,411.9 159.6,396.7 161.8,397.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,407.3 164.1,411.9 164.1,401.5 174.1,396.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,411.9 161.9,413 161.9,397.7 164.1,396.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,402.4 164.1,407.1 164.1,396.7 174.1,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,402.7 173.9,407.4 173.9,396.9 183.9,392.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,407.4 171.7,408.4 171.7,393.1 173.9,392.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,397.8 173.9,402.5 173.9,392.1 183.9,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,398.1 183.7,402.8 183.7,392.3 193.7,387.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,402.8 181.5,403.8 181.5,388.5 183.7,387.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,393.2 183.7,397.9 183.7,387.5 193.7,382.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,393.5 193.5,398.2 193.5,387.8 203.5,383.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,398.2 191.3,399.2 191.3,383.9 193.5,382.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,388.7 193.5,393.3 193.5,382.9 203.5,378.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,388.9 203.3,393.6 203.3,383.2 213.3,378.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,393.6 201.1,394.6 201.1,379.4 203.3,378.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,384.1 203.3,388.8 203.3,378.3 213.3,373.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,384.3 213.1,389 213.1,378.6 223.1,373.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,389 210.9,390 210.9,374.8 213.1,373.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,379.5 213.1,384.2 213.1,373.7 223.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,379.7 222.9,384.4 222.9,374 232.9,369.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,384.4 220.7,385.4 220.7,370.2 222.9,369.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,374.9 222.9,379.6 222.9,369.2 232.9,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,375.1 232.7,379.8 232.7,369.4 242.7,364.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,379.8 230.5,380.9 230.5,365.6 232.7,364.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,370.3 232.7,375 232.7,364.6 242.7,359.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,371.1 242.5,375.2 242.5,364.8 251.4,360.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,375.2 240.3,376.3 240.3,361 242.5,360 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,366.4 242.5,370.4 242.5,360 251.1,355.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,370.7 250.1,371.7 250.1,356.4 252.3,355.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,337 213,337 171.7,317.6 142.3,331.4 183.6,350.8 183.7,350.8 183.6,350.8 152.1,365.6
			152,365.6 122.4,379.4 122.6,379.5 161.9,397.9 191.4,384.1 223,369.3 223,369.3 252.4,355.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,362.6 44.3,357.9 44.3,347.5 54.3,352.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,357.9 42.1,356.9 42.1,341.6 44.3,342.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,357.8 44.3,353.1 44.3,342.7 54.3,347.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,367.2 54.1,362.5 54.1,352.1 64.1,356.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,362.5 51.9,361.5 51.9,346.2 54.1,347.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,362.4 54.1,357.7 54.1,347.2 64.1,351.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,371.8 63.9,367.1 63.9,356.7 73.9,361.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,367.1 61.7,366.1 61.7,350.8 63.9,351.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,366.9 63.9,362.3 63.9,351.8 73.9,356.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,376.4 73.7,371.7 73.7,361.2 83.7,365.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,371.7 71.5,370.7 71.5,355.4 73.7,356.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,371.5 73.7,366.8 73.7,356.4 83.7,361.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,381 83.5,376.3 83.5,365.8 93.5,370.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,376.3 81.3,375.2 81.3,360 83.5,361 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,376.1 83.5,371.4 83.5,361 93.5,365.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,385.5 93.3,380.8 93.3,370.4 103.3,375.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,380.9 91.1,379.8 91.1,364.6 93.3,365.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,380.7 93.3,376 93.3,365.6 103.3,370.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,390.1 103,385.4 103,375 113.1,379.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,385.3 103,380.6 103,370.2 113.1,374.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,385.4 100.9,384.4 100.9,369.2 103.1,370.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,394.6 112.8,390 112.8,379.6 122.6,384.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,389.8 112.8,385.2 112.8,374.8 122.6,379.4 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,390 110.7,389 110.7,373.7 112.9,374.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,394.6 120.5,393.6 120.5,378.3 122.7,379.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,346.2 142.3,331.4 142.3,331.4 171.7,317.6 171.7,317.6 132.5,299.3 103.1,313.1 71.5,327.9
			42,341.7 81.2,360.1 122.4,379.4 152,365.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={9} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,362.6 44.3,357.9 44.3,347.5 54.3,352.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,357.9 42.1,356.9 42.1,341.6 44.3,342.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,357.8 44.3,353.1 44.3,342.7 54.3,347.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,367.2 54.1,362.5 54.1,352.1 64.1,356.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,362.5 51.9,361.5 51.9,346.2 54.1,347.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,362.4 54.1,357.7 54.1,347.2 64.1,351.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,371.8 63.9,367.1 63.9,356.7 73.9,361.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,367.1 61.7,366.1 61.7,350.8 63.9,351.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,366.9 63.9,362.3 63.9,351.8 73.9,356.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,376.4 73.7,371.7 73.7,361.2 83.7,365.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,371.7 71.5,370.7 71.5,355.4 73.7,356.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,371.5 73.7,366.8 73.7,356.4 83.7,361.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,381 83.5,376.3 83.5,365.8 93.5,370.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,376.3 81.3,375.2 81.3,360 83.5,361 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,376.1 83.5,371.4 83.5,361 93.5,365.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,385.5 93.3,380.8 93.3,370.4 103.3,375.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,380.9 91.1,379.8 91.1,364.6 93.3,365.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,380.7 93.3,376 93.3,365.6 103.3,370.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,385.4 100.9,384.4 100.9,369.2 103.1,370.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,390.1 103,385.4 103,375 113.1,379.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,385.3 103,380.6 103,370.2 113.1,374.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,390 110.7,389 110.7,373.7 112.9,374.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,394.7 112.8,390 112.8,379.6 122.9,384.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,389.9 112.8,385.2 112.8,374.8 122.9,379.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,394.6 120.5,393.6 120.5,378.3 122.7,379.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,399.3 122.6,394.6 122.6,384.2 132.7,388.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,394.5 122.6,389.8 122.6,379.3 132.7,384 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,403.9 132.4,399.2 132.4,388.8 142.5,393.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,399.2 130.2,398.2 130.2,382.9 132.4,383.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,399.1 132.4,394.4 132.4,383.9 142.5,388.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,408.5 142.2,403.8 142.2,393.4 152.3,398 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,403.8 140,402.8 140,387.5 142.2,388.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,403.6 142.2,399 142.2,388.5 152.3,393.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,412.7 152,408.4 152,397.9 161.2,402.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,408.4 149.8,407.3 149.8,392.1 152,393.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,407.7 152,403.5 152,393.1 160.9,397.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,413 159.6,411.9 159.6,396.7 161.8,397.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,407.3 164.1,411.9 164.1,401.5 174.1,396.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,411.9 161.9,413 161.9,397.7 164.1,396.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,402.4 164.1,407.1 164.1,396.7 174.1,392 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,402.7 173.9,407.4 173.9,396.9 183.9,392.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,407.4 171.7,408.4 171.7,393.1 173.9,392.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,397.8 173.9,402.5 173.9,392.1 183.9,387.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,398.1 183.7,402.8 183.7,392.3 193.7,387.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,402.8 181.5,403.8 181.5,388.5 183.7,387.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,393.2 183.7,397.9 183.7,387.5 193.7,382.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,393.5 193.5,398.2 193.5,387.8 203.5,383.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,398.2 191.3,399.2 191.3,383.9 193.5,382.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,388.7 193.5,393.3 193.5,382.9 203.5,378.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,388.9 203.3,393.6 203.3,383.2 213.3,378.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,393.6 201.1,394.6 201.1,379.4 203.3,378.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,384.1 203.3,388.8 203.3,378.3 213.3,373.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,384.3 213.1,389 213.1,378.6 223.1,373.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,389 210.9,390 210.9,374.8 213.1,373.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,379.5 213.1,384.2 213.1,373.7 223.1,369.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,379.7 222.9,384.4 222.9,374 232.9,369.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,384.4 220.7,385.4 220.7,370.2 222.9,369.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,374.9 222.9,379.6 222.9,369.2 232.9,364.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,375.1 232.7,379.8 232.7,369.4 242.7,364.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,379.8 230.5,380.9 230.5,365.6 232.7,364.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,370.3 232.7,375 232.7,364.6 242.7,359.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,371.1 242.5,375.2 242.5,364.8 251.4,360.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,375.2 240.3,376.3 240.3,361 242.5,360 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,366.4 242.5,370.4 242.5,360 251.1,355.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,370.7 250.1,371.7 250.1,356.4 252.3,355.4 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,337L213.1,337l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,341.7l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,337z M152.1,365.6L152.1,365.6l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,365.6z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={10}
          >
            <FloorNeutral openFloors={openFloors} floor={10}>
              <polygon
                className="st1"
                points="152,348.3 183.6,333.5 183.6,333.5 183.6,333.5 142.2,314.1 142.2,314.1 110.6,328.9 		"
              />
              <path
                className="st16"
                d="M213,319.7L213,319.7l-41.4-19.4l0,0L132.4,282L103,295.8l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,319.7z M183.6,333.5L152,348.3l0,0l-41.4-19.4l31.6-14.8l0,0
			L183.6,333.5L183.6,333.5L183.6,333.5z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,345.3 44.3,340.6 44.3,330.2 54.3,334.9 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,340.6 42.1,339.6 42.1,324.3 44.3,325.4 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,340.5 44.3,335.8 44.3,325.4 54.3,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,349.9 54.1,345.2 54.1,334.8 64.1,339.5 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,345.2 51.9,344.2 51.9,328.9 54.1,329.9 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,345.1 54.1,340.4 54.1,329.9 64.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,354.5 63.9,349.8 63.9,339.4 73.9,344 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,349.8 61.7,348.8 61.7,333.5 63.9,334.5 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,349.6 63.9,345 63.9,334.5 73.9,339.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,359.1 73.7,354.4 73.7,343.9 83.7,348.6 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,354.4 71.5,353.4 71.5,338.1 73.7,339.1 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,354.2 73.7,349.5 73.7,339.1 83.7,343.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,363.7 83.5,359 83.5,348.5 93.5,353.2 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,359 81.3,357.9 81.3,342.7 83.5,343.7 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,358.8 83.5,354.1 83.5,343.7 93.5,348.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,368.2 93.3,363.5 93.3,353.1 103.3,357.8 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,363.6 91.1,362.5 91.1,347.3 93.3,348.3 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,363.4 93.3,358.7 93.3,348.3 103.3,353 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,372.8 103,368.1 103,357.7 113.1,362.4 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,368.1 100.9,367.1 100.9,351.9 103.1,352.9 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,368 103,363.3 103,352.9 113.1,357.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,377.4 112.8,372.7 112.8,362.3 122.9,367 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,372.7 110.7,371.7 110.7,356.4 112.9,357.5 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,372.6 112.8,367.9 112.8,357.5 122.9,362.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,382 122.6,377.3 122.6,366.9 132.7,371.6 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,377.3 120.5,376.3 120.5,361 122.7,362.1 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,377.2 122.6,372.5 122.6,362 132.7,366.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,386.6 132.4,381.9 132.4,371.5 142.5,376.2 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,381.9 130.2,380.9 130.2,365.6 132.4,366.6 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,381.8 132.4,377.1 132.4,366.6 142.5,371.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,391.2 142.2,386.5 142.2,376.1 152.3,380.7 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,386.5 140,385.5 140,370.2 142.2,371.2 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,386.3 142.2,381.7 142.2,371.2 152.3,375.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,395.4 152,391.1 152,380.6 161.2,384.9 					"
                    />
                    <polygon
                      className="st9"
                      points="152,391.1 149.8,390 149.8,374.8 152,375.8 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,390.4 152,386.2 152,375.8 160.9,380 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,395.7 159.6,394.6 159.6,379.4 161.8,380.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,390 164.1,394.6 164.1,384.2 174.1,379.5 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,394.6 161.9,395.7 161.9,380.4 164.1,379.4 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,385.1 164.1,389.8 164.1,379.4 174.1,374.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,385.4 173.9,390.1 173.9,379.6 183.9,374.9 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,390.1 171.7,391.1 171.7,375.8 173.9,374.8 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,380.5 173.9,385.2 173.9,374.8 183.9,370.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,380.8 183.7,385.5 183.7,375 193.7,370.4 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,385.5 181.5,386.5 181.5,371.2 183.7,370.2 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,375.9 183.7,380.6 183.7,370.2 193.7,365.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,376.2 193.5,380.9 193.5,370.5 203.5,365.8 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,380.9 191.3,381.9 191.3,366.6 193.5,365.6 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,371.4 193.5,376 193.5,365.6 203.5,360.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,371.6 203.3,376.3 203.3,365.9 213.3,361.2 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,376.3 201.1,377.3 201.1,362.1 203.3,361 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,366.8 203.3,371.5 203.3,361 213.3,356.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,367 213.1,371.7 213.1,361.3 223.1,356.6 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,371.7 210.9,372.7 210.9,357.5 213.1,356.4 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,362.2 213.1,366.9 213.1,356.4 223.1,351.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,362.4 222.9,367.1 222.9,356.7 232.9,352 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,367.1 220.7,368.1 220.7,352.9 222.9,351.9 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,357.6 222.9,362.3 222.9,351.9 232.9,347.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,357.8 232.7,362.5 232.7,352.1 242.7,347.4 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,362.5 230.5,363.6 230.5,348.3 232.7,347.3 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,353 232.7,357.7 232.7,347.3 242.7,342.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,353.8 242.5,357.9 242.5,347.5 251.4,343.3 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,357.9 240.3,359 240.3,343.7 242.5,342.7 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,349.1 242.5,353.1 242.5,342.7 251.1,338.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,353.4 250.1,354.4 250.1,339.1 252.3,338.1 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,382 122.6,377.3 122.6,366.9 132.7,371.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,377.2 122.6,372.5 122.6,362 132.7,366.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,386.6 132.4,381.9 132.4,371.5 142.5,376.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,381.9 130.2,380.9 130.2,365.6 132.4,366.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,381.8 132.4,377.1 132.4,366.6 142.5,371.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,391.2 142.2,386.5 142.2,376.1 152.3,380.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,386.5 140,385.5 140,370.2 142.2,371.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,386.3 142.2,381.7 142.2,371.2 152.3,375.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,395.4 152,391.1 152,380.6 161.2,384.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,391.1 149.8,390 149.8,374.8 152,375.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,390.4 152,386.2 152,375.8 160.9,380 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,395.7 159.6,394.6 159.6,379.4 161.8,380.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,390 164.1,394.6 164.1,384.2 174.1,379.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,394.6 161.9,395.7 161.9,380.4 164.1,379.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,385.1 164.1,389.8 164.1,379.4 174.1,374.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,385.4 173.9,390.1 173.9,379.6 183.9,374.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,390.1 171.7,391.1 171.7,375.8 173.9,374.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,380.5 173.9,385.2 173.9,374.8 183.9,370.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,380.8 183.7,385.5 183.7,375 193.7,370.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,385.5 181.5,386.5 181.5,371.2 183.7,370.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,375.9 183.7,380.6 183.7,370.2 193.7,365.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,376.2 193.5,380.9 193.5,370.5 203.5,365.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,380.9 191.3,381.9 191.3,366.6 193.5,365.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,371.4 193.5,376 193.5,365.6 203.5,360.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,371.6 203.3,376.3 203.3,365.9 213.3,361.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,376.3 201.1,377.3 201.1,362.1 203.3,361 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,366.8 203.3,371.5 203.3,361 213.3,356.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,367.1 213.1,371.7 213.1,361.3 222.7,356.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,371.7 210.9,372.7 210.9,357.5 213.1,356.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,362.3 213.1,366.9 213.1,356.4 222.7,351.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,367.1 220.7,368.1 220.7,352.9 222.9,351.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,348.3 152,348.3 122.4,362.1 122.6,362.2 161.9,380.6 191.4,366.8 223,352 223,352
			183.6,333.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,345.3 44.3,340.6 44.3,330.2 54.3,334.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,340.6 42.1,339.6 42.1,324.3 44.3,325.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,340.5 44.3,335.8 44.3,325.4 54.3,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,349.9 54.1,345.2 54.1,334.8 64.1,339.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,345.2 51.9,344.2 51.9,328.9 54.1,329.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,345.1 54.1,340.4 54.1,329.9 64.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,354.5 63.9,349.8 63.9,339.4 73.9,344 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,349.8 61.7,348.8 61.7,333.5 63.9,334.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,349.6 63.9,345 63.9,334.5 73.9,339.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,359.1 73.7,354.4 73.7,343.9 83.7,348.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,354.4 71.5,353.4 71.5,338.1 73.7,339.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,354.2 73.7,349.5 73.7,339.1 83.7,343.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,363.7 83.5,359 83.5,348.5 93.5,353.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,359 81.3,357.9 81.3,342.7 83.5,343.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,358.8 83.5,354.1 83.5,343.7 93.5,348.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,368.2 93.3,363.5 93.3,353.1 103.3,357.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,363.6 91.1,362.5 91.1,347.3 93.3,348.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,363.4 93.3,358.7 93.3,348.3 103.3,353 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,372.8 103,368.1 103,357.7 113.1,362.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,368 103,363.3 103,352.9 113.1,357.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,368.1 100.9,367.1 100.9,351.9 103.1,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,377.3 112.8,372.7 112.8,362.3 122.7,366.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,372.5 112.8,367.9 112.8,357.5 122.7,362.1 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,372.7 110.7,371.7 110.7,356.4 112.9,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,377.3 120.5,376.3 120.5,361 122.7,362.1 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,362.4 222.9,367.1 222.9,356.7 232.9,352 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,357.6 222.9,362.3 222.9,351.9 232.9,347.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,357.8 232.7,362.5 232.7,352.1 242.7,347.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,362.5 230.5,363.6 230.5,348.3 232.7,347.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,353 232.7,357.7 232.7,347.3 242.7,342.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,353.8 242.5,357.9 242.5,347.5 251.4,343.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,357.9 240.3,359 240.3,343.7 242.5,342.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,349.1 242.5,353.1 242.5,342.7 251.1,338.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,353.4 250.1,354.4 250.1,339.1 252.3,338.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,319.7 213,319.7 171.7,300.3 171.7,300.3 132.5,282 103.1,295.8 71.5,310.6 42,324.4
			81.2,342.8 122.4,362.1 152,348.3 110.7,328.9 142.3,314.1 142.3,314.1 183.6,333.5 183.7,333.5 183.6,333.5 223,352 252.4,338.2 
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,382 122.6,377.3 122.6,366.9 132.7,371.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,377.2 122.6,372.5 122.6,362 132.7,366.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,386.6 132.4,381.9 132.4,371.5 142.5,376.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,381.9 130.2,380.9 130.2,365.6 132.4,366.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,381.8 132.4,377.1 132.4,366.6 142.5,371.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,391.2 142.2,386.5 142.2,376.1 152.3,380.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,386.5 140,385.5 140,370.2 142.2,371.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,386.3 142.2,381.7 142.2,371.2 152.3,375.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,395.4 152,391.1 152,380.6 161.2,384.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,391.1 149.8,390 149.8,374.8 152,375.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,390.4 152,386.2 152,375.8 160.9,380 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,395.7 159.6,394.6 159.6,379.4 161.8,380.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,390 164.1,394.6 164.1,384.2 174.1,379.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,394.6 161.9,395.7 161.9,380.4 164.1,379.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,385.1 164.1,389.8 164.1,379.4 174.1,374.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,385.4 173.9,390.1 173.9,379.6 183.9,374.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,390.1 171.7,391.1 171.7,375.8 173.9,374.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,380.5 173.9,385.2 173.9,374.8 183.9,370.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,380.8 183.7,385.5 183.7,375 193.7,370.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,385.5 181.5,386.5 181.5,371.2 183.7,370.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,375.9 183.7,380.6 183.7,370.2 193.7,365.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,376.2 193.5,380.9 193.5,370.5 203.5,365.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,380.9 191.3,381.9 191.3,366.6 193.5,365.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,371.4 193.5,376 193.5,365.6 203.5,360.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,371.6 203.3,376.3 203.3,365.9 213.3,361.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,376.3 201.1,377.3 201.1,362.1 203.3,361 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,366.8 203.3,371.5 203.3,361 213.3,356.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,367 213.1,371.7 213.1,361.3 223.1,356.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,371.7 210.9,372.7 210.9,357.5 213.1,356.4 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,362.2 213.1,366.9 213.1,356.4 223.1,351.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,362.4 222.9,367.1 222.9,356.7 232.9,352 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,367.1 220.7,368.1 220.7,352.9 222.9,351.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,357.6 222.9,362.3 222.9,351.9 232.9,347.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,357.8 232.7,362.5 232.7,352.1 242.7,347.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,362.5 230.5,363.6 230.5,348.3 232.7,347.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,353 232.7,357.7 232.7,347.3 242.7,342.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,353.8 242.5,357.9 242.5,347.5 251.4,343.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,357.9 240.3,359 240.3,343.7 242.5,342.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,349.1 242.5,353.1 242.5,342.7 251.1,338.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,353.4 250.1,354.4 250.1,339.1 252.3,338.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,319.7 213,319.7 183.6,333.5 183.7,333.5 183.6,333.5 152.1,348.3 152,348.3 122.4,362.1
			122.6,362.2 161.9,380.6 191.4,366.8 223,352 223,352 252.4,338.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,345.3 44.3,340.6 44.3,330.2 54.3,334.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,340.6 42.1,339.6 42.1,324.3 44.3,325.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,340.5 44.3,335.8 44.3,325.4 54.3,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,349.9 54.1,345.2 54.1,334.8 64.1,339.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,345.2 51.9,344.2 51.9,328.9 54.1,329.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,345.1 54.1,340.4 54.1,329.9 64.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,354.5 63.9,349.8 63.9,339.4 73.9,344 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,349.8 61.7,348.8 61.7,333.5 63.9,334.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,349.6 63.9,345 63.9,334.5 73.9,339.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,359.1 73.7,354.4 73.7,343.9 83.7,348.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,354.4 71.5,353.4 71.5,338.1 73.7,339.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,354.2 73.7,349.5 73.7,339.1 83.7,343.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,363.7 83.5,359 83.5,348.5 93.5,353.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,359 81.3,357.9 81.3,342.7 83.5,343.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,358.8 83.5,354.1 83.5,343.7 93.5,348.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,368.2 93.3,363.5 93.3,353.1 103.3,357.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,363.6 91.1,362.5 91.1,347.3 93.3,348.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,363.4 93.3,358.7 93.3,348.3 103.3,353 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,372.8 103,368.1 103,357.7 113.1,362.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,368 103,363.3 103,352.9 113.1,357.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,368.1 100.9,367.1 100.9,351.9 103.1,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,377.3 112.8,372.7 112.8,362.3 122.6,366.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,372.5 112.8,367.9 112.8,357.5 122.6,362.1 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,372.7 110.7,371.7 110.7,356.4 112.9,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,377.3 120.5,376.3 120.5,361 122.7,362.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,314.1 183.6,333.5 213,319.7 171.7,300.3 171.7,300.3 132.5,282 103.1,295.8 71.5,310.6
			42,324.4 81.2,342.8 122.4,362.1 152,348.3 110.7,328.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,382 122.6,377.3 122.6,366.9 132.7,371.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,377.2 122.6,372.5 122.6,362 132.7,366.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,386.6 132.4,381.9 132.4,371.5 142.5,376.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,381.9 130.2,380.9 130.2,365.6 132.4,366.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,381.8 132.4,377.1 132.4,366.6 142.5,371.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,391.2 142.2,386.5 142.2,376.1 152.3,380.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,386.5 140,385.5 140,370.2 142.2,371.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,386.3 142.2,381.7 142.2,371.2 152.3,375.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,395.4 152,391.1 152,380.6 161.2,384.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,391.1 149.8,390 149.8,374.8 152,375.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,390.4 152,386.2 152,375.8 160.9,380 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,395.7 159.6,394.6 159.6,379.4 161.8,380.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,390 164.1,394.6 164.1,384.2 174.1,379.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,394.6 161.9,395.7 161.9,380.4 164.1,379.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,385.1 164.1,389.8 164.1,379.4 174.1,374.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,385.4 173.9,390.1 173.9,379.6 183.9,374.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,390.1 171.7,391.1 171.7,375.8 173.9,374.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,380.5 173.9,385.2 173.9,374.8 183.9,370.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,380.8 183.7,385.5 183.7,375 193.7,370.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,385.5 181.5,386.5 181.5,371.2 183.7,370.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,375.9 183.7,380.6 183.7,370.2 193.7,365.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,376.2 193.5,380.9 193.5,370.5 203.5,365.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,380.9 191.3,381.9 191.3,366.6 193.5,365.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,371.4 193.5,376 193.5,365.6 203.5,360.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,371.6 203.3,376.3 203.3,365.9 213.3,361.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,376.3 201.1,377.3 201.1,362.1 203.3,361 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,366.8 203.3,371.5 203.3,361 213.3,356.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,367 213.1,371.7 213.1,361.3 223.1,356.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,371.7 210.9,372.7 210.9,357.5 213.1,356.4 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,362.2 213.1,366.9 213.1,356.4 223.1,351.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,362.4 222.9,367.1 222.9,356.7 232.9,352 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,367.1 220.7,368.1 220.7,352.9 222.9,351.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,357.6 222.9,362.3 222.9,351.9 232.9,347.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,357.8 232.7,362.5 232.7,352.1 242.7,347.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,362.5 230.5,363.6 230.5,348.3 232.7,347.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,353 232.7,357.7 232.7,347.3 242.7,342.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,353.8 242.5,357.9 242.5,347.5 251.4,343.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,357.9 240.3,359 240.3,343.7 242.5,342.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,349.1 242.5,353.1 242.5,342.7 251.1,338.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,353.4 250.1,354.4 250.1,339.1 252.3,338.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,319.7 213,319.7 171.7,300.3 142.3,314.1 183.6,333.5 183.7,333.5 183.6,333.5 152.1,348.3
			152,348.3 122.4,362.1 122.6,362.2 161.9,380.6 191.4,366.8 223,352 223,352 252.4,338.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,345.3 44.3,340.6 44.3,330.2 54.3,334.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,340.6 42.1,339.6 42.1,324.3 44.3,325.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,340.5 44.3,335.8 44.3,325.4 54.3,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,349.9 54.1,345.2 54.1,334.8 64.1,339.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,345.2 51.9,344.2 51.9,328.9 54.1,329.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,345.1 54.1,340.4 54.1,329.9 64.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,354.5 63.9,349.8 63.9,339.4 73.9,344 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,349.8 61.7,348.8 61.7,333.5 63.9,334.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,349.6 63.9,345 63.9,334.5 73.9,339.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,359.1 73.7,354.4 73.7,343.9 83.7,348.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,354.4 71.5,353.4 71.5,338.1 73.7,339.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,354.2 73.7,349.5 73.7,339.1 83.7,343.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,363.7 83.5,359 83.5,348.5 93.5,353.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,359 81.3,357.9 81.3,342.7 83.5,343.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,358.8 83.5,354.1 83.5,343.7 93.5,348.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,368.2 93.3,363.5 93.3,353.1 103.3,357.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,363.6 91.1,362.5 91.1,347.3 93.3,348.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,363.4 93.3,358.7 93.3,348.3 103.3,353 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,372.8 103,368.1 103,357.7 113.1,362.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,368 103,363.3 103,352.9 113.1,357.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,368.1 100.9,367.1 100.9,351.9 103.1,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,377.3 112.8,372.7 112.8,362.3 122.6,366.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,372.5 112.8,367.9 112.8,357.5 122.6,362.1 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,372.7 110.7,371.7 110.7,356.4 112.9,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,377.3 120.5,376.3 120.5,361 122.7,362.1 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,328.9 142.3,314.1 142.3,314.1 171.7,300.3 171.7,300.3 132.5,282 103.1,295.8 71.5,310.6
			42,324.4 81.2,342.8 122.4,362.1 152,348.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={10} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,345.3 44.3,340.6 44.3,330.2 54.3,334.9 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,340.6 42.1,339.6 42.1,324.3 44.3,325.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,340.5 44.3,335.8 44.3,325.4 54.3,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,349.9 54.1,345.2 54.1,334.8 64.1,339.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,345.2 51.9,344.2 51.9,328.9 54.1,329.9 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,345.1 54.1,340.4 54.1,329.9 64.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,354.5 63.9,349.8 63.9,339.4 73.9,344 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,349.8 61.7,348.8 61.7,333.5 63.9,334.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,349.6 63.9,345 63.9,334.5 73.9,339.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,359.1 73.7,354.4 73.7,343.9 83.7,348.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,354.4 71.5,353.4 71.5,338.1 73.7,339.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,354.2 73.7,349.5 73.7,339.1 83.7,343.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,363.7 83.5,359 83.5,348.5 93.5,353.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,359 81.3,357.9 81.3,342.7 83.5,343.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,358.8 83.5,354.1 83.5,343.7 93.5,348.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,368.2 93.3,363.5 93.3,353.1 103.3,357.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,363.6 91.1,362.5 91.1,347.3 93.3,348.3 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,363.4 93.3,358.7 93.3,348.3 103.3,353 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,368.1 100.9,367.1 100.9,351.9 103.1,352.9 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,372.8 103,368.1 103,357.7 113.1,362.4 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,368 103,363.3 103,352.9 113.1,357.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,372.7 110.7,371.7 110.7,356.4 112.9,357.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,377.4 112.8,372.7 112.8,362.3 122.9,367 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,372.6 112.8,367.9 112.8,357.5 122.9,362.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,377.3 120.5,376.3 120.5,361 122.7,362.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,382 122.6,377.3 122.6,366.9 132.7,371.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,377.2 122.6,372.5 122.6,362 132.7,366.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,386.6 132.4,381.9 132.4,371.5 142.5,376.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,381.9 130.2,380.9 130.2,365.6 132.4,366.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,381.8 132.4,377.1 132.4,366.6 142.5,371.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,391.2 142.2,386.5 142.2,376.1 152.3,380.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,386.5 140,385.5 140,370.2 142.2,371.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,386.3 142.2,381.7 142.2,371.2 152.3,375.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,395.4 152,391.1 152,380.6 161.2,384.9 					"
                    />
                    <polygon
                      className="st14"
                      points="152,391.1 149.8,390 149.8,374.8 152,375.8 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,390.4 152,386.2 152,375.8 160.9,380 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,395.7 159.6,394.6 159.6,379.4 161.8,380.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,390 164.1,394.6 164.1,384.2 174.1,379.5 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,394.6 161.9,395.7 161.9,380.4 164.1,379.4 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,385.1 164.1,389.8 164.1,379.4 174.1,374.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,385.4 173.9,390.1 173.9,379.6 183.9,374.9 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,390.1 171.7,391.1 171.7,375.8 173.9,374.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,380.5 173.9,385.2 173.9,374.8 183.9,370.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,380.8 183.7,385.5 183.7,375 193.7,370.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,385.5 181.5,386.5 181.5,371.2 183.7,370.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,375.9 183.7,380.6 183.7,370.2 193.7,365.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,376.2 193.5,380.9 193.5,370.5 203.5,365.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,380.9 191.3,381.9 191.3,366.6 193.5,365.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,371.4 193.5,376 193.5,365.6 203.5,360.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,371.6 203.3,376.3 203.3,365.9 213.3,361.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,376.3 201.1,377.3 201.1,362.1 203.3,361 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,366.8 203.3,371.5 203.3,361 213.3,356.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,367 213.1,371.7 213.1,361.3 223.1,356.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,371.7 210.9,372.7 210.9,357.5 213.1,356.4 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,362.2 213.1,366.9 213.1,356.4 223.1,351.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,362.4 222.9,367.1 222.9,356.7 232.9,352 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,367.1 220.7,368.1 220.7,352.9 222.9,351.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,357.6 222.9,362.3 222.9,351.9 232.9,347.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,357.8 232.7,362.5 232.7,352.1 242.7,347.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,362.5 230.5,363.6 230.5,348.3 232.7,347.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,353 232.7,357.7 232.7,347.3 242.7,342.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,353.8 242.5,357.9 242.5,347.5 251.4,343.3 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,357.9 240.3,359 240.3,343.7 242.5,342.7 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,349.1 242.5,353.1 242.5,342.7 251.1,338.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,353.4 250.1,354.4 250.1,339.1 252.3,338.1 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,319.7L213.1,319.7l-41.4-19.4l0,0L132.5,282l-29.4,13.8l-31.6,14.8L42,324.4l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8L223,352l0,0l29.4-13.8L213.1,319.7z M152.1,348.3L152.1,348.3l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,348.3z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={11}
          >
            <FloorNeutral openFloors={openFloors} floor={11}>
              <polygon
                className="st1"
                points="152,331.1 183.6,316.3 183.6,316.3 183.6,316.3 142.2,296.9 142.2,296.9 110.6,311.7 		"
              />
              <path
                className="st16"
                d="M213,302.5L213,302.5l-41.4-19.4l0,0l-39.2-18.3L103,278.6l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,302.5z M183.6,316.3L152,331.1l0,0l-41.4-19.4l31.6-14.8l0,0
			L183.6,316.3L183.6,316.3L183.6,316.3z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,328.1 44.3,323.4 44.3,313 54.3,317.7 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,323.4 42.1,322.4 42.1,307.1 44.3,308.2 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,323.3 44.3,318.6 44.3,308.2 54.3,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,332.7 54.1,328 54.1,317.6 64.1,322.3 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,328 51.9,327 51.9,311.7 54.1,312.7 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,327.9 54.1,323.2 54.1,312.7 64.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,337.3 63.9,332.6 63.9,322.2 73.9,326.8 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,332.6 61.7,331.6 61.7,316.3 63.9,317.3 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,332.4 63.9,327.8 63.9,317.3 73.9,322 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,341.9 73.7,337.2 73.7,326.7 83.7,331.4 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,337.2 71.5,336.2 71.5,320.9 73.7,321.9 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,337 73.7,332.3 73.7,321.9 83.7,326.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,346.5 83.5,341.8 83.5,331.3 93.5,336 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,341.8 81.3,340.7 81.3,325.5 83.5,326.5 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,341.6 83.5,336.9 83.5,326.5 93.5,331.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,351 93.3,346.3 93.3,335.9 103.3,340.6 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,346.4 91.1,345.3 91.1,330.1 93.3,331.1 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,346.2 93.3,341.5 93.3,331.1 103.3,335.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,355.6 103,350.9 103,340.5 113.1,345.2 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,350.9 100.9,349.9 100.9,334.7 103.1,335.7 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,350.8 103,346.1 103,335.7 113.1,340.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,360.2 112.8,355.5 112.8,345.1 122.9,349.8 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,355.5 110.7,354.5 110.7,339.2 112.9,340.3 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,355.4 112.8,350.7 112.8,340.3 122.9,345 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,364.8 122.6,360.1 122.6,349.7 132.7,354.4 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,360.1 120.5,359.1 120.5,343.8 122.7,344.9 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,360 122.6,355.3 122.6,344.8 132.7,349.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,369.4 132.4,364.7 132.4,354.3 142.5,359 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,364.7 130.2,363.7 130.2,348.4 132.4,349.4 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,364.6 132.4,359.9 132.4,349.4 142.5,354.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,374 142.2,369.3 142.2,358.9 152.3,363.5 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,369.3 140,368.3 140,353 142.2,354 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,369.1 142.2,364.5 142.2,354 152.3,358.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,378.2 152,373.9 152,363.4 161.2,367.7 					"
                    />
                    <polygon
                      className="st9"
                      points="152,373.9 149.8,372.8 149.8,357.6 152,358.6 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,373.2 152,369 152,358.6 160.9,362.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,378.5 159.6,377.4 159.6,362.2 161.8,363.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,372.8 164.1,377.4 164.1,367 174.1,362.3 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,377.4 161.9,378.5 161.9,363.2 164.1,362.2 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,367.9 164.1,372.6 164.1,362.2 174.1,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,368.2 173.9,372.9 173.9,362.4 183.9,357.7 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,372.9 171.7,373.9 171.7,358.6 173.9,357.6 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,363.3 173.9,368 173.9,357.6 183.9,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,363.6 183.7,368.3 183.7,357.8 193.7,353.2 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,368.3 181.5,369.3 181.5,354 183.7,353 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,358.7 183.7,363.4 183.7,353 193.7,348.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,359 193.5,363.7 193.5,353.3 203.5,348.6 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,363.7 191.3,364.7 191.3,349.4 193.5,348.4 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,354.2 193.5,358.8 193.5,348.4 203.5,343.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,354.4 203.3,359.1 203.3,348.7 213.3,344 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,359.1 201.1,360.1 201.1,344.9 203.3,343.8 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,349.6 203.3,354.3 203.3,343.8 213.3,339.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,349.8 213.1,354.5 213.1,344.1 223.1,339.4 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,354.5 210.9,355.5 210.9,340.3 213.1,339.2 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,345 213.1,349.7 213.1,339.2 223.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,345.2 222.9,349.9 222.9,339.5 232.9,334.8 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,349.9 220.7,350.9 220.7,335.7 222.9,334.7 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,340.4 222.9,345.1 222.9,334.7 232.9,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,340.6 232.7,345.3 232.7,334.9 242.7,330.2 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,345.3 230.5,346.4 230.5,331.1 232.7,330.1 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,335.8 232.7,340.5 232.7,330.1 242.7,325.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,336.6 242.5,340.7 242.5,330.3 251.4,326.1 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,340.7 240.3,341.8 240.3,326.5 242.5,325.5 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,331.9 242.5,335.9 242.5,325.5 251.1,321.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,336.2 250.1,337.2 250.1,321.9 252.3,320.9 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,364.8 122.6,360.1 122.6,349.7 132.7,354.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,360 122.6,355.3 122.6,344.8 132.7,349.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,369.4 132.4,364.7 132.4,354.3 142.5,359 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,364.7 130.2,363.7 130.2,348.4 132.4,349.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,364.6 132.4,359.9 132.4,349.4 142.5,354.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,374 142.2,369.3 142.2,358.9 152.3,363.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,369.3 140,368.3 140,353 142.2,354 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,369.1 142.2,364.5 142.2,354 152.3,358.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,378.2 152,373.9 152,363.4 161.2,367.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,373.9 149.8,372.8 149.8,357.6 152,358.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,373.2 152,369 152,358.6 160.9,362.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,378.5 159.6,377.4 159.6,362.2 161.8,363.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,372.8 164.1,377.4 164.1,367 174.1,362.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,377.4 161.9,378.5 161.9,363.2 164.1,362.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,367.9 164.1,372.6 164.1,362.2 174.1,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,368.2 173.9,372.9 173.9,362.4 183.9,357.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,372.9 171.7,373.9 171.7,358.6 173.9,357.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,363.3 173.9,368 173.9,357.6 183.9,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,363.6 183.7,368.3 183.7,357.8 193.7,353.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,368.3 181.5,369.3 181.5,354 183.7,353 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,358.7 183.7,363.4 183.7,353 193.7,348.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,359 193.5,363.7 193.5,353.3 203.5,348.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,363.7 191.3,364.7 191.3,349.4 193.5,348.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,354.2 193.5,358.8 193.5,348.4 203.5,343.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,354.4 203.3,359.1 203.3,348.7 213.3,344 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,359.1 201.1,360.1 201.1,344.9 203.3,343.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,349.6 203.3,354.3 203.3,343.8 213.3,339.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,349.9 213.1,354.5 213.1,344.1 222.7,339.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,354.5 210.9,355.5 210.9,340.3 213.1,339.2 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,345.1 213.1,349.7 213.1,339.2 222.7,334.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,349.9 220.7,350.9 220.7,335.7 222.9,334.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,331.1 152,331.1 122.4,344.9 122.6,345 161.9,363.4 191.4,349.6 223,334.8 223,334.8
			183.6,316.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,328.1 44.3,323.4 44.3,313 54.3,317.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,323.4 42.1,322.4 42.1,307.1 44.3,308.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,323.3 44.3,318.6 44.3,308.2 54.3,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,332.7 54.1,328 54.1,317.6 64.1,322.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,328 51.9,327 51.9,311.7 54.1,312.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,327.9 54.1,323.2 54.1,312.7 64.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,337.3 63.9,332.6 63.9,322.2 73.9,326.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,332.6 61.7,331.6 61.7,316.3 63.9,317.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,332.4 63.9,327.8 63.9,317.3 73.9,322 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,341.9 73.7,337.2 73.7,326.7 83.7,331.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,337.2 71.5,336.2 71.5,320.9 73.7,321.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,337 73.7,332.3 73.7,321.9 83.7,326.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,346.5 83.5,341.8 83.5,331.3 93.5,336 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,341.8 81.3,340.7 81.3,325.5 83.5,326.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,341.6 83.5,336.9 83.5,326.5 93.5,331.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,351 93.3,346.3 93.3,335.9 103.3,340.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,346.4 91.1,345.3 91.1,330.1 93.3,331.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,346.2 93.3,341.5 93.3,331.1 103.3,335.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,355.6 103,350.9 103,340.5 113.1,345.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,350.8 103,346.1 103,335.7 113.1,340.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,350.9 100.9,349.9 100.9,334.7 103.1,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,360.1 112.8,355.5 112.8,345.1 122.7,349.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,355.3 112.8,350.7 112.8,340.3 122.7,344.9 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,355.5 110.7,354.5 110.7,339.2 112.9,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,360.1 120.5,359.1 120.5,343.8 122.7,344.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,345.2 222.9,349.9 222.9,339.5 232.9,334.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,340.4 222.9,345.1 222.9,334.7 232.9,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,340.6 232.7,345.3 232.7,334.9 242.7,330.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,345.3 230.5,346.4 230.5,331.1 232.7,330.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,335.8 232.7,340.5 232.7,330.1 242.7,325.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,336.6 242.5,340.7 242.5,330.3 251.4,326.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,340.7 240.3,341.8 240.3,326.5 242.5,325.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,331.9 242.5,335.9 242.5,325.5 251.1,321.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,336.2 250.1,337.2 250.1,321.9 252.3,320.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,302.5 213,302.5 171.7,283.1 171.7,283.1 132.5,264.8 103.1,278.6 71.5,293.4 42,307.2
			81.2,325.6 122.4,344.9 152,331.1 110.7,311.7 142.3,296.9 142.3,296.9 183.6,316.3 183.7,316.3 183.6,316.3 223,334.8 252.4,321 
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,364.8 122.6,360.1 122.6,349.7 132.7,354.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,360 122.6,355.3 122.6,344.8 132.7,349.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,369.4 132.4,364.7 132.4,354.3 142.5,359 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,364.7 130.2,363.7 130.2,348.4 132.4,349.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,364.6 132.4,359.9 132.4,349.4 142.5,354.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,374 142.2,369.3 142.2,358.9 152.3,363.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,369.3 140,368.3 140,353 142.2,354 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,369.1 142.2,364.5 142.2,354 152.3,358.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,378.2 152,373.9 152,363.4 161.2,367.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,373.9 149.8,372.8 149.8,357.6 152,358.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,373.2 152,369 152,358.6 160.9,362.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,378.5 159.6,377.4 159.6,362.2 161.8,363.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,372.8 164.1,377.4 164.1,367 174.1,362.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,377.4 161.9,378.5 161.9,363.2 164.1,362.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,367.9 164.1,372.6 164.1,362.2 174.1,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,368.2 173.9,372.9 173.9,362.4 183.9,357.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,372.9 171.7,373.9 171.7,358.6 173.9,357.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,363.3 173.9,368 173.9,357.6 183.9,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,363.6 183.7,368.3 183.7,357.8 193.7,353.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,368.3 181.5,369.3 181.5,354 183.7,353 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,358.7 183.7,363.4 183.7,353 193.7,348.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,359 193.5,363.7 193.5,353.3 203.5,348.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,363.7 191.3,364.7 191.3,349.4 193.5,348.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,354.2 193.5,358.8 193.5,348.4 203.5,343.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,354.4 203.3,359.1 203.3,348.7 213.3,344 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,359.1 201.1,360.1 201.1,344.9 203.3,343.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,349.6 203.3,354.3 203.3,343.8 213.3,339.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,349.8 213.1,354.5 213.1,344.1 223.1,339.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,354.5 210.9,355.5 210.9,340.3 213.1,339.2 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,345 213.1,349.7 213.1,339.2 223.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,345.2 222.9,349.9 222.9,339.5 232.9,334.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,349.9 220.7,350.9 220.7,335.7 222.9,334.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,340.4 222.9,345.1 222.9,334.7 232.9,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,340.6 232.7,345.3 232.7,334.9 242.7,330.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,345.3 230.5,346.4 230.5,331.1 232.7,330.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,335.8 232.7,340.5 232.7,330.1 242.7,325.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,336.6 242.5,340.7 242.5,330.3 251.4,326.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,340.7 240.3,341.8 240.3,326.5 242.5,325.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,331.9 242.5,335.9 242.5,325.5 251.1,321.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,336.2 250.1,337.2 250.1,321.9 252.3,320.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,302.5 213,302.5 183.6,316.3 183.7,316.3 183.6,316.3 152.1,331.1 152,331.1 122.4,344.9
			122.6,345 161.9,363.4 191.4,349.6 223,334.8 223,334.8 252.4,321 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,328.1 44.3,323.4 44.3,313 54.3,317.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,323.4 42.1,322.4 42.1,307.1 44.3,308.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,323.3 44.3,318.6 44.3,308.2 54.3,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,332.7 54.1,328 54.1,317.6 64.1,322.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,328 51.9,327 51.9,311.7 54.1,312.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,327.9 54.1,323.2 54.1,312.7 64.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,337.3 63.9,332.6 63.9,322.2 73.9,326.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,332.6 61.7,331.6 61.7,316.3 63.9,317.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,332.4 63.9,327.8 63.9,317.3 73.9,322 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,341.9 73.7,337.2 73.7,326.7 83.7,331.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,337.2 71.5,336.2 71.5,320.9 73.7,321.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,337 73.7,332.3 73.7,321.9 83.7,326.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,346.5 83.5,341.8 83.5,331.3 93.5,336 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,341.8 81.3,340.7 81.3,325.5 83.5,326.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,341.6 83.5,336.9 83.5,326.5 93.5,331.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,351 93.3,346.3 93.3,335.9 103.3,340.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,346.4 91.1,345.3 91.1,330.1 93.3,331.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,346.2 93.3,341.5 93.3,331.1 103.3,335.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,355.6 103,350.9 103,340.5 113.1,345.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,350.8 103,346.1 103,335.7 113.1,340.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,350.9 100.9,349.9 100.9,334.7 103.1,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,360.1 112.8,355.5 112.8,345.1 122.6,349.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,355.3 112.8,350.7 112.8,340.3 122.6,344.9 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,355.5 110.7,354.5 110.7,339.2 112.9,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,360.1 120.5,359.1 120.5,343.8 122.7,344.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,296.9 183.6,316.3 213,302.5 171.7,283.1 171.7,283.1 132.5,264.8 103.1,278.6 71.5,293.4
			42,307.2 81.2,325.6 122.4,344.9 152,331.1 110.7,311.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,364.8 122.6,360.1 122.6,349.7 132.7,354.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,360 122.6,355.3 122.6,344.8 132.7,349.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,369.4 132.4,364.7 132.4,354.3 142.5,359 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,364.7 130.2,363.7 130.2,348.4 132.4,349.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,364.6 132.4,359.9 132.4,349.4 142.5,354.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,374 142.2,369.3 142.2,358.9 152.3,363.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,369.3 140,368.3 140,353 142.2,354 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,369.1 142.2,364.5 142.2,354 152.3,358.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,378.2 152,373.9 152,363.4 161.2,367.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,373.9 149.8,372.8 149.8,357.6 152,358.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,373.2 152,369 152,358.6 160.9,362.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,378.5 159.6,377.4 159.6,362.2 161.8,363.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,372.8 164.1,377.4 164.1,367 174.1,362.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,377.4 161.9,378.5 161.9,363.2 164.1,362.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,367.9 164.1,372.6 164.1,362.2 174.1,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,368.2 173.9,372.9 173.9,362.4 183.9,357.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,372.9 171.7,373.9 171.7,358.6 173.9,357.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,363.3 173.9,368 173.9,357.6 183.9,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,363.6 183.7,368.3 183.7,357.8 193.7,353.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,368.3 181.5,369.3 181.5,354 183.7,353 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,358.7 183.7,363.4 183.7,353 193.7,348.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,359 193.5,363.7 193.5,353.3 203.5,348.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,363.7 191.3,364.7 191.3,349.4 193.5,348.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,354.2 193.5,358.8 193.5,348.4 203.5,343.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,354.4 203.3,359.1 203.3,348.7 213.3,344 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,359.1 201.1,360.1 201.1,344.9 203.3,343.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,349.6 203.3,354.3 203.3,343.8 213.3,339.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,349.8 213.1,354.5 213.1,344.1 223.1,339.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,354.5 210.9,355.5 210.9,340.3 213.1,339.2 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,345 213.1,349.7 213.1,339.2 223.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,345.2 222.9,349.9 222.9,339.5 232.9,334.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,349.9 220.7,350.9 220.7,335.7 222.9,334.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,340.4 222.9,345.1 222.9,334.7 232.9,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,340.6 232.7,345.3 232.7,334.9 242.7,330.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,345.3 230.5,346.4 230.5,331.1 232.7,330.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,335.8 232.7,340.5 232.7,330.1 242.7,325.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,336.6 242.5,340.7 242.5,330.3 251.4,326.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,340.7 240.3,341.8 240.3,326.5 242.5,325.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,331.9 242.5,335.9 242.5,325.5 251.1,321.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,336.2 250.1,337.2 250.1,321.9 252.3,320.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,302.5 213,302.5 171.7,283.1 142.3,296.9 183.6,316.3 183.7,316.3 183.6,316.3 152.1,331.1
			152,331.1 122.4,344.9 122.6,345 161.9,363.4 191.4,349.6 223,334.8 223,334.8 252.4,321 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,328.1 44.3,323.4 44.3,313 54.3,317.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,323.4 42.1,322.4 42.1,307.1 44.3,308.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,323.3 44.3,318.6 44.3,308.2 54.3,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,332.7 54.1,328 54.1,317.6 64.1,322.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,328 51.9,327 51.9,311.7 54.1,312.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,327.9 54.1,323.2 54.1,312.7 64.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,337.3 63.9,332.6 63.9,322.2 73.9,326.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,332.6 61.7,331.6 61.7,316.3 63.9,317.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,332.4 63.9,327.8 63.9,317.3 73.9,322 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,341.9 73.7,337.2 73.7,326.7 83.7,331.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,337.2 71.5,336.2 71.5,320.9 73.7,321.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,337 73.7,332.3 73.7,321.9 83.7,326.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,346.5 83.5,341.8 83.5,331.3 93.5,336 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,341.8 81.3,340.7 81.3,325.5 83.5,326.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,341.6 83.5,336.9 83.5,326.5 93.5,331.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,351 93.3,346.3 93.3,335.9 103.3,340.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,346.4 91.1,345.3 91.1,330.1 93.3,331.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,346.2 93.3,341.5 93.3,331.1 103.3,335.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,355.6 103,350.9 103,340.5 113.1,345.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,350.8 103,346.1 103,335.7 113.1,340.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,350.9 100.9,349.9 100.9,334.7 103.1,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,360.1 112.8,355.5 112.8,345.1 122.6,349.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,355.3 112.8,350.7 112.8,340.3 122.6,344.9 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,355.5 110.7,354.5 110.7,339.2 112.9,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,360.1 120.5,359.1 120.5,343.8 122.7,344.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,311.7 142.3,296.9 142.3,296.9 171.7,283.1 171.7,283.1 132.5,264.8 103.1,278.6 71.5,293.4
			42,307.2 81.2,325.6 122.4,344.9 152,331.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={11} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,328.1 44.3,323.4 44.3,313 54.3,317.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,323.4 42.1,322.4 42.1,307.1 44.3,308.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,323.3 44.3,318.6 44.3,308.2 54.3,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,332.7 54.1,328 54.1,317.6 64.1,322.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,328 51.9,327 51.9,311.7 54.1,312.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,327.9 54.1,323.2 54.1,312.7 64.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,337.3 63.9,332.6 63.9,322.2 73.9,326.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,332.6 61.7,331.6 61.7,316.3 63.9,317.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,332.4 63.9,327.8 63.9,317.3 73.9,322 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,341.9 73.7,337.2 73.7,326.7 83.7,331.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,337.2 71.5,336.2 71.5,320.9 73.7,321.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,337 73.7,332.3 73.7,321.9 83.7,326.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,346.5 83.5,341.8 83.5,331.3 93.5,336 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,341.8 81.3,340.7 81.3,325.5 83.5,326.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,341.6 83.5,336.9 83.5,326.5 93.5,331.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,351 93.3,346.3 93.3,335.9 103.3,340.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,346.4 91.1,345.3 91.1,330.1 93.3,331.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,346.2 93.3,341.5 93.3,331.1 103.3,335.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,350.9 100.9,349.9 100.9,334.7 103.1,335.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,355.6 103,350.9 103,340.5 113.1,345.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,350.8 103,346.1 103,335.7 113.1,340.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,355.5 110.7,354.5 110.7,339.2 112.9,340.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,360.2 112.8,355.5 112.8,345.1 122.9,349.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,355.4 112.8,350.7 112.8,340.3 122.9,345 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,360.1 120.5,359.1 120.5,343.8 122.7,344.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,364.8 122.6,360.1 122.6,349.7 132.7,354.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,360 122.6,355.3 122.6,344.8 132.7,349.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,369.4 132.4,364.7 132.4,354.3 142.5,359 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,364.7 130.2,363.7 130.2,348.4 132.4,349.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,364.6 132.4,359.9 132.4,349.4 142.5,354.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,374 142.2,369.3 142.2,358.9 152.3,363.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,369.3 140,368.3 140,353 142.2,354 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,369.1 142.2,364.5 142.2,354 152.3,358.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,378.2 152,373.9 152,363.4 161.2,367.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,373.9 149.8,372.8 149.8,357.6 152,358.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,373.2 152,369 152,358.6 160.9,362.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,378.5 159.6,377.4 159.6,362.2 161.8,363.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,372.8 164.1,377.4 164.1,367 174.1,362.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,377.4 161.9,378.5 161.9,363.2 164.1,362.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,367.9 164.1,372.6 164.1,362.2 174.1,357.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,368.2 173.9,372.9 173.9,362.4 183.9,357.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,372.9 171.7,373.9 171.7,358.6 173.9,357.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,363.3 173.9,368 173.9,357.6 183.9,352.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,363.6 183.7,368.3 183.7,357.8 193.7,353.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,368.3 181.5,369.3 181.5,354 183.7,353 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,358.7 183.7,363.4 183.7,353 193.7,348.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,359 193.5,363.7 193.5,353.3 203.5,348.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,363.7 191.3,364.7 191.3,349.4 193.5,348.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,354.2 193.5,358.8 193.5,348.4 203.5,343.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,354.4 203.3,359.1 203.3,348.7 213.3,344 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,359.1 201.1,360.1 201.1,344.9 203.3,343.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,349.6 203.3,354.3 203.3,343.8 213.3,339.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,349.8 213.1,354.5 213.1,344.1 223.1,339.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,354.5 210.9,355.5 210.9,340.3 213.1,339.2 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,345 213.1,349.7 213.1,339.2 223.1,334.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,345.2 222.9,349.9 222.9,339.5 232.9,334.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,349.9 220.7,350.9 220.7,335.7 222.9,334.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,340.4 222.9,345.1 222.9,334.7 232.9,330 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,340.6 232.7,345.3 232.7,334.9 242.7,330.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,345.3 230.5,346.4 230.5,331.1 232.7,330.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,335.8 232.7,340.5 232.7,330.1 242.7,325.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,336.6 242.5,340.7 242.5,330.3 251.4,326.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,340.7 240.3,341.8 240.3,326.5 242.5,325.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,331.9 242.5,335.9 242.5,325.5 251.1,321.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,336.2 250.1,337.2 250.1,321.9 252.3,320.9 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,302.5L213.1,302.5l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,307.2l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,302.5z M152.1,331.1L152.1,331.1l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,331.1z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={12}
          >
            <FloorNeutral openFloors={openFloors} floor={12}>
              <polygon
                className="st1"
                points="152,313.9 183.6,299.1 183.6,299.1 183.6,299.1 142.2,279.7 142.2,279.7 110.6,294.5 		"
              />
              <path
                className="st16"
                d="M213,285.3L213,285.3l-41.4-19.4l0,0l-39.2-18.3L103,261.4l-31.6,14.8L41.9,290l39.2,18.4l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,285.3z M183.6,299.1L152,313.9l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,299.1
			L183.6,299.1L183.6,299.1z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,310.9 44.3,306.2 44.3,295.8 54.3,300.5 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,306.2 42.1,305.2 42.1,289.9 44.3,291 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,306.1 44.3,301.4 44.3,291 54.3,295.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,315.5 54.1,310.8 54.1,300.4 64.1,305.1 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,310.8 51.9,309.8 51.9,294.5 54.1,295.5 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,310.7 54.1,306 54.1,295.5 64.1,300.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,320.1 63.9,315.4 63.9,305 73.9,309.6 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,315.4 61.7,314.4 61.7,299.1 63.9,300.1 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,315.2 63.9,310.6 63.9,300.1 73.9,304.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,324.7 73.7,320 73.7,309.5 83.7,314.2 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,320 71.5,319 71.5,303.7 73.7,304.7 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,319.8 73.7,315.1 73.7,304.7 83.7,309.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,329.3 83.5,324.6 83.5,314.1 93.5,318.8 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,324.6 81.3,323.5 81.3,308.3 83.5,309.3 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,324.4 83.5,319.7 83.5,309.3 93.5,314 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,333.8 93.3,329.1 93.3,318.7 103.3,323.4 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,329.2 91.1,328.1 91.1,312.9 93.3,313.9 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,329 93.3,324.3 93.3,313.9 103.3,318.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,338.4 103,333.7 103,323.3 113.1,328 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,333.7 100.9,332.7 100.9,317.5 103.1,318.5 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,333.6 103,328.9 103,318.5 113.1,323.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,343 112.8,338.3 112.8,327.9 122.9,332.6 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,338.3 110.7,337.3 110.7,322 112.9,323.1 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,338.2 112.8,333.5 112.8,323.1 122.9,327.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,347.6 122.6,342.9 122.6,332.5 132.7,337.2 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,342.9 120.5,341.9 120.5,326.6 122.7,327.7 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,342.8 122.6,338.1 122.6,327.6 132.7,332.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,352.2 132.4,347.5 132.4,337.1 142.5,341.8 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,347.5 130.2,346.5 130.2,331.2 132.4,332.2 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,347.4 132.4,342.7 132.4,332.2 142.5,336.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,356.8 142.2,352.1 142.2,341.7 152.3,346.3 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,352.1 140,351.1 140,335.8 142.2,336.8 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,351.9 142.2,347.3 142.2,336.8 152.3,341.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,361 152,356.7 152,346.2 161.2,350.5 					"
                    />
                    <polygon
                      className="st9"
                      points="152,356.7 149.8,355.6 149.8,340.4 152,341.4 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,356 152,351.8 152,341.4 160.9,345.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,361.3 159.6,360.2 159.6,345 161.8,346 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,355.6 164.1,360.2 164.1,349.8 174.1,345.1 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,360.2 161.9,361.3 161.9,346 164.1,345 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,350.7 164.1,355.4 164.1,345 174.1,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,351 173.9,355.7 173.9,345.2 183.9,340.5 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,355.7 171.7,356.7 171.7,341.4 173.9,340.4 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,346.1 173.9,350.8 173.9,340.4 183.9,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,346.4 183.7,351.1 183.7,340.6 193.7,336 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,351.1 181.5,352.1 181.5,336.8 183.7,335.8 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,341.5 183.7,346.2 183.7,335.8 193.7,331.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,341.8 193.5,346.5 193.5,336.1 203.5,331.4 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,346.5 191.3,347.5 191.3,332.2 193.5,331.2 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,337 193.5,341.6 193.5,331.2 203.5,326.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,337.2 203.3,341.9 203.3,331.5 213.3,326.8 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,341.9 201.1,342.9 201.1,327.7 203.3,326.6 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,332.4 203.3,337.1 203.3,326.6 213.3,321.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,332.6 213.1,337.3 213.1,326.9 223.1,322.2 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,337.3 210.9,338.3 210.9,323.1 213.1,322 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,327.8 213.1,332.5 213.1,322 223.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,328 222.9,332.7 222.9,322.3 232.9,317.6 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,332.7 220.7,333.7 220.7,318.5 222.9,317.5 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,323.2 222.9,327.9 222.9,317.5 232.9,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,323.4 232.7,328.1 232.7,317.7 242.7,313 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,328.1 230.5,329.2 230.5,313.9 232.7,312.9 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,318.6 232.7,323.3 232.7,312.9 242.7,308.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,319.4 242.5,323.5 242.5,313.1 251.4,308.9 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,323.5 240.3,324.6 240.3,309.3 242.5,308.3 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,314.7 242.5,318.7 242.5,308.3 251.1,304.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,319 250.1,320 250.1,304.7 252.3,303.7 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,347.6 122.6,342.9 122.6,332.5 132.7,337.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,342.8 122.6,338.1 122.6,327.6 132.7,332.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,352.2 132.4,347.5 132.4,337.1 142.5,341.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,347.5 130.2,346.5 130.2,331.2 132.4,332.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,347.4 132.4,342.7 132.4,332.2 142.5,336.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,356.8 142.2,352.1 142.2,341.7 152.3,346.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,352.1 140,351.1 140,335.8 142.2,336.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,351.9 142.2,347.3 142.2,336.8 152.3,341.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,361 152,356.7 152,346.2 161.2,350.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,356.7 149.8,355.6 149.8,340.4 152,341.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,356 152,351.8 152,341.4 160.9,345.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,361.3 159.6,360.2 159.6,345 161.8,346 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,355.6 164.1,360.2 164.1,349.8 174.1,345.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,360.2 161.9,361.3 161.9,346 164.1,345 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,350.7 164.1,355.4 164.1,345 174.1,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,351 173.9,355.7 173.9,345.2 183.9,340.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,355.7 171.7,356.7 171.7,341.4 173.9,340.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,346.1 173.9,350.8 173.9,340.4 183.9,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,346.4 183.7,351.1 183.7,340.6 193.7,336 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,351.1 181.5,352.1 181.5,336.8 183.7,335.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,341.5 183.7,346.2 183.7,335.8 193.7,331.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,341.8 193.5,346.5 193.5,336.1 203.5,331.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,346.5 191.3,347.5 191.3,332.2 193.5,331.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,337 193.5,341.6 193.5,331.2 203.5,326.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,337.2 203.3,341.9 203.3,331.5 213.3,326.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,341.9 201.1,342.9 201.1,327.7 203.3,326.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,332.4 203.3,337.1 203.3,326.6 213.3,321.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,332.7 213.1,337.3 213.1,326.9 222.7,322.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,337.3 210.9,338.3 210.9,323.1 213.1,322 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,327.9 213.1,332.5 213.1,322 222.7,317.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,332.7 220.7,333.7 220.7,318.5 222.9,317.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,313.9 152,313.9 122.4,327.7 122.6,327.8 161.9,346.2 191.4,332.4 223,317.6 223,317.6
			183.6,299.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,310.9 44.3,306.2 44.3,295.8 54.3,300.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,306.2 42.1,305.2 42.1,289.9 44.3,291 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,306.1 44.3,301.4 44.3,291 54.3,295.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,315.5 54.1,310.8 54.1,300.4 64.1,305.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,310.8 51.9,309.8 51.9,294.5 54.1,295.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,310.7 54.1,306 54.1,295.5 64.1,300.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,320.1 63.9,315.4 63.9,305 73.9,309.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,315.4 61.7,314.4 61.7,299.1 63.9,300.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,315.2 63.9,310.6 63.9,300.1 73.9,304.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,324.7 73.7,320 73.7,309.5 83.7,314.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,320 71.5,319 71.5,303.7 73.7,304.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,319.8 73.7,315.1 73.7,304.7 83.7,309.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,329.3 83.5,324.6 83.5,314.1 93.5,318.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,324.6 81.3,323.5 81.3,308.3 83.5,309.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,324.4 83.5,319.7 83.5,309.3 93.5,314 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,333.8 93.3,329.1 93.3,318.7 103.3,323.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,329.2 91.1,328.1 91.1,312.9 93.3,313.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,329 93.3,324.3 93.3,313.9 103.3,318.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,338.4 103,333.7 103,323.3 113.1,328 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,333.6 103,328.9 103,318.5 113.1,323.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,333.7 100.9,332.7 100.9,317.5 103.1,318.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,342.9 112.8,338.3 112.8,327.9 122.7,332.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,338.1 112.8,333.5 112.8,323.1 122.7,327.7 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,338.3 110.7,337.3 110.7,322 112.9,323.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,342.9 120.5,341.9 120.5,326.6 122.7,327.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,328 222.9,332.7 222.9,322.3 232.9,317.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,323.2 222.9,327.9 222.9,317.5 232.9,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,323.4 232.7,328.1 232.7,317.7 242.7,313 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,328.1 230.5,329.2 230.5,313.9 232.7,312.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,318.6 232.7,323.3 232.7,312.9 242.7,308.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,319.4 242.5,323.5 242.5,313.1 251.4,308.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,323.5 240.3,324.6 240.3,309.3 242.5,308.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,314.7 242.5,318.7 242.5,308.3 251.1,304.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,319 250.1,320 250.1,304.7 252.3,303.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,285.3 213,285.3 171.7,265.9 171.7,265.9 132.5,247.6 103.1,261.4 71.5,276.2 42,290
			81.2,308.4 122.4,327.7 152,313.9 110.7,294.5 142.3,279.7 142.3,279.7 183.6,299.1 183.7,299.1 183.6,299.1 223,317.6 
			252.4,303.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,347.6 122.6,342.9 122.6,332.5 132.7,337.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,342.8 122.6,338.1 122.6,327.6 132.7,332.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,352.2 132.4,347.5 132.4,337.1 142.5,341.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,347.5 130.2,346.5 130.2,331.2 132.4,332.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,347.4 132.4,342.7 132.4,332.2 142.5,336.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,356.8 142.2,352.1 142.2,341.7 152.3,346.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,352.1 140,351.1 140,335.8 142.2,336.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,351.9 142.2,347.3 142.2,336.8 152.3,341.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,361 152,356.7 152,346.2 161.2,350.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,356.7 149.8,355.6 149.8,340.4 152,341.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,356 152,351.8 152,341.4 160.9,345.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,361.3 159.6,360.2 159.6,345 161.8,346 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,355.6 164.1,360.2 164.1,349.8 174.1,345.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,360.2 161.9,361.3 161.9,346 164.1,345 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,350.7 164.1,355.4 164.1,345 174.1,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,351 173.9,355.7 173.9,345.2 183.9,340.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,355.7 171.7,356.7 171.7,341.4 173.9,340.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,346.1 173.9,350.8 173.9,340.4 183.9,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,346.4 183.7,351.1 183.7,340.6 193.7,336 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,351.1 181.5,352.1 181.5,336.8 183.7,335.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,341.5 183.7,346.2 183.7,335.8 193.7,331.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,341.8 193.5,346.5 193.5,336.1 203.5,331.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,346.5 191.3,347.5 191.3,332.2 193.5,331.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,337 193.5,341.6 193.5,331.2 203.5,326.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,337.2 203.3,341.9 203.3,331.5 213.3,326.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,341.9 201.1,342.9 201.1,327.7 203.3,326.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,332.4 203.3,337.1 203.3,326.6 213.3,321.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,332.6 213.1,337.3 213.1,326.9 223.1,322.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,337.3 210.9,338.3 210.9,323.1 213.1,322 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,327.8 213.1,332.5 213.1,322 223.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,328 222.9,332.7 222.9,322.3 232.9,317.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,332.7 220.7,333.7 220.7,318.5 222.9,317.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,323.2 222.9,327.9 222.9,317.5 232.9,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,323.4 232.7,328.1 232.7,317.7 242.7,313 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,328.1 230.5,329.2 230.5,313.9 232.7,312.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,318.6 232.7,323.3 232.7,312.9 242.7,308.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,319.4 242.5,323.5 242.5,313.1 251.4,308.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,323.5 240.3,324.6 240.3,309.3 242.5,308.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,314.7 242.5,318.7 242.5,308.3 251.1,304.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,319 250.1,320 250.1,304.7 252.3,303.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,285.3 213,285.3 183.6,299.1 183.7,299.1 183.6,299.1 152.1,313.9 152,313.9 122.4,327.7
			122.6,327.8 161.9,346.2 191.4,332.4 223,317.6 223,317.6 252.4,303.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,310.9 44.3,306.2 44.3,295.8 54.3,300.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,306.2 42.1,305.2 42.1,289.9 44.3,291 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,306.1 44.3,301.4 44.3,291 54.3,295.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,315.5 54.1,310.8 54.1,300.4 64.1,305.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,310.8 51.9,309.8 51.9,294.5 54.1,295.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,310.7 54.1,306 54.1,295.5 64.1,300.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,320.1 63.9,315.4 63.9,305 73.9,309.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,315.4 61.7,314.4 61.7,299.1 63.9,300.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,315.2 63.9,310.6 63.9,300.1 73.9,304.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,324.7 73.7,320 73.7,309.5 83.7,314.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,320 71.5,319 71.5,303.7 73.7,304.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,319.8 73.7,315.1 73.7,304.7 83.7,309.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,329.3 83.5,324.6 83.5,314.1 93.5,318.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,324.6 81.3,323.5 81.3,308.3 83.5,309.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,324.4 83.5,319.7 83.5,309.3 93.5,314 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,333.8 93.3,329.1 93.3,318.7 103.3,323.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,329.2 91.1,328.1 91.1,312.9 93.3,313.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,329 93.3,324.3 93.3,313.9 103.3,318.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,338.4 103,333.7 103,323.3 113.1,328 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,333.6 103,328.9 103,318.5 113.1,323.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,333.7 100.9,332.7 100.9,317.5 103.1,318.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,342.9 112.8,338.3 112.8,327.9 122.6,332.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,338.1 112.8,333.5 112.8,323.1 122.6,327.7 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,338.3 110.7,337.3 110.7,322 112.9,323.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,342.9 120.5,341.9 120.5,326.6 122.7,327.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,279.7 183.6,299.1 213,285.3 171.7,265.9 171.7,265.9 132.5,247.6 103.1,261.4 71.5,276.2
			42,290 81.2,308.4 122.4,327.7 152,313.9 110.7,294.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,347.6 122.6,342.9 122.6,332.5 132.7,337.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,342.8 122.6,338.1 122.6,327.6 132.7,332.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,352.2 132.4,347.5 132.4,337.1 142.5,341.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,347.5 130.2,346.5 130.2,331.2 132.4,332.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,347.4 132.4,342.7 132.4,332.2 142.5,336.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,356.8 142.2,352.1 142.2,341.7 152.3,346.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,352.1 140,351.1 140,335.8 142.2,336.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,351.9 142.2,347.3 142.2,336.8 152.3,341.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,361 152,356.7 152,346.2 161.2,350.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,356.7 149.8,355.6 149.8,340.4 152,341.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,356 152,351.8 152,341.4 160.9,345.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,361.3 159.6,360.2 159.6,345 161.8,346 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,355.6 164.1,360.2 164.1,349.8 174.1,345.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,360.2 161.9,361.3 161.9,346 164.1,345 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,350.7 164.1,355.4 164.1,345 174.1,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,351 173.9,355.7 173.9,345.2 183.9,340.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,355.7 171.7,356.7 171.7,341.4 173.9,340.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,346.1 173.9,350.8 173.9,340.4 183.9,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,346.4 183.7,351.1 183.7,340.6 193.7,336 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,351.1 181.5,352.1 181.5,336.8 183.7,335.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,341.5 183.7,346.2 183.7,335.8 193.7,331.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,341.8 193.5,346.5 193.5,336.1 203.5,331.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,346.5 191.3,347.5 191.3,332.2 193.5,331.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,337 193.5,341.6 193.5,331.2 203.5,326.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,337.2 203.3,341.9 203.3,331.5 213.3,326.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,341.9 201.1,342.9 201.1,327.7 203.3,326.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,332.4 203.3,337.1 203.3,326.6 213.3,321.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,332.6 213.1,337.3 213.1,326.9 223.1,322.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,337.3 210.9,338.3 210.9,323.1 213.1,322 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,327.8 213.1,332.5 213.1,322 223.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,328 222.9,332.7 222.9,322.3 232.9,317.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,332.7 220.7,333.7 220.7,318.5 222.9,317.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,323.2 222.9,327.9 222.9,317.5 232.9,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,323.4 232.7,328.1 232.7,317.7 242.7,313 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,328.1 230.5,329.2 230.5,313.9 232.7,312.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,318.6 232.7,323.3 232.7,312.9 242.7,308.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,319.4 242.5,323.5 242.5,313.1 251.4,308.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,323.5 240.3,324.6 240.3,309.3 242.5,308.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,314.7 242.5,318.7 242.5,308.3 251.1,304.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,319 250.1,320 250.1,304.7 252.3,303.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,285.3 213,285.3 171.7,265.9 142.3,279.7 183.6,299.1 183.7,299.1 183.6,299.1 152.1,313.9
			152,313.9 122.4,327.7 122.6,327.8 161.9,346.2 191.4,332.4 223,317.6 223,317.6 252.4,303.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,310.9 44.3,306.2 44.3,295.8 54.3,300.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,306.2 42.1,305.2 42.1,289.9 44.3,291 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,306.1 44.3,301.4 44.3,291 54.3,295.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,315.5 54.1,310.8 54.1,300.4 64.1,305.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,310.8 51.9,309.8 51.9,294.5 54.1,295.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,310.7 54.1,306 54.1,295.5 64.1,300.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,320.1 63.9,315.4 63.9,305 73.9,309.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,315.4 61.7,314.4 61.7,299.1 63.9,300.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,315.2 63.9,310.6 63.9,300.1 73.9,304.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,324.7 73.7,320 73.7,309.5 83.7,314.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,320 71.5,319 71.5,303.7 73.7,304.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,319.8 73.7,315.1 73.7,304.7 83.7,309.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,329.3 83.5,324.6 83.5,314.1 93.5,318.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,324.6 81.3,323.5 81.3,308.3 83.5,309.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,324.4 83.5,319.7 83.5,309.3 93.5,314 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,333.8 93.3,329.1 93.3,318.7 103.3,323.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,329.2 91.1,328.1 91.1,312.9 93.3,313.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,329 93.3,324.3 93.3,313.9 103.3,318.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,338.4 103,333.7 103,323.3 113.1,328 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,333.6 103,328.9 103,318.5 113.1,323.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,333.7 100.9,332.7 100.9,317.5 103.1,318.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,342.9 112.8,338.3 112.8,327.9 122.6,332.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,338.1 112.8,333.5 112.8,323.1 122.6,327.7 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,338.3 110.7,337.3 110.7,322 112.9,323.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,342.9 120.5,341.9 120.5,326.6 122.7,327.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,294.5 142.3,279.7 142.3,279.7 171.7,265.9 171.7,265.9 132.5,247.6 103.1,261.4 71.5,276.2
			42,290 81.2,308.4 122.4,327.7 152,313.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={12} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,310.9 44.3,306.2 44.3,295.8 54.3,300.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,306.2 42.1,305.2 42.1,289.9 44.3,291 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,306.1 44.3,301.4 44.3,291 54.3,295.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,315.5 54.1,310.8 54.1,300.4 64.1,305.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,310.8 51.9,309.8 51.9,294.5 54.1,295.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,310.7 54.1,306 54.1,295.5 64.1,300.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,320.1 63.9,315.4 63.9,305 73.9,309.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,315.4 61.7,314.4 61.7,299.1 63.9,300.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,315.2 63.9,310.6 63.9,300.1 73.9,304.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,324.7 73.7,320 73.7,309.5 83.7,314.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,320 71.5,319 71.5,303.7 73.7,304.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,319.8 73.7,315.1 73.7,304.7 83.7,309.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,329.3 83.5,324.6 83.5,314.1 93.5,318.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,324.6 81.3,323.5 81.3,308.3 83.5,309.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,324.4 83.5,319.7 83.5,309.3 93.5,314 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,333.8 93.3,329.1 93.3,318.7 103.3,323.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,329.2 91.1,328.1 91.1,312.9 93.3,313.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,329 93.3,324.3 93.3,313.9 103.3,318.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,333.7 100.9,332.7 100.9,317.5 103.1,318.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,338.4 103,333.7 103,323.3 113.1,328 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,333.6 103,328.9 103,318.5 113.1,323.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,338.3 110.7,337.3 110.7,322 112.9,323.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,343 112.8,338.3 112.8,327.9 122.9,332.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,338.2 112.8,333.5 112.8,323.1 122.9,327.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,342.9 120.5,341.9 120.5,326.6 122.7,327.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,347.6 122.6,342.9 122.6,332.5 132.7,337.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,342.8 122.6,338.1 122.6,327.6 132.7,332.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,352.2 132.4,347.5 132.4,337.1 142.5,341.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,347.5 130.2,346.5 130.2,331.2 132.4,332.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,347.4 132.4,342.7 132.4,332.2 142.5,336.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,356.8 142.2,352.1 142.2,341.7 152.3,346.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,352.1 140,351.1 140,335.8 142.2,336.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,351.9 142.2,347.3 142.2,336.8 152.3,341.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,361 152,356.7 152,346.2 161.2,350.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,356.7 149.8,355.6 149.8,340.4 152,341.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,356 152,351.8 152,341.4 160.9,345.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,361.3 159.6,360.2 159.6,345 161.8,346 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,355.6 164.1,360.2 164.1,349.8 174.1,345.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,360.2 161.9,361.3 161.9,346 164.1,345 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,350.7 164.1,355.4 164.1,345 174.1,340.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,351 173.9,355.7 173.9,345.2 183.9,340.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,355.7 171.7,356.7 171.7,341.4 173.9,340.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,346.1 173.9,350.8 173.9,340.4 183.9,335.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,346.4 183.7,351.1 183.7,340.6 193.7,336 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,351.1 181.5,352.1 181.5,336.8 183.7,335.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,341.5 183.7,346.2 183.7,335.8 193.7,331.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,341.8 193.5,346.5 193.5,336.1 203.5,331.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,346.5 191.3,347.5 191.3,332.2 193.5,331.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,337 193.5,341.6 193.5,331.2 203.5,326.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,337.2 203.3,341.9 203.3,331.5 213.3,326.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,341.9 201.1,342.9 201.1,327.7 203.3,326.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,332.4 203.3,337.1 203.3,326.6 213.3,321.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,332.6 213.1,337.3 213.1,326.9 223.1,322.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,337.3 210.9,338.3 210.9,323.1 213.1,322 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,327.8 213.1,332.5 213.1,322 223.1,317.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,328 222.9,332.7 222.9,322.3 232.9,317.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,332.7 220.7,333.7 220.7,318.5 222.9,317.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,323.2 222.9,327.9 222.9,317.5 232.9,312.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,323.4 232.7,328.1 232.7,317.7 242.7,313 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,328.1 230.5,329.2 230.5,313.9 232.7,312.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,318.6 232.7,323.3 232.7,312.9 242.7,308.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,319.4 242.5,323.5 242.5,313.1 251.4,308.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,323.5 240.3,324.6 240.3,309.3 242.5,308.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,314.7 242.5,318.7 242.5,308.3 251.1,304.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,319 250.1,320 250.1,304.7 252.3,303.7 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,285.3L213.1,285.3l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,290l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,285.3z M152.1,313.9L152.1,313.9l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,313.9z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={13}
          >
            <FloorNeutral openFloors={openFloors} floor={13}>
              <polygon
                className="st1"
                points="152,296.6 183.6,281.8 183.6,281.8 183.6,281.8 142.2,262.4 142.2,262.4 110.6,277.2 		"
              />
              <path
                className="st16"
                d="M213,268L213,268l-41.4-19.4l0,0l-39.2-18.3L103,244.1l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,268z M183.6,281.8L152,296.6l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,281.8
			L183.6,281.8L183.6,281.8z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,293.6 44.3,288.9 44.3,278.5 54.3,283.2 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,288.9 42.1,287.9 42.1,272.6 44.3,273.7 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,288.8 44.3,284.1 44.3,273.7 54.3,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,298.2 54.1,293.5 54.1,283.1 64.1,287.8 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,293.5 51.9,292.5 51.9,277.2 54.1,278.2 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,293.4 54.1,288.7 54.1,278.2 64.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,302.8 63.9,298.1 63.9,287.7 73.9,292.3 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,298.1 61.7,297.1 61.7,281.8 63.9,282.8 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,297.9 63.9,293.3 63.9,282.8 73.9,287.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,307.4 73.7,302.7 73.7,292.2 83.7,296.9 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,302.7 71.5,301.7 71.5,286.4 73.7,287.4 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,302.5 73.7,297.8 73.7,287.4 83.7,292.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,312 83.5,307.3 83.5,296.8 93.5,301.5 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,307.3 81.3,306.2 81.3,291 83.5,292 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,307.1 83.5,302.4 83.5,292 93.5,296.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,316.5 93.3,311.8 93.3,301.4 103.3,306.1 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,311.9 91.1,310.8 91.1,295.6 93.3,296.6 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,311.7 93.3,307 93.3,296.6 103.3,301.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,321.1 103,316.4 103,306 113.1,310.7 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,316.4 100.9,315.4 100.9,300.2 103.1,301.2 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,316.3 103,311.6 103,301.2 113.1,305.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,325.7 112.8,321 112.8,310.6 122.9,315.3 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,321 110.7,320 110.7,304.7 112.9,305.8 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,320.9 112.8,316.2 112.8,305.8 122.9,310.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,330.3 122.6,325.6 122.6,315.2 132.7,319.9 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,325.6 120.5,324.6 120.5,309.3 122.7,310.4 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,325.5 122.6,320.8 122.6,310.3 132.7,315 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,334.9 132.4,330.2 132.4,319.8 142.5,324.5 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,330.2 130.2,329.2 130.2,313.9 132.4,314.9 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,330.1 132.4,325.4 132.4,314.9 142.5,319.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,339.5 142.2,334.8 142.2,324.4 152.3,329 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,334.8 140,333.8 140,318.5 142.2,319.5 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,334.6 142.2,330 142.2,319.5 152.3,324.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,343.7 152,339.4 152,328.9 161.2,333.2 					"
                    />
                    <polygon
                      className="st9"
                      points="152,339.4 149.8,338.3 149.8,323.1 152,324.1 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,338.7 152,334.5 152,324.1 160.9,328.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,344 159.6,342.9 159.6,327.7 161.8,328.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,338.3 164.1,342.9 164.1,332.5 174.1,327.8 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,342.9 161.9,344 161.9,328.7 164.1,327.7 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,333.4 164.1,338.1 164.1,327.7 174.1,323 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,333.7 173.9,338.4 173.9,327.9 183.9,323.2 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,338.4 171.7,339.4 171.7,324.1 173.9,323.1 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,328.8 173.9,333.5 173.9,323.1 183.9,318.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,329.1 183.7,333.8 183.7,323.3 193.7,318.7 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,333.8 181.5,334.8 181.5,319.5 183.7,318.5 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,324.2 183.7,328.9 183.7,318.5 193.7,313.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,324.5 193.5,329.2 193.5,318.8 203.5,314.1 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,329.2 191.3,330.2 191.3,314.9 193.5,313.9 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,319.7 193.5,324.3 193.5,313.9 203.5,309.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,319.9 203.3,324.6 203.3,314.2 213.3,309.5 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,324.6 201.1,325.6 201.1,310.4 203.3,309.3 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,315.1 203.3,319.8 203.3,309.3 213.3,304.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,315.3 213.1,320 213.1,309.6 223.1,304.9 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,320 210.9,321 210.9,305.8 213.1,304.7 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,310.5 213.1,315.2 213.1,304.7 223.1,300.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,310.7 222.9,315.4 222.9,305 232.9,300.3 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,315.4 220.7,316.4 220.7,301.2 222.9,300.2 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,305.9 222.9,310.6 222.9,300.2 232.9,295.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,306.1 232.7,310.8 232.7,300.4 242.7,295.7 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,310.8 230.5,311.9 230.5,296.6 232.7,295.6 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,301.3 232.7,306 232.7,295.6 242.7,290.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,302.1 242.5,306.2 242.5,295.8 251.4,291.6 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,306.2 240.3,307.3 240.3,292 242.5,291 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,297.4 242.5,301.4 242.5,291 251.1,286.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,301.7 250.1,302.7 250.1,287.4 252.3,286.4 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,330.3 122.6,325.6 122.6,315.2 132.7,319.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,325.5 122.6,320.8 122.6,310.3 132.7,315 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,334.9 132.4,330.2 132.4,319.8 142.5,324.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,330.2 130.2,329.2 130.2,313.9 132.4,314.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,330.1 132.4,325.4 132.4,314.9 142.5,319.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,339.5 142.2,334.8 142.2,324.4 152.3,329 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,334.8 140,333.8 140,318.5 142.2,319.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,334.6 142.2,330 142.2,319.5 152.3,324.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,343.7 152,339.4 152,328.9 161.2,333.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,339.4 149.8,338.3 149.8,323.1 152,324.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,338.7 152,334.5 152,324.1 160.9,328.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,344 159.6,342.9 159.6,327.7 161.8,328.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,338.3 164.1,342.9 164.1,332.5 174.1,327.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,342.9 161.9,344 161.9,328.7 164.1,327.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,333.4 164.1,338.1 164.1,327.7 174.1,323 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,333.7 173.9,338.4 173.9,327.9 183.9,323.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,338.4 171.7,339.4 171.7,324.1 173.9,323.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,328.8 173.9,333.5 173.9,323.1 183.9,318.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,329.1 183.7,333.8 183.7,323.3 193.7,318.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,333.8 181.5,334.8 181.5,319.5 183.7,318.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,324.2 183.7,328.9 183.7,318.5 193.7,313.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,324.5 193.5,329.2 193.5,318.8 203.5,314.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,329.2 191.3,330.2 191.3,314.9 193.5,313.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,319.7 193.5,324.3 193.5,313.9 203.5,309.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,319.9 203.3,324.6 203.3,314.2 213.3,309.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,324.6 201.1,325.6 201.1,310.4 203.3,309.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,315.1 203.3,319.8 203.3,309.3 213.3,304.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,315.4 213.1,320 213.1,309.6 222.7,305 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,320 210.9,321 210.9,305.8 213.1,304.7 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,310.6 213.1,315.2 213.1,304.7 222.7,300.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,315.4 220.7,316.4 220.7,301.2 222.9,300.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,296.6 152,296.6 122.4,310.4 122.6,310.5 161.9,328.9 191.4,315.1 223,300.3 223,300.3
			183.6,281.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,293.6 44.3,288.9 44.3,278.5 54.3,283.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,288.9 42.1,287.9 42.1,272.6 44.3,273.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,288.8 44.3,284.1 44.3,273.7 54.3,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,298.2 54.1,293.5 54.1,283.1 64.1,287.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,293.5 51.9,292.5 51.9,277.2 54.1,278.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,293.4 54.1,288.7 54.1,278.2 64.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,302.8 63.9,298.1 63.9,287.7 73.9,292.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,298.1 61.7,297.1 61.7,281.8 63.9,282.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,297.9 63.9,293.3 63.9,282.8 73.9,287.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,307.4 73.7,302.7 73.7,292.2 83.7,296.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,302.7 71.5,301.7 71.5,286.4 73.7,287.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,302.5 73.7,297.8 73.7,287.4 83.7,292.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,312 83.5,307.3 83.5,296.8 93.5,301.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,307.3 81.3,306.2 81.3,291 83.5,292 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,307.1 83.5,302.4 83.5,292 93.5,296.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,316.5 93.3,311.8 93.3,301.4 103.3,306.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,311.9 91.1,310.8 91.1,295.6 93.3,296.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,311.7 93.3,307 93.3,296.6 103.3,301.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,321.1 103,316.4 103,306 113.1,310.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,316.3 103,311.6 103,301.2 113.1,305.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,316.4 100.9,315.4 100.9,300.2 103.1,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,325.6 112.8,321 112.8,310.6 122.7,315.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,320.8 112.8,316.2 112.8,305.8 122.7,310.4 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,321 110.7,320 110.7,304.7 112.9,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,325.6 120.5,324.6 120.5,309.3 122.7,310.4 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,310.7 222.9,315.4 222.9,305 232.9,300.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,305.9 222.9,310.6 222.9,300.2 232.9,295.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,306.1 232.7,310.8 232.7,300.4 242.7,295.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,310.8 230.5,311.9 230.5,296.6 232.7,295.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,301.3 232.7,306 232.7,295.6 242.7,290.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,302.1 242.5,306.2 242.5,295.8 251.4,291.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,306.2 240.3,307.3 240.3,292 242.5,291 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,297.4 242.5,301.4 242.5,291 251.1,286.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,301.7 250.1,302.7 250.1,287.4 252.3,286.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,268 213,268 171.7,248.6 171.7,248.6 132.5,230.3 103.1,244.1 71.5,258.9 42,272.7
			81.2,291.1 122.4,310.4 152,296.6 110.7,277.2 142.3,262.4 142.3,262.4 183.6,281.8 183.7,281.8 183.6,281.8 223,300.3
			252.4,286.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,330.3 122.6,325.6 122.6,315.2 132.7,319.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,325.5 122.6,320.8 122.6,310.3 132.7,315 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,334.9 132.4,330.2 132.4,319.8 142.5,324.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,330.2 130.2,329.2 130.2,313.9 132.4,314.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,330.1 132.4,325.4 132.4,314.9 142.5,319.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,339.5 142.2,334.8 142.2,324.4 152.3,329 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,334.8 140,333.8 140,318.5 142.2,319.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,334.6 142.2,330 142.2,319.5 152.3,324.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,343.7 152,339.4 152,328.9 161.2,333.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,339.4 149.8,338.3 149.8,323.1 152,324.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,338.7 152,334.5 152,324.1 160.9,328.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,344 159.6,342.9 159.6,327.7 161.8,328.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,338.3 164.1,342.9 164.1,332.5 174.1,327.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,342.9 161.9,344 161.9,328.7 164.1,327.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,333.4 164.1,338.1 164.1,327.7 174.1,323 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,333.7 173.9,338.4 173.9,327.9 183.9,323.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,338.4 171.7,339.4 171.7,324.1 173.9,323.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,328.8 173.9,333.5 173.9,323.1 183.9,318.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,329.1 183.7,333.8 183.7,323.3 193.7,318.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,333.8 181.5,334.8 181.5,319.5 183.7,318.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,324.2 183.7,328.9 183.7,318.5 193.7,313.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,324.5 193.5,329.2 193.5,318.8 203.5,314.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,329.2 191.3,330.2 191.3,314.9 193.5,313.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,319.7 193.5,324.3 193.5,313.9 203.5,309.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,319.9 203.3,324.6 203.3,314.2 213.3,309.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,324.6 201.1,325.6 201.1,310.4 203.3,309.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,315.1 203.3,319.8 203.3,309.3 213.3,304.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,315.3 213.1,320 213.1,309.6 223.1,304.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,320 210.9,321 210.9,305.8 213.1,304.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,310.5 213.1,315.2 213.1,304.7 223.1,300.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,310.7 222.9,315.4 222.9,305 232.9,300.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,315.4 220.7,316.4 220.7,301.2 222.9,300.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,305.9 222.9,310.6 222.9,300.2 232.9,295.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,306.1 232.7,310.8 232.7,300.4 242.7,295.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,310.8 230.5,311.9 230.5,296.6 232.7,295.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,301.3 232.7,306 232.7,295.6 242.7,290.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,302.1 242.5,306.2 242.5,295.8 251.4,291.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,306.2 240.3,307.3 240.3,292 242.5,291 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,297.4 242.5,301.4 242.5,291 251.1,286.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,301.7 250.1,302.7 250.1,287.4 252.3,286.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,268 213,268 183.6,281.8 183.7,281.8 183.6,281.8 152.1,296.6 152,296.6 122.4,310.4
			122.6,310.5 161.9,328.9 191.4,315.1 223,300.3 223,300.3 252.4,286.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,293.6 44.3,288.9 44.3,278.5 54.3,283.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,288.9 42.1,287.9 42.1,272.6 44.3,273.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,288.8 44.3,284.1 44.3,273.7 54.3,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,298.2 54.1,293.5 54.1,283.1 64.1,287.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,293.5 51.9,292.5 51.9,277.2 54.1,278.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,293.4 54.1,288.7 54.1,278.2 64.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,302.8 63.9,298.1 63.9,287.7 73.9,292.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,298.1 61.7,297.1 61.7,281.8 63.9,282.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,297.9 63.9,293.3 63.9,282.8 73.9,287.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,307.4 73.7,302.7 73.7,292.2 83.7,296.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,302.7 71.5,301.7 71.5,286.4 73.7,287.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,302.5 73.7,297.8 73.7,287.4 83.7,292.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,312 83.5,307.3 83.5,296.8 93.5,301.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,307.3 81.3,306.2 81.3,291 83.5,292 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,307.1 83.5,302.4 83.5,292 93.5,296.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,316.5 93.3,311.8 93.3,301.4 103.3,306.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,311.9 91.1,310.8 91.1,295.6 93.3,296.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,311.7 93.3,307 93.3,296.6 103.3,301.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,321.1 103,316.4 103,306 113.1,310.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,316.3 103,311.6 103,301.2 113.1,305.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,316.4 100.9,315.4 100.9,300.2 103.1,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,325.6 112.8,321 112.8,310.6 122.6,315.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,320.8 112.8,316.2 112.8,305.8 122.6,310.4 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,321 110.7,320 110.7,304.7 112.9,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,325.6 120.5,324.6 120.5,309.3 122.7,310.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,262.4 183.6,281.8 213,268 171.7,248.6 171.7,248.6 132.5,230.3 103.1,244.1 71.5,258.9
			42,272.7 81.2,291.1 122.4,310.4 152,296.6 110.7,277.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,330.3 122.6,325.6 122.6,315.2 132.7,319.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,325.5 122.6,320.8 122.6,310.3 132.7,315 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,334.9 132.4,330.2 132.4,319.8 142.5,324.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,330.2 130.2,329.2 130.2,313.9 132.4,314.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,330.1 132.4,325.4 132.4,314.9 142.5,319.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,339.5 142.2,334.8 142.2,324.4 152.3,329 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,334.8 140,333.8 140,318.5 142.2,319.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,334.6 142.2,330 142.2,319.5 152.3,324.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,343.7 152,339.4 152,328.9 161.2,333.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,339.4 149.8,338.3 149.8,323.1 152,324.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,338.7 152,334.5 152,324.1 160.9,328.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,344 159.6,342.9 159.6,327.7 161.8,328.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,338.3 164.1,342.9 164.1,332.5 174.1,327.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,342.9 161.9,344 161.9,328.7 164.1,327.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,333.4 164.1,338.1 164.1,327.7 174.1,323 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,333.7 173.9,338.4 173.9,327.9 183.9,323.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,338.4 171.7,339.4 171.7,324.1 173.9,323.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,328.8 173.9,333.5 173.9,323.1 183.9,318.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,329.1 183.7,333.8 183.7,323.3 193.7,318.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,333.8 181.5,334.8 181.5,319.5 183.7,318.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,324.2 183.7,328.9 183.7,318.5 193.7,313.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,324.5 193.5,329.2 193.5,318.8 203.5,314.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,329.2 191.3,330.2 191.3,314.9 193.5,313.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,319.7 193.5,324.3 193.5,313.9 203.5,309.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,319.9 203.3,324.6 203.3,314.2 213.3,309.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,324.6 201.1,325.6 201.1,310.4 203.3,309.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,315.1 203.3,319.8 203.3,309.3 213.3,304.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,315.3 213.1,320 213.1,309.6 223.1,304.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,320 210.9,321 210.9,305.8 213.1,304.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,310.5 213.1,315.2 213.1,304.7 223.1,300.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,310.7 222.9,315.4 222.9,305 232.9,300.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,315.4 220.7,316.4 220.7,301.2 222.9,300.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,305.9 222.9,310.6 222.9,300.2 232.9,295.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,306.1 232.7,310.8 232.7,300.4 242.7,295.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,310.8 230.5,311.9 230.5,296.6 232.7,295.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,301.3 232.7,306 232.7,295.6 242.7,290.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,302.1 242.5,306.2 242.5,295.8 251.4,291.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,306.2 240.3,307.3 240.3,292 242.5,291 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,297.4 242.5,301.4 242.5,291 251.1,286.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,301.7 250.1,302.7 250.1,287.4 252.3,286.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,268 213,268 171.7,248.6 142.3,262.4 183.6,281.8 183.7,281.8 183.6,281.8 152.1,296.6
			152,296.6 122.4,310.4 122.6,310.5 161.9,328.9 191.4,315.1 223,300.3 223,300.3 252.4,286.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,293.6 44.3,288.9 44.3,278.5 54.3,283.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,288.9 42.1,287.9 42.1,272.6 44.3,273.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,288.8 44.3,284.1 44.3,273.7 54.3,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,298.2 54.1,293.5 54.1,283.1 64.1,287.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,293.5 51.9,292.5 51.9,277.2 54.1,278.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,293.4 54.1,288.7 54.1,278.2 64.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,302.8 63.9,298.1 63.9,287.7 73.9,292.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,298.1 61.7,297.1 61.7,281.8 63.9,282.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,297.9 63.9,293.3 63.9,282.8 73.9,287.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,307.4 73.7,302.7 73.7,292.2 83.7,296.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,302.7 71.5,301.7 71.5,286.4 73.7,287.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,302.5 73.7,297.8 73.7,287.4 83.7,292.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,312 83.5,307.3 83.5,296.8 93.5,301.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,307.3 81.3,306.2 81.3,291 83.5,292 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,307.1 83.5,302.4 83.5,292 93.5,296.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,316.5 93.3,311.8 93.3,301.4 103.3,306.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,311.9 91.1,310.8 91.1,295.6 93.3,296.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,311.7 93.3,307 93.3,296.6 103.3,301.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,321.1 103,316.4 103,306 113.1,310.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,316.3 103,311.6 103,301.2 113.1,305.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,316.4 100.9,315.4 100.9,300.2 103.1,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,325.6 112.8,321 112.8,310.6 122.6,315.2 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,320.8 112.8,316.2 112.8,305.8 122.6,310.4 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,321 110.7,320 110.7,304.7 112.9,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,325.6 120.5,324.6 120.5,309.3 122.7,310.4 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,277.2 142.3,262.4 142.3,262.4 171.7,248.6 171.7,248.6 132.5,230.3 103.1,244.1 71.5,258.9
			42,272.7 81.2,291.1 122.4,310.4 152,296.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={13} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,293.6 44.3,288.9 44.3,278.5 54.3,283.2 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,288.9 42.1,287.9 42.1,272.6 44.3,273.7 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,288.8 44.3,284.1 44.3,273.7 54.3,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,298.2 54.1,293.5 54.1,283.1 64.1,287.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,293.5 51.9,292.5 51.9,277.2 54.1,278.2 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,293.4 54.1,288.7 54.1,278.2 64.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,302.8 63.9,298.1 63.9,287.7 73.9,292.3 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,298.1 61.7,297.1 61.7,281.8 63.9,282.8 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,297.9 63.9,293.3 63.9,282.8 73.9,287.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,307.4 73.7,302.7 73.7,292.2 83.7,296.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,302.7 71.5,301.7 71.5,286.4 73.7,287.4 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,302.5 73.7,297.8 73.7,287.4 83.7,292.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,312 83.5,307.3 83.5,296.8 93.5,301.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,307.3 81.3,306.2 81.3,291 83.5,292 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,307.1 83.5,302.4 83.5,292 93.5,296.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,316.5 93.3,311.8 93.3,301.4 103.3,306.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,311.9 91.1,310.8 91.1,295.6 93.3,296.6 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,311.7 93.3,307 93.3,296.6 103.3,301.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,316.4 100.9,315.4 100.9,300.2 103.1,301.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,321.1 103,316.4 103,306 113.1,310.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,316.3 103,311.6 103,301.2 113.1,305.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,321 110.7,320 110.7,304.7 112.9,305.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,325.7 112.8,321 112.8,310.6 122.9,315.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,320.9 112.8,316.2 112.8,305.8 122.9,310.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,325.6 120.5,324.6 120.5,309.3 122.7,310.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,330.3 122.6,325.6 122.6,315.2 132.7,319.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,325.5 122.6,320.8 122.6,310.3 132.7,315 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,334.9 132.4,330.2 132.4,319.8 142.5,324.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,330.2 130.2,329.2 130.2,313.9 132.4,314.9 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,330.1 132.4,325.4 132.4,314.9 142.5,319.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,339.5 142.2,334.8 142.2,324.4 152.3,329 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,334.8 140,333.8 140,318.5 142.2,319.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,334.6 142.2,330 142.2,319.5 152.3,324.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,343.7 152,339.4 152,328.9 161.2,333.2 					"
                    />
                    <polygon
                      className="st14"
                      points="152,339.4 149.8,338.3 149.8,323.1 152,324.1 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,338.7 152,334.5 152,324.1 160.9,328.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,344 159.6,342.9 159.6,327.7 161.8,328.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,338.3 164.1,342.9 164.1,332.5 174.1,327.8 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,342.9 161.9,344 161.9,328.7 164.1,327.7 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,333.4 164.1,338.1 164.1,327.7 174.1,323 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,333.7 173.9,338.4 173.9,327.9 183.9,323.2 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,338.4 171.7,339.4 171.7,324.1 173.9,323.1 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,328.8 173.9,333.5 173.9,323.1 183.9,318.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,329.1 183.7,333.8 183.7,323.3 193.7,318.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,333.8 181.5,334.8 181.5,319.5 183.7,318.5 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,324.2 183.7,328.9 183.7,318.5 193.7,313.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,324.5 193.5,329.2 193.5,318.8 203.5,314.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,329.2 191.3,330.2 191.3,314.9 193.5,313.9 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,319.7 193.5,324.3 193.5,313.9 203.5,309.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,319.9 203.3,324.6 203.3,314.2 213.3,309.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,324.6 201.1,325.6 201.1,310.4 203.3,309.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,315.1 203.3,319.8 203.3,309.3 213.3,304.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,315.3 213.1,320 213.1,309.6 223.1,304.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,320 210.9,321 210.9,305.8 213.1,304.7 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,310.5 213.1,315.2 213.1,304.7 223.1,300.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,310.7 222.9,315.4 222.9,305 232.9,300.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,315.4 220.7,316.4 220.7,301.2 222.9,300.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,305.9 222.9,310.6 222.9,300.2 232.9,295.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,306.1 232.7,310.8 232.7,300.4 242.7,295.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,310.8 230.5,311.9 230.5,296.6 232.7,295.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,301.3 232.7,306 232.7,295.6 242.7,290.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,302.1 242.5,306.2 242.5,295.8 251.4,291.6 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,306.2 240.3,307.3 240.3,292 242.5,291 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,297.4 242.5,301.4 242.5,291 251.1,286.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,301.7 250.1,302.7 250.1,287.4 252.3,286.4 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,268L213.1,268l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,272.7l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,268z M152.1,296.6L152.1,296.6l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,296.6z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={14}
          >
            <FloorNeutral openFloors={openFloors} floor={14}>
              <polygon
                className="st1"
                points="152,279.4 183.6,264.6 183.6,264.6 183.6,264.6 142.2,245.2 142.2,245.2 110.6,260 		"
              />
              <path
                className="st16"
                d="M213,250.8L213,250.8l-41.4-19.4l0,0l-39.2-18.3L103,226.9l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,250.8z M183.6,264.6L152,279.4l0,0L110.6,260l31.6-14.8l0,0
			L183.6,264.6L183.6,264.6L183.6,264.6z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,276.4 44.3,271.7 44.3,261.3 54.3,266 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,271.7 42.1,270.7 42.1,255.4 44.3,256.5 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,271.6 44.3,266.9 44.3,256.5 54.3,261.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,281 54.1,276.3 54.1,265.9 64.1,270.6 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,276.3 51.9,275.3 51.9,260 54.1,261 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,276.2 54.1,271.5 54.1,261 64.1,265.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,285.6 63.9,280.9 63.9,270.5 73.9,275.1 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,280.9 61.7,279.9 61.7,264.6 63.9,265.6 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,280.7 63.9,276.1 63.9,265.6 73.9,270.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,290.2 73.7,285.5 73.7,275 83.7,279.7 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,285.5 71.5,284.5 71.5,269.2 73.7,270.2 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,285.3 73.7,280.6 73.7,270.2 83.7,274.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,294.8 83.5,290.1 83.5,279.6 93.5,284.3 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,290.1 81.3,289 81.3,273.8 83.5,274.8 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,289.9 83.5,285.2 83.5,274.8 93.5,279.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,299.3 93.3,294.6 93.3,284.2 103.3,288.9 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,294.7 91.1,293.6 91.1,278.4 93.3,279.4 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,294.5 93.3,289.8 93.3,279.4 103.3,284.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,303.9 103,299.2 103,288.8 113.1,293.5 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,299.2 100.9,298.2 100.9,283 103.1,284 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,299.1 103,294.4 103,284 113.1,288.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,308.5 112.8,303.8 112.8,293.4 122.9,298.1 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,303.8 110.7,302.8 110.7,287.5 112.9,288.6 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,303.7 112.8,299 112.8,288.6 122.9,293.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,313.1 122.6,308.4 122.6,298 132.7,302.7 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,308.4 120.5,307.4 120.5,292.1 122.7,293.2 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,308.3 122.6,303.6 122.6,293.1 132.7,297.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,317.7 132.4,313 132.4,302.6 142.5,307.3 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,313 130.2,312 130.2,296.7 132.4,297.7 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,312.9 132.4,308.2 132.4,297.7 142.5,302.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,322.3 142.2,317.6 142.2,307.2 152.3,311.8 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,317.6 140,316.6 140,301.3 142.2,302.3 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,317.4 142.2,312.8 142.2,302.3 152.3,307 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,326.5 152,322.2 152,311.7 161.2,316 					"
                    />
                    <polygon
                      className="st9"
                      points="152,322.2 149.8,321.1 149.8,305.9 152,306.9 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,321.5 152,317.3 152,306.9 160.9,311.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,326.8 159.6,325.7 159.6,310.5 161.8,311.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,321.1 164.1,325.7 164.1,315.3 174.1,310.6 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,325.7 161.9,326.8 161.9,311.5 164.1,310.5 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,316.2 164.1,320.9 164.1,310.5 174.1,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,316.5 173.9,321.2 173.9,310.7 183.9,306 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,321.2 171.7,322.2 171.7,306.9 173.9,305.9 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,311.6 173.9,316.3 173.9,305.9 183.9,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,311.9 183.7,316.6 183.7,306.1 193.7,301.5 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,316.6 181.5,317.6 181.5,302.3 183.7,301.3 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,307 183.7,311.7 183.7,301.3 193.7,296.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,307.3 193.5,312 193.5,301.6 203.5,296.9 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,312 191.3,313 191.3,297.7 193.5,296.7 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,302.5 193.5,307.1 193.5,296.7 203.5,292 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,302.7 203.3,307.4 203.3,297 213.3,292.3 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,307.4 201.1,308.4 201.1,293.2 203.3,292.1 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,297.9 203.3,302.6 203.3,292.1 213.3,287.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,298.1 213.1,302.8 213.1,292.4 223.1,287.7 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,302.8 210.9,303.8 210.9,288.6 213.1,287.5 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,293.3 213.1,298 213.1,287.5 223.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,293.5 222.9,298.2 222.9,287.8 232.9,283.1 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,298.2 220.7,299.2 220.7,284 222.9,283 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,288.7 222.9,293.4 222.9,283 232.9,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,288.9 232.7,293.6 232.7,283.2 242.7,278.5 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,293.6 230.5,294.7 230.5,279.4 232.7,278.4 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,284.1 232.7,288.8 232.7,278.4 242.7,273.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,284.9 242.5,289 242.5,278.6 251.4,274.4 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,289 240.3,290.1 240.3,274.8 242.5,273.8 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,280.2 242.5,284.2 242.5,273.8 251.1,269.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,284.5 250.1,285.5 250.1,270.2 252.3,269.2 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,313.1 122.6,308.4 122.6,298 132.7,302.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,308.3 122.6,303.6 122.6,293.1 132.7,297.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,317.7 132.4,313 132.4,302.6 142.5,307.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,313 130.2,312 130.2,296.7 132.4,297.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,312.9 132.4,308.2 132.4,297.7 142.5,302.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,322.3 142.2,317.6 142.2,307.2 152.3,311.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,317.6 140,316.6 140,301.3 142.2,302.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,317.4 142.2,312.8 142.2,302.3 152.3,307 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,326.5 152,322.2 152,311.7 161.2,316 					"
                    />
                    <polygon
                      className="st14"
                      points="152,322.2 149.8,321.1 149.8,305.9 152,306.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,321.5 152,317.3 152,306.9 160.9,311.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,326.8 159.6,325.7 159.6,310.5 161.8,311.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,321.1 164.1,325.7 164.1,315.3 174.1,310.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,325.7 161.9,326.8 161.9,311.5 164.1,310.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,316.2 164.1,320.9 164.1,310.5 174.1,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,316.5 173.9,321.2 173.9,310.7 183.9,306 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,321.2 171.7,322.2 171.7,306.9 173.9,305.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,311.6 173.9,316.3 173.9,305.9 183.9,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,311.9 183.7,316.6 183.7,306.1 193.7,301.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,316.6 181.5,317.6 181.5,302.3 183.7,301.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,307 183.7,311.7 183.7,301.3 193.7,296.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,307.3 193.5,312 193.5,301.6 203.5,296.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,312 191.3,313 191.3,297.7 193.5,296.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,302.5 193.5,307.1 193.5,296.7 203.5,292 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,302.7 203.3,307.4 203.3,297 213.3,292.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,307.4 201.1,308.4 201.1,293.2 203.3,292.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,297.9 203.3,302.6 203.3,292.1 213.3,287.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,298.2 213.1,302.8 213.1,292.4 222.7,287.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,302.8 210.9,303.8 210.9,288.6 213.1,287.5 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,293.4 213.1,298 213.1,287.5 222.7,283 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,298.2 220.7,299.2 220.7,284 222.9,283 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,279.4 152,279.4 122.4,293.2 122.6,293.3 161.9,311.7 191.4,297.9 223,283.1 223,283.1
			183.6,264.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,276.4 44.3,271.7 44.3,261.3 54.3,266 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,271.7 42.1,270.7 42.1,255.4 44.3,256.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,271.6 44.3,266.9 44.3,256.5 54.3,261.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,281 54.1,276.3 54.1,265.9 64.1,270.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,276.3 51.9,275.3 51.9,260 54.1,261 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,276.2 54.1,271.5 54.1,261 64.1,265.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,285.6 63.9,280.9 63.9,270.5 73.9,275.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,280.9 61.7,279.9 61.7,264.6 63.9,265.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,280.7 63.9,276.1 63.9,265.6 73.9,270.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,290.2 73.7,285.5 73.7,275 83.7,279.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,285.5 71.5,284.5 71.5,269.2 73.7,270.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,285.3 73.7,280.6 73.7,270.2 83.7,274.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,294.8 83.5,290.1 83.5,279.6 93.5,284.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,290.1 81.3,289 81.3,273.8 83.5,274.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,289.9 83.5,285.2 83.5,274.8 93.5,279.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,299.3 93.3,294.6 93.3,284.2 103.3,288.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,294.7 91.1,293.6 91.1,278.4 93.3,279.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,294.5 93.3,289.8 93.3,279.4 103.3,284.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,303.9 103,299.2 103,288.8 113.1,293.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,299.1 103,294.4 103,284 113.1,288.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,299.2 100.9,298.2 100.9,283 103.1,284 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,308.4 112.8,303.8 112.8,293.4 122.7,298 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,303.6 112.8,299 112.8,288.6 122.7,293.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,303.8 110.7,302.8 110.7,287.5 112.9,288.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,308.4 120.5,307.4 120.5,292.1 122.7,293.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,293.5 222.9,298.2 222.9,287.8 232.9,283.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,288.7 222.9,293.4 222.9,283 232.9,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,288.9 232.7,293.6 232.7,283.2 242.7,278.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,293.6 230.5,294.7 230.5,279.4 232.7,278.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,284.1 232.7,288.8 232.7,278.4 242.7,273.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,284.9 242.5,289 242.5,278.6 251.4,274.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,289 240.3,290.1 240.3,274.8 242.5,273.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,280.2 242.5,284.2 242.5,273.8 251.1,269.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,284.5 250.1,285.5 250.1,270.2 252.3,269.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,250.8 213,250.8 171.7,231.4 171.7,231.4 132.5,213.1 103.1,226.9 71.5,241.7 42,255.5
			81.2,273.9 122.4,293.2 152,279.4 110.7,260 142.3,245.2 142.3,245.2 183.6,264.6 183.7,264.6 183.6,264.6 223,283.1 252.4,269.3
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,313.1 122.6,308.4 122.6,298 132.7,302.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,308.3 122.6,303.6 122.6,293.1 132.7,297.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,317.7 132.4,313 132.4,302.6 142.5,307.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,313 130.2,312 130.2,296.7 132.4,297.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,312.9 132.4,308.2 132.4,297.7 142.5,302.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,322.3 142.2,317.6 142.2,307.2 152.3,311.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,317.6 140,316.6 140,301.3 142.2,302.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,317.4 142.2,312.8 142.2,302.3 152.3,307 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,326.5 152,322.2 152,311.7 161.2,316 					"
                    />
                    <polygon
                      className="st14"
                      points="152,322.2 149.8,321.1 149.8,305.9 152,306.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,321.5 152,317.3 152,306.9 160.9,311.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,326.8 159.6,325.7 159.6,310.5 161.8,311.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,321.1 164.1,325.7 164.1,315.3 174.1,310.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,325.7 161.9,326.8 161.9,311.5 164.1,310.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,316.2 164.1,320.9 164.1,310.5 174.1,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,316.5 173.9,321.2 173.9,310.7 183.9,306 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,321.2 171.7,322.2 171.7,306.9 173.9,305.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,311.6 173.9,316.3 173.9,305.9 183.9,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,311.9 183.7,316.6 183.7,306.1 193.7,301.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,316.6 181.5,317.6 181.5,302.3 183.7,301.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,307 183.7,311.7 183.7,301.3 193.7,296.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,307.3 193.5,312 193.5,301.6 203.5,296.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,312 191.3,313 191.3,297.7 193.5,296.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,302.5 193.5,307.1 193.5,296.7 203.5,292 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,302.7 203.3,307.4 203.3,297 213.3,292.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,307.4 201.1,308.4 201.1,293.2 203.3,292.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,297.9 203.3,302.6 203.3,292.1 213.3,287.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,298.1 213.1,302.8 213.1,292.4 223.1,287.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,302.8 210.9,303.8 210.9,288.6 213.1,287.5 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,293.3 213.1,298 213.1,287.5 223.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,293.5 222.9,298.2 222.9,287.8 232.9,283.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,298.2 220.7,299.2 220.7,284 222.9,283 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,288.7 222.9,293.4 222.9,283 232.9,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,288.9 232.7,293.6 232.7,283.2 242.7,278.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,293.6 230.5,294.7 230.5,279.4 232.7,278.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,284.1 232.7,288.8 232.7,278.4 242.7,273.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,284.9 242.5,289 242.5,278.6 251.4,274.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,289 240.3,290.1 240.3,274.8 242.5,273.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,280.2 242.5,284.2 242.5,273.8 251.1,269.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,284.5 250.1,285.5 250.1,270.2 252.3,269.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,250.8 213,250.8 183.6,264.6 183.7,264.6 183.6,264.6 152.1,279.4 152,279.4 122.4,293.2
			122.6,293.3 161.9,311.7 191.4,297.9 223,283.1 223,283.1 252.4,269.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,276.4 44.3,271.7 44.3,261.3 54.3,266 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,271.7 42.1,270.7 42.1,255.4 44.3,256.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,271.6 44.3,266.9 44.3,256.5 54.3,261.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,281 54.1,276.3 54.1,265.9 64.1,270.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,276.3 51.9,275.3 51.9,260 54.1,261 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,276.2 54.1,271.5 54.1,261 64.1,265.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,285.6 63.9,280.9 63.9,270.5 73.9,275.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,280.9 61.7,279.9 61.7,264.6 63.9,265.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,280.7 63.9,276.1 63.9,265.6 73.9,270.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,290.2 73.7,285.5 73.7,275 83.7,279.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,285.5 71.5,284.5 71.5,269.2 73.7,270.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,285.3 73.7,280.6 73.7,270.2 83.7,274.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,294.8 83.5,290.1 83.5,279.6 93.5,284.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,290.1 81.3,289 81.3,273.8 83.5,274.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,289.9 83.5,285.2 83.5,274.8 93.5,279.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,299.3 93.3,294.6 93.3,284.2 103.3,288.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,294.7 91.1,293.6 91.1,278.4 93.3,279.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,294.5 93.3,289.8 93.3,279.4 103.3,284.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,303.9 103,299.2 103,288.8 113.1,293.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,299.1 103,294.4 103,284 113.1,288.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,299.2 100.9,298.2 100.9,283 103.1,284 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,308.4 112.8,303.8 112.8,293.4 122.6,298 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,303.6 112.8,299 112.8,288.6 122.6,293.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,303.8 110.7,302.8 110.7,287.5 112.9,288.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,308.4 120.5,307.4 120.5,292.1 122.7,293.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,245.2 183.6,264.6 213,250.8 171.7,231.4 171.7,231.4 132.5,213.1 103.1,226.9 71.5,241.7
			42,255.5 81.2,273.9 122.4,293.2 152,279.4 110.7,260 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,313.1 122.6,308.4 122.6,298 132.7,302.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,308.3 122.6,303.6 122.6,293.1 132.7,297.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,317.7 132.4,313 132.4,302.6 142.5,307.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,313 130.2,312 130.2,296.7 132.4,297.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,312.9 132.4,308.2 132.4,297.7 142.5,302.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,322.3 142.2,317.6 142.2,307.2 152.3,311.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,317.6 140,316.6 140,301.3 142.2,302.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,317.4 142.2,312.8 142.2,302.3 152.3,307 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,326.5 152,322.2 152,311.7 161.2,316 					"
                    />
                    <polygon
                      className="st14"
                      points="152,322.2 149.8,321.1 149.8,305.9 152,306.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,321.5 152,317.3 152,306.9 160.9,311.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,326.8 159.6,325.7 159.6,310.5 161.8,311.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,321.1 164.1,325.7 164.1,315.3 174.1,310.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,325.7 161.9,326.8 161.9,311.5 164.1,310.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,316.2 164.1,320.9 164.1,310.5 174.1,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,316.5 173.9,321.2 173.9,310.7 183.9,306 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,321.2 171.7,322.2 171.7,306.9 173.9,305.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,311.6 173.9,316.3 173.9,305.9 183.9,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,311.9 183.7,316.6 183.7,306.1 193.7,301.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,316.6 181.5,317.6 181.5,302.3 183.7,301.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,307 183.7,311.7 183.7,301.3 193.7,296.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,307.3 193.5,312 193.5,301.6 203.5,296.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,312 191.3,313 191.3,297.7 193.5,296.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,302.5 193.5,307.1 193.5,296.7 203.5,292 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,302.7 203.3,307.4 203.3,297 213.3,292.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,307.4 201.1,308.4 201.1,293.2 203.3,292.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,297.9 203.3,302.6 203.3,292.1 213.3,287.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,298.1 213.1,302.8 213.1,292.4 223.1,287.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,302.8 210.9,303.8 210.9,288.6 213.1,287.5 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,293.3 213.1,298 213.1,287.5 223.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,293.5 222.9,298.2 222.9,287.8 232.9,283.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,298.2 220.7,299.2 220.7,284 222.9,283 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,288.7 222.9,293.4 222.9,283 232.9,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,288.9 232.7,293.6 232.7,283.2 242.7,278.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,293.6 230.5,294.7 230.5,279.4 232.7,278.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,284.1 232.7,288.8 232.7,278.4 242.7,273.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,284.9 242.5,289 242.5,278.6 251.4,274.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,289 240.3,290.1 240.3,274.8 242.5,273.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,280.2 242.5,284.2 242.5,273.8 251.1,269.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,284.5 250.1,285.5 250.1,270.2 252.3,269.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,250.8 213,250.8 171.7,231.4 142.3,245.2 183.6,264.6 183.7,264.6 183.6,264.6 152.1,279.4
			152,279.4 122.4,293.2 122.6,293.3 161.9,311.7 191.4,297.9 223,283.1 223,283.1 252.4,269.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,276.4 44.3,271.7 44.3,261.3 54.3,266 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,271.7 42.1,270.7 42.1,255.4 44.3,256.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,271.6 44.3,266.9 44.3,256.5 54.3,261.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,281 54.1,276.3 54.1,265.9 64.1,270.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,276.3 51.9,275.3 51.9,260 54.1,261 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,276.2 54.1,271.5 54.1,261 64.1,265.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,285.6 63.9,280.9 63.9,270.5 73.9,275.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,280.9 61.7,279.9 61.7,264.6 63.9,265.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,280.7 63.9,276.1 63.9,265.6 73.9,270.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,290.2 73.7,285.5 73.7,275 83.7,279.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,285.5 71.5,284.5 71.5,269.2 73.7,270.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,285.3 73.7,280.6 73.7,270.2 83.7,274.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,294.8 83.5,290.1 83.5,279.6 93.5,284.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,290.1 81.3,289 81.3,273.8 83.5,274.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,289.9 83.5,285.2 83.5,274.8 93.5,279.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,299.3 93.3,294.6 93.3,284.2 103.3,288.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,294.7 91.1,293.6 91.1,278.4 93.3,279.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,294.5 93.3,289.8 93.3,279.4 103.3,284.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,303.9 103,299.2 103,288.8 113.1,293.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,299.1 103,294.4 103,284 113.1,288.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,299.2 100.9,298.2 100.9,283 103.1,284 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,308.4 112.8,303.8 112.8,293.4 122.6,298 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,303.6 112.8,299 112.8,288.6 122.6,293.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,303.8 110.7,302.8 110.7,287.5 112.9,288.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,308.4 120.5,307.4 120.5,292.1 122.7,293.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,260 142.3,245.2 142.3,245.2 171.7,231.4 171.7,231.4 132.5,213.1 103.1,226.9 71.5,241.7
			42,255.5 81.2,273.9 122.4,293.2 152,279.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={14} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,276.4 44.3,271.7 44.3,261.3 54.3,266 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,271.7 42.1,270.7 42.1,255.4 44.3,256.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,271.6 44.3,266.9 44.3,256.5 54.3,261.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,281 54.1,276.3 54.1,265.9 64.1,270.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,276.3 51.9,275.3 51.9,260 54.1,261 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,276.2 54.1,271.5 54.1,261 64.1,265.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,285.6 63.9,280.9 63.9,270.5 73.9,275.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,280.9 61.7,279.9 61.7,264.6 63.9,265.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,280.7 63.9,276.1 63.9,265.6 73.9,270.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,290.2 73.7,285.5 73.7,275 83.7,279.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,285.5 71.5,284.5 71.5,269.2 73.7,270.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,285.3 73.7,280.6 73.7,270.2 83.7,274.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,294.8 83.5,290.1 83.5,279.6 93.5,284.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,290.1 81.3,289 81.3,273.8 83.5,274.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,289.9 83.5,285.2 83.5,274.8 93.5,279.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,299.3 93.3,294.6 93.3,284.2 103.3,288.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,294.7 91.1,293.6 91.1,278.4 93.3,279.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,294.5 93.3,289.8 93.3,279.4 103.3,284.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,299.2 100.9,298.2 100.9,283 103.1,284 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,303.9 103,299.2 103,288.8 113.1,293.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,299.1 103,294.4 103,284 113.1,288.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,303.8 110.7,302.8 110.7,287.5 112.9,288.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,308.5 112.8,303.8 112.8,293.4 122.9,298.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,303.7 112.8,299 112.8,288.6 122.9,293.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,308.4 120.5,307.4 120.5,292.1 122.7,293.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,313.1 122.6,308.4 122.6,298 132.7,302.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,308.3 122.6,303.6 122.6,293.1 132.7,297.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,317.7 132.4,313 132.4,302.6 142.5,307.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,313 130.2,312 130.2,296.7 132.4,297.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,312.9 132.4,308.2 132.4,297.7 142.5,302.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,322.3 142.2,317.6 142.2,307.2 152.3,311.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,317.6 140,316.6 140,301.3 142.2,302.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,317.4 142.2,312.8 142.2,302.3 152.3,307 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,326.5 152,322.2 152,311.7 161.2,316 					"
                    />
                    <polygon
                      className="st14"
                      points="152,322.2 149.8,321.1 149.8,305.9 152,306.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,321.5 152,317.3 152,306.9 160.9,311.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,326.8 159.6,325.7 159.6,310.5 161.8,311.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,321.1 164.1,325.7 164.1,315.3 174.1,310.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,325.7 161.9,326.8 161.9,311.5 164.1,310.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,316.2 164.1,320.9 164.1,310.5 174.1,305.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,316.5 173.9,321.2 173.9,310.7 183.9,306 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,321.2 171.7,322.2 171.7,306.9 173.9,305.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,311.6 173.9,316.3 173.9,305.9 183.9,301.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,311.9 183.7,316.6 183.7,306.1 193.7,301.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,316.6 181.5,317.6 181.5,302.3 183.7,301.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,307 183.7,311.7 183.7,301.3 193.7,296.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,307.3 193.5,312 193.5,301.6 203.5,296.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,312 191.3,313 191.3,297.7 193.5,296.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,302.5 193.5,307.1 193.5,296.7 203.5,292 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,302.7 203.3,307.4 203.3,297 213.3,292.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,307.4 201.1,308.4 201.1,293.2 203.3,292.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,297.9 203.3,302.6 203.3,292.1 213.3,287.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,298.1 213.1,302.8 213.1,292.4 223.1,287.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,302.8 210.9,303.8 210.9,288.6 213.1,287.5 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,293.3 213.1,298 213.1,287.5 223.1,282.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,293.5 222.9,298.2 222.9,287.8 232.9,283.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,298.2 220.7,299.2 220.7,284 222.9,283 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,288.7 222.9,293.4 222.9,283 232.9,278.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,288.9 232.7,293.6 232.7,283.2 242.7,278.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,293.6 230.5,294.7 230.5,279.4 232.7,278.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,284.1 232.7,288.8 232.7,278.4 242.7,273.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,284.9 242.5,289 242.5,278.6 251.4,274.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,289 240.3,290.1 240.3,274.8 242.5,273.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,280.2 242.5,284.2 242.5,273.8 251.1,269.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,284.5 250.1,285.5 250.1,270.2 252.3,269.2 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,250.8L213.1,250.8l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,255.5l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,250.8z M152.1,279.4L152.1,279.4L110.7,260l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,279.4z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={15}
          >
            <FloorNeutral openFloors={openFloors} floor={15}>
              <polygon
                className="st1"
                points="152,262.1 183.6,247.3 183.6,247.3 183.6,247.3 142.2,227.9 142.2,227.9 110.6,242.7 		"
              />
              <path
                className="st16"
                d="M213,233.5L213,233.5l-41.4-19.4l0,0l-39.2-18.3L103,209.6l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,233.5z M183.6,247.3L152,262.1l0,0l-41.4-19.4l31.6-14.8l0,0
			L183.6,247.3L183.6,247.3L183.6,247.3z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,259.1 44.3,254.4 44.3,244 54.3,248.7 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,254.4 42.1,253.4 42.1,238.1 44.3,239.2 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,254.3 44.3,249.6 44.3,239.2 54.3,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,263.7 54.1,259 54.1,248.6 64.1,253.3 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,259 51.9,258 51.9,242.7 54.1,243.7 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,258.9 54.1,254.2 54.1,243.7 64.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,268.3 63.9,263.6 63.9,253.2 73.9,257.8 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,263.6 61.7,262.6 61.7,247.3 63.9,248.3 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,263.4 63.9,258.8 63.9,248.3 73.9,253 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,272.9 73.7,268.2 73.7,257.7 83.7,262.4 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,268.2 71.5,267.2 71.5,251.9 73.7,252.9 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,268 73.7,263.3 73.7,252.9 83.7,257.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,277.5 83.5,272.8 83.5,262.3 93.5,267 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,272.8 81.3,271.7 81.3,256.5 83.5,257.5 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,272.6 83.5,267.9 83.5,257.5 93.5,262.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,282 93.3,277.3 93.3,266.9 103.3,271.6 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,277.4 91.1,276.3 91.1,261.1 93.3,262.1 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,277.2 93.3,272.5 93.3,262.1 103.3,266.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,286.6 103,281.9 103,271.5 113.1,276.2 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,281.9 100.9,280.9 100.9,265.7 103.1,266.7 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,281.8 103,277.1 103,266.7 113.1,271.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,291.2 112.8,286.5 112.8,276.1 122.9,280.8 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,286.5 110.7,285.5 110.7,270.2 112.9,271.3 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,286.4 112.8,281.7 112.8,271.3 122.9,276 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,295.8 122.6,291.1 122.6,280.7 132.7,285.4 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,291.1 120.5,290.1 120.5,274.8 122.7,275.9 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,291 122.6,286.3 122.6,275.8 132.7,280.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,300.4 132.4,295.7 132.4,285.3 142.5,290 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,295.7 130.2,294.7 130.2,279.4 132.4,280.4 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,295.6 132.4,290.9 132.4,280.4 142.5,285.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,305 142.2,300.3 142.2,289.9 152.3,294.5 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,300.3 140,299.3 140,284 142.2,285 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,300.1 142.2,295.5 142.2,285 152.3,289.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,309.2 152,304.9 152,294.4 161.2,298.7 					"
                    />
                    <polygon
                      className="st9"
                      points="152,304.9 149.8,303.8 149.8,288.6 152,289.6 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,304.2 152,300 152,289.6 160.9,293.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,309.5 159.6,308.4 159.6,293.2 161.8,294.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,303.8 164.1,308.4 164.1,298 174.1,293.3 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,308.4 161.9,309.5 161.9,294.2 164.1,293.2 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,298.9 164.1,303.6 164.1,293.2 174.1,288.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,299.2 173.9,303.9 173.9,293.4 183.9,288.7 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,303.9 171.7,304.9 171.7,289.6 173.9,288.6 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,294.3 173.9,299 173.9,288.6 183.9,283.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,294.6 183.7,299.3 183.7,288.8 193.7,284.2 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,299.3 181.5,300.3 181.5,285 183.7,284 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,289.7 183.7,294.4 183.7,284 193.7,279.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,290 193.5,294.7 193.5,284.3 203.5,279.6 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,294.7 191.3,295.7 191.3,280.4 193.5,279.4 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,285.2 193.5,289.8 193.5,279.4 203.5,274.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,285.4 203.3,290.1 203.3,279.7 213.3,275 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,290.1 201.1,291.1 201.1,275.9 203.3,274.8 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,280.6 203.3,285.3 203.3,274.8 213.3,270.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,280.8 213.1,285.5 213.1,275.1 223.1,270.4 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,285.5 210.9,286.5 210.9,271.3 213.1,270.2 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,276 213.1,280.7 213.1,270.2 223.1,265.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,276.2 222.9,280.9 222.9,270.5 232.9,265.8 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,280.9 220.7,281.9 220.7,266.7 222.9,265.7 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,271.4 222.9,276.1 222.9,265.7 232.9,261 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,271.6 232.7,276.3 232.7,265.9 242.7,261.2 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,276.3 230.5,277.4 230.5,262.1 232.7,261.1 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,266.8 232.7,271.5 232.7,261.1 242.7,256.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,267.6 242.5,271.7 242.5,261.3 251.4,257.1 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,271.7 240.3,272.8 240.3,257.5 242.5,256.5 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,262.9 242.5,266.9 242.5,256.5 251.1,252.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,267.2 250.1,268.2 250.1,252.9 252.3,251.9 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,295.8 122.6,291.1 122.6,280.7 132.7,285.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,291 122.6,286.3 122.6,275.8 132.7,280.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,300.4 132.4,295.7 132.4,285.3 142.5,290 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,295.7 130.2,294.7 130.2,279.4 132.4,280.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,295.6 132.4,290.9 132.4,280.4 142.5,285.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,305 142.2,300.3 142.2,289.9 152.3,294.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,300.3 140,299.3 140,284 142.2,285 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,300.1 142.2,295.5 142.2,285 152.3,289.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,309.2 152,304.9 152,294.4 161.2,298.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,304.9 149.8,303.8 149.8,288.6 152,289.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,304.2 152,300 152,289.6 160.9,293.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,309.5 159.6,308.4 159.6,293.2 161.8,294.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,303.8 164.1,308.4 164.1,298 174.1,293.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,308.4 161.9,309.5 161.9,294.2 164.1,293.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,298.9 164.1,303.6 164.1,293.2 174.1,288.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,299.2 173.9,303.9 173.9,293.4 183.9,288.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,303.9 171.7,304.9 171.7,289.6 173.9,288.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,294.3 173.9,299 173.9,288.6 183.9,283.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,294.6 183.7,299.3 183.7,288.8 193.7,284.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,299.3 181.5,300.3 181.5,285 183.7,284 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,289.7 183.7,294.4 183.7,284 193.7,279.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,290 193.5,294.7 193.5,284.3 203.5,279.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,294.7 191.3,295.7 191.3,280.4 193.5,279.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,285.2 193.5,289.8 193.5,279.4 203.5,274.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,285.4 203.3,290.1 203.3,279.7 213.3,275 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,290.1 201.1,291.1 201.1,275.9 203.3,274.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,280.6 203.3,285.3 203.3,274.8 213.3,270.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,280.9 213.1,285.5 213.1,275.1 222.7,270.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,285.5 210.9,286.5 210.9,271.3 213.1,270.2 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,276.1 213.1,280.7 213.1,270.2 222.7,265.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,280.9 220.7,281.9 220.7,266.7 222.9,265.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,262.1 152,262.1 122.4,275.9 122.6,276 161.9,294.4 191.4,280.6 223,265.8 223,265.8
			183.6,247.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,259.1 44.3,254.4 44.3,244 54.3,248.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,254.4 42.1,253.4 42.1,238.1 44.3,239.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,254.3 44.3,249.6 44.3,239.2 54.3,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,263.7 54.1,259 54.1,248.6 64.1,253.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,259 51.9,258 51.9,242.7 54.1,243.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,258.9 54.1,254.2 54.1,243.7 64.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,268.3 63.9,263.6 63.9,253.2 73.9,257.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,263.6 61.7,262.6 61.7,247.3 63.9,248.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,263.4 63.9,258.8 63.9,248.3 73.9,253 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,272.9 73.7,268.2 73.7,257.7 83.7,262.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,268.2 71.5,267.2 71.5,251.9 73.7,252.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,268 73.7,263.3 73.7,252.9 83.7,257.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,277.5 83.5,272.8 83.5,262.3 93.5,267 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,272.8 81.3,271.7 81.3,256.5 83.5,257.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,272.6 83.5,267.9 83.5,257.5 93.5,262.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,282 93.3,277.3 93.3,266.9 103.3,271.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,277.4 91.1,276.3 91.1,261.1 93.3,262.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,277.2 93.3,272.5 93.3,262.1 103.3,266.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,286.6 103,281.9 103,271.5 113.1,276.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,281.8 103,277.1 103,266.7 113.1,271.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,281.9 100.9,280.9 100.9,265.7 103.1,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,291.1 112.8,286.5 112.8,276.1 122.7,280.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,286.3 112.8,281.7 112.8,271.3 122.7,275.9 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,286.5 110.7,285.5 110.7,270.2 112.9,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,291.1 120.5,290.1 120.5,274.8 122.7,275.9 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,276.2 222.9,280.9 222.9,270.5 232.9,265.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,271.4 222.9,276.1 222.9,265.7 232.9,261 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,271.6 232.7,276.3 232.7,265.9 242.7,261.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,276.3 230.5,277.4 230.5,262.1 232.7,261.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,266.8 232.7,271.5 232.7,261.1 242.7,256.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,267.6 242.5,271.7 242.5,261.3 251.4,257.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,271.7 240.3,272.8 240.3,257.5 242.5,256.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,262.9 242.5,266.9 242.5,256.5 251.1,252.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,267.2 250.1,268.2 250.1,252.9 252.3,251.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,233.5 213,233.5 171.7,214.1 171.7,214.1 132.5,195.8 103.1,209.6 71.5,224.4 42,238.2
			81.2,256.6 122.4,275.9 152,262.1 110.7,242.7 142.3,227.9 142.3,227.9 183.6,247.3 183.7,247.3 183.6,247.3 223,265.8 252.4,252
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,295.8 122.6,291.1 122.6,280.7 132.7,285.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,291 122.6,286.3 122.6,275.8 132.7,280.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,300.4 132.4,295.7 132.4,285.3 142.5,290 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,295.7 130.2,294.7 130.2,279.4 132.4,280.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,295.6 132.4,290.9 132.4,280.4 142.5,285.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,305 142.2,300.3 142.2,289.9 152.3,294.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,300.3 140,299.3 140,284 142.2,285 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,300.1 142.2,295.5 142.2,285 152.3,289.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,309.2 152,304.9 152,294.4 161.2,298.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,304.9 149.8,303.8 149.8,288.6 152,289.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,304.2 152,300 152,289.6 160.9,293.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,309.5 159.6,308.4 159.6,293.2 161.8,294.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,303.8 164.1,308.4 164.1,298 174.1,293.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,308.4 161.9,309.5 161.9,294.2 164.1,293.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,298.9 164.1,303.6 164.1,293.2 174.1,288.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,299.2 173.9,303.9 173.9,293.4 183.9,288.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,303.9 171.7,304.9 171.7,289.6 173.9,288.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,294.3 173.9,299 173.9,288.6 183.9,283.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,294.6 183.7,299.3 183.7,288.8 193.7,284.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,299.3 181.5,300.3 181.5,285 183.7,284 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,289.7 183.7,294.4 183.7,284 193.7,279.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,290 193.5,294.7 193.5,284.3 203.5,279.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,294.7 191.3,295.7 191.3,280.4 193.5,279.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,285.2 193.5,289.8 193.5,279.4 203.5,274.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,285.4 203.3,290.1 203.3,279.7 213.3,275 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,290.1 201.1,291.1 201.1,275.9 203.3,274.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,280.6 203.3,285.3 203.3,274.8 213.3,270.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,280.8 213.1,285.5 213.1,275.1 223.1,270.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,285.5 210.9,286.5 210.9,271.3 213.1,270.2 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,276 213.1,280.7 213.1,270.2 223.1,265.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,276.2 222.9,280.9 222.9,270.5 232.9,265.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,280.9 220.7,281.9 220.7,266.7 222.9,265.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,271.4 222.9,276.1 222.9,265.7 232.9,261 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,271.6 232.7,276.3 232.7,265.9 242.7,261.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,276.3 230.5,277.4 230.5,262.1 232.7,261.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,266.8 232.7,271.5 232.7,261.1 242.7,256.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,267.6 242.5,271.7 242.5,261.3 251.4,257.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,271.7 240.3,272.8 240.3,257.5 242.5,256.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,262.9 242.5,266.9 242.5,256.5 251.1,252.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,267.2 250.1,268.2 250.1,252.9 252.3,251.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,233.5 213,233.5 183.6,247.3 183.7,247.3 183.6,247.3 152.1,262.1 152,262.1 122.4,275.9
			122.6,276 161.9,294.4 191.4,280.6 223,265.8 223,265.8 252.4,252 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,259.1 44.3,254.4 44.3,244 54.3,248.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,254.4 42.1,253.4 42.1,238.1 44.3,239.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,254.3 44.3,249.6 44.3,239.2 54.3,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,263.7 54.1,259 54.1,248.6 64.1,253.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,259 51.9,258 51.9,242.7 54.1,243.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,258.9 54.1,254.2 54.1,243.7 64.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,268.3 63.9,263.6 63.9,253.2 73.9,257.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,263.6 61.7,262.6 61.7,247.3 63.9,248.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,263.4 63.9,258.8 63.9,248.3 73.9,253 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,272.9 73.7,268.2 73.7,257.7 83.7,262.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,268.2 71.5,267.2 71.5,251.9 73.7,252.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,268 73.7,263.3 73.7,252.9 83.7,257.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,277.5 83.5,272.8 83.5,262.3 93.5,267 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,272.8 81.3,271.7 81.3,256.5 83.5,257.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,272.6 83.5,267.9 83.5,257.5 93.5,262.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,282 93.3,277.3 93.3,266.9 103.3,271.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,277.4 91.1,276.3 91.1,261.1 93.3,262.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,277.2 93.3,272.5 93.3,262.1 103.3,266.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,286.6 103,281.9 103,271.5 113.1,276.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,281.8 103,277.1 103,266.7 113.1,271.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,281.9 100.9,280.9 100.9,265.7 103.1,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,291.1 112.8,286.5 112.8,276.1 122.6,280.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,286.3 112.8,281.7 112.8,271.3 122.6,275.9 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,286.5 110.7,285.5 110.7,270.2 112.9,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,291.1 120.5,290.1 120.5,274.8 122.7,275.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,227.9 183.6,247.3 213,233.5 171.7,214.1 171.7,214.1 132.5,195.8 103.1,209.6 71.5,224.4
			42,238.2 81.2,256.6 122.4,275.9 152,262.1 110.7,242.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,295.8 122.6,291.1 122.6,280.7 132.7,285.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,291 122.6,286.3 122.6,275.8 132.7,280.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,300.4 132.4,295.7 132.4,285.3 142.5,290 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,295.7 130.2,294.7 130.2,279.4 132.4,280.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,295.6 132.4,290.9 132.4,280.4 142.5,285.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,305 142.2,300.3 142.2,289.9 152.3,294.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,300.3 140,299.3 140,284 142.2,285 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,300.1 142.2,295.5 142.2,285 152.3,289.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,309.2 152,304.9 152,294.4 161.2,298.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,304.9 149.8,303.8 149.8,288.6 152,289.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,304.2 152,300 152,289.6 160.9,293.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,309.5 159.6,308.4 159.6,293.2 161.8,294.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,303.8 164.1,308.4 164.1,298 174.1,293.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,308.4 161.9,309.5 161.9,294.2 164.1,293.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,298.9 164.1,303.6 164.1,293.2 174.1,288.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,299.2 173.9,303.9 173.9,293.4 183.9,288.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,303.9 171.7,304.9 171.7,289.6 173.9,288.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,294.3 173.9,299 173.9,288.6 183.9,283.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,294.6 183.7,299.3 183.7,288.8 193.7,284.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,299.3 181.5,300.3 181.5,285 183.7,284 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,289.7 183.7,294.4 183.7,284 193.7,279.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,290 193.5,294.7 193.5,284.3 203.5,279.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,294.7 191.3,295.7 191.3,280.4 193.5,279.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,285.2 193.5,289.8 193.5,279.4 203.5,274.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,285.4 203.3,290.1 203.3,279.7 213.3,275 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,290.1 201.1,291.1 201.1,275.9 203.3,274.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,280.6 203.3,285.3 203.3,274.8 213.3,270.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,280.8 213.1,285.5 213.1,275.1 223.1,270.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,285.5 210.9,286.5 210.9,271.3 213.1,270.2 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,276 213.1,280.7 213.1,270.2 223.1,265.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,276.2 222.9,280.9 222.9,270.5 232.9,265.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,280.9 220.7,281.9 220.7,266.7 222.9,265.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,271.4 222.9,276.1 222.9,265.7 232.9,261 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,271.6 232.7,276.3 232.7,265.9 242.7,261.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,276.3 230.5,277.4 230.5,262.1 232.7,261.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,266.8 232.7,271.5 232.7,261.1 242.7,256.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,267.6 242.5,271.7 242.5,261.3 251.4,257.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,271.7 240.3,272.8 240.3,257.5 242.5,256.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,262.9 242.5,266.9 242.5,256.5 251.1,252.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,267.2 250.1,268.2 250.1,252.9 252.3,251.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,233.5 213,233.5 171.7,214.1 142.3,227.9 183.6,247.3 183.7,247.3 183.6,247.3 152.1,262.1
			152,262.1 122.4,275.9 122.6,276 161.9,294.4 191.4,280.6 223,265.8 223,265.8 252.4,252 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,259.1 44.3,254.4 44.3,244 54.3,248.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,254.4 42.1,253.4 42.1,238.1 44.3,239.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,254.3 44.3,249.6 44.3,239.2 54.3,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,263.7 54.1,259 54.1,248.6 64.1,253.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,259 51.9,258 51.9,242.7 54.1,243.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,258.9 54.1,254.2 54.1,243.7 64.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,268.3 63.9,263.6 63.9,253.2 73.9,257.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,263.6 61.7,262.6 61.7,247.3 63.9,248.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,263.4 63.9,258.8 63.9,248.3 73.9,253 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,272.9 73.7,268.2 73.7,257.7 83.7,262.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,268.2 71.5,267.2 71.5,251.9 73.7,252.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,268 73.7,263.3 73.7,252.9 83.7,257.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,277.5 83.5,272.8 83.5,262.3 93.5,267 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,272.8 81.3,271.7 81.3,256.5 83.5,257.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,272.6 83.5,267.9 83.5,257.5 93.5,262.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,282 93.3,277.3 93.3,266.9 103.3,271.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,277.4 91.1,276.3 91.1,261.1 93.3,262.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,277.2 93.3,272.5 93.3,262.1 103.3,266.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,286.6 103,281.9 103,271.5 113.1,276.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,281.8 103,277.1 103,266.7 113.1,271.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,281.9 100.9,280.9 100.9,265.7 103.1,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,291.1 112.8,286.5 112.8,276.1 122.6,280.7 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,286.3 112.8,281.7 112.8,271.3 122.6,275.9 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,286.5 110.7,285.5 110.7,270.2 112.9,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,291.1 120.5,290.1 120.5,274.8 122.7,275.9 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,242.7 142.3,227.9 142.3,227.9 171.7,214.1 171.7,214.1 132.5,195.8 103.1,209.6 71.5,224.4
			42,238.2 81.2,256.6 122.4,275.9 152,262.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={15} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,259.1 44.3,254.4 44.3,244 54.3,248.7 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,254.4 42.1,253.4 42.1,238.1 44.3,239.2 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,254.3 44.3,249.6 44.3,239.2 54.3,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,263.7 54.1,259 54.1,248.6 64.1,253.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,259 51.9,258 51.9,242.7 54.1,243.7 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,258.9 54.1,254.2 54.1,243.7 64.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,268.3 63.9,263.6 63.9,253.2 73.9,257.8 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,263.6 61.7,262.6 61.7,247.3 63.9,248.3 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,263.4 63.9,258.8 63.9,248.3 73.9,253 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,272.9 73.7,268.2 73.7,257.7 83.7,262.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,268.2 71.5,267.2 71.5,251.9 73.7,252.9 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,268 73.7,263.3 73.7,252.9 83.7,257.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,277.5 83.5,272.8 83.5,262.3 93.5,267 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,272.8 81.3,271.7 81.3,256.5 83.5,257.5 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,272.6 83.5,267.9 83.5,257.5 93.5,262.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,282 93.3,277.3 93.3,266.9 103.3,271.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,277.4 91.1,276.3 91.1,261.1 93.3,262.1 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,277.2 93.3,272.5 93.3,262.1 103.3,266.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,281.9 100.9,280.9 100.9,265.7 103.1,266.7 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,286.6 103,281.9 103,271.5 113.1,276.2 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,281.8 103,277.1 103,266.7 113.1,271.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,286.5 110.7,285.5 110.7,270.2 112.9,271.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,291.2 112.8,286.5 112.8,276.1 122.9,280.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,286.4 112.8,281.7 112.8,271.3 122.9,276 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,291.1 120.5,290.1 120.5,274.8 122.7,275.9 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,295.8 122.6,291.1 122.6,280.7 132.7,285.4 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,291 122.6,286.3 122.6,275.8 132.7,280.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,300.4 132.4,295.7 132.4,285.3 142.5,290 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,295.7 130.2,294.7 130.2,279.4 132.4,280.4 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,295.6 132.4,290.9 132.4,280.4 142.5,285.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,305 142.2,300.3 142.2,289.9 152.3,294.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,300.3 140,299.3 140,284 142.2,285 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,300.1 142.2,295.5 142.2,285 152.3,289.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,309.2 152,304.9 152,294.4 161.2,298.7 					"
                    />
                    <polygon
                      className="st14"
                      points="152,304.9 149.8,303.8 149.8,288.6 152,289.6 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,304.2 152,300 152,289.6 160.9,293.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,309.5 159.6,308.4 159.6,293.2 161.8,294.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,303.8 164.1,308.4 164.1,298 174.1,293.3 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,308.4 161.9,309.5 161.9,294.2 164.1,293.2 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,298.9 164.1,303.6 164.1,293.2 174.1,288.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,299.2 173.9,303.9 173.9,293.4 183.9,288.7 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,303.9 171.7,304.9 171.7,289.6 173.9,288.6 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,294.3 173.9,299 173.9,288.6 183.9,283.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,294.6 183.7,299.3 183.7,288.8 193.7,284.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,299.3 181.5,300.3 181.5,285 183.7,284 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,289.7 183.7,294.4 183.7,284 193.7,279.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,290 193.5,294.7 193.5,284.3 203.5,279.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,294.7 191.3,295.7 191.3,280.4 193.5,279.4 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,285.2 193.5,289.8 193.5,279.4 203.5,274.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,285.4 203.3,290.1 203.3,279.7 213.3,275 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,290.1 201.1,291.1 201.1,275.9 203.3,274.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,280.6 203.3,285.3 203.3,274.8 213.3,270.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,280.8 213.1,285.5 213.1,275.1 223.1,270.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,285.5 210.9,286.5 210.9,271.3 213.1,270.2 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,276 213.1,280.7 213.1,270.2 223.1,265.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,276.2 222.9,280.9 222.9,270.5 232.9,265.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,280.9 220.7,281.9 220.7,266.7 222.9,265.7 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,271.4 222.9,276.1 222.9,265.7 232.9,261 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,271.6 232.7,276.3 232.7,265.9 242.7,261.2 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,276.3 230.5,277.4 230.5,262.1 232.7,261.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,266.8 232.7,271.5 232.7,261.1 242.7,256.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,267.6 242.5,271.7 242.5,261.3 251.4,257.1 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,271.7 240.3,272.8 240.3,257.5 242.5,256.5 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,262.9 242.5,266.9 242.5,256.5 251.1,252.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,267.2 250.1,268.2 250.1,252.9 252.3,251.9 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,233.5L213.1,233.5l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,238.2l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,233.5z M152.1,262.1L152.1,262.1l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,262.1z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={16}
          >
            <FloorNeutral openFloors={openFloors} floor={16}>
              <polygon
                className="st1"
                points="152,244.9 183.6,230.1 183.6,230.1 183.6,230.1 142.2,210.7 142.2,210.7 110.6,225.5 		"
              />
              <path
                className="st16"
                d="M213,216.3L213,216.3l-41.4-19.4l0,0l-39.2-18.3L103,192.4l-31.6,14.8L41.9,221l39.2,18.4l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,216.3z M183.6,230.1L152,244.9l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,230.1
			L183.6,230.1L183.6,230.1z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,241.9 44.3,237.2 44.3,226.8 54.3,231.5 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,237.2 42.1,236.2 42.1,220.9 44.3,222 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,237.1 44.3,232.4 44.3,222 54.3,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,246.5 54.1,241.8 54.1,231.4 64.1,236.1 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,241.8 51.9,240.8 51.9,225.5 54.1,226.5 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,241.7 54.1,237 54.1,226.5 64.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,251.1 63.9,246.4 63.9,236 73.9,240.6 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,246.4 61.7,245.4 61.7,230.1 63.9,231.1 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,246.2 63.9,241.6 63.9,231.1 73.9,235.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,255.7 73.7,251 73.7,240.5 83.7,245.2 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,251 71.5,250 71.5,234.7 73.7,235.7 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,250.8 73.7,246.1 73.7,235.7 83.7,240.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,260.3 83.5,255.6 83.5,245.1 93.5,249.8 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,255.6 81.3,254.5 81.3,239.3 83.5,240.3 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,255.4 83.5,250.7 83.5,240.3 93.5,245 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,264.8 93.3,260.1 93.3,249.7 103.3,254.4 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,260.2 91.1,259.1 91.1,243.9 93.3,244.9 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,260 93.3,255.3 93.3,244.9 103.3,249.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,269.4 103,264.7 103,254.3 113.1,259 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,264.7 100.9,263.7 100.9,248.5 103.1,249.5 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,264.6 103,259.9 103,249.5 113.1,254.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,274 112.8,269.3 112.8,258.9 122.9,263.6 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,269.3 110.7,268.3 110.7,253 112.9,254.1 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,269.2 112.8,264.5 112.8,254.1 122.9,258.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,278.6 122.6,273.9 122.6,263.5 132.7,268.2 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,273.9 120.5,272.9 120.5,257.6 122.7,258.7 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,273.8 122.6,269.1 122.6,258.6 132.7,263.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,283.2 132.4,278.5 132.4,268.1 142.5,272.8 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,278.5 130.2,277.5 130.2,262.2 132.4,263.2 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,278.4 132.4,273.7 132.4,263.2 142.5,267.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,287.8 142.2,283.1 142.2,272.7 152.3,277.3 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,283.1 140,282.1 140,266.8 142.2,267.8 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,282.9 142.2,278.3 142.2,267.8 152.3,272.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,292 152,287.7 152,277.2 161.2,281.5 					"
                    />
                    <polygon
                      className="st9"
                      points="152,287.7 149.8,286.6 149.8,271.4 152,272.4 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,287 152,282.8 152,272.4 160.9,276.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,292.3 159.6,291.2 159.6,276 161.8,277 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,286.6 164.1,291.2 164.1,280.8 174.1,276.1 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,291.2 161.9,292.3 161.9,277 164.1,276 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,281.7 164.1,286.4 164.1,276 174.1,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,282 173.9,286.7 173.9,276.2 183.9,271.5 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,286.7 171.7,287.7 171.7,272.4 173.9,271.4 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,277.1 173.9,281.8 173.9,271.4 183.9,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,277.4 183.7,282.1 183.7,271.6 193.7,267 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,282.1 181.5,283.1 181.5,267.8 183.7,266.8 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,272.5 183.7,277.2 183.7,266.8 193.7,262.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,272.8 193.5,277.5 193.5,267.1 203.5,262.4 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,277.5 191.3,278.5 191.3,263.2 193.5,262.2 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,268 193.5,272.6 193.5,262.2 203.5,257.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,268.2 203.3,272.9 203.3,262.5 213.3,257.8 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,272.9 201.1,273.9 201.1,258.7 203.3,257.6 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,263.4 203.3,268.1 203.3,257.6 213.3,252.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,263.6 213.1,268.3 213.1,257.9 223.1,253.2 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,268.3 210.9,269.3 210.9,254.1 213.1,253 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,258.8 213.1,263.5 213.1,253 223.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,259 222.9,263.7 222.9,253.3 232.9,248.6 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,263.7 220.7,264.7 220.7,249.5 222.9,248.5 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,254.2 222.9,258.9 222.9,248.5 232.9,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,254.4 232.7,259.1 232.7,248.7 242.7,244 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,259.1 230.5,260.2 230.5,244.9 232.7,243.9 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,249.6 232.7,254.3 232.7,243.9 242.7,239.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,250.4 242.5,254.5 242.5,244.1 251.4,239.9 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,254.5 240.3,255.6 240.3,240.3 242.5,239.3 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,245.7 242.5,249.7 242.5,239.3 251.1,235.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,250 250.1,251 250.1,235.7 252.3,234.7 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,278.6 122.6,273.9 122.6,263.5 132.7,268.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,273.8 122.6,269.1 122.6,258.6 132.7,263.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,283.2 132.4,278.5 132.4,268.1 142.5,272.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,278.5 130.2,277.5 130.2,262.2 132.4,263.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,278.4 132.4,273.7 132.4,263.2 142.5,267.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,287.8 142.2,283.1 142.2,272.7 152.3,277.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,283.1 140,282.1 140,266.8 142.2,267.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,282.9 142.2,278.3 142.2,267.8 152.3,272.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,292 152,287.7 152,277.2 161.2,281.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,287.7 149.8,286.6 149.8,271.4 152,272.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,287 152,282.8 152,272.4 160.9,276.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,292.3 159.6,291.2 159.6,276 161.8,277 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,286.6 164.1,291.2 164.1,280.8 174.1,276.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,291.2 161.9,292.3 161.9,277 164.1,276 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,281.7 164.1,286.4 164.1,276 174.1,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,282 173.9,286.7 173.9,276.2 183.9,271.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,286.7 171.7,287.7 171.7,272.4 173.9,271.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,277.1 173.9,281.8 173.9,271.4 183.9,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,277.4 183.7,282.1 183.7,271.6 193.7,267 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,282.1 181.5,283.1 181.5,267.8 183.7,266.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,272.5 183.7,277.2 183.7,266.8 193.7,262.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,272.8 193.5,277.5 193.5,267.1 203.5,262.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,277.5 191.3,278.5 191.3,263.2 193.5,262.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,268 193.5,272.6 193.5,262.2 203.5,257.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,268.2 203.3,272.9 203.3,262.5 213.3,257.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,272.9 201.1,273.9 201.1,258.7 203.3,257.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,263.4 203.3,268.1 203.3,257.6 213.3,252.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,263.7 213.1,268.3 213.1,257.9 222.7,253.3 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,268.3 210.9,269.3 210.9,254.1 213.1,253 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,258.9 213.1,263.5 213.1,253 222.7,248.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,263.7 220.7,264.7 220.7,249.5 222.9,248.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,244.9 152,244.9 122.4,258.7 122.6,258.8 161.9,277.2 191.4,263.4 223,248.6 223,248.6
			183.6,230.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,241.9 44.3,237.2 44.3,226.8 54.3,231.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,237.2 42.1,236.2 42.1,220.9 44.3,222 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,237.1 44.3,232.4 44.3,222 54.3,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,246.5 54.1,241.8 54.1,231.4 64.1,236.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,241.8 51.9,240.8 51.9,225.5 54.1,226.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,241.7 54.1,237 54.1,226.5 64.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,251.1 63.9,246.4 63.9,236 73.9,240.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,246.4 61.7,245.4 61.7,230.1 63.9,231.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,246.2 63.9,241.6 63.9,231.1 73.9,235.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,255.7 73.7,251 73.7,240.5 83.7,245.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,251 71.5,250 71.5,234.7 73.7,235.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,250.8 73.7,246.1 73.7,235.7 83.7,240.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,260.3 83.5,255.6 83.5,245.1 93.5,249.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,255.6 81.3,254.5 81.3,239.3 83.5,240.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,255.4 83.5,250.7 83.5,240.3 93.5,245 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,264.8 93.3,260.1 93.3,249.7 103.3,254.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,260.2 91.1,259.1 91.1,243.9 93.3,244.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,260 93.3,255.3 93.3,244.9 103.3,249.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,269.4 103,264.7 103,254.3 113.1,259 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,264.6 103,259.9 103,249.5 113.1,254.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,264.7 100.9,263.7 100.9,248.5 103.1,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,273.9 112.8,269.3 112.8,258.9 122.7,263.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,269.1 112.8,264.5 112.8,254.1 122.7,258.7 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,269.3 110.7,268.3 110.7,253 112.9,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,273.9 120.5,272.9 120.5,257.6 122.7,258.7 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,259 222.9,263.7 222.9,253.3 232.9,248.6 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,254.2 222.9,258.9 222.9,248.5 232.9,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,254.4 232.7,259.1 232.7,248.7 242.7,244 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,259.1 230.5,260.2 230.5,244.9 232.7,243.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,249.6 232.7,254.3 232.7,243.9 242.7,239.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,250.4 242.5,254.5 242.5,244.1 251.4,239.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,254.5 240.3,255.6 240.3,240.3 242.5,239.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,245.7 242.5,249.7 242.5,239.3 251.1,235.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,250 250.1,251 250.1,235.7 252.3,234.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,216.3 213,216.3 171.7,196.9 171.7,196.9 132.5,178.6 103.1,192.4 71.5,207.2 42,221
			81.2,239.4 122.4,258.7 152,244.9 110.7,225.5 142.3,210.7 142.3,210.7 183.6,230.1 183.7,230.1 183.6,230.1 223,248.6
			252.4,234.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,278.6 122.6,273.9 122.6,263.5 132.7,268.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,273.8 122.6,269.1 122.6,258.6 132.7,263.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,283.2 132.4,278.5 132.4,268.1 142.5,272.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,278.5 130.2,277.5 130.2,262.2 132.4,263.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,278.4 132.4,273.7 132.4,263.2 142.5,267.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,287.8 142.2,283.1 142.2,272.7 152.3,277.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,283.1 140,282.1 140,266.8 142.2,267.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,282.9 142.2,278.3 142.2,267.8 152.3,272.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,292 152,287.7 152,277.2 161.2,281.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,287.7 149.8,286.6 149.8,271.4 152,272.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,287 152,282.8 152,272.4 160.9,276.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,292.3 159.6,291.2 159.6,276 161.8,277 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,286.6 164.1,291.2 164.1,280.8 174.1,276.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,291.2 161.9,292.3 161.9,277 164.1,276 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,281.7 164.1,286.4 164.1,276 174.1,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,282 173.9,286.7 173.9,276.2 183.9,271.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,286.7 171.7,287.7 171.7,272.4 173.9,271.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,277.1 173.9,281.8 173.9,271.4 183.9,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,277.4 183.7,282.1 183.7,271.6 193.7,267 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,282.1 181.5,283.1 181.5,267.8 183.7,266.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,272.5 183.7,277.2 183.7,266.8 193.7,262.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,272.8 193.5,277.5 193.5,267.1 203.5,262.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,277.5 191.3,278.5 191.3,263.2 193.5,262.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,268 193.5,272.6 193.5,262.2 203.5,257.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,268.2 203.3,272.9 203.3,262.5 213.3,257.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,272.9 201.1,273.9 201.1,258.7 203.3,257.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,263.4 203.3,268.1 203.3,257.6 213.3,252.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,263.6 213.1,268.3 213.1,257.9 223.1,253.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,268.3 210.9,269.3 210.9,254.1 213.1,253 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,258.8 213.1,263.5 213.1,253 223.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,259 222.9,263.7 222.9,253.3 232.9,248.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,263.7 220.7,264.7 220.7,249.5 222.9,248.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,254.2 222.9,258.9 222.9,248.5 232.9,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,254.4 232.7,259.1 232.7,248.7 242.7,244 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,259.1 230.5,260.2 230.5,244.9 232.7,243.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,249.6 232.7,254.3 232.7,243.9 242.7,239.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,250.4 242.5,254.5 242.5,244.1 251.4,239.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,254.5 240.3,255.6 240.3,240.3 242.5,239.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,245.7 242.5,249.7 242.5,239.3 251.1,235.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,250 250.1,251 250.1,235.7 252.3,234.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,216.3 213,216.3 183.6,230.1 183.7,230.1 183.6,230.1 152.1,244.9 152,244.9 122.4,258.7
			122.6,258.8 161.9,277.2 191.4,263.4 223,248.6 223,248.6 252.4,234.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,241.9 44.3,237.2 44.3,226.8 54.3,231.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,237.2 42.1,236.2 42.1,220.9 44.3,222 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,237.1 44.3,232.4 44.3,222 54.3,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,246.5 54.1,241.8 54.1,231.4 64.1,236.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,241.8 51.9,240.8 51.9,225.5 54.1,226.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,241.7 54.1,237 54.1,226.5 64.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,251.1 63.9,246.4 63.9,236 73.9,240.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,246.4 61.7,245.4 61.7,230.1 63.9,231.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,246.2 63.9,241.6 63.9,231.1 73.9,235.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,255.7 73.7,251 73.7,240.5 83.7,245.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,251 71.5,250 71.5,234.7 73.7,235.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,250.8 73.7,246.1 73.7,235.7 83.7,240.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,260.3 83.5,255.6 83.5,245.1 93.5,249.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,255.6 81.3,254.5 81.3,239.3 83.5,240.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,255.4 83.5,250.7 83.5,240.3 93.5,245 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,264.8 93.3,260.1 93.3,249.7 103.3,254.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,260.2 91.1,259.1 91.1,243.9 93.3,244.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,260 93.3,255.3 93.3,244.9 103.3,249.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,269.4 103,264.7 103,254.3 113.1,259 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,264.6 103,259.9 103,249.5 113.1,254.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,264.7 100.9,263.7 100.9,248.5 103.1,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,273.9 112.8,269.3 112.8,258.9 122.6,263.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,269.1 112.8,264.5 112.8,254.1 122.6,258.7 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,269.3 110.7,268.3 110.7,253 112.9,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,273.9 120.5,272.9 120.5,257.6 122.7,258.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,210.7 183.6,230.1 213,216.3 171.7,196.9 171.7,196.9 132.5,178.6 103.1,192.4 71.5,207.2
			42,221 81.2,239.4 122.4,258.7 152,244.9 110.7,225.5 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,278.6 122.6,273.9 122.6,263.5 132.7,268.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,273.8 122.6,269.1 122.6,258.6 132.7,263.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,283.2 132.4,278.5 132.4,268.1 142.5,272.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,278.5 130.2,277.5 130.2,262.2 132.4,263.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,278.4 132.4,273.7 132.4,263.2 142.5,267.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,287.8 142.2,283.1 142.2,272.7 152.3,277.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,283.1 140,282.1 140,266.8 142.2,267.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,282.9 142.2,278.3 142.2,267.8 152.3,272.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,292 152,287.7 152,277.2 161.2,281.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,287.7 149.8,286.6 149.8,271.4 152,272.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,287 152,282.8 152,272.4 160.9,276.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,292.3 159.6,291.2 159.6,276 161.8,277 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,286.6 164.1,291.2 164.1,280.8 174.1,276.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,291.2 161.9,292.3 161.9,277 164.1,276 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,281.7 164.1,286.4 164.1,276 174.1,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,282 173.9,286.7 173.9,276.2 183.9,271.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,286.7 171.7,287.7 171.7,272.4 173.9,271.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,277.1 173.9,281.8 173.9,271.4 183.9,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,277.4 183.7,282.1 183.7,271.6 193.7,267 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,282.1 181.5,283.1 181.5,267.8 183.7,266.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,272.5 183.7,277.2 183.7,266.8 193.7,262.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,272.8 193.5,277.5 193.5,267.1 203.5,262.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,277.5 191.3,278.5 191.3,263.2 193.5,262.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,268 193.5,272.6 193.5,262.2 203.5,257.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,268.2 203.3,272.9 203.3,262.5 213.3,257.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,272.9 201.1,273.9 201.1,258.7 203.3,257.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,263.4 203.3,268.1 203.3,257.6 213.3,252.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,263.6 213.1,268.3 213.1,257.9 223.1,253.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,268.3 210.9,269.3 210.9,254.1 213.1,253 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,258.8 213.1,263.5 213.1,253 223.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,259 222.9,263.7 222.9,253.3 232.9,248.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,263.7 220.7,264.7 220.7,249.5 222.9,248.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,254.2 222.9,258.9 222.9,248.5 232.9,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,254.4 232.7,259.1 232.7,248.7 242.7,244 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,259.1 230.5,260.2 230.5,244.9 232.7,243.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,249.6 232.7,254.3 232.7,243.9 242.7,239.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,250.4 242.5,254.5 242.5,244.1 251.4,239.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,254.5 240.3,255.6 240.3,240.3 242.5,239.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,245.7 242.5,249.7 242.5,239.3 251.1,235.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,250 250.1,251 250.1,235.7 252.3,234.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,216.3 213,216.3 171.7,196.9 142.3,210.7 183.6,230.1 183.7,230.1 183.6,230.1 152.1,244.9
			152,244.9 122.4,258.7 122.6,258.8 161.9,277.2 191.4,263.4 223,248.6 223,248.6 252.4,234.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,241.9 44.3,237.2 44.3,226.8 54.3,231.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,237.2 42.1,236.2 42.1,220.9 44.3,222 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,237.1 44.3,232.4 44.3,222 54.3,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,246.5 54.1,241.8 54.1,231.4 64.1,236.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,241.8 51.9,240.8 51.9,225.5 54.1,226.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,241.7 54.1,237 54.1,226.5 64.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,251.1 63.9,246.4 63.9,236 73.9,240.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,246.4 61.7,245.4 61.7,230.1 63.9,231.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,246.2 63.9,241.6 63.9,231.1 73.9,235.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,255.7 73.7,251 73.7,240.5 83.7,245.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,251 71.5,250 71.5,234.7 73.7,235.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,250.8 73.7,246.1 73.7,235.7 83.7,240.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,260.3 83.5,255.6 83.5,245.1 93.5,249.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,255.6 81.3,254.5 81.3,239.3 83.5,240.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,255.4 83.5,250.7 83.5,240.3 93.5,245 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,264.8 93.3,260.1 93.3,249.7 103.3,254.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,260.2 91.1,259.1 91.1,243.9 93.3,244.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,260 93.3,255.3 93.3,244.9 103.3,249.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,269.4 103,264.7 103,254.3 113.1,259 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,264.6 103,259.9 103,249.5 113.1,254.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,264.7 100.9,263.7 100.9,248.5 103.1,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,273.9 112.8,269.3 112.8,258.9 122.6,263.5 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,269.1 112.8,264.5 112.8,254.1 122.6,258.7 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,269.3 110.7,268.3 110.7,253 112.9,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,273.9 120.5,272.9 120.5,257.6 122.7,258.7 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,225.5 142.3,210.7 142.3,210.7 171.7,196.9 171.7,196.9 132.5,178.6 103.1,192.4 71.5,207.2
			42,221 81.2,239.4 122.4,258.7 152,244.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={16} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,241.9 44.3,237.2 44.3,226.8 54.3,231.5 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,237.2 42.1,236.2 42.1,220.9 44.3,222 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,237.1 44.3,232.4 44.3,222 54.3,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,246.5 54.1,241.8 54.1,231.4 64.1,236.1 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,241.8 51.9,240.8 51.9,225.5 54.1,226.5 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,241.7 54.1,237 54.1,226.5 64.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,251.1 63.9,246.4 63.9,236 73.9,240.6 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,246.4 61.7,245.4 61.7,230.1 63.9,231.1 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,246.2 63.9,241.6 63.9,231.1 73.9,235.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,255.7 73.7,251 73.7,240.5 83.7,245.2 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,251 71.5,250 71.5,234.7 73.7,235.7 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,250.8 73.7,246.1 73.7,235.7 83.7,240.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,260.3 83.5,255.6 83.5,245.1 93.5,249.8 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,255.6 81.3,254.5 81.3,239.3 83.5,240.3 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,255.4 83.5,250.7 83.5,240.3 93.5,245 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,264.8 93.3,260.1 93.3,249.7 103.3,254.4 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,260.2 91.1,259.1 91.1,243.9 93.3,244.9 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,260 93.3,255.3 93.3,244.9 103.3,249.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,264.7 100.9,263.7 100.9,248.5 103.1,249.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,269.4 103,264.7 103,254.3 113.1,259 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,264.6 103,259.9 103,249.5 113.1,254.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,269.3 110.7,268.3 110.7,253 112.9,254.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,274 112.8,269.3 112.8,258.9 122.9,263.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,269.2 112.8,264.5 112.8,254.1 122.9,258.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,273.9 120.5,272.9 120.5,257.6 122.7,258.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,278.6 122.6,273.9 122.6,263.5 132.7,268.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,273.8 122.6,269.1 122.6,258.6 132.7,263.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,283.2 132.4,278.5 132.4,268.1 142.5,272.8 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,278.5 130.2,277.5 130.2,262.2 132.4,263.2 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,278.4 132.4,273.7 132.4,263.2 142.5,267.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,287.8 142.2,283.1 142.2,272.7 152.3,277.3 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,283.1 140,282.1 140,266.8 142.2,267.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,282.9 142.2,278.3 142.2,267.8 152.3,272.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,292 152,287.7 152,277.2 161.2,281.5 					"
                    />
                    <polygon
                      className="st14"
                      points="152,287.7 149.8,286.6 149.8,271.4 152,272.4 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,287 152,282.8 152,272.4 160.9,276.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,292.3 159.6,291.2 159.6,276 161.8,277 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,286.6 164.1,291.2 164.1,280.8 174.1,276.1 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,291.2 161.9,292.3 161.9,277 164.1,276 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,281.7 164.1,286.4 164.1,276 174.1,271.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,282 173.9,286.7 173.9,276.2 183.9,271.5 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,286.7 171.7,287.7 171.7,272.4 173.9,271.4 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,277.1 173.9,281.8 173.9,271.4 183.9,266.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,277.4 183.7,282.1 183.7,271.6 193.7,267 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,282.1 181.5,283.1 181.5,267.8 183.7,266.8 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,272.5 183.7,277.2 183.7,266.8 193.7,262.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,272.8 193.5,277.5 193.5,267.1 203.5,262.4 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,277.5 191.3,278.5 191.3,263.2 193.5,262.2 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,268 193.5,272.6 193.5,262.2 203.5,257.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,268.2 203.3,272.9 203.3,262.5 213.3,257.8 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,272.9 201.1,273.9 201.1,258.7 203.3,257.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,263.4 203.3,268.1 203.3,257.6 213.3,252.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,263.6 213.1,268.3 213.1,257.9 223.1,253.2 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,268.3 210.9,269.3 210.9,254.1 213.1,253 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,258.8 213.1,263.5 213.1,253 223.1,248.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,259 222.9,263.7 222.9,253.3 232.9,248.6 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,263.7 220.7,264.7 220.7,249.5 222.9,248.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,254.2 222.9,258.9 222.9,248.5 232.9,243.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,254.4 232.7,259.1 232.7,248.7 242.7,244 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,259.1 230.5,260.2 230.5,244.9 232.7,243.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,249.6 232.7,254.3 232.7,243.9 242.7,239.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,250.4 242.5,254.5 242.5,244.1 251.4,239.9 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,254.5 240.3,255.6 240.3,240.3 242.5,239.3 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,245.7 242.5,249.7 242.5,239.3 251.1,235.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,250 250.1,251 250.1,235.7 252.3,234.7 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,216.3L213.1,216.3l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,221l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,216.3z M152.1,244.9L152.1,244.9l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,244.9z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={17}
          >
            <FloorNeutral openFloors={openFloors} floor={17}>
              <polygon
                className="st1"
                points="152,227.7 183.6,212.9 183.6,212.9 183.6,212.9 142.2,193.5 142.2,193.5 110.6,208.3 		"
              />
              <path
                className="st16"
                d="M213,199.1L213,199.1l-41.4-19.4l0,0l-39.2-18.3L103,175.2L71.4,190l-29.5,13.8l39.2,18.4l41.3,19.3l0.1,0.1
			l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,199.1z M183.6,212.9L152,227.7l0,0l-41.4-19.4l31.6-14.8l0,0L183.6,212.9
			L183.6,212.9L183.6,212.9z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,224.7 44.3,220 44.3,209.6 54.3,214.3 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,220 42.1,219 42.1,203.7 44.3,204.8 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,219.9 44.3,215.2 44.3,204.8 54.3,209.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,229.3 54.1,224.6 54.1,214.2 64.1,218.9 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,224.6 51.9,223.6 51.9,208.3 54.1,209.3 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,224.5 54.1,219.8 54.1,209.3 64.1,214 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,233.9 63.9,229.2 63.9,218.8 73.9,223.4 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,229.2 61.7,228.2 61.7,212.9 63.9,213.9 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,229 63.9,224.4 63.9,213.9 73.9,218.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,238.5 73.7,233.8 73.7,223.3 83.7,228 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,233.8 71.5,232.8 71.5,217.5 73.7,218.5 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,233.6 73.7,228.9 73.7,218.5 83.7,223.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,243.1 83.5,238.4 83.5,227.9 93.5,232.6 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,238.4 81.3,237.3 81.3,222.1 83.5,223.1 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,238.2 83.5,233.5 83.5,223.1 93.5,227.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,247.6 93.3,242.9 93.3,232.5 103.3,237.2 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,243 91.1,241.9 91.1,226.7 93.3,227.7 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,242.8 93.3,238.1 93.3,227.7 103.3,232.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,252.2 103,247.5 103,237.1 113.1,241.8 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,247.5 100.9,246.5 100.9,231.3 103.1,232.3 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,247.4 103,242.7 103,232.3 113.1,237 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,256.8 112.8,252.1 112.8,241.7 122.9,246.4 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,252.1 110.7,251.1 110.7,235.8 112.9,236.9 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,252 112.8,247.3 112.8,236.9 122.9,241.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,261.4 122.6,256.7 122.6,246.3 132.7,251 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,256.7 120.5,255.7 120.5,240.4 122.7,241.5 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,256.6 122.6,251.9 122.6,241.4 132.7,246.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,266 132.4,261.3 132.4,250.9 142.5,255.6 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,261.3 130.2,260.3 130.2,245 132.4,246 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,261.2 132.4,256.5 132.4,246 142.5,250.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,270.6 142.2,265.9 142.2,255.5 152.3,260.1 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,265.9 140,264.9 140,249.6 142.2,250.6 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,265.7 142.2,261.1 142.2,250.6 152.3,255.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,274.8 152,270.5 152,260 161.2,264.3 					"
                    />
                    <polygon
                      className="st9"
                      points="152,270.5 149.8,269.4 149.8,254.2 152,255.2 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,269.8 152,265.6 152,255.2 160.9,259.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,275.1 159.6,274 159.6,258.8 161.8,259.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,269.4 164.1,274 164.1,263.6 174.1,258.9 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,274 161.9,275.1 161.9,259.8 164.1,258.8 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,264.5 164.1,269.2 164.1,258.8 174.1,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,264.8 173.9,269.5 173.9,259 183.9,254.3 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,269.5 171.7,270.5 171.7,255.2 173.9,254.2 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,259.9 173.9,264.6 173.9,254.2 183.9,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,260.2 183.7,264.9 183.7,254.4 193.7,249.8 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,264.9 181.5,265.9 181.5,250.6 183.7,249.6 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,255.3 183.7,260 183.7,249.6 193.7,244.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,255.6 193.5,260.3 193.5,249.9 203.5,245.2 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,260.3 191.3,261.3 191.3,246 193.5,245 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,250.8 193.5,255.4 193.5,245 203.5,240.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,251 203.3,255.7 203.3,245.3 213.3,240.6 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,255.7 201.1,256.7 201.1,241.5 203.3,240.4 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,246.2 203.3,250.9 203.3,240.4 213.3,235.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,246.4 213.1,251.1 213.1,240.7 223.1,236 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,251.1 210.9,252.1 210.9,236.9 213.1,235.8 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,241.6 213.1,246.3 213.1,235.8 223.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,241.8 222.9,246.5 222.9,236.1 232.9,231.4 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,246.5 220.7,247.5 220.7,232.3 222.9,231.3 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,237 222.9,241.7 222.9,231.3 232.9,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,237.2 232.7,241.9 232.7,231.5 242.7,226.8 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,241.9 230.5,243 230.5,227.7 232.7,226.7 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,232.4 232.7,237.1 232.7,226.7 242.7,222 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,233.2 242.5,237.3 242.5,226.9 251.4,222.7 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,237.3 240.3,238.4 240.3,223.1 242.5,222.1 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,228.5 242.5,232.5 242.5,222.1 251.1,218 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,232.8 250.1,233.8 250.1,218.5 252.3,217.5 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,261.4 122.6,256.7 122.6,246.3 132.7,251 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,256.6 122.6,251.9 122.6,241.4 132.7,246.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,266 132.4,261.3 132.4,250.9 142.5,255.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,261.3 130.2,260.3 130.2,245 132.4,246 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,261.2 132.4,256.5 132.4,246 142.5,250.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,270.6 142.2,265.9 142.2,255.5 152.3,260.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,265.9 140,264.9 140,249.6 142.2,250.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,265.7 142.2,261.1 142.2,250.6 152.3,255.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,274.8 152,270.5 152,260 161.2,264.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,270.5 149.8,269.4 149.8,254.2 152,255.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,269.8 152,265.6 152,255.2 160.9,259.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,275.1 159.6,274 159.6,258.8 161.8,259.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,269.4 164.1,274 164.1,263.6 174.1,258.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,274 161.9,275.1 161.9,259.8 164.1,258.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,264.5 164.1,269.2 164.1,258.8 174.1,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,264.8 173.9,269.5 173.9,259 183.9,254.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,269.5 171.7,270.5 171.7,255.2 173.9,254.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,259.9 173.9,264.6 173.9,254.2 183.9,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,260.2 183.7,264.9 183.7,254.4 193.7,249.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,264.9 181.5,265.9 181.5,250.6 183.7,249.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,255.3 183.7,260 183.7,249.6 193.7,244.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,255.6 193.5,260.3 193.5,249.9 203.5,245.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,260.3 191.3,261.3 191.3,246 193.5,245 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,250.8 193.5,255.4 193.5,245 203.5,240.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,251 203.3,255.7 203.3,245.3 213.3,240.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,255.7 201.1,256.7 201.1,241.5 203.3,240.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,246.2 203.3,250.9 203.3,240.4 213.3,235.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,246.5 213.1,251.1 213.1,240.7 222.7,236.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,251.1 210.9,252.1 210.9,236.9 213.1,235.8 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,241.7 213.1,246.3 213.1,235.8 222.7,231.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,246.5 220.7,247.5 220.7,232.3 222.9,231.3 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,227.7 152,227.7 122.4,241.5 122.6,241.6 161.9,260 191.4,246.2 223,231.4 223,231.4
			183.6,212.9 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,224.7 44.3,220 44.3,209.6 54.3,214.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,220 42.1,219 42.1,203.7 44.3,204.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,219.9 44.3,215.2 44.3,204.8 54.3,209.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,229.3 54.1,224.6 54.1,214.2 64.1,218.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,224.6 51.9,223.6 51.9,208.3 54.1,209.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,224.5 54.1,219.8 54.1,209.3 64.1,214 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,233.9 63.9,229.2 63.9,218.8 73.9,223.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,229.2 61.7,228.2 61.7,212.9 63.9,213.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,229 63.9,224.4 63.9,213.9 73.9,218.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,238.5 73.7,233.8 73.7,223.3 83.7,228 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,233.8 71.5,232.8 71.5,217.5 73.7,218.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,233.6 73.7,228.9 73.7,218.5 83.7,223.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,243.1 83.5,238.4 83.5,227.9 93.5,232.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,238.4 81.3,237.3 81.3,222.1 83.5,223.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,238.2 83.5,233.5 83.5,223.1 93.5,227.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,247.6 93.3,242.9 93.3,232.5 103.3,237.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,243 91.1,241.9 91.1,226.7 93.3,227.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,242.8 93.3,238.1 93.3,227.7 103.3,232.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,252.2 103,247.5 103,237.1 113.1,241.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,247.4 103,242.7 103,232.3 113.1,237 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,247.5 100.9,246.5 100.9,231.3 103.1,232.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,256.7 112.8,252.1 112.8,241.7 122.7,246.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,251.9 112.8,247.3 112.8,236.9 122.7,241.5 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,252.1 110.7,251.1 110.7,235.8 112.9,236.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,256.7 120.5,255.7 120.5,240.4 122.7,241.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,241.8 222.9,246.5 222.9,236.1 232.9,231.4 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,237 222.9,241.7 222.9,231.3 232.9,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,237.2 232.7,241.9 232.7,231.5 242.7,226.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,241.9 230.5,243 230.5,227.7 232.7,226.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,232.4 232.7,237.1 232.7,226.7 242.7,222 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,233.2 242.5,237.3 242.5,226.9 251.4,222.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,237.3 240.3,238.4 240.3,223.1 242.5,222.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,228.5 242.5,232.5 242.5,222.1 251.1,218 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,232.8 250.1,233.8 250.1,218.5 252.3,217.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,199.1 213,199.1 171.7,179.7 171.7,179.7 132.5,161.4 103.1,175.2 71.5,190 42,203.8
			81.2,222.2 122.4,241.5 152,227.7 110.7,208.3 142.3,193.5 142.3,193.5 183.6,212.9 183.7,212.9 183.6,212.9 223,231.4
			252.4,217.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,261.4 122.6,256.7 122.6,246.3 132.7,251 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,256.6 122.6,251.9 122.6,241.4 132.7,246.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,266 132.4,261.3 132.4,250.9 142.5,255.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,261.3 130.2,260.3 130.2,245 132.4,246 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,261.2 132.4,256.5 132.4,246 142.5,250.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,270.6 142.2,265.9 142.2,255.5 152.3,260.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,265.9 140,264.9 140,249.6 142.2,250.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,265.7 142.2,261.1 142.2,250.6 152.3,255.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,274.8 152,270.5 152,260 161.2,264.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,270.5 149.8,269.4 149.8,254.2 152,255.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,269.8 152,265.6 152,255.2 160.9,259.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,275.1 159.6,274 159.6,258.8 161.8,259.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,269.4 164.1,274 164.1,263.6 174.1,258.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,274 161.9,275.1 161.9,259.8 164.1,258.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,264.5 164.1,269.2 164.1,258.8 174.1,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,264.8 173.9,269.5 173.9,259 183.9,254.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,269.5 171.7,270.5 171.7,255.2 173.9,254.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,259.9 173.9,264.6 173.9,254.2 183.9,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,260.2 183.7,264.9 183.7,254.4 193.7,249.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,264.9 181.5,265.9 181.5,250.6 183.7,249.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,255.3 183.7,260 183.7,249.6 193.7,244.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,255.6 193.5,260.3 193.5,249.9 203.5,245.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,260.3 191.3,261.3 191.3,246 193.5,245 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,250.8 193.5,255.4 193.5,245 203.5,240.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,251 203.3,255.7 203.3,245.3 213.3,240.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,255.7 201.1,256.7 201.1,241.5 203.3,240.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,246.2 203.3,250.9 203.3,240.4 213.3,235.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,246.4 213.1,251.1 213.1,240.7 223.1,236 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,251.1 210.9,252.1 210.9,236.9 213.1,235.8 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,241.6 213.1,246.3 213.1,235.8 223.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,241.8 222.9,246.5 222.9,236.1 232.9,231.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,246.5 220.7,247.5 220.7,232.3 222.9,231.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,237 222.9,241.7 222.9,231.3 232.9,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,237.2 232.7,241.9 232.7,231.5 242.7,226.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,241.9 230.5,243 230.5,227.7 232.7,226.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,232.4 232.7,237.1 232.7,226.7 242.7,222 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,233.2 242.5,237.3 242.5,226.9 251.4,222.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,237.3 240.3,238.4 240.3,223.1 242.5,222.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,228.5 242.5,232.5 242.5,222.1 251.1,218 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,232.8 250.1,233.8 250.1,218.5 252.3,217.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,199.1 213,199.1 183.6,212.9 183.7,212.9 183.6,212.9 152.1,227.7 152,227.7 122.4,241.5
			122.6,241.6 161.9,260 191.4,246.2 223,231.4 223,231.4 252.4,217.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,224.7 44.3,220 44.3,209.6 54.3,214.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,220 42.1,219 42.1,203.7 44.3,204.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,219.9 44.3,215.2 44.3,204.8 54.3,209.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,229.3 54.1,224.6 54.1,214.2 64.1,218.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,224.6 51.9,223.6 51.9,208.3 54.1,209.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,224.5 54.1,219.8 54.1,209.3 64.1,214 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,233.9 63.9,229.2 63.9,218.8 73.9,223.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,229.2 61.7,228.2 61.7,212.9 63.9,213.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,229 63.9,224.4 63.9,213.9 73.9,218.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,238.5 73.7,233.8 73.7,223.3 83.7,228 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,233.8 71.5,232.8 71.5,217.5 73.7,218.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,233.6 73.7,228.9 73.7,218.5 83.7,223.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,243.1 83.5,238.4 83.5,227.9 93.5,232.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,238.4 81.3,237.3 81.3,222.1 83.5,223.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,238.2 83.5,233.5 83.5,223.1 93.5,227.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,247.6 93.3,242.9 93.3,232.5 103.3,237.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,243 91.1,241.9 91.1,226.7 93.3,227.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,242.8 93.3,238.1 93.3,227.7 103.3,232.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,252.2 103,247.5 103,237.1 113.1,241.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,247.4 103,242.7 103,232.3 113.1,237 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,247.5 100.9,246.5 100.9,231.3 103.1,232.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,256.7 112.8,252.1 112.8,241.7 122.6,246.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,251.9 112.8,247.3 112.8,236.9 122.6,241.5 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,252.1 110.7,251.1 110.7,235.8 112.9,236.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,256.7 120.5,255.7 120.5,240.4 122.7,241.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,193.5 183.6,212.9 213,199.1 171.7,179.7 171.7,179.7 132.5,161.4 103.1,175.2 71.5,190
			42,203.8 81.2,222.2 122.4,241.5 152,227.7 110.7,208.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,261.4 122.6,256.7 122.6,246.3 132.7,251 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,256.6 122.6,251.9 122.6,241.4 132.7,246.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,266 132.4,261.3 132.4,250.9 142.5,255.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,261.3 130.2,260.3 130.2,245 132.4,246 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,261.2 132.4,256.5 132.4,246 142.5,250.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,270.6 142.2,265.9 142.2,255.5 152.3,260.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,265.9 140,264.9 140,249.6 142.2,250.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,265.7 142.2,261.1 142.2,250.6 152.3,255.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,274.8 152,270.5 152,260 161.2,264.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,270.5 149.8,269.4 149.8,254.2 152,255.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,269.8 152,265.6 152,255.2 160.9,259.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,275.1 159.6,274 159.6,258.8 161.8,259.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,269.4 164.1,274 164.1,263.6 174.1,258.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,274 161.9,275.1 161.9,259.8 164.1,258.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,264.5 164.1,269.2 164.1,258.8 174.1,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,264.8 173.9,269.5 173.9,259 183.9,254.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,269.5 171.7,270.5 171.7,255.2 173.9,254.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,259.9 173.9,264.6 173.9,254.2 183.9,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,260.2 183.7,264.9 183.7,254.4 193.7,249.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,264.9 181.5,265.9 181.5,250.6 183.7,249.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,255.3 183.7,260 183.7,249.6 193.7,244.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,255.6 193.5,260.3 193.5,249.9 203.5,245.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,260.3 191.3,261.3 191.3,246 193.5,245 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,250.8 193.5,255.4 193.5,245 203.5,240.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,251 203.3,255.7 203.3,245.3 213.3,240.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,255.7 201.1,256.7 201.1,241.5 203.3,240.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,246.2 203.3,250.9 203.3,240.4 213.3,235.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,246.4 213.1,251.1 213.1,240.7 223.1,236 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,251.1 210.9,252.1 210.9,236.9 213.1,235.8 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,241.6 213.1,246.3 213.1,235.8 223.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,241.8 222.9,246.5 222.9,236.1 232.9,231.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,246.5 220.7,247.5 220.7,232.3 222.9,231.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,237 222.9,241.7 222.9,231.3 232.9,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,237.2 232.7,241.9 232.7,231.5 242.7,226.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,241.9 230.5,243 230.5,227.7 232.7,226.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,232.4 232.7,237.1 232.7,226.7 242.7,222 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,233.2 242.5,237.3 242.5,226.9 251.4,222.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,237.3 240.3,238.4 240.3,223.1 242.5,222.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,228.5 242.5,232.5 242.5,222.1 251.1,218 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,232.8 250.1,233.8 250.1,218.5 252.3,217.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,199.1 213,199.1 171.7,179.7 142.3,193.5 183.6,212.9 183.7,212.9 183.6,212.9 152.1,227.7
			152,227.7 122.4,241.5 122.6,241.6 161.9,260 191.4,246.2 223,231.4 223,231.4 252.4,217.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,224.7 44.3,220 44.3,209.6 54.3,214.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,220 42.1,219 42.1,203.7 44.3,204.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,219.9 44.3,215.2 44.3,204.8 54.3,209.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,229.3 54.1,224.6 54.1,214.2 64.1,218.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,224.6 51.9,223.6 51.9,208.3 54.1,209.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,224.5 54.1,219.8 54.1,209.3 64.1,214 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,233.9 63.9,229.2 63.9,218.8 73.9,223.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,229.2 61.7,228.2 61.7,212.9 63.9,213.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,229 63.9,224.4 63.9,213.9 73.9,218.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,238.5 73.7,233.8 73.7,223.3 83.7,228 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,233.8 71.5,232.8 71.5,217.5 73.7,218.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,233.6 73.7,228.9 73.7,218.5 83.7,223.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,243.1 83.5,238.4 83.5,227.9 93.5,232.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,238.4 81.3,237.3 81.3,222.1 83.5,223.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,238.2 83.5,233.5 83.5,223.1 93.5,227.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,247.6 93.3,242.9 93.3,232.5 103.3,237.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,243 91.1,241.9 91.1,226.7 93.3,227.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,242.8 93.3,238.1 93.3,227.7 103.3,232.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,252.2 103,247.5 103,237.1 113.1,241.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,247.4 103,242.7 103,232.3 113.1,237 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,247.5 100.9,246.5 100.9,231.3 103.1,232.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,256.7 112.8,252.1 112.8,241.7 122.6,246.3 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,251.9 112.8,247.3 112.8,236.9 122.6,241.5 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,252.1 110.7,251.1 110.7,235.8 112.9,236.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,256.7 120.5,255.7 120.5,240.4 122.7,241.5 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,208.3 142.3,193.5 142.3,193.5 171.7,179.7 171.7,179.7 132.5,161.4 103.1,175.2 71.5,190
			42,203.8 81.2,222.2 122.4,241.5 152,227.7 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={17} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,224.7 44.3,220 44.3,209.6 54.3,214.3 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,220 42.1,219 42.1,203.7 44.3,204.8 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,219.9 44.3,215.2 44.3,204.8 54.3,209.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,229.3 54.1,224.6 54.1,214.2 64.1,218.9 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,224.6 51.9,223.6 51.9,208.3 54.1,209.3 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,224.5 54.1,219.8 54.1,209.3 64.1,214 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,233.9 63.9,229.2 63.9,218.8 73.9,223.4 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,229.2 61.7,228.2 61.7,212.9 63.9,213.9 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,229 63.9,224.4 63.9,213.9 73.9,218.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,238.5 73.7,233.8 73.7,223.3 83.7,228 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,233.8 71.5,232.8 71.5,217.5 73.7,218.5 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,233.6 73.7,228.9 73.7,218.5 83.7,223.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,243.1 83.5,238.4 83.5,227.9 93.5,232.6 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,238.4 81.3,237.3 81.3,222.1 83.5,223.1 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,238.2 83.5,233.5 83.5,223.1 93.5,227.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,247.6 93.3,242.9 93.3,232.5 103.3,237.2 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,243 91.1,241.9 91.1,226.7 93.3,227.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,242.8 93.3,238.1 93.3,227.7 103.3,232.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,247.5 100.9,246.5 100.9,231.3 103.1,232.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,252.2 103,247.5 103,237.1 113.1,241.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,247.4 103,242.7 103,232.3 113.1,237 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,252.1 110.7,251.1 110.7,235.8 112.9,236.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,256.8 112.8,252.1 112.8,241.7 122.9,246.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,252 112.8,247.3 112.8,236.9 122.9,241.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,256.7 120.5,255.7 120.5,240.4 122.7,241.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,261.4 122.6,256.7 122.6,246.3 132.7,251 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,256.6 122.6,251.9 122.6,241.4 132.7,246.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,266 132.4,261.3 132.4,250.9 142.5,255.6 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,261.3 130.2,260.3 130.2,245 132.4,246 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,261.2 132.4,256.5 132.4,246 142.5,250.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,270.6 142.2,265.9 142.2,255.5 152.3,260.1 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,265.9 140,264.9 140,249.6 142.2,250.6 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,265.7 142.2,261.1 142.2,250.6 152.3,255.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,274.8 152,270.5 152,260 161.2,264.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152,270.5 149.8,269.4 149.8,254.2 152,255.2 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,269.8 152,265.6 152,255.2 160.9,259.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,275.1 159.6,274 159.6,258.8 161.8,259.8 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,269.4 164.1,274 164.1,263.6 174.1,258.9 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,274 161.9,275.1 161.9,259.8 164.1,258.8 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,264.5 164.1,269.2 164.1,258.8 174.1,254.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,264.8 173.9,269.5 173.9,259 183.9,254.3 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,269.5 171.7,270.5 171.7,255.2 173.9,254.2 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,259.9 173.9,264.6 173.9,254.2 183.9,249.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,260.2 183.7,264.9 183.7,254.4 193.7,249.8 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,264.9 181.5,265.9 181.5,250.6 183.7,249.6 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,255.3 183.7,260 183.7,249.6 193.7,244.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,255.6 193.5,260.3 193.5,249.9 203.5,245.2 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,260.3 191.3,261.3 191.3,246 193.5,245 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,250.8 193.5,255.4 193.5,245 203.5,240.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,251 203.3,255.7 203.3,245.3 213.3,240.6 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,255.7 201.1,256.7 201.1,241.5 203.3,240.4 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,246.2 203.3,250.9 203.3,240.4 213.3,235.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,246.4 213.1,251.1 213.1,240.7 223.1,236 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,251.1 210.9,252.1 210.9,236.9 213.1,235.8 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,241.6 213.1,246.3 213.1,235.8 223.1,231.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,241.8 222.9,246.5 222.9,236.1 232.9,231.4 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,246.5 220.7,247.5 220.7,232.3 222.9,231.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,237 222.9,241.7 222.9,231.3 232.9,226.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,237.2 232.7,241.9 232.7,231.5 242.7,226.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,241.9 230.5,243 230.5,227.7 232.7,226.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,232.4 232.7,237.1 232.7,226.7 242.7,222 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,233.2 242.5,237.3 242.5,226.9 251.4,222.7 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,237.3 240.3,238.4 240.3,223.1 242.5,222.1 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,228.5 242.5,232.5 242.5,222.1 251.1,218 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,232.8 250.1,233.8 250.1,218.5 252.3,217.5 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,199.1L213.1,199.1l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8L71.5,190L42,203.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,199.1z M152.1,227.7L152.1,227.7l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,227.7z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={18}
          >
            <FloorNeutral openFloors={openFloors} floor={18}>
              <polygon
                className="st1"
                points="152,210.4 183.6,195.6 183.6,195.6 183.6,195.6 142.2,176.2 142.2,176.2 110.6,191 		"
              />
              <path
                className="st16"
                d="M213,181.8L213,181.8l-41.4-19.4l0,0l-39.2-18.3L103,157.9l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,181.8z M183.6,195.6L152,210.4l0,0L110.6,191l31.6-14.8l0,0
			L183.6,195.6L183.6,195.6L183.6,195.6z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,207.4 44.3,202.7 44.3,192.3 54.3,197 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,202.7 42.1,201.7 42.1,186.4 44.3,187.5 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,202.6 44.3,197.9 44.3,187.5 54.3,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,212 54.1,207.3 54.1,196.9 64.1,201.6 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,207.3 51.9,206.3 51.9,191 54.1,192 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,207.2 54.1,202.5 54.1,192 64.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,216.6 63.9,211.9 63.9,201.5 73.9,206.1 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,211.9 61.7,210.9 61.7,195.6 63.9,196.6 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,211.7 63.9,207.1 63.9,196.6 73.9,201.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,221.2 73.7,216.5 73.7,206 83.7,210.7 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,216.5 71.5,215.5 71.5,200.2 73.7,201.2 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,216.3 73.7,211.6 73.7,201.2 83.7,205.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,225.8 83.5,221.1 83.5,210.6 93.5,215.3 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,221.1 81.3,220 81.3,204.8 83.5,205.8 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,220.9 83.5,216.2 83.5,205.8 93.5,210.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,230.3 93.3,225.6 93.3,215.2 103.3,219.9 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,225.7 91.1,224.6 91.1,209.4 93.3,210.4 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,225.5 93.3,220.8 93.3,210.4 103.3,215.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,234.9 103,230.2 103,219.8 113.1,224.5 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,230.2 100.9,229.2 100.9,214 103.1,215 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,230.1 103,225.4 103,215 113.1,219.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,239.5 112.8,234.8 112.8,224.4 122.9,229.1 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,234.8 110.7,233.8 110.7,218.5 112.9,219.6 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,234.7 112.8,230 112.8,219.6 122.9,224.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,244.1 122.6,239.4 122.6,229 132.7,233.7 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,239.4 120.5,238.4 120.5,223.1 122.7,224.2 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,239.3 122.6,234.6 122.6,224.1 132.7,228.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,248.7 132.4,244 132.4,233.6 142.5,238.3 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,244 130.2,243 130.2,227.7 132.4,228.7 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,243.9 132.4,239.2 132.4,228.7 142.5,233.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,253.3 142.2,248.6 142.2,238.2 152.3,242.8 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,248.6 140,247.6 140,232.3 142.2,233.3 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,248.4 142.2,243.8 142.2,233.3 152.3,238 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,257.5 152,253.2 152,242.7 161.2,247 					"
                    />
                    <polygon
                      className="st9"
                      points="152,253.2 149.8,252.1 149.8,236.9 152,237.9 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,252.5 152,248.3 152,237.9 160.9,242.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,257.8 159.6,256.7 159.6,241.5 161.8,242.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,252.1 164.1,256.7 164.1,246.3 174.1,241.6 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,256.7 161.9,257.8 161.9,242.5 164.1,241.5 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,247.2 164.1,251.9 164.1,241.5 174.1,236.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,247.5 173.9,252.2 173.9,241.7 183.9,237 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,252.2 171.7,253.2 171.7,237.9 173.9,236.9 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,242.6 173.9,247.3 173.9,236.9 183.9,232.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,242.9 183.7,247.6 183.7,237.1 193.7,232.5 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,247.6 181.5,248.6 181.5,233.3 183.7,232.3 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,238 183.7,242.7 183.7,232.3 193.7,227.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,238.3 193.5,243 193.5,232.6 203.5,227.9 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,243 191.3,244 191.3,228.7 193.5,227.7 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,233.5 193.5,238.1 193.5,227.7 203.5,223 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,233.7 203.3,238.4 203.3,228 213.3,223.3 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,238.4 201.1,239.4 201.1,224.2 203.3,223.1 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,228.9 203.3,233.6 203.3,223.1 213.3,218.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,229.1 213.1,233.8 213.1,223.4 223.1,218.7 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,233.8 210.9,234.8 210.9,219.6 213.1,218.5 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,224.3 213.1,229 213.1,218.5 223.1,213.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,224.5 222.9,229.2 222.9,218.8 232.9,214.1 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,229.2 220.7,230.2 220.7,215 222.9,214 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,219.7 222.9,224.4 222.9,214 232.9,209.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,219.9 232.7,224.6 232.7,214.2 242.7,209.5 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,224.6 230.5,225.7 230.5,210.4 232.7,209.4 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,215.1 232.7,219.8 232.7,209.4 242.7,204.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,215.9 242.5,220 242.5,209.6 251.4,205.4 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,220 240.3,221.1 240.3,205.8 242.5,204.8 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,211.2 242.5,215.2 242.5,204.8 251.1,200.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,215.5 250.1,216.5 250.1,201.2 252.3,200.2 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,244.1 122.6,239.4 122.6,229 132.7,233.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,239.3 122.6,234.6 122.6,224.1 132.7,228.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,248.7 132.4,244 132.4,233.6 142.5,238.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,244 130.2,243 130.2,227.7 132.4,228.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,243.9 132.4,239.2 132.4,228.7 142.5,233.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,253.3 142.2,248.6 142.2,238.2 152.3,242.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,248.6 140,247.6 140,232.3 142.2,233.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,248.4 142.2,243.8 142.2,233.3 152.3,238 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,257.5 152,253.2 152,242.7 161.2,247 					"
                    />
                    <polygon
                      className="st14"
                      points="152,253.2 149.8,252.1 149.8,236.9 152,237.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,252.5 152,248.3 152,237.9 160.9,242.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,257.8 159.6,256.7 159.6,241.5 161.8,242.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,252.1 164.1,256.7 164.1,246.3 174.1,241.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,256.7 161.9,257.8 161.9,242.5 164.1,241.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,247.2 164.1,251.9 164.1,241.5 174.1,236.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,247.5 173.9,252.2 173.9,241.7 183.9,237 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,252.2 171.7,253.2 171.7,237.9 173.9,236.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,242.6 173.9,247.3 173.9,236.9 183.9,232.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,242.9 183.7,247.6 183.7,237.1 193.7,232.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,247.6 181.5,248.6 181.5,233.3 183.7,232.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,238 183.7,242.7 183.7,232.3 193.7,227.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,238.3 193.5,243 193.5,232.6 203.5,227.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,243 191.3,244 191.3,228.7 193.5,227.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,233.5 193.5,238.1 193.5,227.7 203.5,223 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,233.7 203.3,238.4 203.3,228 213.3,223.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,238.4 201.1,239.4 201.1,224.2 203.3,223.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,228.9 203.3,233.6 203.3,223.1 213.3,218.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,229.2 213.1,233.8 213.1,223.4 222.7,218.8 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,233.8 210.9,234.8 210.9,219.6 213.1,218.5 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,224.4 213.1,229 213.1,218.5 222.7,214 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,229.2 220.7,230.2 220.7,215 222.9,214 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,210.4 152,210.4 122.4,224.2 122.6,224.3 161.9,242.7 191.4,228.9 223,214.1 223,214.1
			183.6,195.6 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,207.4 44.3,202.7 44.3,192.3 54.3,197 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,202.7 42.1,201.7 42.1,186.4 44.3,187.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,202.6 44.3,197.9 44.3,187.5 54.3,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,212 54.1,207.3 54.1,196.9 64.1,201.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,207.3 51.9,206.3 51.9,191 54.1,192 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,207.2 54.1,202.5 54.1,192 64.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,216.6 63.9,211.9 63.9,201.5 73.9,206.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,211.9 61.7,210.9 61.7,195.6 63.9,196.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,211.7 63.9,207.1 63.9,196.6 73.9,201.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,221.2 73.7,216.5 73.7,206 83.7,210.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,216.5 71.5,215.5 71.5,200.2 73.7,201.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,216.3 73.7,211.6 73.7,201.2 83.7,205.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,225.8 83.5,221.1 83.5,210.6 93.5,215.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,221.1 81.3,220 81.3,204.8 83.5,205.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,220.9 83.5,216.2 83.5,205.8 93.5,210.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,230.3 93.3,225.6 93.3,215.2 103.3,219.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,225.7 91.1,224.6 91.1,209.4 93.3,210.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,225.5 93.3,220.8 93.3,210.4 103.3,215.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,234.9 103,230.2 103,219.8 113.1,224.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,230.1 103,225.4 103,215 113.1,219.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,230.2 100.9,229.2 100.9,214 103.1,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,239.4 112.8,234.8 112.8,224.4 122.7,229 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,234.6 112.8,230 112.8,219.6 122.7,224.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,234.8 110.7,233.8 110.7,218.5 112.9,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,239.4 120.5,238.4 120.5,223.1 122.7,224.2 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,224.5 222.9,229.2 222.9,218.8 232.9,214.1 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,219.7 222.9,224.4 222.9,214 232.9,209.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,219.9 232.7,224.6 232.7,214.2 242.7,209.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,224.6 230.5,225.7 230.5,210.4 232.7,209.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,215.1 232.7,219.8 232.7,209.4 242.7,204.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,215.9 242.5,220 242.5,209.6 251.4,205.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,220 240.3,221.1 240.3,205.8 242.5,204.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,211.2 242.5,215.2 242.5,204.8 251.1,200.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,215.5 250.1,216.5 250.1,201.2 252.3,200.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,181.8 213,181.8 171.7,162.4 171.7,162.4 132.5,144.1 103.1,157.9 71.5,172.7 42,186.5
			81.2,204.9 122.4,224.2 152,210.4 110.7,191 142.3,176.2 142.3,176.2 183.6,195.6 183.7,195.6 183.6,195.6 223,214.1 252.4,200.3
					"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,244.1 122.6,239.4 122.6,229 132.7,233.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,239.3 122.6,234.6 122.6,224.1 132.7,228.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,248.7 132.4,244 132.4,233.6 142.5,238.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,244 130.2,243 130.2,227.7 132.4,228.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,243.9 132.4,239.2 132.4,228.7 142.5,233.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,253.3 142.2,248.6 142.2,238.2 152.3,242.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,248.6 140,247.6 140,232.3 142.2,233.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,248.4 142.2,243.8 142.2,233.3 152.3,238 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,257.5 152,253.2 152,242.7 161.2,247 					"
                    />
                    <polygon
                      className="st14"
                      points="152,253.2 149.8,252.1 149.8,236.9 152,237.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,252.5 152,248.3 152,237.9 160.9,242.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,257.8 159.6,256.7 159.6,241.5 161.8,242.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,252.1 164.1,256.7 164.1,246.3 174.1,241.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,256.7 161.9,257.8 161.9,242.5 164.1,241.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,247.2 164.1,251.9 164.1,241.5 174.1,236.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,247.5 173.9,252.2 173.9,241.7 183.9,237 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,252.2 171.7,253.2 171.7,237.9 173.9,236.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,242.6 173.9,247.3 173.9,236.9 183.9,232.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,242.9 183.7,247.6 183.7,237.1 193.7,232.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,247.6 181.5,248.6 181.5,233.3 183.7,232.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,238 183.7,242.7 183.7,232.3 193.7,227.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,238.3 193.5,243 193.5,232.6 203.5,227.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,243 191.3,244 191.3,228.7 193.5,227.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,233.5 193.5,238.1 193.5,227.7 203.5,223 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,233.7 203.3,238.4 203.3,228 213.3,223.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,238.4 201.1,239.4 201.1,224.2 203.3,223.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,228.9 203.3,233.6 203.3,223.1 213.3,218.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,229.1 213.1,233.8 213.1,223.4 223.1,218.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,233.8 210.9,234.8 210.9,219.6 213.1,218.5 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,224.3 213.1,229 213.1,218.5 223.1,213.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,224.5 222.9,229.2 222.9,218.8 232.9,214.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,229.2 220.7,230.2 220.7,215 222.9,214 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,219.7 222.9,224.4 222.9,214 232.9,209.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,219.9 232.7,224.6 232.7,214.2 242.7,209.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,224.6 230.5,225.7 230.5,210.4 232.7,209.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,215.1 232.7,219.8 232.7,209.4 242.7,204.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,215.9 242.5,220 242.5,209.6 251.4,205.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,220 240.3,221.1 240.3,205.8 242.5,204.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,211.2 242.5,215.2 242.5,204.8 251.1,200.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,215.5 250.1,216.5 250.1,201.2 252.3,200.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,181.8 213,181.8 183.6,195.6 183.7,195.6 183.6,195.6 152.1,210.4 152,210.4 122.4,224.2
			122.6,224.3 161.9,242.7 191.4,228.9 223,214.1 223,214.1 252.4,200.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,207.4 44.3,202.7 44.3,192.3 54.3,197 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,202.7 42.1,201.7 42.1,186.4 44.3,187.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,202.6 44.3,197.9 44.3,187.5 54.3,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,212 54.1,207.3 54.1,196.9 64.1,201.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,207.3 51.9,206.3 51.9,191 54.1,192 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,207.2 54.1,202.5 54.1,192 64.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,216.6 63.9,211.9 63.9,201.5 73.9,206.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,211.9 61.7,210.9 61.7,195.6 63.9,196.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,211.7 63.9,207.1 63.9,196.6 73.9,201.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,221.2 73.7,216.5 73.7,206 83.7,210.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,216.5 71.5,215.5 71.5,200.2 73.7,201.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,216.3 73.7,211.6 73.7,201.2 83.7,205.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,225.8 83.5,221.1 83.5,210.6 93.5,215.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,221.1 81.3,220 81.3,204.8 83.5,205.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,220.9 83.5,216.2 83.5,205.8 93.5,210.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,230.3 93.3,225.6 93.3,215.2 103.3,219.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,225.7 91.1,224.6 91.1,209.4 93.3,210.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,225.5 93.3,220.8 93.3,210.4 103.3,215.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,234.9 103,230.2 103,219.8 113.1,224.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,230.1 103,225.4 103,215 113.1,219.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,230.2 100.9,229.2 100.9,214 103.1,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,239.4 112.8,234.8 112.8,224.4 122.6,229 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,234.6 112.8,230 112.8,219.6 122.6,224.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,234.8 110.7,233.8 110.7,218.5 112.9,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,239.4 120.5,238.4 120.5,223.1 122.7,224.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,176.2 183.6,195.6 213,181.8 171.7,162.4 171.7,162.4 132.5,144.1 103.1,157.9 71.5,172.7
			42,186.5 81.2,204.9 122.4,224.2 152,210.4 110.7,191 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,244.1 122.6,239.4 122.6,229 132.7,233.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,239.3 122.6,234.6 122.6,224.1 132.7,228.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,248.7 132.4,244 132.4,233.6 142.5,238.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,244 130.2,243 130.2,227.7 132.4,228.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,243.9 132.4,239.2 132.4,228.7 142.5,233.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,253.3 142.2,248.6 142.2,238.2 152.3,242.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,248.6 140,247.6 140,232.3 142.2,233.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,248.4 142.2,243.8 142.2,233.3 152.3,238 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,257.5 152,253.2 152,242.7 161.2,247 					"
                    />
                    <polygon
                      className="st14"
                      points="152,253.2 149.8,252.1 149.8,236.9 152,237.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,252.5 152,248.3 152,237.9 160.9,242.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,257.8 159.6,256.7 159.6,241.5 161.8,242.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,252.1 164.1,256.7 164.1,246.3 174.1,241.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,256.7 161.9,257.8 161.9,242.5 164.1,241.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,247.2 164.1,251.9 164.1,241.5 174.1,236.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,247.5 173.9,252.2 173.9,241.7 183.9,237 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,252.2 171.7,253.2 171.7,237.9 173.9,236.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,242.6 173.9,247.3 173.9,236.9 183.9,232.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,242.9 183.7,247.6 183.7,237.1 193.7,232.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,247.6 181.5,248.6 181.5,233.3 183.7,232.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,238 183.7,242.7 183.7,232.3 193.7,227.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,238.3 193.5,243 193.5,232.6 203.5,227.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,243 191.3,244 191.3,228.7 193.5,227.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,233.5 193.5,238.1 193.5,227.7 203.5,223 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,233.7 203.3,238.4 203.3,228 213.3,223.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,238.4 201.1,239.4 201.1,224.2 203.3,223.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,228.9 203.3,233.6 203.3,223.1 213.3,218.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,229.1 213.1,233.8 213.1,223.4 223.1,218.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,233.8 210.9,234.8 210.9,219.6 213.1,218.5 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,224.3 213.1,229 213.1,218.5 223.1,213.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,224.5 222.9,229.2 222.9,218.8 232.9,214.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,229.2 220.7,230.2 220.7,215 222.9,214 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,219.7 222.9,224.4 222.9,214 232.9,209.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,219.9 232.7,224.6 232.7,214.2 242.7,209.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,224.6 230.5,225.7 230.5,210.4 232.7,209.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,215.1 232.7,219.8 232.7,209.4 242.7,204.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,215.9 242.5,220 242.5,209.6 251.4,205.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,220 240.3,221.1 240.3,205.8 242.5,204.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,211.2 242.5,215.2 242.5,204.8 251.1,200.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,215.5 250.1,216.5 250.1,201.2 252.3,200.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,181.8 213,181.8 171.7,162.4 142.3,176.2 183.6,195.6 183.7,195.6 183.6,195.6 152.1,210.4
			152,210.4 122.4,224.2 122.6,224.3 161.9,242.7 191.4,228.9 223,214.1 223,214.1 252.4,200.3 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,207.4 44.3,202.7 44.3,192.3 54.3,197 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,202.7 42.1,201.7 42.1,186.4 44.3,187.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,202.6 44.3,197.9 44.3,187.5 54.3,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,212 54.1,207.3 54.1,196.9 64.1,201.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,207.3 51.9,206.3 51.9,191 54.1,192 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,207.2 54.1,202.5 54.1,192 64.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,216.6 63.9,211.9 63.9,201.5 73.9,206.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,211.9 61.7,210.9 61.7,195.6 63.9,196.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,211.7 63.9,207.1 63.9,196.6 73.9,201.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,221.2 73.7,216.5 73.7,206 83.7,210.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,216.5 71.5,215.5 71.5,200.2 73.7,201.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,216.3 73.7,211.6 73.7,201.2 83.7,205.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,225.8 83.5,221.1 83.5,210.6 93.5,215.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,221.1 81.3,220 81.3,204.8 83.5,205.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,220.9 83.5,216.2 83.5,205.8 93.5,210.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,230.3 93.3,225.6 93.3,215.2 103.3,219.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,225.7 91.1,224.6 91.1,209.4 93.3,210.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,225.5 93.3,220.8 93.3,210.4 103.3,215.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,234.9 103,230.2 103,219.8 113.1,224.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,230.1 103,225.4 103,215 113.1,219.7 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,230.2 100.9,229.2 100.9,214 103.1,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,239.4 112.8,234.8 112.8,224.4 122.6,229 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,234.6 112.8,230 112.8,219.6 122.6,224.2 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,234.8 110.7,233.8 110.7,218.5 112.9,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,239.4 120.5,238.4 120.5,223.1 122.7,224.2 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,191 142.3,176.2 142.3,176.2 171.7,162.4 171.7,162.4 132.5,144.1 103.1,157.9 71.5,172.7
			42,186.5 81.2,204.9 122.4,224.2 152,210.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={18} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,207.4 44.3,202.7 44.3,192.3 54.3,197 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,202.7 42.1,201.7 42.1,186.4 44.3,187.5 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,202.6 44.3,197.9 44.3,187.5 54.3,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,212 54.1,207.3 54.1,196.9 64.1,201.6 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,207.3 51.9,206.3 51.9,191 54.1,192 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,207.2 54.1,202.5 54.1,192 64.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,216.6 63.9,211.9 63.9,201.5 73.9,206.1 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,211.9 61.7,210.9 61.7,195.6 63.9,196.6 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,211.7 63.9,207.1 63.9,196.6 73.9,201.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,221.2 73.7,216.5 73.7,206 83.7,210.7 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,216.5 71.5,215.5 71.5,200.2 73.7,201.2 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,216.3 73.7,211.6 73.7,201.2 83.7,205.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,225.8 83.5,221.1 83.5,210.6 93.5,215.3 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,221.1 81.3,220 81.3,204.8 83.5,205.8 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,220.9 83.5,216.2 83.5,205.8 93.5,210.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,230.3 93.3,225.6 93.3,215.2 103.3,219.9 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,225.7 91.1,224.6 91.1,209.4 93.3,210.4 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,225.5 93.3,220.8 93.3,210.4 103.3,215.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,230.2 100.9,229.2 100.9,214 103.1,215 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,234.9 103,230.2 103,219.8 113.1,224.5 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,230.1 103,225.4 103,215 113.1,219.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,234.8 110.7,233.8 110.7,218.5 112.9,219.6 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,239.5 112.8,234.8 112.8,224.4 122.9,229.1 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,234.7 112.8,230 112.8,219.6 122.9,224.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,239.4 120.5,238.4 120.5,223.1 122.7,224.2 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,244.1 122.6,239.4 122.6,229 132.7,233.7 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,239.3 122.6,234.6 122.6,224.1 132.7,228.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,248.7 132.4,244 132.4,233.6 142.5,238.3 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,244 130.2,243 130.2,227.7 132.4,228.7 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,243.9 132.4,239.2 132.4,228.7 142.5,233.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,253.3 142.2,248.6 142.2,238.2 152.3,242.8 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,248.6 140,247.6 140,232.3 142.2,233.3 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,248.4 142.2,243.8 142.2,233.3 152.3,238 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,257.5 152,253.2 152,242.7 161.2,247 					"
                    />
                    <polygon
                      className="st14"
                      points="152,253.2 149.8,252.1 149.8,236.9 152,237.9 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,252.5 152,248.3 152,237.9 160.9,242.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,257.8 159.6,256.7 159.6,241.5 161.8,242.5 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,252.1 164.1,256.7 164.1,246.3 174.1,241.6 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,256.7 161.9,257.8 161.9,242.5 164.1,241.5 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,247.2 164.1,251.9 164.1,241.5 174.1,236.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,247.5 173.9,252.2 173.9,241.7 183.9,237 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,252.2 171.7,253.2 171.7,237.9 173.9,236.9 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,242.6 173.9,247.3 173.9,236.9 183.9,232.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,242.9 183.7,247.6 183.7,237.1 193.7,232.5 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,247.6 181.5,248.6 181.5,233.3 183.7,232.3 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,238 183.7,242.7 183.7,232.3 193.7,227.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,238.3 193.5,243 193.5,232.6 203.5,227.9 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,243 191.3,244 191.3,228.7 193.5,227.7 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,233.5 193.5,238.1 193.5,227.7 203.5,223 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,233.7 203.3,238.4 203.3,228 213.3,223.3 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,238.4 201.1,239.4 201.1,224.2 203.3,223.1 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,228.9 203.3,233.6 203.3,223.1 213.3,218.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,229.1 213.1,233.8 213.1,223.4 223.1,218.7 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,233.8 210.9,234.8 210.9,219.6 213.1,218.5 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,224.3 213.1,229 213.1,218.5 223.1,213.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,224.5 222.9,229.2 222.9,218.8 232.9,214.1 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,229.2 220.7,230.2 220.7,215 222.9,214 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,219.7 222.9,224.4 222.9,214 232.9,209.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,219.9 232.7,224.6 232.7,214.2 242.7,209.5 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,224.6 230.5,225.7 230.5,210.4 232.7,209.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,215.1 232.7,219.8 232.7,209.4 242.7,204.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,215.9 242.5,220 242.5,209.6 251.4,205.4 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,220 240.3,221.1 240.3,205.8 242.5,204.8 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,211.2 242.5,215.2 242.5,204.8 251.1,200.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,215.5 250.1,216.5 250.1,201.2 252.3,200.2 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,181.8L213.1,181.8l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,186.5l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,181.8z M152.1,210.4L152.1,210.4L110.7,191l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,210.4z"
              />
            </Unit>
          </Floor>
          <Floor
            openFloors={openFloors}
            highlightedUnits={highlightedUnits}
            floor={19}
          >
            <FloorNeutral openFloors={openFloors} floor={19}>
              <polygon
                className="st1"
                points="152,193.2 183.6,178.4 183.6,178.4 183.6,178.4 142.2,159 142.2,159 110.6,173.8 		"
              />
              <path
                className="st16"
                d="M213,164.6L213,164.6l-41.4-19.4l0,0l-39.2-18.3L103,140.7l-31.6,14.8l-29.5,13.8l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213,164.6z M183.6,178.4L152,193.2l0,0l-41.4-19.4l31.6-14.8l0,0
			L183.6,178.4L183.6,178.4L183.6,178.4z"
              />
              <g>
                <g>
                  <g>
                    <polygon
                      className="st9"
                      points="54.3,190.2 44.3,185.5 44.3,175.1 54.3,179.8 					"
                    />
                    <polygon
                      className="st9"
                      points="44.3,185.5 42.1,184.5 42.1,169.2 44.3,170.3 					"
                    />
                    <polygon
                      className="st9"
                      points="54.3,185.4 44.3,180.7 44.3,170.3 54.3,174.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="64.1,194.8 54.1,190.1 54.1,179.7 64.1,184.4 					"
                    />
                    <polygon
                      className="st9"
                      points="54.1,190.1 51.9,189.1 51.9,173.8 54.1,174.8 					"
                    />
                    <polygon
                      className="st9"
                      points="64.1,190 54.1,185.3 54.1,174.8 64.1,179.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="73.9,199.4 63.9,194.7 63.9,184.3 73.9,188.9 					"
                    />
                    <polygon
                      className="st9"
                      points="63.9,194.7 61.7,193.7 61.7,178.4 63.9,179.4 					"
                    />
                    <polygon
                      className="st9"
                      points="73.9,194.5 63.9,189.9 63.9,179.4 73.9,184.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="83.7,204 73.7,199.3 73.7,188.8 83.7,193.5 					"
                    />
                    <polygon
                      className="st9"
                      points="73.7,199.3 71.5,198.3 71.5,183 73.7,184 					"
                    />
                    <polygon
                      className="st9"
                      points="83.7,199.1 73.7,194.4 73.7,184 83.7,188.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="93.5,208.6 83.5,203.9 83.5,193.4 93.5,198.1 					"
                    />
                    <polygon
                      className="st9"
                      points="83.5,203.9 81.3,202.8 81.3,187.6 83.5,188.6 					"
                    />
                    <polygon
                      className="st9"
                      points="93.5,203.7 83.5,199 83.5,188.6 93.5,193.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="103.3,213.1 93.3,208.4 93.3,198 103.3,202.7 					"
                    />
                    <polygon
                      className="st9"
                      points="93.3,208.5 91.1,207.4 91.1,192.2 93.3,193.2 					"
                    />
                    <polygon
                      className="st9"
                      points="103.3,208.3 93.3,203.6 93.3,193.2 103.3,197.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="113.1,217.7 103,213 103,202.6 113.1,207.3 					"
                    />
                    <polygon
                      className="st9"
                      points="103.1,213 100.9,212 100.9,196.8 103.1,197.8 					"
                    />
                    <polygon
                      className="st9"
                      points="113.1,212.9 103,208.2 103,197.8 113.1,202.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="122.9,222.3 112.8,217.6 112.8,207.2 122.9,211.9 					"
                    />
                    <polygon
                      className="st9"
                      points="112.9,217.6 110.7,216.6 110.7,201.3 112.9,202.4 					"
                    />
                    <polygon
                      className="st9"
                      points="122.9,217.5 112.8,212.8 112.8,202.4 122.9,207.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="132.7,226.9 122.6,222.2 122.6,211.8 132.7,216.5 					"
                    />
                    <polygon
                      className="st9"
                      points="122.7,222.2 120.5,221.2 120.5,205.9 122.7,207 					"
                    />
                    <polygon
                      className="st9"
                      points="132.7,222.1 122.6,217.4 122.6,206.9 132.7,211.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="142.5,231.5 132.4,226.8 132.4,216.4 142.5,221.1 					"
                    />
                    <polygon
                      className="st9"
                      points="132.4,226.8 130.2,225.8 130.2,210.5 132.4,211.5 					"
                    />
                    <polygon
                      className="st9"
                      points="142.5,226.7 132.4,222 132.4,211.5 142.5,216.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="152.3,236.1 142.2,231.4 142.2,221 152.3,225.6 					"
                    />
                    <polygon
                      className="st9"
                      points="142.2,231.4 140,230.4 140,215.1 142.2,216.1 					"
                    />
                    <polygon
                      className="st9"
                      points="152.3,231.2 142.2,226.6 142.2,216.1 152.3,220.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.2,240.3 152,236 152,225.5 161.2,229.8 					"
                    />
                    <polygon
                      className="st9"
                      points="152,236 149.8,234.9 149.8,219.7 152,220.7 					"
                    />
                    <polygon
                      className="st9"
                      points="160.9,235.3 152,231.1 152,220.7 160.9,224.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st9"
                      points="161.8,240.6 159.6,239.5 159.6,224.3 161.8,225.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st10"
                      points="174.1,234.9 164.1,239.5 164.1,229.1 174.1,224.4 					"
                    />
                    <polygon
                      className="st10"
                      points="164.1,239.5 161.9,240.6 161.9,225.3 164.1,224.3 					"
                    />
                    <polygon
                      className="st10"
                      points="174.1,230 164.1,234.7 164.1,224.3 174.1,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="183.9,230.3 173.9,235 173.9,224.5 183.9,219.8 					"
                    />
                    <polygon
                      className="st10"
                      points="173.9,235 171.7,236 171.7,220.7 173.9,219.7 					"
                    />
                    <polygon
                      className="st10"
                      points="183.9,225.4 173.9,230.1 173.9,219.7 183.9,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="193.7,225.7 183.7,230.4 183.7,219.9 193.7,215.3 					"
                    />
                    <polygon
                      className="st10"
                      points="183.7,230.4 181.5,231.4 181.5,216.1 183.7,215.1 					"
                    />
                    <polygon
                      className="st10"
                      points="193.7,220.8 183.7,225.5 183.7,215.1 193.7,210.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="203.5,221.1 193.5,225.8 193.5,215.4 203.5,210.7 					"
                    />
                    <polygon
                      className="st10"
                      points="193.5,225.8 191.3,226.8 191.3,211.5 193.5,210.5 					"
                    />
                    <polygon
                      className="st10"
                      points="203.5,216.3 193.5,220.9 193.5,210.5 203.5,205.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="213.3,216.5 203.3,221.2 203.3,210.8 213.3,206.1 					"
                    />
                    <polygon
                      className="st10"
                      points="203.3,221.2 201.1,222.2 201.1,207 203.3,205.9 					"
                    />
                    <polygon
                      className="st10"
                      points="213.3,211.7 203.3,216.4 203.3,205.9 213.3,201.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="223.1,211.9 213.1,216.6 213.1,206.2 223.1,201.5 					"
                    />
                    <polygon
                      className="st10"
                      points="213.1,216.6 210.9,217.6 210.9,202.4 213.1,201.3 					"
                    />
                    <polygon
                      className="st10"
                      points="223.1,207.1 213.1,211.8 213.1,201.3 223.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="232.9,207.3 222.9,212 222.9,201.6 232.9,196.9 					"
                    />
                    <polygon
                      className="st10"
                      points="222.9,212 220.7,213 220.7,197.8 222.9,196.8 					"
                    />
                    <polygon
                      className="st10"
                      points="232.9,202.5 222.9,207.2 222.9,196.8 232.9,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="242.7,202.7 232.7,207.4 232.7,197 242.7,192.3 					"
                    />
                    <polygon
                      className="st10"
                      points="232.7,207.4 230.5,208.5 230.5,193.2 232.7,192.2 					"
                    />
                    <polygon
                      className="st10"
                      points="242.7,197.9 232.7,202.6 232.7,192.2 242.7,187.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="251.4,198.7 242.5,202.8 242.5,192.4 251.4,188.2 					"
                    />
                    <polygon
                      className="st10"
                      points="242.5,202.8 240.3,203.9 240.3,188.6 242.5,187.6 					"
                    />
                    <polygon
                      className="st10"
                      points="251.1,194 242.5,198 242.5,187.6 251.1,183.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st10"
                      points="252.3,198.3 250.1,199.3 250.1,184 252.3,183 					"
                    />
                  </g>
                </g>
              </g>
            </FloorNeutral>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={9}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,226.9 122.6,222.2 122.6,211.8 132.7,216.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,222.1 122.6,217.4 122.6,206.9 132.7,211.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,231.5 132.4,226.8 132.4,216.4 142.5,221.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,226.8 130.2,225.8 130.2,210.5 132.4,211.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,226.7 132.4,222 132.4,211.5 142.5,216.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,236.1 142.2,231.4 142.2,221 152.3,225.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,231.4 140,230.4 140,215.1 142.2,216.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,231.2 142.2,226.6 142.2,216.1 152.3,220.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,240.3 152,236 152,225.5 161.2,229.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,236 149.8,234.9 149.8,219.7 152,220.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,235.3 152,231.1 152,220.7 160.9,224.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,240.6 159.6,239.5 159.6,224.3 161.8,225.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,234.9 164.1,239.5 164.1,229.1 174.1,224.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,239.5 161.9,240.6 161.9,225.3 164.1,224.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,230 164.1,234.7 164.1,224.3 174.1,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,230.3 173.9,235 173.9,224.5 183.9,219.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,235 171.7,236 171.7,220.7 173.9,219.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,225.4 173.9,230.1 173.9,219.7 183.9,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,225.7 183.7,230.4 183.7,219.9 193.7,215.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,230.4 181.5,231.4 181.5,216.1 183.7,215.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,220.8 183.7,225.5 183.7,215.1 193.7,210.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,221.1 193.5,225.8 193.5,215.4 203.5,210.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,225.8 191.3,226.8 191.3,211.5 193.5,210.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,216.3 193.5,220.9 193.5,210.5 203.5,205.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,216.5 203.3,221.2 203.3,210.8 213.3,206.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,221.2 201.1,222.2 201.1,207 203.3,205.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,211.7 203.3,216.4 203.3,205.9 213.3,201.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.7,212 213.1,216.6 213.1,206.2 222.7,201.6 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,216.6 210.9,217.6 210.9,202.4 213.1,201.3 					"
                    />
                    <polygon
                      className="st15"
                      points="222.7,207.2 213.1,211.8 213.1,201.3 222.7,196.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="222.9,212 220.7,213 220.7,197.8 222.9,196.8 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="152.1,193.2 152,193.2 122.4,207 122.6,207.1 161.9,225.5 191.4,211.7 223,196.9 223,196.9
			183.6,178.4 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={8}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,190.2 44.3,185.5 44.3,175.1 54.3,179.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,185.5 42.1,184.5 42.1,169.2 44.3,170.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,185.4 44.3,180.7 44.3,170.3 54.3,174.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,194.8 54.1,190.1 54.1,179.7 64.1,184.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,190.1 51.9,189.1 51.9,173.8 54.1,174.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,190 54.1,185.3 54.1,174.8 64.1,179.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,199.4 63.9,194.7 63.9,184.3 73.9,188.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,194.7 61.7,193.7 61.7,178.4 63.9,179.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,194.5 63.9,189.9 63.9,179.4 73.9,184.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,204 73.7,199.3 73.7,188.8 83.7,193.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,199.3 71.5,198.3 71.5,183 73.7,184 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,199.1 73.7,194.4 73.7,184 83.7,188.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,208.6 83.5,203.9 83.5,193.4 93.5,198.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,203.9 81.3,202.8 81.3,187.6 83.5,188.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,203.7 83.5,199 83.5,188.6 93.5,193.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,213.1 93.3,208.4 93.3,198 103.3,202.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,208.5 91.1,207.4 91.1,192.2 93.3,193.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,208.3 93.3,203.6 93.3,193.2 103.3,197.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,217.7 103,213 103,202.6 113.1,207.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,212.9 103,208.2 103,197.8 113.1,202.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,213 100.9,212 100.9,196.8 103.1,197.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,222.2 112.8,217.6 112.8,207.2 122.7,211.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.7,217.4 112.8,212.8 112.8,202.4 122.7,207 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,217.6 110.7,216.6 110.7,201.3 112.9,202.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,222.2 120.5,221.2 120.5,205.9 122.7,207 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,207.3 222.9,212 222.9,201.6 232.9,196.9 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,202.5 222.9,207.2 222.9,196.8 232.9,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,202.7 232.7,207.4 232.7,197 242.7,192.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,207.4 230.5,208.5 230.5,193.2 232.7,192.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,197.9 232.7,202.6 232.7,192.2 242.7,187.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,198.7 242.5,202.8 242.5,192.4 251.4,188.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,202.8 240.3,203.9 240.3,188.6 242.5,187.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,194 242.5,198 242.5,187.6 251.1,183.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,198.3 250.1,199.3 250.1,184 252.3,183 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,164.6 213,164.6 171.7,145.2 171.7,145.2 132.5,126.9 103.1,140.7 71.5,155.5 42,169.3
			81.2,187.7 122.4,207 152,193.2 110.7,173.8 142.3,159 142.3,159 183.6,178.4 183.7,178.4 183.6,178.4 223,196.9 252.4,183.1 		
			"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={7}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,226.9 122.6,222.2 122.6,211.8 132.7,216.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,222.1 122.6,217.4 122.6,206.9 132.7,211.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,231.5 132.4,226.8 132.4,216.4 142.5,221.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,226.8 130.2,225.8 130.2,210.5 132.4,211.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,226.7 132.4,222 132.4,211.5 142.5,216.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,236.1 142.2,231.4 142.2,221 152.3,225.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,231.4 140,230.4 140,215.1 142.2,216.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,231.2 142.2,226.6 142.2,216.1 152.3,220.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,240.3 152,236 152,225.5 161.2,229.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,236 149.8,234.9 149.8,219.7 152,220.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,235.3 152,231.1 152,220.7 160.9,224.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,240.6 159.6,239.5 159.6,224.3 161.8,225.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,234.9 164.1,239.5 164.1,229.1 174.1,224.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,239.5 161.9,240.6 161.9,225.3 164.1,224.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,230 164.1,234.7 164.1,224.3 174.1,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,230.3 173.9,235 173.9,224.5 183.9,219.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,235 171.7,236 171.7,220.7 173.9,219.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,225.4 173.9,230.1 173.9,219.7 183.9,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,225.7 183.7,230.4 183.7,219.9 193.7,215.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,230.4 181.5,231.4 181.5,216.1 183.7,215.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,220.8 183.7,225.5 183.7,215.1 193.7,210.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,221.1 193.5,225.8 193.5,215.4 203.5,210.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,225.8 191.3,226.8 191.3,211.5 193.5,210.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,216.3 193.5,220.9 193.5,210.5 203.5,205.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,216.5 203.3,221.2 203.3,210.8 213.3,206.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,221.2 201.1,222.2 201.1,207 203.3,205.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,211.7 203.3,216.4 203.3,205.9 213.3,201.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,211.9 213.1,216.6 213.1,206.2 223.1,201.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,216.6 210.9,217.6 210.9,202.4 213.1,201.3 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,207.1 213.1,211.8 213.1,201.3 223.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,207.3 222.9,212 222.9,201.6 232.9,196.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,212 220.7,213 220.7,197.8 222.9,196.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,202.5 222.9,207.2 222.9,196.8 232.9,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,202.7 232.7,207.4 232.7,197 242.7,192.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,207.4 230.5,208.5 230.5,193.2 232.7,192.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,197.9 232.7,202.6 232.7,192.2 242.7,187.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,198.7 242.5,202.8 242.5,192.4 251.4,188.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,202.8 240.3,203.9 240.3,188.6 242.5,187.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,194 242.5,198 242.5,187.6 251.1,183.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,198.3 250.1,199.3 250.1,184 252.3,183 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,164.6 213,164.6 183.6,178.4 183.7,178.4 183.6,178.4 152.1,193.2 152,193.2 122.4,207
			122.6,207.1 161.9,225.5 191.4,211.7 223,196.9 223,196.9 252.4,183.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={6}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,190.2 44.3,185.5 44.3,175.1 54.3,179.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,185.5 42.1,184.5 42.1,169.2 44.3,170.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,185.4 44.3,180.7 44.3,170.3 54.3,174.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,194.8 54.1,190.1 54.1,179.7 64.1,184.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,190.1 51.9,189.1 51.9,173.8 54.1,174.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,190 54.1,185.3 54.1,174.8 64.1,179.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,199.4 63.9,194.7 63.9,184.3 73.9,188.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,194.7 61.7,193.7 61.7,178.4 63.9,179.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,194.5 63.9,189.9 63.9,179.4 73.9,184.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,204 73.7,199.3 73.7,188.8 83.7,193.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,199.3 71.5,198.3 71.5,183 73.7,184 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,199.1 73.7,194.4 73.7,184 83.7,188.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,208.6 83.5,203.9 83.5,193.4 93.5,198.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,203.9 81.3,202.8 81.3,187.6 83.5,188.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,203.7 83.5,199 83.5,188.6 93.5,193.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,213.1 93.3,208.4 93.3,198 103.3,202.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,208.5 91.1,207.4 91.1,192.2 93.3,193.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,208.3 93.3,203.6 93.3,193.2 103.3,197.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,217.7 103,213 103,202.6 113.1,207.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,212.9 103,208.2 103,197.8 113.1,202.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,213 100.9,212 100.9,196.8 103.1,197.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,222.2 112.8,217.6 112.8,207.2 122.6,211.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,217.4 112.8,212.8 112.8,202.4 122.6,207 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,217.6 110.7,216.6 110.7,201.3 112.9,202.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,222.2 120.5,221.2 120.5,205.9 122.7,207 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="142.3,159 183.6,178.4 213,164.6 171.7,145.2 171.7,145.2 132.5,126.9 103.1,140.7 71.5,155.5
			42,169.3 81.2,187.7 122.4,207 152,193.2 110.7,173.8 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={5}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="132.7,226.9 122.6,222.2 122.6,211.8 132.7,216.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,222.1 122.6,217.4 122.6,206.9 132.7,211.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,231.5 132.4,226.8 132.4,216.4 142.5,221.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,226.8 130.2,225.8 130.2,210.5 132.4,211.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,226.7 132.4,222 132.4,211.5 142.5,216.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,236.1 142.2,231.4 142.2,221 152.3,225.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,231.4 140,230.4 140,215.1 142.2,216.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,231.2 142.2,226.6 142.2,216.1 152.3,220.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,240.3 152,236 152,225.5 161.2,229.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,236 149.8,234.9 149.8,219.7 152,220.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,235.3 152,231.1 152,220.7 160.9,224.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,240.6 159.6,239.5 159.6,224.3 161.8,225.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,234.9 164.1,239.5 164.1,229.1 174.1,224.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,239.5 161.9,240.6 161.9,225.3 164.1,224.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,230 164.1,234.7 164.1,224.3 174.1,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,230.3 173.9,235 173.9,224.5 183.9,219.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,235 171.7,236 171.7,220.7 173.9,219.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,225.4 173.9,230.1 173.9,219.7 183.9,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,225.7 183.7,230.4 183.7,219.9 193.7,215.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,230.4 181.5,231.4 181.5,216.1 183.7,215.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,220.8 183.7,225.5 183.7,215.1 193.7,210.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,221.1 193.5,225.8 193.5,215.4 203.5,210.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,225.8 191.3,226.8 191.3,211.5 193.5,210.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,216.3 193.5,220.9 193.5,210.5 203.5,205.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,216.5 203.3,221.2 203.3,210.8 213.3,206.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,221.2 201.1,222.2 201.1,207 203.3,205.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,211.7 203.3,216.4 203.3,205.9 213.3,201.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,211.9 213.1,216.6 213.1,206.2 223.1,201.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,216.6 210.9,217.6 210.9,202.4 213.1,201.3 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,207.1 213.1,211.8 213.1,201.3 223.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,207.3 222.9,212 222.9,201.6 232.9,196.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,212 220.7,213 220.7,197.8 222.9,196.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,202.5 222.9,207.2 222.9,196.8 232.9,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,202.7 232.7,207.4 232.7,197 242.7,192.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,207.4 230.5,208.5 230.5,193.2 232.7,192.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,197.9 232.7,202.6 232.7,192.2 242.7,187.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,198.7 242.5,202.8 242.5,192.4 251.4,188.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,202.8 240.3,203.9 240.3,188.6 242.5,187.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,194 242.5,198 242.5,187.6 251.1,183.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,198.3 250.1,199.3 250.1,184 252.3,183 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="213.1,164.6 213,164.6 171.7,145.2 142.3,159 183.6,178.4 183.7,178.4 183.6,178.4 152.1,193.2
			152,193.2 122.4,207 122.6,207.1 161.9,225.5 191.4,211.7 223,196.9 223,196.9 252.4,183.1 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={4}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,190.2 44.3,185.5 44.3,175.1 54.3,179.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,185.5 42.1,184.5 42.1,169.2 44.3,170.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,185.4 44.3,180.7 44.3,170.3 54.3,174.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,194.8 54.1,190.1 54.1,179.7 64.1,184.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,190.1 51.9,189.1 51.9,173.8 54.1,174.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,190 54.1,185.3 54.1,174.8 64.1,179.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,199.4 63.9,194.7 63.9,184.3 73.9,188.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,194.7 61.7,193.7 61.7,178.4 63.9,179.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,194.5 63.9,189.9 63.9,179.4 73.9,184.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,204 73.7,199.3 73.7,188.8 83.7,193.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,199.3 71.5,198.3 71.5,183 73.7,184 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,199.1 73.7,194.4 73.7,184 83.7,188.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,208.6 83.5,203.9 83.5,193.4 93.5,198.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,203.9 81.3,202.8 81.3,187.6 83.5,188.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,203.7 83.5,199 83.5,188.6 93.5,193.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,213.1 93.3,208.4 93.3,198 103.3,202.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,208.5 91.1,207.4 91.1,192.2 93.3,193.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,208.3 93.3,203.6 93.3,193.2 103.3,197.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="113.1,217.7 103,213 103,202.6 113.1,207.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,212.9 103,208.2 103,197.8 113.1,202.5 					"
                    />
                    <polygon
                      className="st14"
                      points="103.1,213 100.9,212 100.9,196.8 103.1,197.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.6,222.2 112.8,217.6 112.8,207.2 122.6,211.8 					"
                    />
                    <polygon
                      className="st14"
                      points="122.6,217.4 112.8,212.8 112.8,202.4 122.6,207 					"
                    />
                    <polygon
                      className="st14"
                      points="112.9,217.6 110.7,216.6 110.7,201.3 112.9,202.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,222.2 120.5,221.2 120.5,205.9 122.7,207 					"
                    />
                  </g>
                </g>
              </g>
              <polygon
                className="st13"
                points="110.7,173.8 142.3,159 142.3,159 171.7,145.2 171.7,145.2 132.5,126.9 103.1,140.7 71.5,155.5
			42,169.3 81.2,187.7 122.4,207 152,193.2 		"
              />
            </Unit>
            <Unit highlightedUnits={highlightedUnits} floor={19} unit={3}>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st14"
                      points="54.3,190.2 44.3,185.5 44.3,175.1 54.3,179.8 					"
                    />
                    <polygon
                      className="st14"
                      points="44.3,185.5 42.1,184.5 42.1,169.2 44.3,170.3 					"
                    />
                    <polygon
                      className="st14"
                      points="54.3,185.4 44.3,180.7 44.3,170.3 54.3,174.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="64.1,194.8 54.1,190.1 54.1,179.7 64.1,184.4 					"
                    />
                    <polygon
                      className="st14"
                      points="54.1,190.1 51.9,189.1 51.9,173.8 54.1,174.8 					"
                    />
                    <polygon
                      className="st14"
                      points="64.1,190 54.1,185.3 54.1,174.8 64.1,179.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="73.9,199.4 63.9,194.7 63.9,184.3 73.9,188.9 					"
                    />
                    <polygon
                      className="st14"
                      points="63.9,194.7 61.7,193.7 61.7,178.4 63.9,179.4 					"
                    />
                    <polygon
                      className="st14"
                      points="73.9,194.5 63.9,189.9 63.9,179.4 73.9,184.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="83.7,204 73.7,199.3 73.7,188.8 83.7,193.5 					"
                    />
                    <polygon
                      className="st14"
                      points="73.7,199.3 71.5,198.3 71.5,183 73.7,184 					"
                    />
                    <polygon
                      className="st14"
                      points="83.7,199.1 73.7,194.4 73.7,184 83.7,188.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="93.5,208.6 83.5,203.9 83.5,193.4 93.5,198.1 					"
                    />
                    <polygon
                      className="st14"
                      points="83.5,203.9 81.3,202.8 81.3,187.6 83.5,188.6 					"
                    />
                    <polygon
                      className="st14"
                      points="93.5,203.7 83.5,199 83.5,188.6 93.5,193.3 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.3,213.1 93.3,208.4 93.3,198 103.3,202.7 					"
                    />
                    <polygon
                      className="st14"
                      points="93.3,208.5 91.1,207.4 91.1,192.2 93.3,193.2 					"
                    />
                    <polygon
                      className="st14"
                      points="103.3,208.3 93.3,203.6 93.3,193.2 103.3,197.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="103.1,213 100.9,212 100.9,196.8 103.1,197.8 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,217.7 103,213 103,202.6 113.1,207.3 					"
                    />
                    <polygon
                      className="st14"
                      points="113.1,212.9 103,208.2 103,197.8 113.1,202.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="112.9,217.6 110.7,216.6 110.7,201.3 112.9,202.4 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,222.3 112.8,217.6 112.8,207.2 122.9,211.9 					"
                    />
                    <polygon
                      className="st14"
                      points="122.9,217.5 112.8,212.8 112.8,202.4 122.9,207.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="122.7,222.2 120.5,221.2 120.5,205.9 122.7,207 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,226.9 122.6,222.2 122.6,211.8 132.7,216.5 					"
                    />
                    <polygon
                      className="st14"
                      points="132.7,222.1 122.6,217.4 122.6,206.9 132.7,211.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="142.5,231.5 132.4,226.8 132.4,216.4 142.5,221.1 					"
                    />
                    <polygon
                      className="st14"
                      points="132.4,226.8 130.2,225.8 130.2,210.5 132.4,211.5 					"
                    />
                    <polygon
                      className="st14"
                      points="142.5,226.7 132.4,222 132.4,211.5 142.5,216.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="152.3,236.1 142.2,231.4 142.2,221 152.3,225.6 					"
                    />
                    <polygon
                      className="st14"
                      points="142.2,231.4 140,230.4 140,215.1 142.2,216.1 					"
                    />
                    <polygon
                      className="st14"
                      points="152.3,231.2 142.2,226.6 142.2,216.1 152.3,220.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.2,240.3 152,236 152,225.5 161.2,229.8 					"
                    />
                    <polygon
                      className="st14"
                      points="152,236 149.8,234.9 149.8,219.7 152,220.7 					"
                    />
                    <polygon
                      className="st14"
                      points="160.9,235.3 152,231.1 152,220.7 160.9,224.9 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st14"
                      points="161.8,240.6 159.6,239.5 159.6,224.3 161.8,225.3 					"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      className="st15"
                      points="174.1,234.9 164.1,239.5 164.1,229.1 174.1,224.4 					"
                    />
                    <polygon
                      className="st15"
                      points="164.1,239.5 161.9,240.6 161.9,225.3 164.1,224.3 					"
                    />
                    <polygon
                      className="st15"
                      points="174.1,230 164.1,234.7 164.1,224.3 174.1,219.6 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="183.9,230.3 173.9,235 173.9,224.5 183.9,219.8 					"
                    />
                    <polygon
                      className="st15"
                      points="173.9,235 171.7,236 171.7,220.7 173.9,219.7 					"
                    />
                    <polygon
                      className="st15"
                      points="183.9,225.4 173.9,230.1 173.9,219.7 183.9,215 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="193.7,225.7 183.7,230.4 183.7,219.9 193.7,215.3 					"
                    />
                    <polygon
                      className="st15"
                      points="183.7,230.4 181.5,231.4 181.5,216.1 183.7,215.1 					"
                    />
                    <polygon
                      className="st15"
                      points="193.7,220.8 183.7,225.5 183.7,215.1 193.7,210.4 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="203.5,221.1 193.5,225.8 193.5,215.4 203.5,210.7 					"
                    />
                    <polygon
                      className="st15"
                      points="193.5,225.8 191.3,226.8 191.3,211.5 193.5,210.5 					"
                    />
                    <polygon
                      className="st15"
                      points="203.5,216.3 193.5,220.9 193.5,210.5 203.5,205.8 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="213.3,216.5 203.3,221.2 203.3,210.8 213.3,206.1 					"
                    />
                    <polygon
                      className="st15"
                      points="203.3,221.2 201.1,222.2 201.1,207 203.3,205.9 					"
                    />
                    <polygon
                      className="st15"
                      points="213.3,211.7 203.3,216.4 203.3,205.9 213.3,201.2 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="223.1,211.9 213.1,216.6 213.1,206.2 223.1,201.5 					"
                    />
                    <polygon
                      className="st15"
                      points="213.1,216.6 210.9,217.6 210.9,202.4 213.1,201.3 					"
                    />
                    <polygon
                      className="st15"
                      points="223.1,207.1 213.1,211.8 213.1,201.3 223.1,196.7 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="232.9,207.3 222.9,212 222.9,201.6 232.9,196.9 					"
                    />
                    <polygon
                      className="st15"
                      points="222.9,212 220.7,213 220.7,197.8 222.9,196.8 					"
                    />
                    <polygon
                      className="st15"
                      points="232.9,202.5 222.9,207.2 222.9,196.8 232.9,192.1 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="242.7,202.7 232.7,207.4 232.7,197 242.7,192.3 					"
                    />
                    <polygon
                      className="st15"
                      points="232.7,207.4 230.5,208.5 230.5,193.2 232.7,192.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.7,197.9 232.7,202.6 232.7,192.2 242.7,187.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="251.4,198.7 242.5,202.8 242.5,192.4 251.4,188.2 					"
                    />
                    <polygon
                      className="st15"
                      points="242.5,202.8 240.3,203.9 240.3,188.6 242.5,187.6 					"
                    />
                    <polygon
                      className="st15"
                      points="251.1,194 242.5,198 242.5,187.6 251.1,183.5 					"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st15"
                      points="252.3,198.3 250.1,199.3 250.1,184 252.3,183 					"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st13"
                d="M213.1,164.6L213.1,164.6l-41.4-19.4l0,0l-39.2-18.3l-29.4,13.8l-31.6,14.8L42,169.3l39.2,18.4l41.3,19.3
			l0.1,0.1l39.3,18.4l29.5-13.8l31.6-14.8l0,0l29.4-13.8L213.1,164.6z M152.1,193.2L152.1,193.2l-41.4-19.4l31.6-14.8l0,0l41.3,19.4
			l0,0h-0.1L152.1,193.2z"
              />
            </Unit>
            <g id="fensterachsen_2_">
              <g>
                <line className="st0" x1="48" y1="172.3" x2="48" y2="178.6" />
                <line
                  className="st0"
                  x1="57.8"
                  y1="176.8"
                  x2="57.8"
                  y2="183.2"
                />
                <line
                  className="st0"
                  x1="67.6"
                  y1="181.4"
                  x2="67.6"
                  y2="187.8"
                />
                <line className="st0" x1="77.4" y1="186" x2="77.4" y2="192.4" />
                <line className="st0" x1="87.2" y1="190.6" x2="87.2" y2="197" />
                <line className="st0" x1="97" y1="195.2" x2="97" y2="201.6" />
                <line
                  className="st0"
                  x1="106.8"
                  y1="199.8"
                  x2="106.8"
                  y2="206.1"
                />
                <line
                  className="st0"
                  x1="116.6"
                  y1="204.4"
                  x2="116.6"
                  y2="210.7"
                />
                <line
                  className="st0"
                  x1="126.4"
                  y1="208.9"
                  x2="126.4"
                  y2="215.3"
                />
                <line
                  className="st0"
                  x1="136.2"
                  y1="213.5"
                  x2="136.2"
                  y2="219.9"
                />
                <line className="st0" x1="146" y1="218.1" x2="146" y2="224.5" />
                <line
                  className="st0"
                  x1="155.8"
                  y1="222.7"
                  x2="155.8"
                  y2="229.1"
                />
                <line className="st0" x1="48" y1="178.6" x2="48" y2="184.9" />
                <line
                  className="st0"
                  x1="57.8"
                  y1="183.1"
                  x2="57.8"
                  y2="189.5"
                />
                <line
                  className="st0"
                  x1="67.6"
                  y1="187.7"
                  x2="67.6"
                  y2="194.1"
                />
                <line
                  className="st0"
                  x1="77.4"
                  y1="192.3"
                  x2="77.4"
                  y2="198.7"
                />
                <line
                  className="st0"
                  x1="87.2"
                  y1="196.9"
                  x2="87.2"
                  y2="203.3"
                />
                <line className="st0" x1="97" y1="201.5" x2="97" y2="207.8" />
                <line
                  className="st0"
                  x1="106.8"
                  y1="206.1"
                  x2="106.8"
                  y2="212.4"
                />
                <line
                  className="st0"
                  x1="116.6"
                  y1="210.7"
                  x2="116.6"
                  y2="217"
                />
                <line
                  className="st0"
                  x1="126.4"
                  y1="215.2"
                  x2="126.4"
                  y2="221.6"
                />
                <line
                  className="st0"
                  x1="136.2"
                  y1="219.8"
                  x2="136.2"
                  y2="226.2"
                />
                <line className="st0" x1="146" y1="224.4" x2="146" y2="230.8" />
                <line
                  className="st0"
                  x1="155.8"
                  y1="229"
                  x2="155.8"
                  y2="235.4"
                />
                <line className="st0" x1="48" y1="180" x2="48" y2="186.4" />
                <line
                  className="st0"
                  x1="57.8"
                  y1="184.6"
                  x2="57.8"
                  y2="190.9"
                />
                <line
                  className="st0"
                  x1="67.6"
                  y1="189.2"
                  x2="67.6"
                  y2="195.5"
                />
                <line
                  className="st0"
                  x1="77.4"
                  y1="193.8"
                  x2="77.4"
                  y2="200.1"
                />
                <line
                  className="st0"
                  x1="87.2"
                  y1="198.3"
                  x2="87.2"
                  y2="204.7"
                />
                <line className="st0" x1="97" y1="202.9" x2="97" y2="209.3" />
                <line
                  className="st0"
                  x1="106.8"
                  y1="207.5"
                  x2="106.8"
                  y2="213.9"
                />
                <line
                  className="st0"
                  x1="116.6"
                  y1="212.1"
                  x2="116.6"
                  y2="218.5"
                />
                <line
                  className="st0"
                  x1="126.4"
                  y1="216.7"
                  x2="126.4"
                  y2="223"
                />
                <line
                  className="st0"
                  x1="136.2"
                  y1="221.3"
                  x2="136.2"
                  y2="227.6"
                />
                <line className="st0" x1="146" y1="225.8" x2="146" y2="232.2" />
                <line
                  className="st0"
                  x1="155.8"
                  y1="230.4"
                  x2="155.8"
                  y2="236.8"
                />
                <line className="st0" x1="48" y1="181.1" x2="48" y2="187.5" />
                <line
                  className="st0"
                  x1="57.8"
                  y1="185.7"
                  x2="57.8"
                  y2="192.1"
                />
                <line
                  className="st0"
                  x1="67.6"
                  y1="190.3"
                  x2="67.6"
                  y2="196.6"
                />
                <line
                  className="st0"
                  x1="77.4"
                  y1="194.9"
                  x2="77.4"
                  y2="201.2"
                />
                <line
                  className="st0"
                  x1="87.2"
                  y1="199.5"
                  x2="87.2"
                  y2="205.8"
                />
                <line className="st0" x1="97" y1="204" x2="97" y2="210.4" />
                <line
                  className="st0"
                  x1="106.8"
                  y1="208.6"
                  x2="106.8"
                  y2="215"
                />
                <line
                  className="st0"
                  x1="116.6"
                  y1="213.2"
                  x2="116.6"
                  y2="219.6"
                />
                <line
                  className="st0"
                  x1="126.4"
                  y1="217.8"
                  x2="126.4"
                  y2="224.2"
                />
                <line
                  className="st0"
                  x1="136.2"
                  y1="222.4"
                  x2="136.2"
                  y2="228.7"
                />
                <line className="st0" x1="146" y1="227" x2="146" y2="233.3" />
                <line
                  className="st0"
                  x1="155.8"
                  y1="231.5"
                  x2="155.8"
                  y2="237.9"
                />
              </g>
              <g>
                <line
                  className="st0"
                  x1="246.2"
                  y1="186"
                  x2="246.2"
                  y2="192.4"
                />
                <line
                  className="st0"
                  x1="236.4"
                  y1="190.6"
                  x2="236.4"
                  y2="197"
                />
                <line
                  className="st0"
                  x1="226.6"
                  y1="195.2"
                  x2="226.6"
                  y2="201.6"
                />
                <line
                  className="st0"
                  x1="216.8"
                  y1="199.8"
                  x2="216.8"
                  y2="206.1"
                />
                <line className="st0" x1="207" y1="204.4" x2="207" y2="210.7" />
                <line
                  className="st0"
                  x1="197.2"
                  y1="208.9"
                  x2="197.2"
                  y2="215.3"
                />
                <line
                  className="st0"
                  x1="187.4"
                  y1="213.5"
                  x2="187.4"
                  y2="219.9"
                />
                <line
                  className="st0"
                  x1="177.6"
                  y1="218.1"
                  x2="177.6"
                  y2="224.5"
                />
                <line
                  className="st0"
                  x1="167.8"
                  y1="222.7"
                  x2="167.8"
                  y2="229.1"
                />
                <line
                  className="st0"
                  x1="246.2"
                  y1="192.3"
                  x2="246.2"
                  y2="198.7"
                />
                <line
                  className="st0"
                  x1="236.4"
                  y1="196.9"
                  x2="236.4"
                  y2="203.3"
                />
                <line
                  className="st0"
                  x1="226.6"
                  y1="201.5"
                  x2="226.6"
                  y2="207.8"
                />
                <line
                  className="st0"
                  x1="216.8"
                  y1="206.1"
                  x2="216.8"
                  y2="212.4"
                />
                <line className="st0" x1="207" y1="210.7" x2="207" y2="217" />
                <line
                  className="st0"
                  x1="197.2"
                  y1="215.2"
                  x2="197.2"
                  y2="221.6"
                />
                <line
                  className="st0"
                  x1="187.4"
                  y1="219.8"
                  x2="187.4"
                  y2="226.2"
                />
                <line
                  className="st0"
                  x1="177.6"
                  y1="224.4"
                  x2="177.6"
                  y2="230.8"
                />
                <line
                  className="st0"
                  x1="167.8"
                  y1="229"
                  x2="167.8"
                  y2="235.4"
                />
                <line
                  className="st0"
                  x1="246.2"
                  y1="193.8"
                  x2="246.2"
                  y2="200.1"
                />
                <line
                  className="st0"
                  x1="236.4"
                  y1="198.3"
                  x2="236.4"
                  y2="204.7"
                />
                <line
                  className="st0"
                  x1="226.6"
                  y1="202.9"
                  x2="226.6"
                  y2="209.3"
                />
                <line
                  className="st0"
                  x1="216.8"
                  y1="207.5"
                  x2="216.8"
                  y2="213.9"
                />
                <line className="st0" x1="207" y1="212.1" x2="207" y2="218.5" />
                <line
                  className="st0"
                  x1="197.2"
                  y1="216.7"
                  x2="197.2"
                  y2="223"
                />
                <line
                  className="st0"
                  x1="187.4"
                  y1="221.3"
                  x2="187.4"
                  y2="227.6"
                />
                <line
                  className="st0"
                  x1="177.6"
                  y1="225.8"
                  x2="177.6"
                  y2="232.2"
                />
                <line
                  className="st0"
                  x1="167.8"
                  y1="230.4"
                  x2="167.8"
                  y2="236.8"
                />
                <line
                  className="st0"
                  x1="246.2"
                  y1="194.9"
                  x2="246.2"
                  y2="201.2"
                />
                <line
                  className="st0"
                  x1="236.4"
                  y1="199.5"
                  x2="236.4"
                  y2="205.8"
                />
                <line
                  className="st0"
                  x1="226.6"
                  y1="204"
                  x2="226.6"
                  y2="210.4"
                />
                <line
                  className="st0"
                  x1="216.8"
                  y1="208.6"
                  x2="216.8"
                  y2="215"
                />
                <line className="st0" x1="207" y1="213.2" x2="207" y2="219.6" />
                <line
                  className="st0"
                  x1="197.2"
                  y1="217.8"
                  x2="197.2"
                  y2="224.2"
                />
                <line
                  className="st0"
                  x1="187.4"
                  y1="222.4"
                  x2="187.4"
                  y2="228.7"
                />
                <line
                  className="st0"
                  x1="177.6"
                  y1="227"
                  x2="177.6"
                  y2="233.3"
                />
                <line
                  className="st0"
                  x1="167.8"
                  y1="231.5"
                  x2="167.8"
                  y2="237.9"
                />
              </g>
            </g>
          </Floor>
          <g id="Haus_rechts">
            <polygon
              id="NEUER_OBEN_2_"
              className="st4"
              points="298.1,435.1 279.6,426.4 279.6,443.8 	"
            />
            <g>
              <polygon
                id="NEUER_OBEN"
                className="st4"
                points="205.5,581.5 264.2,609 264.2,506 205.5,478.5 		"
              />
              <polygon
                className="st4"
                points="445.1,524.3 264.2,609 264.2,506 445.1,421.3 		"
              />
              <polygon
                className="st4"
                points="298.1,435.1 268.6,448.9 237.1,463.7 207.6,477.5 207.6,477.5 205.5,478.5 225,487.6 264.1,506
			293.6,492.2 325.1,477.4 354.6,463.6 354.6,463.6 384.1,449.8 415.6,435 445.1,421.2 406,402.8 368,385 341.9,397.2 307,413.5
			279.6,426.4 		"
              />
            </g>
            <g>
              <polygon
                id="NEUER_OBEN_1_"
                className="st17"
                points="205.4,581.6 264.2,609.1 264.2,506.1 205.4,478.6 		"
              />
              <polygon
                className="st17"
                points="445.1,524.4 264.2,609.1 264.2,506.1 445.1,421.4 		"
              />
              <polygon
                className="st17"
                points="298.1,435.2 268.6,449 237,463.8 207.6,477.6 207.6,477.6 205.4,478.6 224.9,487.7 264.1,506.1
			293.5,492.3 325.1,477.5 354.6,463.7 354.6,463.7 384,449.9 415.6,435.1 445.1,421.3 405.9,402.9 367.9,385.1 341.8,397.3
			306.9,413.6 279.5,426.5 		"
              />
            </g>
            <line className="st6" x1="279.8" y1="426.5" x2="279.8" y2="443.6" />
          </g>
        </g>
      </svg>
    );

    return (
      <figure className="image sticky-rendering" style={{ height: "620px" }}>
        <div className="fit-container">{svg}</div>
      </figure>
    );
  }
}

export default Isometrie;
