import React, { Fragment, Component } from "react";
import Slider from "rc-slider";
import Image from "../../Image";
import Translate from "../../../../lib/App/common/translator/components/translate";

export function variationenSwitch(variation, components, isReadOnly) {
  const { ReadOnlyVariation, Variation } = components;
  if (variation.classes.indexOf("read_only") > -1 || isReadOnly) {
    return ReadOnlyVariation;
  } else {
    return Variation;
  }
}

const VariationenSwitch = props => (
  <ul className="group" style={{ display: "flex", flexDirection: "column" }}>
    {props.variationen.map(item => {
      const VariationComponent = props.switches.variationenSwitch(
        item,
        props.components,
        props.isReadOnly
      );

      return (
        <VariationComponent
          key={item.variationId}
          {...item}
          handleVariationChange={props.handleVariationChange}
        />
      );
    })}
  </ul>
);

export default VariationenSwitch;

export const Variation = props => {
  const aufpreisBeschreibung =
    props.aufpreisBeschreibung !== undefined ? (
      <Fragment>
        <span className="costs-additional">
          <Translate
            id={"variationen/" + props.variationCode + "/aufpreisBeschreibung"}
          >
            {props.aufpreisBeschreibung}
          </Translate>
        </span>
        <br />
      </Fragment>
    ) : null;
  const beschreibung =
    props.beschreibung !== undefined ? (
      <Fragment>
        <Translate id={"variationen/" + props.variationCode + "/beschreibung"}>
          {props.beschreibung}
        </Translate>
        <br />
      </Fragment>
    ) : null;
  const image = props.imagePath ? (
    <Image
      src={props.imagePath}
      alt={"IMAGE " + props.name}
      height="1000w"
      ratio="r1.6"
    />
  ) : null;
  const { action, disabled } = props.isReadOnly
    ? { action: () => {}, disabled: " disabled" }
    : { action: () => props.handleVariationChange(props), disabled: "" };

  const translateName = (
    <Translate id={"variationen/" + props.variationCode + "/name"}>
      {props.name}
    </Translate>
  );

  return (
    <li className="group">
      <label
        className="label"
        htmlFor={"bk-option-" + props.variationId}
        onClick={action}
      >
        <input
          type="radio"
          name={"bk_option-" + props.variationId}
          id={"bk-option-" + props.variationId}
          onChange={() => {}}
          checked={props.selected}
          disabled={props.isReadOnly}
        />
        <span>
          <span className={"radio-check" + disabled} />
          {translateName}
          <br />
          <small>
            {beschreibung}
            {aufpreisBeschreibung}
          </small>
        </span>
      </label>
    </li>
  );
};

export const ReadOnlyVariation = props => <Variation {...props} isReadOnly />;

ReadOnlyVariation.defaultProps = { isReadOnly: true };

export const BooleanVariation = props => {
  const name =
    props.name === "1" ? "Ja" : props.name === "0" ? "Nein" : props.name;
  const aufpreisBeschreibung =
    props.aufpreisBeschreibung !== undefined ? (
      <small className="costs-additional">
        <Translate
          id={"variationen/" + props.variationCode + "/aufpreisbeschreibung"}
        >
          {props.aufpreisBeschreibung}
        </Translate>
      </small>
    ) : null;
  const { action, disabled } = props.isReadOnly
    ? { action: () => {}, disabled: " disabled" }
    : { action: () => props.handleVariationChange(props), disabled: "" };

  // Name of variant (Basic, Business, etc...)
  const translateName = (
    <Translate id={"variationen/" + props.variationCode + "/name"}>
      {name}
    </Translate>
  );
  const translateDesc = props.beschreibung ? (
    <Translate id={"variationen/" + props.variationCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <li className="group">
      <label
        className="label"
        htmlFor={"bk-options-" + props.variationId}
        onClick={action}
      >
        <input
          type="radio"
          name={"bk-options-" + props.variationId}
          id={"bk-options-" + props.variationId}
          onChange={() => {}}
          checked={props.selected}
          disabled={props.isReadOnly}
        />
        <span>
          <span className={"radio-check" + disabled} />
          {translateName}
          {aufpreisBeschreibung}
        </span>
        <span>
          <small>{translateDesc}</small>
        </span>
      </label>
    </li>
  );
};
export class Debounce extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      hasError: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAfterChange = this.handleAfterChange.bind(this);
  }

  checkInput(pattern, value) {
    const replacedValue = value.replace(/[\r\n]/g, " ");
    const regExp = new RegExp(pattern);
    return regExp.test(replacedValue);
  }

  debouncedSave(value, delay, type) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.handleAfterChange(value, type), delay);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({ value: nextProps.value }));
  }

  // override specific
  handleChange(value) {}

  handleAfterChange(value, type) {
    if (this.state.value !== this.props.value) {
      this.props.handleVariationChange(this.props, value, type);
    }
  }

  render() {
    return <div>debounce</div>;
  }
}

export class SliderVariation extends Debounce {
  handleChange(inputValue) {
    const number = Number(inputValue);
    if (isNaN(number)) {
      return;
    }

    const round = number < this.state.value ? Math.floor : Math.ceil;
    const value = Math.min(
      this.props.max,
      Math.max(
        this.props.min,
        this.props.min +
          round((number - this.props.min) / this.props.step) * this.props.step
      )
    );
    this.setState(state => ({ value }));
    this.debouncedSave(value, 500, "number");
  }

  render() {
    const { min, max, step } = this.props;
    let marks = {};
    const maxMarkers = 8; // set maxMarkers to max - 2 because min and max
    const markerStep = Math.max(Math.floor((max - min) / maxMarkers), step);
    for (let i = min; i < max; i += markerStep) {
      marks[i] = i;
    }
    marks[max] = max;
    const aufpreisBeschreibung = this.props.aufpreisBeschreibung ? (
      <React.Fragment>
        <br />
        <span className="costs-additional" style={{ textTransform: "none" }}>
          <Translate
            id={"optionen/" + this.props.optionCode + "/aufpreisBeschreibung"}
          >
            {this.props.aufpreisBeschreibung}
          </Translate>
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <br />
        <span className="costs-additional" style={{ textTransform: "none" }}>
          <Translate
            id={"optionen/" + this.props.optionCode + "/aufpreisBeschreibung"}
          >
            {this.props.aufpreisBeschreibung}
          </Translate>
        </span>
      </React.Fragment>
    );
    const { action, actionInput } = this.props.isReadOnly
      ? { action: () => {}, actionInput: () => {} }
      : {
          action: this.handleChange,
          actionInput: event => this.handleChange(event.target.value)
        };

    const translateName = (
      <Translate id={"optionen/" + this.props.optionCode + "/name"}>
        {this.props.name}
      </Translate>
    );

    return (
      <div className="group">
        {max && max !== 0 ? (
          <>
            <label htmlFor={"bk-options-" + this.props.numberId}>
              <span>{translateName}</span>
              {aufpreisBeschreibung}
            </label>

            <Slider
              name={"slider-" + this.props.numberId}
              step={step}
              min={min}
              max={max}
              value={this.state.value}
              onChange={action}
              marks={marks}
              disabled={this.props.isReadOnly}
            />
            <input
              type="number"
              value={this.state.value}
              onChange={actionInput}
              onFocus={event => event.target.select()}
              id={"bk-options-" + this.props.numberId}
              disabled={this.props.isReadOnly}
            />
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export const TextVariation = props => {
  const InputComponent = textVariationSwitch(props);
  const translateName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  return (
    <Fragment>
      <label htmlFor={"bk-text-" + props.textId}>{translateName}</label>
      <InputComponent {...props} />
    </Fragment>
  );
};

function textVariationSwitch(props) {
  if (~props.classes.indexOf("textarea")) {
    return TextVariationTextarea;
  } else {
    return TextVariationInput;
  }
}

class TextVariationInput extends Debounce {
  handleChange(value, delay = 5000) {
    this.setState(state => ({ value }));
    if (this.checkInput(this.props.pattern, value)) {
      this.setState(state => ({ hasError: false }));
      this.debouncedSave(value, delay, "text");
    } else {
      this.setState(state => ({ hasError: true }));
    }
  }

  render() {
    const action = this.props.isReadOnly
      ? () => {}
      : event => this.handleChange(event.target.value);
    return (
      <input
        type="text"
        name={"bk-text-" + this.props.textId}
        id={"bk-text-" + this.props.textId}
        value={this.state.value}
        placeholder={this.props.placeholder}
        onChange={action}
        onBlur={action}
        disabled={this.props.isReadOnly}
      />
    );
  }
}

class TextVariationTextarea extends Debounce {
  handleChange(value) {
    this.setState(state => ({ value }));
  }

  handleBlur(value, delay = 10) {
    this.setState(state => ({ value }));
    if (this.checkInput(this.props.pattern, value)) {
      this.setState(state => ({ hasError: false }));
      this.debouncedSave(value, delay, "text");
    } else {
      this.setState(state => ({ hasError: true }));
    }
  }

  render() {
    const errorMsg = this.props.error || "Ungültige Eingabe";
    const error = this.state.hasError ? (
      <div className="error">
        <i className="fas fa-bell" /> {errorMsg}
      </div>
    ) : null;
    const action = this.props.isReadOnly
      ? () => {}
      : event => this.handleChange(event.target.value);
    const actionBlur = this.props.isReadOnly
      ? () => {}
      : event => this.handleBlur(event.target.value);
    return (
      <Fragment>
        <textarea
          name={"bk-text-" + this.props.textId}
          cols="32"
          rows="6"
          id={"bk-text-" + this.props.textId}
          placeholder={this.props.placeholder}
          value={this.state.value}
          disabled={this.props.isReadOnly}
          onChange={action}
          onBlur={actionBlur}
        />
        {error}
      </Fragment>
    );
  }
}
