import React from "react";
import SearchGroupOption from "../SearchGroupOption";
import Columns from "../Columns";
import SearchLayoutGroup from "./SearchLayoutGroup";
import PropTypes from "prop-types";
import SearchGroupLabel from "../SearchGroupLabel";
import SearchGroupUnorderedList from "../SearchGroupList";
import Translate from "../../../../../lib/App/common/translator/components/translate";

const SearchLayout = props => {
  const layoutGroups = props.variationen.map(item => {
    const checked = props.activeOption
      ? props.activeOption === item.variationCode
      : item.isDefault;

    const translateName = (
      <Translate id={"variationen/" + item.variationCode + "/name"}>
        {item.name}
      </Translate>
    );
    const translateTooltip = (
      <Translate id={"variationen/" + item.variationCode + "/tooltip"}>
        {item.tooltip}
      </Translate>
    );

    return (
      <SearchLayoutGroup
        id={"bk-search-layout-" + item.variationCode}
        key={"bk-search-layout-" + item.variationCode}
        optionDesc={translateName}
        optionTooltip={translateTooltip}
        tooltipToLeft={item.classes.indexOf("tooltip-left") >= 0}
        imgDesc={item.name}
        isChecked={checked}
        value={item.variationCode}
        clicked={props.clicked}
      />
    );
  });

  return (
    <SearchGroupOption optionClass={"bk-search-group-option-layout"}>
      <SearchGroupLabel
        title={props.optionName}
        desc={""}
        translateId={props.translateId}
      />
      <Columns large={7} medium={7} small={12}>
        <SearchGroupUnorderedList>{layoutGroups}</SearchGroupUnorderedList>
      </Columns>
    </SearchGroupOption>
  );
};

SearchLayout.propTypes = {
  clicked: PropTypes.func.isRequired,
  activeOption: PropTypes.string
};

export default SearchLayout;
