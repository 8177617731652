import React from "react";
import { Link } from "react-router-dom";
import { RenameKonfigurationLinkBarButtonModal } from "../../App/common/Modal/Modal";
import Translate from "../../lib/App/common/translator/components/translate";
import { connect } from "react-redux";

const LinkBar = props => {
  const [building] = props.building || [];

  const pdfDownloadName = props.report.generatePdf ? (
    <Translate id={"Zusammenfassung/LinkBar/pdf-processing"}>
      PDF wird generiert..
    </Translate>
  ) : (
    <Translate id={"Zusammenfassung/LinkBar/pdf-ready"}>PDF Download</Translate>
  );
  const pdfDownloadStyle = !props.report.generatePdf
    ? { cursor: "pointer" }
    : { cursor: "wait" };
  const translateTermin = (
    <Translate id="Zusammenfassung/LinkBar/termin">
      Termin vereinbaren
    </Translate>
  );
  const translateNew = (
    <Translate id="Zusammenfassung/LinkBar/new">Neu</Translate>
  );

  return (
    <div className="bk-summary-navbar group columns large-12 medium-12 small-12">
      <ul className="group">
        <SaveButton
          showSaveButton={props.showSaveButton}
          konfigurationId={props.konfigurationId}
          konfigurationName={props.konfigurationName}
        />
        <li>
          <button
            className="btn"
            style={pdfDownloadStyle}
            onClick={props.fetchReport}
            disabled={props.report.generatePdf}
            type="button"
          >
            <i className="fas fa-download" /> {pdfDownloadName}
          </button>
        </li>
        <li>
          <Link className="btn" to="/">
            <i className="fas fa-plus-circle" /> {translateNew}
          </Link>
        </li>
        <li>
          <a
            target="_blank"
            className="btn"
            href={['Conex', 'Lindbergh'].indexOf(building) > -1 ? "mailto: letting@jll.com" : "mailto: vermarktung.zh@privera.ch"}
          >
            <i className="fas fa-calendar" /> {translateTermin}
          </a>
        </li>
      </ul>
    </div>
  );
};

LinkBar.defaultProps = {
  showSaveButton: false,
  fetchReport: () => {}
};

const mapStateToProps = state => {
  return {
    building: state.wohnungsgrundriss.current.gebaeudeCode
  };
};

export default connect(mapStateToProps)(LinkBar);

const SaveButton = props => {
  const translateSave = (
    <Translate id="Zusammenfassung/LinkBar/save">Speichern</Translate>
  );
  const link = props.showSaveButton ? (
    <Link className="btn" to="/anmelden">
      <i className="fas fa-save" /> {translateSave}
    </Link>
  ) : (
    <RenameKonfigurationLinkBarButtonModal
      konfigurationId={props.konfigurationId}
      name={props.konfigurationName}
    />
  );

  return <li>{link}</li>;
};
