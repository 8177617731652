import React from "react";
import { NumberFormat } from "../../../../lib/App/common/ValueFormatter";
import Translate from "../../../../lib/App/common/translator/components/translate";
import { CurrencyFormat } from "../../../../lib/App/common/ValueFormatter";

const getSequence = dataArray => {
  let sequence = [];
  let maxValue = dataArray[0];
  let minValue = dataArray[0];
  for (var i = 0; i < dataArray.length; i++) {
    if (i == dataArray.length - 1 || dataArray[i + 1] != dataArray[i] + 1) {
      if (maxValue == minValue) {
        dataArray[i] === 0 ? sequence.push("EG") : sequence.push(dataArray[i]);
      } else {
        minValue === 0
          ? sequence.push("EG - " + maxValue)
          : sequence.push(minValue + ". - " + maxValue);
      }
      minValue = dataArray[i + 1];
      maxValue = dataArray[i + 1];
    } else if (dataArray[i + 1] == dataArray[i] + 1) {
      maxValue++;
    }
  }

  return sequence;
};

const BasicHightlights = props => {
  const flaeche = props.wohnungsgrundriss.flaeche || "";
  const konfigurationV = props.konfigurationV || {};
  const buildingName = props.wohnungsgrundriss.gebaeudeCode
    ? props.wohnungsgrundriss.gebaeudeCode[0]
    : "";
  const etagen = props.wohnungsgrundriss.etageNummer || [];
  let etage;
  if (etagen.length >= 1) {
    etage = getSequence(etagen);
  } else {
    etage = parseInt(etagen[0]);
  }

  const translateEtageType =
    etagen.length >= 1 &&
    etage !== 0 &&
    (etagen[0] !== 0 || etagen.length > 1) ? (
      <Translate id="BasicHighlights/etage-type/og" data={{ floor: etage }} />
    ) : (
      <Translate id="BasicHighlights/etage-type/eg">EG</Translate>
    );

  const translateEtage = (
    <Translate id="BasicHighlights/etage">Etage</Translate>
  );
  const translateMietzins = (
    <Translate id="KonfiguratorHighlights/mietzins">
      Mietzins
    </Translate>
  );
  const translateFlaeche = (
    <Translate id="BasicHighlights/flaeche">Fläche</Translate>
  );
  const translateMA = (
    <Translate id="BasicHighlights/ma">Max. Arbeitsplätze</Translate>
  );
  const translateMonthly = (
    <Translate id="KonfiguratorHighlights/monthly">
      / monatlich
    </Translate>
  );

  return (
    <table>
      <tbody>
        <tr>
          <td>Gebäude</td>
          <td>{buildingName}</td>
        </tr>
        <tr>
          <td>{translateEtage}</td>
          <td>{translateEtageType}</td>
        </tr>
        <tr>
          <td>{translateFlaeche}</td>
          <td>
            <NumberFormat value={Number(flaeche)} /> m<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td>{translateMA}</td>
          <td>
            <NumberFormat value={Number(konfigurationV.maxEmployee)} />
          </td>
        </tr>
        <tr>
          <td>{translateMietzins}</td>
          <td><CurrencyFormat value={konfigurationV.totalMonthly} currency="CHF" /> {translateMonthly}</td>
        </tr>
      </tbody>
    </table>
  );
};

const DeskShareRatio = props => {
  const buerolayout = props.highlights
    ? props.highlights.buerolayout.variationCode
    : "";
  const deskShareRatio =
    props.highlights && buerolayout !== "blnds"
      ? props.highlights["desk-share-ratio"].value +
        " " +
        props.highlights["desk-share-ratio"].unit
      : "";

  const deskShareTitle =
    buerolayout !== "blnds"
      ? "Desk Share Ratio"
      : props.highlights.buerolayout.name;
  const translateDeskShareTitle = (
    <Translate id="BasicHighlights/desk-share-ratio">
      {deskShareTitle}
    </Translate>
  );

  return (
    <tr>
      <td>{translateDeskShareTitle}</td>
      <td>{deskShareRatio}</td>
    </tr>
  );
};

export default BasicHightlights;
