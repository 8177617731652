import { all } from "redux-saga/effects";
import { sagas as wohnungListSagas } from "../BueroList/saga";
import { sagas as wohnungsgrundrissSagas } from "../lib/App/common/wohnungsgrundriss/saga";
import { sagas as konfigurationSagas } from "../App/common/Konfigurator/saga";
import { sagas as loginSagas } from "../User/saga";
import { sagas as zusammenfassungSagas } from "../lib/Zusammenfassung/saga";
import { sagas as authSagas } from "./common/auth/saga";
import { sagas as konfigurationListSagas } from "../lib/KonfigurationList/saga";
import { sagas as exportSagas } from "../lib/Export/saga";
import { sagas as errorSagas } from "../lib/App/common/error/saga";
import { sagas as staticVariationSagas } from "../lib/App/common/optionen/saga";

export default function* rootSaga() {
  yield all([
    ...wohnungListSagas,
    ...wohnungsgrundrissSagas,
    ...konfigurationSagas,
    ...loginSagas,
    ...zusammenfassungSagas,
    ...authSagas,
    ...konfigurationListSagas,
    ...exportSagas,
    ...errorSagas,
    ...staticVariationSagas
  ]);
}
