import { put, call, takeLatest } from "redux-saga/effects";
import * as actionType from "../../../lib/App/common/auth/actionType";
import * as userActionType from "../../../lib/User/actionType";
import * as konfigurationActionType from "../../../lib/App/common/konfigurator/actionType";
import * as errorActionType from "../../../lib/App/common/error/actionType";
import provider from "../../../lib/App/common/auth/provider";
import localStore from "../../../lib/App/localStore";
import history from "../../../lib/App/common/history";

export function* fetch(api, localStore) {
  try {
    const getItemOfLocalStore = localStore.getItem.bind(localStore);
    const renewToken = yield call(getItemOfLocalStore, "renewToken");
    if (renewToken !== null && renewToken !== undefined) {
      const response = yield call(api.access);
      yield call(
        fetchDecision,
        response.data.user,
        response.data.konfigurationVs
      );
    }
  } catch (error) {
    yield put({ type: errorActionType.ERROR, payload: error });
  }
}

export function* fetchDecision(user, konfigurationVs) {
  if (user !== undefined) {
    yield put({ type: userActionType.SET, payload: user });
  }

  if (konfigurationVs !== undefined && konfigurationVs.length > 0) {
    yield put({
      type: konfigurationActionType.SET_KONFIGURATION_V,
      payload: { konfigurationV: [...konfigurationVs].pop(), lowPrio: true }
    });
  }
}

export function* fetchLastKonfiguration(api) {
  try {
    const response = yield call(api.access);
    yield call(fetchLastKonfigurationDecision, response.data.konfigurationVs);
  } catch (error) {
    yield put({ type: errorActionType.ERROR, payload: error });
  }
}

export function* fetchLastKonfigurationDecision(konfigurationVs) {
  const historyPush = history.push.bind(history);
  if (konfigurationVs !== undefined && konfigurationVs.length > 0) {
    const konfigurationV = konfigurationVs[0];
    const lastUrl =
      konfigurationV.frontendProperty !== undefined &&
      konfigurationV.frontendProperty.lastUrl !== undefined
        ? konfigurationV.frontendProperty.lastUrl
        : "/konfigurator/" +
          konfigurationV.konfigurationVId +
          "/zusammenfassung";
    console.log("lastUrl", lastUrl);
    yield call(historyPush, lastUrl);
  } else {
    yield call(historyPush, "/konfigurationen");
  }
}

export const sagas = [
  takeLatest(actionType.FETCH, fetch, provider, localStore),
  takeLatest(
    actionType.FETCH_LAST_KONFIGURATION,
    fetchLastKonfiguration,
    provider
  )
];
