import React from "react";
import { connect } from "react-redux";
import {
  Filter,
  mapStateToProps,
  mapDispatchToProps
} from "../../../lib/WohnungList/components/Filter";

import SearchGroup from "./components/SearchGroup";
import SearchLayout from "./components/SearchLayout/SearchLayout";
import SearchRatio from "./components/SearchRatio/SearchRatio";
import SearchRooms from "./components/SearchRooms/SearchRooms";
import Slider from "rc-slider";
import AreaSearch from "./components/AreaSearch";
import StaffSearch from "./components/StaffSearch";

import OfficeSpace from "../../../style/assets/site/pics/icon_office_space.svg";
import StaffNum from "../../../style/assets/site/pics/icon_staff_num.svg";

const maxArea = 2000;
const minArea = 0;

const maxStaff = 170;
const minStaff = 0;

export const createSliderWithTooltip = Slider.createSliderWithTooltip;
class Search extends Filter {
  constructor(props) {
    super(props);

    this.handleAreaChanged = this.handleAreaChanged.bind(this);
    this.handleStaffChanged = this.handleStaffChanged.bind(this);
    this.handleFocusChanged = this.handleFocusChanged.bind(this);
    this.handleLayoutChanged = this.handleLayoutChanged.bind(this);
    this.handleRatioChange = this.handleRatioChange.bind(this);
    this.handleSitzungszimmerChanged = this.handleSitzungszimmerChanged.bind(
      this
    );
  }

  handleFocusChanged(event, lastToggled) {
    event.target.select();
    this.props.actions.updateFilters({
      [lastToggled]: Number(event.target.value),
      lastToggled: lastToggled,
      initial: false
    });
  }

  handleAreaChanged(newValue) {
    this.props.actions.updateFilters({
      area: Number(newValue),
      lastToggled: "area",
      initial: false
    });
  }

  handleStaffChanged(newValue) {
    this.props.actions.updateFilters({
      staff: Number(newValue),
      lastToggled: "staff",
      initial: false
    });
  }

  handleLayoutChanged(newValue) {
    this.props.actions.updateFilters({ buerolayout: newValue });
  }

  handleRatioChange(newValue) {
    this.props.actions.updateFilters({ "desk-share-ratio": Number(newValue) });
  }

  handleSitzungszimmerChanged(newValue) {
    this.props.actions.updateFilters({ sitzungszimmer: newValue });
  }

  render() {
    let marksStaff = {};
    marksStaff[minStaff] = minStaff;
    marksStaff[maxStaff] = maxStaff;

    const layout = this.props.optionen
      ? this.props.optionen.filter(item => item.optionCode === "buerolayout")[0]
      : undefined;
    const deskshareratio = this.props.optionen
      ? this.props.optionen.filter(
          item => item.optionCode === "desk-share-ratio"
        )[0]
      : undefined;
    const sitzungszimmer = this.props.optionen
      ? this.props.optionen.filter(
          item => item.optionCode === "sitzungszimmer"
        )[0]
      : undefined;

    const ratio =
      deskshareratio && this.props.buerolayout === "blds" ? (
        <SearchRatio
          number={deskshareratio.number}
          changed={this.handleRatioChange}
          current={this.props["desk-share-ratio"]}
          activeOption={this.props.buerolayout}
          translateId={"optionen/" + deskshareratio.optionCode + "/"}
        />
      ) : null;

    const rooms = sitzungszimmer ? (
      <SearchRooms
        sitzungszimmerName={sitzungszimmer.name}
        sitzungszimmerVariationen={sitzungszimmer.variationen}
        sitzungszimmerChanged={this.handleSitzungszimmerChanged}
        sitzungszimmer={this.props.sitzungszimmer}
        translateId={"optionen/" + sitzungszimmer.optionCode + "/"}
      />
    ) : null;

    return (
      <section className="group bk-search">
        <form action="./" method="post" className="group" noValidate>
          <SearchGroup groupClass="bk-search-group-basic">
            <AreaSearch
              area={this.props.area}
              handleAreaChanged={this.handleAreaChanged}
              handleFocusChanged={this.handleFocusChanged}
              minArea={minArea}
              maxArea={maxArea}
              lastToggled={this.props.lastToggled}
              initial={this.props.initial}
              image={OfficeSpace}
            />

            <StaffSearch
              staff={this.props.staff}
              handleStaffChanged={this.handleStaffChanged}
              handleFocusChanged={this.handleFocusChanged}
              minStaff={minStaff}
              maxStaff={maxStaff}
              lastToggled={this.props.lastToggled}
              initial={this.props.initial}
              image={StaffNum}
            />
          </SearchGroup>

          <SearchGroup groupClass={"bk-search-group-options"}>
            <SearchLayout
              optionName={layout ? layout.name : ""}
              variationen={layout ? layout.variationen : []}
              clicked={this.handleLayoutChanged}
              activeOption={this.props.buerolayout}
              translateId={layout ? "optionen/" + layout.optionCode + "/" : ""}
            />
            {ratio}
            {rooms}
          </SearchGroup>
        </form>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
