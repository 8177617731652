import React from "react";
import { Provider } from "react-redux";
import configureStore from "./store";

// used for ValuFormatter
import { IntlProvider } from "react-intl";
import { IntlFormats } from "./../lib/App/common/ValueFormatter";

// used for translations
import { LocalizeProvider } from "react-localize-redux";
import Main from "./main";

import "react-toastify/dist/ReactToastify.css";
import "./../style/assets/site/css/Import.scss";
import "./App.scss";
require("@formatjs/intl-pluralrules/polyfill");
require("@formatjs/intl-pluralrules/locale-data/de");
require("@formatjs/intl-pluralrules/locale-data/en");

require("@formatjs/intl-relativetimeformat/polyfill");
require("@formatjs/intl-relativetimeformat/locale-data/de");
require("@formatjs/intl-relativetimeformat/locale-data/en");

const store = configureStore();

export const App = props => (
  <Provider store={store}>
    <IntlProvider locale={"de-CH"} formats={IntlFormats}>
      <LocalizeProvider store={store}>
        <Main />
      </LocalizeProvider>
    </IntlProvider>
  </Provider>
);

export default App;
