import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Profil,
  ProfilDetailForm,
  ProfilPasswordForm,
  mapStateToProps,
  mapDispatchToProps
} from "../../lib/User/components/Profil";
import SubHeader from "../../App/common/Header/SubHeader";
import { InputLeonardo, DropdownLeonardo } from "../../App/common/Form/Form";
import Translate from "../../lib/App/common/translator/components/translate";

export class ProfilLeonardo extends Profil {
  render() {
    return (
      <Fragment>
        <SubHeader
          title="Profil"
          description="Ändern Sie hier Ihre Benut­zerdaten oder das Passwort."
          translateId={"Profil/"}
        />
        <section className="group bk-first">
          <ProfilDetailFormLeonardo
            user={this.props.user}
            feedback={this.props.feedback}
            actions={this.props.actions}
          />
          <ProfilPasswordFormLeonardo
            feedback={this.props.feedback}
            actions={this.props.actions}
          />
        </section>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfilLeonardo));

export class ProfilDetailFormLeonardo extends ProfilDetailForm {
  handleSubmit(event) {
    window.scrollTo(0, 0);
    this.setState(state => ({ formSend: true }));
    super.handleSubmit(
      event,
      this.props.actions.updateProfile,
      this.state.form.values
    );
  }

  getError(feedback) {
    const translate = (
      <Translate id="Profil/error">
        Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben.
      </Translate>
    );

    return feedback.profilUpdate === false && this.state.formSend ? (
      <div className="error">
        <i className="fas fa-bell" /> {translate}
      </div>
    ) : null;
  }

  getSuccess(feedback) {
    const translate = (
      <Translate id="Profil/success">
        Die Angaben wurden erfolgreich geändert.
      </Translate>
    );

    return feedback.profilUpdate === true && this.state.formSend ? (
      <div className="success">
        <i className="far fa-check-circle" /> {translate}
      </div>
    ) : null;
  }

  getForm(user) {
    const optionen = this.getGenderList();
    return (
      <div className="columns large-12 medium-12 small-12">
        <div className="group">
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("email", isValid)}
              getValue={value => this.updateValue("email", value)}
              showError={this.state.form.showError}
              type="email"
              displayName="E-Mail"
              id="registrationEmail"
              title="Bitte geben Sie eine gültige E-Mail Adresse ein"
              value={user.email}
              disabled
              translateId={"User/Profil/"}
              required
            />
          </ol>
          <ol>
            <li>
              <DropdownLeonardo
                errorClass="error"
                isValid={isValid => this.updateIsValid("title", isValid)}
                getValue={value => this.updateValue("title", value)}
                showError={this.state.form.showError}
                id="registrationTitle"
                pleaseSelectTitle="Anrede"
                displayName="Anrede"
                title="Bitte geben Sie Ihre Anrede ein"
                optionen={optionen}
                translateId={"User/Profil/"}
                value={user.title}
                required
              />
            </li>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("nameFirst", isValid)}
              getValue={value => this.updateValue("nameFirst", value)}
              showError={this.state.form.showError}
              id="registrationNameFirst"
              displayName="Vorname"
              title="Bitte geben Sie Ihren Vornamen ein"
              translateId={"User/Profil/"}
              value={user.nameFirst}
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("nameLast", isValid)}
              getValue={value => this.updateValue("nameLast", value)}
              showError={this.state.form.showError}
              id="registrationNameLast"
              displayName="Nachname"
              title="Bitte geben Sie Ihren Nachname ein"
              translateId={"User/Profil/"}
              value={user.nameLast}
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressStreet", isValid)}
              getValue={value => this.updateValue("addressStreet", value)}
              showError={this.state.form.showError}
              id="registrationAddressStreet"
              displayName="Strasse"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Strasse ein"
              value={user.addressStreet}
              required
            />
          </ol>
          <ol className="group">
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressPlace", isValid)}
              getValue={value => this.updateValue("addressPlace", value)}
              showError={this.state.form.showError}
              id="registrationPlace"
              displayName="Ort"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihren Ort ein"
              value={user.addressPlace}
              required
            />
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressZip", isValid)}
              getValue={value => this.updateValue("addressZip", value)}
              showError={this.state.form.showError}
              id="registrationAddressZip"
              displayName="Postleitzahl"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Postleitzahl ein"
              value={user.addressZip}
              required
            />
          </ol>
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressCountry", isValid)}
              getValue={value => this.updateValue("addressCountry", value)}
              showError={this.state.form.showError}
              id="registrationAddressCountry"
              displayName="Land"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihr Land ein"
              value={user.addressCountry}
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("phoneNr", isValid)}
              getValue={value => this.updateValue("phoneNr", value)}
              showError={this.state.form.showError}
              id="registrationPhoneNr"
              displayName="Telefonnummer"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Telefonnummer ein"
              value={user.phoneNr}
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("companyName", isValid)}
              getValue={value => this.updateValue("companyName", value)}
              showError={this.state.form.showError}
              id="registrationCompanyName"
              displayName="Firma"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Arbeitsfirma ein"
              value={user.companyName}
            />
            <li className="bk-submit">
              <button className="btn btn-inverted" onClick={this.handleSubmit}>
                <Translate id={"Profil/abschicken"}>Details ändern</Translate>
              </button>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  render() {
    const { user, feedback } = this.props;
    const error = this.getError(feedback);
    const success = this.getSuccess(feedback);
    const form = this.getForm(user);
    return (
      <form className="group" noValidate>
        <fieldset className="group row">
          {success}
          {error}
          {form}
        </fieldset>
      </form>
    );
  }
}

export class ProfilPasswordFormLeonardo extends ProfilPasswordForm {
  getError(feedback) {
    const translate = (
      <Translate id="ProfilPassword/error">
        Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben.
      </Translate>
    );
    return feedback.profilPasswordUpdate === false && this.state.formSend ? (
      <div className="error">
        <i className="fas fa-bell" /> {translate}
      </div>
    ) : null;
  }

  getSuccess(feedback) {
    let { password, passwordConfirm } = this.state.form.values;
    let success = null;
    if (feedback.profilPasswordUpdate === true && this.state.formSend) {
      const translate = (
        <Translate id="ProfilPassword/success">
          Das Passwort wurde erfolgreich geändert
        </Translate>
      );
      success = (
        <div className="success">
          <i className="far fa-check-circle" /> {translate}
        </div>
      );
      password = undefined;
      passwordConfirm = undefined;
    }

    return { success, password, passwordConfirm };
  }

  getForm(password, passwordConfirm) {
    return (
      <div className="columns large-12 medium-12 small-12">
        <div className="group">
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("password", isValid)}
              getValue={value => this.updateValue("password", value)}
              showError={this.state.form.showError}
              id="profilPassword"
              displayName="Passwort"
              type="password"
              title="Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$"
              value={password}
              translateId={"ProfilPassword/"}
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid =>
                this.updateIsValid("passwordConfirm", isValid)
              }
              getValue={value => this.updateValue("passwordConfirm", value)}
              showError={this.state.form.showError}
              id="profilPasswordConfirm"
              displayName="Passwort bestätigen"
              type="password"
              title="Das Passwort stimmt nicht überein"
              translateId={"ProfilPassword/"}
              pattern={
                "^" +
                this.patternValueEscape(this.state.form.values.password) +
                "$"
              }
              value={passwordConfirm}
              required
            />
            <li className="bk-submit">
              <button className="btn btn-inverted" onClick={this.handleSubmit}>
                <Translate id={"ProfilPassword/abschicken"}>
                  Passwort ändern
                </Translate>
              </button>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  render() {
    const { feedback } = this.props;
    const error = this.getError(feedback);
    let { success, password, passwordConfirm } = this.getSuccess(feedback);
    const form = this.getForm(password, passwordConfirm);
    return (
      <form className="group" noValidate>
        <fieldset className="group row">
          {success}
          {error}
          {form}
        </fieldset>
      </form>
    );
  }
}
