import React from "react";
import { NumberVariation, TextVariation } from "./Variationen";
import Image from "../../App/common/Image";
import Translate from "../../lib/App/common/translator/components/translate";
import { flagTwoOptions } from "../../App/common/Konfigurator/components/Optionen";

const Optionen = (props) => {
  const optionen = flagTwoOptions(props.optionen);

  return optionen.map((option) => {
    const optionWithStyle = { ...option, styling: props.styling };
    return optionenSwitch(optionWithStyle, optionen);
  });
};

export default Optionen;

function optionenSwitch(option, options) {
  if (
    ~option.classes.indexOf("boolean") &&
    ~option.classes.indexOf("no_image") &&
    ~option.classes.indexOf("summary_full_width")
  ) {
    return <FullWidthOption key={option.optionId} {...option} />;
  } else if (
    ~option.classes.indexOf("boolean") &&
    ~option.classes.indexOf("no_image")
  ) {
    return <BooleanNoImage key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("boolean")) {
    return <BooleanOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("info")) {
    return <InfoOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("number")) {
    // SPECIFIC FOR KFG-ONE
    if (option.show === 1) {
      return (
        <DoubleNumberOption
          key={option.optionId}
          options={options.filter((item) => {
            if (option.name.indexOf("Parkplatz") > -1) {
              return item.name.indexOf("Parkplatz") > -1;
            }

            return item.name === option.name;
          })}
        />
      );
    } else if (option.show === undefined) {
      return <NumberOption key={option.optionId} {...option} />;
    } else {
      return null;
    }
  } else if (~option.classes.indexOf("text")) {
    return <TextOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("summary_full_width")) {
    return <FullWidthOption key={option.optionId} {...option} />;
  } else {
    return <Option key={option.optionId} {...option} />;
  }
}

const BooleanOption = (props) => {
  const variation = props.variationen[0] || {};
  const variationName =
    variation.name === "1"
      ? "Ja"
      : variation.name === "0"
      ? "Nein"
      : variation.name;
  const rowSpan = ~props.classes.indexOf("zusatzkosten")
    ? "large-12 medium-12 small-12"
    : "large-6 medium-6 small-12";
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variationName}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-4 medium-4 small-12">
        <h2>{translateOption}</h2>
      </div>
      <div className="columns large-2 medium-2 small-12">
        <Image
          src={`variationen/${props.optionCode}`}
          alt={"IMAGE " + props.name}
          height="1000w"
          ratio="r1.6"
        />
      </div>
      <div className="columns large-6 medium-6 small-12">
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
    </div>
  );
};

const BooleanNoImage = (props) => {
  const variation = props.variationen[0] || {};
  const variationName =
    variation.name === "1"
      ? "Ja"
      : variation.name === "0"
      ? "Nein"
      : variation.name;

  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variationName}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className={"bk-options-summary group columns large-12 medium-12 small-12"}
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOption}</h2>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
    </div>
  );
};

const InfoOption = (props) => {
  const variation = props.variationen[0] || {};
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = variation.name ? (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variation.name}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOption}</h2>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
      </div>
    </div>
  );
};
const DoubleNumberOption = (props) => {
  const [option1, option2] = props.options;
  const displayImage = option1.optionCode.indexOf("parkplatz") < 0;
  const number = option1.number || {};

  const translateOptionName = (
    <Translate id={"optionen/" + option1.optionCode + "/name"}>
      {option1.name}
    </Translate>
  );
  const translateOptionDesc = option1.beschreibung ? (
    <Translate id={"optionen/" + option1.optionCode + "/beschreibung"}>
      {option1.beschreibung}
    </Translate>
  ) : (
    ""
  );

  const image = displayImage ? (
    <div className="columns large-2 medium-2 small-12">
      <Image
        src={`variationen/${option1.optionCode}`}
        alt={"IMAGE " + option1.name}
        height="1000w"
        ratio="r1.6"
      />
    </div>
  ) : null;

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={option1.styling}
    >
      <div
        className={`columns small-12 ${
          displayImage ? "large-4 medium-4" : "large-6 medium-6"
        }`}
      >
        <h2>{displayImage ? option1.name : "Parkplatz"}</h2>
        <p>{translateOptionDesc}</p>
      </div>
      {image}
      <div className="columns large-6 medium-6 small-12">
        <NumberVariation
          {...option1.number}
          name={option1.name}
          code={option1.optionCode}
        />
        <NumberVariation
          {...option2.number}
          name={option2.name}
          code={option2.optionCode}
        />
      </div>
    </div>
  );
};

const NumberOption = (props) => {
  const displayTitle =
    props.optionCode.indexOf("move-nbr") < 0 &&
    props.optionCode.indexOf("move-box") < 0 &&
    props.optionCode.indexOf("work") < 0;

  const displayImage =
    props.optionCode.indexOf("parkplatz") < 0 && displayTitle;

  const number = props.number || {};

  const translateOptionName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateOptionDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  const image = displayImage ? (
    <div className="columns large-2 medium-2 small-12">
      <Image
        src={`variationen/${props.optionCode}`}
        alt={"IMAGE " + props.name}
        height="1000w"
        ratio="r1.6"
      />
    </div>
  ) : null;

  const style = !displayTitle
    ? {
        borderTop: "none",
        paddingTop: 0,
      }
    : {};

  return props.number.max > 0 ? (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={{ ...props.styling, ...style }}
    >
      <div
        className={`columns small-12 ${
          displayImage ? "large-4 medium-4" : "large-6 medium-6"
        }`}
      >
        {displayTitle ? (
          <h2>{translateOptionName}</h2>
        ) : (
          <div style={{ opacity: 0 }}>-</div>
        )}
      </div>
      {image}
      <div className="columns large-6 medium-6 small-12">
        <NumberVariation
          {...number}
          name={props.name}
          code={props.optionCode}
        />
      </div>
    </div>
  ) : null;
};

const TextOption = (props) => {
  const text = props.text || {};

  const translateOptionName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateOptionDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOptionName}</h2>
        <p>{translateOptionDesc}</p>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <TextVariation {...text} code={props.optionCode} />
      </div>
    </div>
  );
};

const FullWidthOption = (props) => {
  const variation = props.variationen[0] || {};
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variation.name}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-12 medium-12 small-12">
        <h2>{translateOption}</h2>
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
    </div>
  );
};

const Option = (props) => {
  const variation = props.variationen[0] || {};
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;
  const image =
    variation.imagePath !== undefined ? (
      <Image
        src={variation.imagePath}
        alt={"IMAGE " + variation.name}
        height="1000w"
        ratio="r1.6"
      />
    ) : null;
  const optionCodeImage =
    props.optionCode === "desk" ? "work" : props.optionCode;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variation.name}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-4 medium-4 small-12">
        <h2>{translateOption}</h2>
      </div>
      {props.optionCode !== "miete" && !props.optionCode.startsWith("lager") ? (
        <>
          <div className="columns large-2 medium-2 small-12">
            <Image
              src={`variationen/${optionCodeImage}`}
              alt={"IMAGE " + props.name}
              height="1000w"
              ratio="r1.6"
            />
          </div>
          <div className="columns large-6 medium-6 small-12">
            <div>{props.variationen[0].name}</div>
            {aufpreisBeschreibung}
            <p>{translateVariationDesc}</p>
          </div>
        </>
      ) : (
        <>
          <div
            className="columns large-2 medium-2 small-12"
            style={{ visibility: "hidden" }}
          >
            hidden
          </div>
          <div className="columns large-6 medium-6 small-12">
            {props.beschreibung}
          </div>
        </>
      )}
    </div>
  );
};
