import React from "react";
import Slider from "rc-slider";
import Columns from "./Columns";
import { Tooltip } from "../../../../App/common/InfoButton/InfoButton";

const stepArea = 50;

const AreaSearch = props => {
  let marksArea = {};
  marksArea[props.minArea] = props.minArea;
  for (let i = 1250; i < props.maxArea; i += 1250) {
    marksArea[i] = "";
  }
  marksArea[props.maxArea] = props.maxArea;

  const groupClasses =
    props.lastToggled === "staff" && !props.initial
      ? "group disabled"
      : "group";

  return (
    <Columns large={6} medium={6} small={12}>
      <div className={groupClasses}>
        <label htmlFor="bk-search-area">
          <img src={props.image} alt={"Bürofläche"} />
        </label>
        <Slider
          name={"area"}
          step={stepArea}
          min={props.minArea}
          max={props.maxArea}
          value={props.area}
          onChange={props.handleAreaChanged}
          marks={marksArea}
        />
        <label className={"label"} htmlFor={"bk-search-area"}>
          <input
            type="number"
            value={props.area}
            min={props.minArea}
            max={props.maxArea}
            onFocus={event => {
              props.handleFocusChanged(event, "area");
            }}
            onClick={event => {
              props.handleFocusChanged(event, "area");
            }}
            onChange={event => {
              props.handleAreaChanged(event.target.value);
            }}
            id="bk-search-area"
            placeholder="Bürofläche (m²)"
          />
          <Tooltip
            toolTip={"Suche nach Anzahl m²"}
            translateId={"optionen/search-area/tooltip"}
            tooltipToLeft
            outerStyle={{ display: "inline-block", top: "10px" }}
            innerStyle={{ display: "block" }}
          />
        </label>
      </div>
    </Columns>
  );
};

export default AreaSearch;
