import React, { Fragment } from "react";
import { CurrencyFormat } from "../../../../lib/App/common/ValueFormatter";
import Translate from "../../../../lib/App/common/translator/components/translate";

const ZusatzoptionenHighlights = props => {
  const konfigurationV = props.konfigurationV || {};
  const translateMove = (
    <Translate id="ZusatzoptionenHighlights/move">
      upMOVE – Umzug
    </Translate>
  );
  const translateGreen = (
    <Translate id="ZusatzoptionenHighlights/green">
      upGREEN – Bepflanzung
    </Translate>
  );
  const translateClean = (
    <Translate id="ZusatzoptionenHighlights/clean">
      upCLEAN – Reinigung
    </Translate>
  );
  const translateStyle = (
    <Translate id="ZusatzoptionenHighlights/style">
      upSTYLE – Arbeitsplätze
    </Translate>
  );
  const translateMonthly = (
    <Translate id="ZusatzoptionenHighlights/monthly">
      / monatlich
    </Translate>
  );
  const translateOnce = (
    <Translate id="ZusatzoptionenHighlights/once">
      / einmalig
    </Translate>
  );

  return (
    <Fragment>
      <tr>
        <td>{translateClean}</td>
        <td>
          <CurrencyFormat value={konfigurationV.upClean} currency="CHF" /> {translateMonthly}
        </td>
      </tr>
      <tr>
        <td>{translateGreen}</td>
        <td>
          <CurrencyFormat value={konfigurationV.upGreen} currency="CHF" /> {translateMonthly}
        </td>
      </tr>
      <tr>
        <td>{translateStyle}</td>
        <td>
          <CurrencyFormat value={konfigurationV.upStyle} currency="CHF" /> {translateMonthly}
        </td>
      </tr>
      <tr>
        <td>{translateMove}</td>
        <td>
          <CurrencyFormat value={konfigurationV.upMove} currency="CHF" /> {translateOnce}
        </td>
      </tr>
    </Fragment>
  );
};

export default ZusatzoptionenHighlights;
