import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  RegistrierungForm_,
  mapStateToProps,
  mapDispatchToProps
} from "../../lib/User/components/Registrierung";
import SubHeader from "../../App/common/Header/SubHeader";
import { InputLeonardo, DropdownLeonardo } from "../../App/common/Form/Form";
import Translate from "../../lib/App/common/translator/components/translate";

const RegistrierungLeonardo = props => {
  return (
    <Fragment>
      <SubHeader
        title="Registrieren"
        description="Erstellen Sie ein Benutzerkonto. So können Sie Ihre Konfiguration speichern und später wieder aufrufen."
        translateId={"Registrieren/"}
      />
      <section className="group bk-first">
        <RegistrierungFormLeonardo history={props.history} />
      </section>
    </Fragment>
  );
};

export default RegistrierungLeonardo;

export class RegistrierungFormLeonardo_ extends RegistrierungForm_ {
  getError() {
    const translate = (
      <Translate id="Registrieren/error">
        Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben...
      </Translate>
    );

    return this.props.feedback.registration === false ? (
      <div className="error">
        <i className="fas fa-bell" /> {translate}
      </div>
    ) : null;
  }

  getSuccessOrForm() {
    const translate = (
      <Translate id="Registrieren/success">
        Sie erhalten in Kürze eine E-Mail mit einem Link, mit dem Sie die
        Registrierung abschliessen können.
      </Translate>
    );

    return this.props.feedback.registration === true ? (
      <div className="success">
        <i className="far fa-check-circle" /> {translate}
      </div>
    ) : (
      this.getForm()
    );
  }

  getForm() {
    const optionen = this.getGenderList();
    return (
      <div className="columns large-12 medium-12 small-12">
        <div className="group">
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("email", isValid)}
              getValue={value => this.updateValue("email", value)}
              showError={this.state.form.showError}
              type="email"
              displayName="E-Mail"
              id="registrationEmail"
              title="Bitte geben Sie eine gültige E-Mail Adresse ein"
              translateId={"User/Profil/"}
              required
            />
          </ol>
          <ol className="group">
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={isValid => this.updateIsValid("password", isValid)}
              getValue={value => this.updateValue("password", value)}
              showError={this.state.form.showError}
              id="registrationPassword"
              displayName="Passwort"
              type="password"
              translateId={"User/Profil/"}
              title="Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$"
              required
            />
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={isValid =>
                this.updateIsValid("passwordConfirm", isValid)
              }
              getValue={value => this.updateValue("passwordConfirm", value)}
              showError={this.state.form.showError}
              id="registrationPasswordConfirm"
              displayName="Passwort bestätigen"
              type="password"
              translateId={"User/Profil/"}
              title="Das Passwort stimmt nicht überein"
              pattern={
                "^" +
                this.patternValueEscape(this.state.form.values.password) +
                "$"
              }
              required
            />
          </ol>
          <ol>
            <li>
              <DropdownLeonardo
                errorClass="error"
                isValid={isValid => this.updateIsValid("title", isValid)}
                getValue={value => this.updateValue("title", value)}
                showError={this.state.form.showError}
                id="registrationTitle"
                pleaseSelectTitle="Anrede"
                displayName="Anrede"
                translateId={"User/Profil/"}
                title="Bitte geben Sie Ihre Anrede ein"
                optionen={optionen}
                required
              />
            </li>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("nameFirst", isValid)}
              getValue={value => this.updateValue("nameFirst", value)}
              showError={this.state.form.showError}
              id="registrationNameFirst"
              displayName="Vorname"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihren Vornamen ein"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("nameLast", isValid)}
              getValue={value => this.updateValue("nameLast", value)}
              showError={this.state.form.showError}
              id="registrationNameLast"
              displayName="Nachname"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihren Nachname ein"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressStreet", isValid)}
              getValue={value => this.updateValue("addressStreet", value)}
              showError={this.state.form.showError}
              id="registrationAddressStreet"
              displayName="Strasse"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Strasse ein"
              required
            />
          </ol>
          <ol className="group">
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressPlace", isValid)}
              getValue={value => this.updateValue("addressPlace", value)}
              showError={this.state.form.showError}
              id="registrationPlace"
              displayName="Ort"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihren Ort ein"
              required
            />
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressZip", isValid)}
              getValue={value => this.updateValue("addressZip", value)}
              showError={this.state.form.showError}
              id="registrationAddressZip"
              displayName="Postleitzahl"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Postleitzahl ein"
              required
            />
          </ol>
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("addressCountry", isValid)}
              getValue={value => this.updateValue("addressCountry", value)}
              showError={this.state.form.showError}
              id="registrationAddressCountry"
              displayName="Land"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihr Land ein"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("phoneNr", isValid)}
              getValue={value => this.updateValue("phoneNr", value)}
              showError={this.state.form.showError}
              id="registrationPhoneNr"
              displayName="Telefonnummer"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Telefonnummer ein"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={isValid => this.updateIsValid("companyName", isValid)}
              getValue={value => this.updateValue("companyName", value)}
              showError={this.state.form.showError}
              id="registrationCompanyName"
              displayName="Firma"
              translateId={"User/Profil/"}
              title="Bitte geben Sie Ihre Arbeitsfirma ein"
            />
            <li className="bk-submit">
              <button className="btn btn-inverted" onClick={this.handleSubmit}>
                <Translate id={"Registrieren/abschicken"}>
                  Registrieren
                </Translate>
              </button>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  render() {
    const error = this.getError();
    const successOrForm = this.getSuccessOrForm();
    return (
      <Fragment>
        <form className="group" noValidate>
          <fieldset className="group row">
            {error}
            {successOrForm}
          </fieldset>
        </form>
        <ul class="group navoptions">
          <li>
            <Link to={"anmelden"}>
              <Translate id={"Registrieren/bereits-registriert"}>
                Haben Sie bereits einen Account?
              </Translate>
            </Link>
          </li>
        </ul>
      </Fragment>
    );
  }
}

const RegistrierungFormLeonardo = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrierungFormLeonardo_);
