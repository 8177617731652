import React, { Fragment } from "react";
import StickyNoteArea from "../App/common/StickyNote/StickyNoteArea";
import Steps from "../App/common/Steps/Steps";
import Search from "./components/Search/Search";
import SearchResults from "./components/SearchResults/SearchResults";
import SubHeader from "./../App/common/Header/SubHeader";
import { connect } from "react-redux";
import {
  WohnungList,
  mapStateToProps,
  mapDispatchToProps
} from "../lib/WohnungList";

export class BueroListOne extends WohnungList {
  componentDidMount() {
    this.props.optionenActions.fetchStaticVariations("buerolist");
    this.props.actions.fetchList();
  }
  render() {
    const list = this.getList();
    return (
      <Fragment>
        <SubHeader
          title="Bürokon­fi­gurator"
          description={
            "Flächeneffizienz optimieren: Mit dem Büroflächenkonfigurator kann die optimale Fläche & Anordnung der Räume unter Berücksichtigung der Arbeitsplatzorganisation für Ihr Unternehmen berechnet werden. Entweder über die Anzahl Mitarbeitende oder über die gewünschte Fläche."
          }
          translateId={"BueroList/"}
        />
        <Steps step={1} />
        <Search optionen={this.props.staticVariationOptions} />
        <SearchResults
          actions={this.props.actions}
          rows={list}
          sort={this.props.sort}
        />
        <StickyNoteArea />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BueroListOne);
