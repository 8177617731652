import React from "react";
import PropTypes from "prop-types";

const Columns = props => {
  const large = " large-" + props.large;
  const medium = " medium-" + props.medium;
  const small = " small-" + props.small;
  return (
    <div className={"columns" + (large + medium + small)}>{props.children}</div>
  );
};

Columns.propTypes = {
  large: PropTypes.number.isRequired,
  medium: PropTypes.number.isRequired,
  small: PropTypes.number.isRequired
};

export default Columns;
