import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../lib/App/common/konfigurator/action";
import ImageZoom from "react-medium-image-zoom";
import Translate from "../../../../lib/App/common/translator/components/translate";

class ResultDetail extends Component {
  constructor(props) {
    super(props);

    this.handleKonfigurationStart = this.handleKonfigurationStart.bind(this);
  }

  handleKonfigurationStart(event) {
    event.preventDefault();
    const variationIds = this.props.staticVariationOptions
      .filter(option => option.variationen)
      .map(option => {
        const variation = this.handleVariation(option, this.props.filters);
        if (variation) {
          return variation;
        }
      })
      .filter(variation => variation !== null && variation !== undefined);

    const numbers = this.props.staticVariationOptions
      .filter(option => option.number)
      .map(option => {
        return this.handleNumber(option, this.props.filters);
      })
      .filter(number => number !== null && number !== undefined);

    const fetchNewParams = {
      wohnungsgrundrissId: this.props.buero.defaultWohnungsgrundrissId,
      nextPage: konfigurationVId =>
        "/konfigurator/" + konfigurationVId + "/zusatzoptionen",
      variationIds: variationIds,
      numbers: numbers
    };

    this.props.actions.fetchNew(fetchNewParams);
  }

  handleVariation(option, filters) {
    const filterValue = filters[option.optionCode];
    const selectedVariation = option.variationen.filter(variation => {
      return variation.variationCode === filterValue;
    })[0];
    if (selectedVariation) {
      return selectedVariation.variationId;
    }
  }

  handleNumber(option, filters) {
    const filterValue = filters[option.optionCode];
    if (filterValue !== undefined && filterValue !== null) {
      return { numberId: option.number.numberId, value: filterValue };
    }
  }
  render() {
    const einheitCodes = this.props.buero.einheitCodes.sort(
      (a, b) => Math.floor(a) - Math.floor(b)
    );
    const allBueroImageCodes = einheitCodes.map(v => {
      // NEED TO ADAPT
      // const code = v.split('.')[1]
      return v;
    });
    const translateButton = (
      <Translate id={"BueroList/ResultDetail/konfigurieren"}>
        konfigurieren
      </Translate>
    );
    const distinctBueroCodes = [...new Set(allBueroImageCodes)].reverse();
    return (
      <tr className="bk-result-detail">
        <td colSpan="7">
          <div className="group">
            <div className="group">
              <p>
                <a onClick={this.handleKonfigurationStart} className="btn">
                  {translateButton}
                </a>
              </p>
            </div>
            <div className="group bk-result-images bk-layout-images">
              {distinctBueroCodes.map((code, index) => {
                return (
                  <a
                    href="javascript:void(0);"
                    key={"grundriss-" + index + "-" + code}
                  >
                    <ImageZoom
                      image={{
                        src: require("./../../../../static/grundrisse/grundriss_" +
                          code +
                          ".png"),
                        alt: "Grundriss"
                      }}
                      zoomImage={{
                        src: require("./../../../../static/grundrisse/grundriss_" +
                          code +
                          ".png"),
                        alt: "Grundriss",
                        className: "zoomed-grundriss"
                      }}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {
    filters: state.wohnungList.filters,
    staticVariationOptions: state.staticVariations
      ? state.staticVariations[0].optionen
      : []
  };
}

export function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultDetail);
