import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NumberFormat } from "../../../lib/App/common/ValueFormatter";
import Translate from "../../../lib/App/common/translator/components/translate";

const StickyNoteArea = props => {
  const m2Num = (
    <strong>
      <NumberFormat value={Math.floor(props.flaeche)} /> m<sup>2</sup>
    </strong>
  );
  const staffNum = (
    <strong>
      <NumberFormat value={Math.floor(props.staff)} />
    </strong>
  );
  const translateFlaeche = (
    <Translate id={"StickyNote/Area/flaeche"} data={{ m2: m2Num }}>
      {"Gemäss Ihren Angaben benötigen Sie ca. ${m2}"}
    </Translate>
  );
  const translateStaff = (
    <Translate id={"StickyNote/Area/staff"} data={{ staff: staffNum }}>
      {
        "Gemäss Ihren Angaben benötigen Sie eine Bürofläche für ca. ${staff} Mitar­beiter."
      }
    </Translate>
  );

  const flaeche = <Fragment>{translateFlaeche}</Fragment>;

  const staff = <Fragment>{translateStaff}</Fragment>;

  return (
    <div
      id="bk-search-note-sticky-wrapper"
      className="sticky-wrapper"
      style={{ height: "60px" }}
    >
      <ul id="bk-search-note" className="group" style={{ bottom: "2vh" }}>
        <li>
          <p>
            <i
              className="fas fa-calculator fa-w-14 fa-lg"
              style={{ maxHeight: "50px" }}
            />
            {props.isAreaShown ? flaeche : staff}
          </p>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    flaeche: state.wohnungList.filters["search-area"],
    staff: state.wohnungList.filters["search-employee"],
    isAreaShown: state.wohnungList.filters.lastToggled === "staff" // if staff was selected we want to show how much space is needed
  };
};

export default connect(mapStateToProps)(StickyNoteArea);
