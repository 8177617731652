import React, { Fragment } from "react";
import VariationenSwitch, {
  BooleanVariation,
  SliderVariation,
  TextVariation,
} from "./Variationen";
import Translate from "../../../../lib/App/common/translator/components/translate";
import Image from "../../Image";
import ReactTooltip from "react-tooltip";

const buildLink = (text, link) => {
  return `
    <a href="${link}" style="color: blue; text-decoration: underline" target="_blank" rel="noopener noreferrer">${text}</a>
    `;
};

const replaceToLink = (value) => {
  const substring = "${link}";
  const index = value.indexOf(substring);

  if (index < 0) {
    return value;
  }

  let indexStart = index;
  let indexEnd = index;

  while (indexStart > 0) {
    if (value[indexStart] === " ") {
      indexStart += 1;
      break;
    }
    indexStart -= 1;
  }

  while (indexEnd < value.length) {
    if (value[indexEnd] === " ") {
      break;
    }
    indexEnd += 1;
  }

  const link = value.substring(index + substring.length, indexEnd);
  const text = value.slice(indexStart, index);

  return replaceToLink(
    value.replace(text + substring + link, buildLink(text, link))
  );
};

export function optionenSwitch(option, components, OwnOptionComponent) {
  const { BooleanOption, TextOption } = components;
  if (option.classes.indexOf("boolean") > -1) {
    return BooleanOption;
  } else if (~option.classes.indexOf("text")) {
    return TextOption;
  } else if (~option.classes.indexOf("info")) {
    return InfoOption;
  } else if (~option.classes.indexOf("number")) {
    // SPECIFIC FOR KFG-ONE
    if (option.show === 1) {
      return DoubleNumberOption;
    } else {
      return NumberOption;
    }
  } else if (OwnOptionComponent !== undefined) {
    return OwnOptionComponent;
  } else {
    return VariationenSwitch;
  }
}

export const flagTwoOptions = (op) => {
  const options = op;
  // SPECIFIC FOR KFG-ONE
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].show !== undefined) continue;

    for (let j = i + 1; j < options.length; j += 1) {
      if (
        options[i].name.indexOf("Parkplatz") > -1 &&
        options[j].name.indexOf("Parkplatz") > -1
      ) {
        options[i].show = 1;
        options[j].show = 2;
      } else if (options[i].name === options[j].name) {
        options[i].show = 1;
        options[j].show = 2;
      }
    }
  }

  return options;
};

const OptionenSwitch = (props) => {
  const options = flagTwoOptions(props.optionen);

  return (
    <Fragment>
      {props.optionen.map((option) => {
        const OptionComponent = props.switches.optionenSwitch(
          option,
          props.components,
          props.ownOptionComponent,
          options
        );

        if (OptionComponent && option.show === undefined) {
          return (
            <OptionComponent
              key={option.optionId}
              {...option}
              kategorieId={props.kategorieId}
              switches={props.switches}
              components={props.components}
              isReadOnly={props.isReadOnly}
              handleVariationChange={props.handleVariationChange}
              handleFrontendPropertyChange={props.handleFrontendPropertyChange}
            />
          );
        } else if (option.show === 1) {
          return (
            <OptionComponent
              key={option.optionId}
              options={options.filter((item) => {
                if (option.name.indexOf("Parkplatz") > -1) {
                  return item.name.indexOf("Parkplatz") > -1;
                }

                return item.name === option.name;
              })}
              kategorieId={props.kategorieId}
              switches={props.switches}
              components={props.components}
              isReadOnly={props.isReadOnly}
              handleVariationChange={props.handleVariationChange}
              handleFrontendPropertyChange={props.handleFrontendPropertyChange}
            />
          );
        }

        return null;
      })}
    </Fragment>
  );
};

export default OptionenSwitch;

export const Option = (props) => {
  const getDesc = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceToLink(props.beschreibung.replaceAll("${br}", "<br>")),
        }}
      ></div>
    );
  };

  const translateName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const desc = props.beschreibung ? getDesc() : "";

  const optionCodeImage =
    props.optionCode === "desk" ? "work" : props.optionCode;

  return (
    <div
      key={props.optionId}
      className="bk-options-thumbs group columns large-12 medium-12 small-12"
      style={props.style}
    >
      <div className="columns small-12">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2>{translateName}</h2>
        </div>
        <div style={{ paddingBottom: "1em" }}>{desc}</div>
      </div>
      {props.optionCode !== "miete" && !props.optionCode.startsWith("lager") ? (
        <>
          <div
            className="columns large-5 medium-5 small-12"
            style={{ paddingRight: "1.5em" }}
          >
            <Image
              src={`variationen/${optionCodeImage}`}
              alt={"IMAGE " + props.name}
              height="1000w"
              ratio="r1.6"
            />
          </div>
          <div className="columns large-7 medium-7 small-12">
            <VariationenSwitch
              {...props}
              kategorieId={props.kategorieId}
              switches={props.switches}
              components={props.components}
              isReadOnly={props.isReadOnly}
              handleVariationChange={props.handleVariationChange}
              handleFrontendPropertyChange={props.handleFrontendPropertyChange}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export const BooleanOption = (props) => {
  const variationen = props.variationen || [];
  const translateName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-radios group columns small-12"
      style={props.style}
    >
      <div className="columns small-12">
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className="columns large-5 medium-5 small-12">
        <Image
          src={`variationen/${props.optionCode}`}
          alt={"IMAGE " + props.name}
          height="1000w"
          ratio="r1.6"
        />
      </div>
      <div className="columns large-7 medium-7 small-12">
        <ul className="group">
          {variationen.map((item) => (
            <BooleanVariation key={item.variationId} {...props} {...item} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export const InfoOption = (props) => {
  const variation = props.variationen.length > 0 ? props.variationen[0] : {};
  const translateName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      key={props.optionId}
      className="bk-options-thumbs group columns small-12"
      style={props.style}
    >
      <div className="columns large-5 medium-5 small-12">
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className="columns large-7 medium-7 small-12">
        <h3>{variation.name || ""}</h3>
        <p>{variation.beschreibung || ""}</p>
      </div>
    </div>
  );
};

export const TextOption = (props) => {
  const text = props.text || {};
  const translateName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-radios group columns large-12 medium-12 small-12"
      style={props.style}
    >
      <div className="columns large-5 medium-5 small-12">
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className="columns large-7 medium-7 small-12">
        <TextVariation {...props} {...text} />
      </div>
    </div>
  );
};

export const NumberOption = (props) => {
  const displayTitle =
    props.optionCode.indexOf("move-nbr") < 0 &&
    props.optionCode.indexOf("move-box") < 0 &&
    props.optionCode.indexOf("work") < 0;

  const displayImage =
    props.optionCode.indexOf("parkplatz") < 0 && displayTitle;

  const number = props.number || {};

  const translateName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );
  const image = displayImage ? (
    <div
      className="columns large-5 medium-5 small-12"
      style={{ color: "#fff" }}
    >
      <Image
        src={`variationen/${props.optionCode}`}
        alt={"IMAGE " + props.name}
        height="1000w"
        ratio="r1.6"
      />
    </div>
  ) : null;

  const tooltipId = `tooltip-${Math.floor(Math.random() * 10000000)}`;
  const style = !displayTitle
    ? {
        borderTop: "none",
        paddingTop: 0,
      }
    : {};

  return props.number.max > 0 ? (
    <div
      className="bk-options-radios group columns large-12 medium-12 small-12"
      style={{ ...props.style, ...style }}
    >
      <div
        className={`columns small-12 ${displayImage ? "" : "large-5 medium-5"}`}
      >
        {displayTitle ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2>{translateName}</h2>
            {props.beschreibung ? (
              <>
                <span
                  className="icon"
                  data-for={tooltipId}
                  data-tip={props.beschreibung.replaceAll("${br}", "<br>")}
                  style={{ marginLeft: "0.5em" }}
                >
                  <i className="fa fa-info-circle fa-lg" aria-hidden="true"></i>{" "}
                </span>
                <ReactTooltip
                  id={tooltipId}
                  place="right"
                  type="dark"
                  effect="solid"
                  multiline
                  className="bk-tooltip"
                />
              </>
            ) : null}
          </div>
        ) : null}
        <div style={{ paddingBottom: "1em" }}>{translateDesc}</div>
      </div>
      {image}
      <div className="columns large-7 medium-7 small-12">
        <SliderVariation {...props} {...number} />
      </div>
    </div>
  ) : null;
};

export const DoubleNumberOption = (props) => {
  const [option1, option2] = props.options;

  const displayImage = option1.optionCode.indexOf("parkplatz") < 0;
  const translateDesc = option1.beschreibung ? (
    <Translate id={"optionen/" + option1.optionCode + "/beschreibung"}>
      {option1.beschreibung}
    </Translate>
  ) : (
    ""
  );
  const image = displayImage ? (
    <div
      className="columns large-5 medium-5 small-12"
      style={{ color: "#fff" }}
    >
      <Image
        src={`variationen/${option1.optionCode}`}
        alt={"IMAGE " + option1.name}
        height="1000w"
        ratio="r1.6"
      />
    </div>
  ) : null;

  return (
    <div
      className="bk-options-radios group columns large-12 medium-12 small-12"
      style={option1.style}
    >
      <div
        className={`columns small-12 ${displayImage ? "" : "large-5 medium-5"}`}
      >
        <h2>{displayImage ? option1.name : "Parkplatz"}</h2>
        <p>{translateDesc}</p>
      </div>
      {image}
      <div className="columns large-7 medium-7 small-12">
        <div className="columns small-12" style={{ paddingRight: "0" }}>
          <SliderVariation {...props} {...option1} {...option1.number} />
        </div>
        <div
          className="columns small-12 padding-right-40"
          style={{ marginTop: "2em" }}
        >
          <SliderVariation {...props} {...option2} {...option2.number} />
        </div>
      </div>
    </div>
  );
};
