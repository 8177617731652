import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import {
  NavBar,
  mapStateToProps,
  mapDispatchToProps
} from "../../../lib/App/components/NavBar";
import Translate from "../../../lib/App/common/translator/components/translate";

class Header extends NavBar {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };

    this.closeBurger = this.closeBurger.bind(this);
  }

  getLink(url, name) {
    if (url === undefined) return null;
    return (
      <li onClick={this.closeBurger}>
        <Link to={url}>{name}</Link>
      </li>
    );
  }

  getLogout(hasUser) {
    return hasUser ? (
      <li>
        <a onClick={this.handleLogoutClick}>
          <Translate id="NavBar/abmelden">Abmelden</Translate>
        </a>
      </li>
    ) : null;
  }

  closeBurger() {
    this.setState({ isActive: false });
  }

  render() {
    const isKonfigurator = this.isKonfigurator();
    const hasUser = this.hasUser();
    const isMakler = this.isMakler(hasUser);

    // not logged in
    const register = this.getRegister(hasUser);
    const login = this.getLogin(hasUser);

    const konfigurations = this.getKonfigurations(hasUser);
    const maklerKonfigurations = this.getMaklerKonfigurations(isMakler);

    const profil = this.getProfil(hasUser);
    const logout = this.getLogout(hasUser);
    const unsaved = this.getUnsaved(hasUser, undefined, false);
    const backToKonfigurator = this.getBackToKonfigurator(isKonfigurator);
    const activeLanguageCode = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : false;

    return (
      <header className={"group row"}>
        <Logo />
        <LangSwitch
          setLang={this.props.setActiveLanguage}
          activeLang={activeLanguageCode}
        />
        <MobileBurger />
        <ul className="group navtop">
          {backToKonfigurator}
          {unsaved}
          {login}
          {register}
          {konfigurations}
          {maklerKonfigurations}
          {profil}
          {logout}
        </ul>
      </header>
    );
  }
}

class Logo extends Component {
  render() {
    return (
      <div className="columns large-12 medium-12 small-12">
        <Link
          to={{
            pathname: process.env.REACT_APP_EXTERNAL_HOMEPAGE_URL
              ? process.env.REACT_APP_EXTERNAL_HOMEPAGE_URL
              : "https://www.workone.ch/"
          }}
          target="_blank"
          id={"logo"}
        >
          <img
            src={require("./../../../style/assets/site/pics/kfg_logo.svg")}
            alt="One Bürokonfigurator"
          />
        </Link>
      </div>
    );
  }
}

const LangSwitch = ({ setLang, activeLang }) => {
  return (
    <ul className="group navlang">
      <li className={activeLang === "default" ? "active" : ""}>
        <Link
          to={"/"}
          onClick={e => {
            e.preventDefault();
            setLang("default");
          }}
        >
          DE
        </Link>
      </li>
      <li className={activeLang === "en" ? "active" : ""}>
        <Link
          to={"/"}
          onClick={e => {
            e.preventDefault();
            setLang("en");
          }}
        >
          EN
        </Link>
      </li>
    </ul>
  );
};

class MobileBurger extends Component {
  render() {
    return (
      <a className="group hamburger hamburger--squeeze">
        <div className="hamburger-box">
          <div className="hamburger-inner" />
        </div>
      </a>
    );
  }
}

export default withLocalize(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
);
