import React from "react";
import PropTypes from "prop-types";
import { SearchGroupListEntry } from "../SearchGroupList";
import { InfoButton } from "./../../../../../App/common/InfoButton/InfoButton";

const SearchLayoutGroup = props => {
  return (
    <SearchGroupListEntry>
      <label className="label" htmlFor={props.id}>
        <input
          type="radio"
          name="bk_search_layout"
          id={props.id}
          value={props.value}
          checked={props.isChecked ? "checked" : ""}
          onChange={event => props.clicked(event.target.value)}
        />
        <InfoButton
          optionDesc={props.optionDesc}
          optionTooltip={props.optionTooltip}
          tooltipToLeft={props.tooltipToLeft}
        >
          <span className="radio-check" />
        </InfoButton>
      </label>
    </SearchGroupListEntry>
  );
};

SearchLayoutGroup.propTypes = {
  id: PropTypes.string.isRequired,
  optionDesc: PropTypes.node.isRequired,
  optionTooltip: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired
};

export default SearchLayoutGroup;
